

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import Select from 'react-select';


const baseUrl = "https://myghschool.myghschools.com/school";

function EditDeptMember() { 

  useEffect(()=>{
		document.title = "MGS | Dashboard";
	});

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [departments, setDepartments] = useState([]);
  const [olddepartments, setOldDepartments] = useState([]);
  const departmentsOptions = departments.map(department => ({ value: department.id, label: department.name }));
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    position: "",
    image: null,
    school: selectedSchool, //this is toe ensure that school is selected to be associated with the subject
    department: [],
  });


  const goBack = () => {  
    navigate(-1);
  };
  const [ setError] = useState(null);
  

  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
         axios.get(`${baseUrl}/schools/${selectedSchool}/departments/`,{
        headers: {
        
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDepartments(res.data);
      }
        )
      
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken]);



  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
      axios.get(`${baseUrl}/schools/${selectedSchool}/departmentmembers/${id}`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
       
      })
      .then((res) => {
        setFormData({
          ...res.data,
         department: res.data.department.map(department => department.id), // map over the course objects to get an array of IDs
  
        });
        setOldDepartments(res.data);
      })
      
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id]);
  

  const handleSelectChange = (selectedOptions) => {
    setFormData({ ...formData, department: selectedOptions ? selectedOptions.map(option => option.value) : [] });
}


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setFormData({ ...formData, [name]: e.target.files[0] || null });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("position", formData.position);

    if (formData.image && formData.image.name) { // Check if the image file is an object with a name property
        formDataObj.append("image", formData.image);
      } else if (typeof formData.image === 'string') { // If the image file is a string (URL), don't append it
        // Do nothing
      } else if (formData.image) { // If the image file is a Blob without a name property
        formDataObj.append("image", formData.image, 'image.jpg'); // Add a filename
      }
    formDataObj.append("school", formData.school); //school field
    formData.department.forEach((departmentId) => {
      formDataObj.append('department', departmentId);
    });

    try {
      const token = accessToken
      const response = await axios.patch(`${baseUrl}/schools/${selectedSchool}/departmentmembers/${id}/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      //... existing submit logic
      Swal.fire({
        icon: "success",
        title: "Department Member Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      goBack();
      
    } catch (err) {
      if (err.response.status === 400) {
      setError("Name already exist. Please try again.");
    }
    else{
      setError("Something went wrong. Please try again later.");
    }
  }
  }; 
 
  if (isLoading) {
    return <h2>Loading...</h2>; 
  }

  const departmentNames = olddepartments?.department?.map(dept => dept?.name);

  const departmentNamesStr = departmentNames ? departmentNames.join(', ') : '';



  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit {olddepartments && olddepartments?.name} of <span className="text-red">{ departmentNamesStr}</span></h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Department</button><br/><br/>
          <form onSubmit={handleSubmit}>
          <label className="edit-content mb-1 col-6 "><strong>Select new Department</strong></label>
                <Select 
                    id="department" 
                    isMulti 
                    onChange={handleSelectChange} 
                    options={departmentsOptions} 
                    className="edit-content col-6"
                /> 
                <br></br>
                <div className="row">
                    <div className="edit-content form-group col-md-10 col-4">
                    <label className="edit-content mb-2 col-4 "><strong style={{color:'red'}}>Department of Member</strong></label>
                {olddepartments?.department && olddepartments?.department?.length > 0 ? (
                    
                    <div className="edit-content row col-6">
                    {olddepartments?.department?.map((department, index) => (
                       
                        <div key={index} className="col-2 edit-content">
                        <p><i className="fa-solid fa-square-check "> {department?.name}</i></p>
                        </div>
                    ))}
            
                    </div>
                ) : (
                    <p>No associated department.</p>
                )}

                    </div>
                </div>

            <div className="form-group col-6 edit-content"><br></br>
              <label className="edit-content mb-2 col-4 "><strong>Name</strong></label>
               <input type="text" id="name" name="name" value={formData?.name} onChange={handleChange} className="form-control"  />
            </div><br></br>
            <div className="form-group col-6">
               <label className="mb-1 "><strong>Position</strong></label>
               <input type="text" id="position" name="position" value={formData?.position} onChange={handleChange} className="form-control" />
             </div><br></br>
       
                 <label className="btn btn-success col-lg-6 mb-2 edit-content" >
                {formData?.image ? formData?.image?.name : "Upload Member Image"}
                 <input type="file" id="image" name="image" onChange={handleChange} className="d-none" />
                 <div className="edit-content">
                {formData?.image && (
                typeof formData?.image === 'string'
                    ? <img src={formData?.image} alt="Existing" style={{ width: '100%', height: '100px' }} />
                    : <img src={URL.createObjectURL(formData?.image)} alt="Uploaded" style={{ width: '100px', objectFit: 'contain'}} />
                )} </div></label> <br></br>
                <Button variant="primary" type="submit">
                    Update
                </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditDeptMember;

