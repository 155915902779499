import React, { useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../axios/axios";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function AddNews() {

    const { accessToken } = useAuth();
    let navigate = useNavigate();

    const [formData, setFormData] = useState({
        title: "",
        story: "",
        author: "",
        category:"",
        image2: null,
        featured_image: null,

      });

      const goBack = () => {  
        navigate(-1);
      };
      
  const [setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (["image2", "featured_image"].includes(name)) {
      setFormData({ ...formData, [name]: e.target.files[0] });
     
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
  formDataObj.append("title", formData.title);
  formDataObj.append("story", formData.story);
  formDataObj.append("author", formData.author ? formData.author : "Anonymous");
  // formDataObj.append("author", formData.author);
  formDataObj.append("category", formData.category);
  if (formData.featured_image) {
    formDataObj.append("featured_image", formData.featured_image);
}

if (formData.image2) {
  formDataObj.append("image2", formData.image2);
}


    try {
     
    const token = accessToken
      const response = await axiosInstance.post("https://myghschool.myghschools.com/schoolblog/", formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
     
          "Authorization": `Bearer ${token}`,
         
        },
        });

      if (response.status === 201) {
        setFormData({ name: "", description: "", image: null });
        setError(null);

      if (response.status === 201) {
        setFormData({
            title: "",
            story: "",
            author: "",
            category: "",
            image2: null,
            featured_image: null,
        });
        setError(null);

      Swal.fire({
        icon: "success",
        title: "News Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    goBack();
}
    } catch (err) {
    setError("Error submitting the form. Please try again.");
}

  };

  return (

    <>
    <Link to="/admin/allnews"><button className="btn btn-primary btn-sm">Back to News Board</button></Link><br/><br/>
    <div className="authincation ">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-md-8">
						<div className="authincation-content">
							<div className="row no-gutters">
								<div className="col-xl-12">
									<div className="auth-form">
										
							<h4 className="text-center mb-4 ">Write a beautiful and authentic story</h4>
                             <form onSubmit={handleSubmit}>
                                <div className="row">
                                <div className="col-lg-6 mb-3">
                                    <div className="form-group mb-3">
                                        <label className="text-label"><strong>Title<span style={{color: 'red'}}>*</span></strong></label>
                                        <input type="text" name="title" value={formData.title} onChange={handleChange} className="form-control" required placeholder="Please Enter title of the story"
                                        />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_level"><strong>Category<span style={{color: 'red'}}>*</span></strong></label>
                                        <select id="school_level" name="category" value={formData.category} onChange={handleChange} className="form-select mb-3" required>
                                            <option disabled>Choose... </option> 
                                            <option value="Entertainment">Entertainment</option>
                                            <option value="Sports">Sports</option>
                                            <option value="Politics">Politics</option>
                                            <option value="Health">Health</option>
                                            <option value="Love & Relationship">Love & Relationship</option>
                                            <option value="Business">Business</option>
                                            <option value="Technology">Technology</option>
                                        </select>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                    <div className="form-group mb-3">
                                        <label className="text-label"><strong>Author<span style={{color: 'red'}}>&nbsp;*Empty means Anonymous*</span></strong></label>
                                        <input type="text" name="author" value={formData.author} onChange={handleChange} className="form-control" placeholder="Please enter your name"
                                        />
                                    </div>
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="story"><strong>Story<span style={{color: 'red'}}>*</span></strong></label>
                                   <ReactQuill theme="snow" value={formData.story} onChange={(content) => setFormData({ ...formData, story: content })} />
                                    </div>
                                    </div>
                                      <label className="btn btn-success w-100" >
                                      {formData.featured_image ? formData.featured_image.name : "Upload Featured Image"}
                                      <input type="file" id="featured_image" name="featured_image" onChange={handleChange} className="d-none" />
                                      </label>
                                      {formData.featured_image && (
                                      typeof formData.featured_image === 'string'
                                          ? <img src={formData.featured_image} alt="Existing" style={{ width: '100px', height: '100px' }} />
                                          : <img src={URL.createObjectURL(formData.featured_image)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
                                      )}
                                    <label className="btn btn-success w-100" >
                                      {formData.image2 ? formData.image2.name : "Upload News Image"}
                                      <input type="file" id="image2" name="image2" onChange={handleChange} className="d-none" />
                                      </label>
                                      {formData.image2 && (
                                      typeof formData.image2 === 'string'
                                          ? <img src={formData.image2} alt="Existing" style={{ width: '100px', height: '100px' }} />
                                          : <img src={URL.createObjectURL(formData.image2)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
                                      )}
                                </div>
		
                                <div className="text-center mt-4">
                                    <button type="submit" className="btn btn-primary btn-block">Create News</button>
                                </div>
							          </form>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
</>

  );
}
export default AddNews;

