
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import useAuth from "../../../hooks/useAuth";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import axios from "../../../axios/axios";


const baseUrl="https://myghschool.myghschools.com/user";
const SideBar = () => {
    const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);
    const {userId, accessToken} = useAuth();
    const [user, setUser] = useState(null); 
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        var btn = document.querySelector(".nav-control");
        var aaa = document.querySelector("#main-wrapper");
        function toggleFunc() {
            return aaa.classList.toggle("menu-toggle");
        }
        btn.addEventListener("click", toggleFunc);

          // Cleanup function
    return () => {
        btn.removeEventListener("click", toggleFunc);
    }
    }, []);

    useEffect(() => {
		const fetchData = async () => {
			if (userId) {
		  try {
			const response = await axios.get(`${baseUrl}/users/${userId}`, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			})
            .then(response => {
                setUser(response.data);
                setLoading(false);
              })

		  } catch (error) {
		  }
     
		}
		};
		fetchData();
	  }, [userId, accessToken]);


     //For scroll
 const [hideOnScroll, setHideOnScroll] = useState(true)
 useScrollPosition(
     ({ prevPos, currPos }) => {
       const isShow = currPos.y > prevPos.y
       if (isShow !== hideOnScroll) setHideOnScroll(isShow)
     },
     [hideOnScroll]
 )

 if (loading) {
    return <div>Loading...</div>;
}

    return (
        <div
            className={`dlabnav ${iconHover} ${
                sidebarposition.value === "fixed" &&
                sidebarLayout.value === "horizontal" &&
                headerposition.value === "static"
                  ? hideOnScroll > 120
                    ? "fixed"
                    : ""
                    : ""
                }`}
        >          
             <PerfectScrollbar className="dlabnav-scroll">
            <div className="dlabnav-scroll">
                <ul className="metismenu" id="menu">
                    <li>
                        <Link to={`/admin/dashboard/${userId}`}>
                            <i className="bi bi-grid"></i>
                            <span className="nav-text">Dashboard Light</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/change-password">
                        <i className="fa-solid fa-lock"></i>
                            <span className="nav-text">Change Password</span>     
                        </Link>

                    </li>
                    <li>
                        <Link to={`/admin/dashboard-dark/${userId}`}>
                            <i className="bi bi-grid"></i>
                            <span className="nav-text">Dashboard Dark</span>
                        </Link>
                    </li>
                {user.is_staff ? (
                     <li>
                     <Link to={`/admin/adminprofile/${userId}`}>
                     <i className="fa-regular fa-user"></i>
                         <span className="nav-text">Profile</span>
                     </Link>
                 </li> 
                ) : (
                    <li>
                    <Link to={`/admin/userprofile/${userId}`}>
                        <i className="bi bi-grid"></i>
                        <span className="nav-text">Profile</span>
                    </Link>
                </li>
                )
                      }

                {user.is_staff ? (
                      <li>
                      <Link to="/admin/admins">
                      <i className="fa-solid fa-user-group"></i>
                          <span className="nav-text">Admins</span>
                      </Link>
                  </li>
                ) : null
                 }   
                  
                  {user.is_staff ? (
                    <li>
                        <Link to="/admin/allnews">
                        <i className="fa-regular fa-newspaper"></i>
                            <span className="nav-text">News </span>
                        </Link>
                    </li>
                    ) : null
                        } 
                {user.is_staff ? (    
                    <li>
                        <Link to="/admin/allschools">
                        <i className="fa-solid fa-school"></i>
                            <span className="nav-text">Schools</span>
                        </Link>
                    </li>
                    ) : null}

                    <li>
                        <Link to="/admin/addschool">
                        <i className="fa-solid fa-school"></i>
                            <span className="nav-text">Add School</span>
                        </Link>
                    </li>
                    {user.is_staff ? ( 
                    <li>
                        <Link to="/admin/users">
                        <i className="fa-solid fa-users"></i>
                            <span className="nav-text">Users</span>
                        </Link>
                    </li>
                        ) : null}
                </ul>

                <div className="copyright">
                    <p><strong>My Ghana Schools</strong> © 2023 All Rights Reserved</p>
                    <p className="fs-12">Made with <span className="heart"></span> by Noble Technology Solution Center</p>
                </div>
            </div>
            </PerfectScrollbar>
        </div>
    );
};

export default SideBar;
