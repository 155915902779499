import React, {useContext, useState} from 'react'
import { Link } from 'react-router-dom'
import { SchoolContext } from '../../../AuthContext/SchoolProvider'
import SchoolNavbar from '../../layouts/SchoolNavbar';
import { Dropdown, Modal, Carousel } from "react-bootstrap";
import useAuth from '../../../hooks/useAuth'
import { useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import parse from 'html-react-parser'

const baseUrl = 'https://myghschool.myghschools.com/school'
const HealthFacility = () => {

    const { selectedSchool } = useContext(SchoolContext);

	const {accessToken} = useAuth();
	const [healthfacilitys, setHealthFacility] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	useEffect(() =>{
	axios.get(`${baseUrl}/schools/${selectedSchool}/healthfacility`).then((response)=>{

		setHealthFacility(response.data);
	});

	},[selectedSchool]);

	const handleDelete = async (healthfacilityId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this Health Facility Data!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/contacts/${healthfacilityId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(healthfacilitys)){
					const newHealthfacilitys = healthfacilitys.filter(healthfacility => healthfacility.id !== healthfacilityId);
					setHealthFacility(newHealthfacilitys);
				} 
				swal('Good job!', 'Successfully Deleted', "success");
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your Health Facility data is safe!");
		  }
		});
	  }
  
    const closeModal = () => setShowModal(false);
      
    const images = healthfacilitys.length > 0 ?[
        healthfacilitys[0]?.mainImage1,
        healthfacilitys[0]?.mainImage2,
        healthfacilitys[0]?.mainImage3,
        healthfacilitys[0]?.mainImage4,
      ]: [];// replace with your actual image URLs


      return(
		<React.Fragment>
		  <SchoolNavbar />
          <div className="container">
		  {healthfacilitys && healthfacilitys?.length === 0 ? (
               <div className="widget-heading d-flex justify-content-between align-items-center">
               <h3 className="m-0">School Health Facility Details</h3>
               <Link to={`/admin/addhealthfacility/${selectedSchool}`} className="btn btn-primary btn-sm">Add Health Facility</Link>
             </div> 
             ):(
         <div className="row">
          <div className="col-md-12">
            <div className="card">
            <div className="card-header justify-content-between" style={{fontSize:'1.5em'}}>
                    <div>
                    <strong>School Health Facility</strong>
                    </div>
                <div className="mt-2 mt-6">
                            <Dropdown className="dropdown ms-auto">
                <Dropdown.Toggle
                  variant="primary"
                  className="btn btn-primary light sharp i-false"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                    </g>
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                  <Dropdown.Item as="div" className="dropdown-item">
                          <i className="fa-solid fa-pen-to-square text-primary me-2"></i>
                  <Link to={`/admin/edithealthfacility/${healthfacilitys[0]?.id}`}>
                    Edit Health Facility
                  </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" className="dropdown-item">
                          <i className="fa-solid fa-trash text-primary me-2"></i>
                   <Link to="#" onClick={() => handleDelete(healthfacilitys[0]?.id)}> Delete Health Facility </Link>
                  </Dropdown.Item>
         
                </Dropdown.Menu>
              </Dropdown>						
            </div>
          </div>
          <div className="card-body">
            <h4><span style={{color:'red'}}>Facility Name:</span> {healthfacilitys[0]?.name}</h4>
            <h4><span style={{color:'red'}}>Description:</span> {parse(healthfacilitys[0]?.description)}</h4>
            <div className="row">
                  {images.map((image, index) => (
                <div className="col-xl-4 col-md-6" key={index}>
                  <div className="card all-crs-wid">
                    <div className="card-body">
                      <div className="courses-bx">
                        <div className="dlab-media">
                                    <img 
                                            src={image} 
                                            alt="" 
                                            onClick={() => {
                                                setSelectedImageIndex(index);
                                                setShowModal(true);
                                            }}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                     ))}
					
					                <div className="col-xl-4 col-md-6">
                            <div className="card all-crs-wid">
                                <div className="card-body">
                                {healthfacilitys[0]?.video && (
                                <div className="courses-bx">
                                {/* For video */}
                                        <video width="320" height="240" controls className="col-12">
                                            <source src={healthfacilitys[0]?.video} />
                                            Your browser does not support the video tag.
                                        </video>
                                </div>
                                 )}
                                </div>
                              
                            </div>
                            </div>
                            <Modal size="lg" show={showModal} onHide={closeModal} className="col-lg-12">
                                <Modal.Header closeButton >
                                    <Modal.Title>Health Facility Images</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="card-body pb-1">
                                    <Carousel activeIndex={selectedImageIndex} onSelect={setSelectedImageIndex}>
                                        {images && images.map((image, index) => (
                                            <Carousel.Item key={index}>
                                                <img className="col-lg-3 col-md-6 mb-4 "
                                                    src={image} alt="Slide" style={{ width:"100%", height: "300px", objectFit: "contain"}}
                                                />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Modal.Body>
                            </Modal>
			            </div>	
                        </div>
                        </div>
                    </div>
                    </div>
               )}
            </div>   
        </React.Fragment>
	);
};

export default HealthFacility


