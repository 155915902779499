

import React from 'react'
import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const PersistLogin = () => {

    const [loading, setLoading] = useState(true);
    const refresh = useRefreshToken();
    const { accessToken, setUser, setUserId } = useAuth();
    const axiosPrivate = useAxiosPrivate();


    useEffect(() => {

        let isMounted = true;

        async function verifyUser(){
            try{
                await refresh();
                const { data } = await axiosPrivate.get(`user/users/`);
                if (data && data.user) { // Ensure that the user object exists in the response data.
                    setUser(data);
                    setUserId(data.user.id); // Set the user ID.
                }
                setUser(data);
            }
            catch(error){
        
            }
            finally{
               isMounted && setLoading(false);
            }
        }

        !accessToken ? verifyUser() : setLoading(false);
        return () => { 
            isMounted = false 
        };
        }, []);

  return (

    <>
        {loading ? <h1>Loading...</h1> : <Outlet />}
    </>
  )
}

export default PersistLogin;



