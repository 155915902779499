import React,{useState, useEffect} from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logout from './Logout';

import axios from "../../../axios/axios";
import  useAuth  from "../../../hooks/useAuth";


const Header = () => {

	const {userId, accessToken } = useAuth();
	const [user, setUser] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			if (userId) {
		  try {
			const response = await axios.get(`user/users/${userId}`, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
			
			setUser(response.data);
		  } catch (error) {

		  }
		};
		};
		fetchData();
	  }, [userId, accessToken]);

	const [headerFix, setheaderFix] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []); 
	
  return ( 
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content card">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
				<div
					className="dashboard_bar"
					style={{ textTransform: "capitalize" }}
				  >
							{user?.admin?.gender === "Male" || user?.owner?.gender === "Male" ? (
								<span>
									<span style={{fontSize:'0.6em'}}>Welcome &nbsp;&nbsp;</span>
								{`Mr. ${user.first_name} ${user.last_name}`}
								</span>
							) : (
								`Mrs. ${user?.first_name} ${user?.last_name}`
							)}
				</div>
            </div>
			<div className="navbar-nav header-right">
				<div className="nav-item d-flex align-items-center">
					<div className="input-group search-area">
					</div>					
				</div>
				<div className="dlab-side-menu">
					
					<div className="sidebar-social-link ">
						
					</div>
					<ul>
						<Dropdown as="div" className="nav-item dropdown header-profile">
							<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
								{user?.admin ? (
									<img src={user?.admin?.admin_image} width={20} alt="" />
								) : (
									<img src={user?.owner?.owner_image} width={20} alt="" />
								)}
								
							</Dropdown.Toggle>
							<Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-end">
							{user?.is_staff ? (
									<li>
									<Link to={`/admin/adminprofile/${userId}`} className="dropdown-item ai-icon">
									<svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1" width={18} height={18} viewBox="0 0 24 24" fill="none"
										stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
									>
										<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
										<circle cx={12} cy={7} r={4} />
									</svg>
										<span className="nav-text">Profile</span>
									</Link>
								</li> 
								) : (
									<li>
									<Link to={`/admin/userprofile/${userId}`} className="dropdown-item ai-icon">
									<svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1" width={18} height={18} viewBox="0 0 24 24" fill="none"
										stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
									>
										<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
										<circle cx={12} cy={7} r={4} />
									</svg>
										<span className="nav-text">Profile</span>
									</Link>
								</li>
								)}
								<Logout />
							</Dropdown.Menu>
						</Dropdown> 	
					</ul>
					
				</div>

			</div>
			
			
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;


