import { createContext, useState, useEffect } from "react";
import { axiosPrivateInstance } from "../axios/axios";


const AuthContext = createContext({
    user:{},
    setUser: () => {},
    accessToken: null,
    refreshToken: null,
    csrfToken: null,
    setAccessToken: () => {},
    setRefreshToken: () => {},
    setCSRFToken: () => {},
});


export const AuthProvider = (props) => {
    
    const [user, setUser] = useState(() => {
        // Get the user from local storage on initial load
        const localUser = localStorage.getItem('user');
        return localUser ? JSON.parse(localUser) : {};
      });
    const [accessToken, setAccessToken] = useState();
    const [refreshToken, setRefreshToken] = useState();
    const [csrfToken, setCSRFToken] = useState();
    const [userId, setUserId] = useState(localStorage.getItem('userId') || null);

    
    useEffect(() => {
        // Save the user to local storage whenever it changes
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
        } else {
          localStorage.removeItem('user');
        }
      }, [user]);
  
      const setAndPersistUser = (newUser) => {
          setUser(newUser);
          if (newUser) {
              localStorage.setItem('user', JSON.stringify(newUser));
          } else {
              localStorage.removeItem('user');
          }
      };


    const logout = async () => {
        try {

            const response = await axiosPrivateInstance.post("user/logout/")
            setAndPersistUser(null);
            setAccessToken(null)
            setCSRFToken(null)           
            localStorage.removeItem('userId');    
        }
        catch (error) {
            console.log(error)
        }
    }

    const setAndPersistUserId = (newUserId) => {
        if (newUserId === undefined) {

            return;
        }
        setUserId(newUserId);
        localStorage.setItem('userId', newUserId);
    };
    

    useEffect(() => {
        const localUserId = localStorage.getItem('userId');
        if (localUserId) {
            setUserId(localUserId);
        }

    }, []);

    return(
        <AuthContext.Provider value={{ 
            user, setUser,
            accessToken, setAccessToken,
            refreshToken, setRefreshToken,
            csrfToken, setCSRFToken,
            userId, setAndPersistUserId , 
            logout
        }}>
            {props.children}

        </AuthContext.Provider>
    )
}

export default AuthContext;

