import React, { useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const { id } = useParams();

    let errorsObj = { email: ''};

    const handleSubmit = async (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        try {
            const response = await axios.put(`https://myghschool.rubykaryo.com/user/${id}/new-password/`,{      
               password: password,
               password1: password1,
            });
            setSuccess(response.data.detail);
            setError('');
            window.location.href = '/login';
        } catch (error) {
            if(error.response){
            setError(error.response.data.error);

            }else if(error.request){
                setError(error.request);
        }else{
            setError(error.message);
        }
    }
    };

    return (
     
          <div className="login-main-page" style={{backgroundImage:"url("+  +")", backgroundSize:'cover'}}>
          <div className="login-wrapper">
              
              <div className="container h-100">
                  <div className="row h-100 align-items-center justify-contain-center">
                      <div className="col-xl-12 mt-3">
                          <div className="card">
                              <div className="card-body p-0">
                                  <div className="row m-0">
                                      <div className="col-xl-6 col-md-6 sign text-center">	
                                      <Link to="/">
                                      <img src="/assets1/img/logo/MYGHLOGO.png" alt="logo"  />	</Link>							
                                      </div>
                                      <div className="col-xl-6 col-md-6">
                                        
                                          <div className="sign-in-your">
                                              <h4 className="fs-20 font-w800 text-black">Reset Password </h4>
                                              Please enter new password<br/><br />
                                              {success && <p style={{color: 'green'}}>{success}</p>}
                                              {error && <p style={{color:"red"}}>{error}</p>}
                                              <form onSubmit={handleSubmit}>
                                                  <div className="mb-3">
                                                      <label className="mb-1"><strong>Enter New Password</strong></label>
                                                      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required className="form-control" placeholder="Please enter new password"/>
                                                  </div>
                                                  <div className="col-lg-6 mb-2">
                                                    <label>Confirm New Password:</label>
                                                    <input type="password" className="form-control" value={password1} onChange={e => setPassword1(e.target.value)} placeholder="Please Confirm password" required/>
                                                </div>
                                                   
                                                  <div className="text-center">
                                                      <button type="submit" className="btn btn-primary btn-block">Reset Password</button>
                                                  </div>
                                              </form>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    
    );
}

export default ResetPassword;
