import React,{useState, useEffect, useContext}  from 'react';
import {Link} from 'react-router-dom';
import {Tab, Nav} from 'react-bootstrap';
import axios from 'axios';
import SchoolNavbar from '../../layouts/SchoolNavbar';
import { useParams, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from '../../../hooks/useAuth';
import { Dropdown, Modal, Carousel } from "react-bootstrap";
import parse from 'html-react-parser';


const baseUrl='https://myghschool.myghschools.com/school';

const ViewGallery =()=> {

	const [gallery, setGalleries] = useState([]);
	const { selectedSchool } = useContext(SchoolContext);
	const {id} = useParams();
	const {accessToken} = useAuth();
	const navigate = useNavigate();

	const [showModal, setShowModal] = useState(false);
    const [carouselImageIndex, setCarouselImageIndex] = useState(0);
	

	const openModal = (index) => {
        setCarouselImageIndex(index);
        setShowModal(true);
    };

    const closeModal = () => setShowModal(false);

	const goBack= () =>{
		navigate(-1);
	}

	useEffect(() => {
		const fetchGallery = async () => {
		  try {
			const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/galleries/${id}`);
	        setGalleries(response.data);
		  } catch (error) {
		  }
		};
	
		fetchGallery();
	  }, [id, selectedSchool]);


	  const handleDelete = async (galleriesId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this Gallery!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/galleries/${galleriesId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(gallery)){
					const newGalleries = gallery.filter(gallery => gallery.id !== galleriesId);
					setGalleries(newGalleries);
				}
				swal('Good job!', 'Successfully Deleted', "success");

				goBack();
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your Gallery data is safe!");
		  }
		});
	  }


	  const determineMimeType = (url) => {
		if (url.endsWith('.mp4')) return 'video/mp4';
		if (url.endsWith('.webm')) return 'video/webm';
		if (url.endsWith('.avi')) return 'video/ogg';
		if (url.endsWith('.mov')) return 'video/mov';
		
		return 'video/*'; // fallback to a general video MIME type
	}

	return(
		<>
				
		<SchoolNavbar/>
		<div className="col">
		<h2>Gallery Details</h2>
		<button className="btn btn-primary btn-xs mb-4" onClick={() => goBack()}>Back</button>
		</div>
			<div className="row">
				<div className="col-xl-8 col-xxl-7">
					<div className="card">
						<div className="card-body">
							<div className="course-content d-flex justify-content-between flex-wrap">
								<div>
									<h3><span style={{fontSize:'14px', color:'red'}}>Gallery Title:</span> {gallery.title}</h3>
									<ul className="d-flex align-items-center raiting my-0 flex-wrap">
										<li><span className="font-w500" style={{color:'red'}}>Date : </span>{new Date(gallery.galdate).toLocaleDateString()}</li>
									
									</ul>
								</div>
								<div className="mt-2">
								<Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fa-solid fa-pen-to-square text-primary me-2"></i>
					  <Link to={`/admin/editgallery/${id}`}>
                        Edit Gallery
						</Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fa-solid fa-trash text-primary me-2"></i>
                       <Link to="#" onClick={() => handleDelete(gallery.id)}> Delete Gallery </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>						
								</div>
							</div>
							<div className="video-img style-1">
								<div className="view-demo">
									<img src={gallery.mainImage} alt="" />
							
								</div>	
							</div>
							<Tab.Container defaultActiveKey='About'>
								<div className="course-details-tab style-2 mt-4">
									<nav>
										<Nav as='div' className="nav nav-tabs tab-auto" id="nav-tab">
											<Nav.Link as='button' className="nav-link" id="nav-about-tab" eventKey='About' type="button">Gallery Description</Nav.Link>
										</Nav>
									</nav>
									<Tab.Content className="tab-content" id="nav-tabContent">
										<Tab.Pane  id="nav-about" eventKey='About'>
											<div className="about-content">
												<h4>What the gallery is all about</h4>
												{gallery.description && typeof gallery.description === 'string' ? parse(gallery.description) : null}
											</div>
										</Tab.Pane>	<br/>								
									</Tab.Content>
								</div>
							
							</Tab.Container>	
						</div>	
					</div>
				</div>
				<div className="col-xl-4 col-xxl-5">
		
					<div className="custome-accordion">
						<div className="card" >
						<div className=" pt-0">
						<h2 className="accordion-header border-0">
							<span className="acc-heading">Gallery Images</span>
							</h2>
							{gallery.galleryimages && gallery.galleryimages.map((galleries, i) => (
							    <div key={galleries.id} className="accordion-body card-body pt-0">
								<div key={i} className="acc-courses">
									<div className="d-flex justify-content-between align-items-center">
										<div className="d-flex align-items-center">
											<span className="acc-icon">
							
											</span>
											<img src={galleries.image}  alt="achievement" onClick={() => openModal(i)} style={{cursor:'pointer', width:'100%', objectFit: 'cover' }}/>
								
										</div>
									</div>
								</div>
								</div>
						
							))}
							</div>
							</div>
					</div>						
				</div>
                </div>
				<Modal size="lg" show={showModal} onHide={closeModal} className="col-lg-12">
                    <Modal.Header closeButton >
                        <Modal.Title> Gallery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="card-body pb-1">
                        <Carousel activeIndex={carouselImageIndex} onSelect={setCarouselImageIndex}>
                            {gallery.galleryimages && gallery.galleryimages.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <img className="col-lg-3 col-md-6 mb-4 "
                                        src={image.image} alt="Slide" style={{ width:"100%", objectFit: 'cover' }}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Modal.Body>
                </Modal>
		</>
	)
}
export default ViewGallery;

