


import React, { useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";

function AddBoardMember() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [backendErrors, setBackendErrors] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    image: null,
    school: selectedSchool,  //assuming there's a school field in the course model
  });

  const goBack = () => {  
    navigate(-1);
  };

  const [ setError] = useState(null);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setFormData({ ...formData, image: e.target.files[0] });
    }
    else {
      setFormData({ ...formData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("position", formData.position);
    if (formData.image) {
        formDataObj.append("image", formData.image);
    }
    formDataObj.append("school", formData.school); //school field


    try {
      const token = accessToken
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/boardofdirectors/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
        
      });

      Swal.fire({
        icon: "success",
        title: "Board of Director Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      
      goBack();
    } catch (err) {
      if (err) {
      setBackendErrors(err);
      setError("Something went wrong. Please try again later.");
    }
  }

  };
  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Board Member</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Board Members page</button><br/><br/>
          <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Name</strong></label>
              {backendErrors?.response?.data?.name && backendErrors?.response?.data?.name === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Title of donation required</div>: null}
               <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-control" required />
            </div>
            </div>

            <div className="col-lg-6 mb-2">
            <div className="form-group">
               <label className="mb-1 "><strong>Position</strong></label>
                 {backendErrors?.response?.data?.position && backendErrors?.response?.data?.position=== "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Title of donation required</div>: null}
               <input type="text" id="position" name="position" value={formData.position} onChange={handleChange} className="form-control" required/>
             </div>
             </div><br/>
             <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.image ? formData.image.name : "Upload Health Facility Image"}
                  <input type="file" id="image" name="image" onChange={handleChange} className="d-none" />
                <br/>
                <div className="edit-content">
                {formData.image && (
                 <div style={{ position: "relative" }}>
                  <button onClick={() => setFormData({...formData, image: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.image === 'string'
                ? <img src={formData.image} alt="Existing" />
                : <img src={URL.createObjectURL(formData.image)} alt="Uploaded"  />
              }</div>  )} </div> </label><br/>
             
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddBoardMember;


