import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function RequestResetPassword() {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://myghschool.rubykaryo.com/user/reset-password/', {
                email
            });
            setSuccess(response.data.success);
        } catch (error) {
            setError(error.response.data.error);
        }
    };

    return (
     
          <div className="login-main-page" style={{backgroundImage:"url("+  +")", backgroundSize:'cover'}}>
          <div className="login-wrapper">
              
              <div className="container h-100">
                  <div className="row h-100 align-items-center justify-contain-center">
                      <div className="col-xl-12 mt-3">
                          <div className="card">
                              <div className="card-body p-0">
                                  <div className="row m-0">
                                      <div className="col-xl-6 col-md-6 sign text-center">	
                                      <Link to="/">
                                      <img src="/assets1/img/logo/MYGHLOGO.png" alt="logo"  />	</Link>							
                                      </div>
                                      <div className="col-xl-6 col-md-6">
                                        
                                          <div className="sign-in-your">
                                              <h4 className="fs-20 font-w800 text-black">Forget Password </h4>
                                              <span>Please enter the email address you use<br /> during registration</span><br /><br />
                                              {success && <p>{success}</p>}
                                              {error && <p style={{color: "red"}}>{error}</p>}
                                              <form onSubmit={handleSubmit}>
                                                  <div className="mb-3">
                                                      <label className="mb-1"><strong>Enter Email</strong></label>
                                                      <input type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} required className="form-control" placeholder="Please enter your email address"/>
                                                  </div>
                                             
                                                      <Link to="/login"><i className="fa-solid fa-user-plus" style={{color:'red'}}></i> &nbsp;<strong> Sign In</strong></Link><br/><br/>
                                                      <Link to="/register"><i className="fa-solid fa-user-plus" style={{color:'red'}}></i> &nbsp;<strong> Register New Account</strong></Link><br/><br/>
                                                  
                                                   
                                                  <div className="text-center">
                                                      <button type="submit" className="btn btn-primary btn-block">Forget Password</button>
                                                  </div>
                                              </form>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    
    );
}

export default RequestResetPassword;
