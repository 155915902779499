import React, { useState } from 'react';
import useAuth from '../hooks/useAuth';
import { axiosInstance } from '../axios/axios';

function ChangePassword() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const { accessToken } = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setSuccess(null);

        const token = accessToken

        console.log('working token for the backend', token)
        try {
            const response = await axiosInstance.put(`/user/change-password/`, {
            old_password: oldPassword,
            new_password1: newPassword1,
            new_password2: newPassword2,
                 }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
             });
             
            setSuccess('Password successfully changed!');
        } catch (error) {
            if (error.response) {
                setError(error.response.data);
            } else {
                setError('An unknown error occurred.');
            }
        }
    };

    return (
        <>
        <div>
        {error && <div className="alert alert-danger" role="alert">Old password does not match</div>}
        {success && <div className="alert alert-success" role="alert">{success}</div>}
        </div>
        <form onSubmit={handleSubmit}>

            <div className="col-lg-6 mb-2">
                <label>Old Password:</label>
                <input type="password" className="form-control" value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
            </div>
            <div className="col-lg-6 mb-2">
                <label>New Password:</label>
                <input type="password" className="form-control" value={newPassword1} onChange={e => setNewPassword1(e.target.value)} />
            </div>
            <div className="col-lg-6 mb-2">
                <label>Confirm New Password:</label>
                <input type="password" className="form-control" value={newPassword2} onChange={e => setNewPassword2(e.target.value)} />
            </div>
            <button type="submit" className="btn btn-primary">Change Password</button>

     
        </form>
        </>
    );
}

export default ChangePassword;


