import { useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import logo from "../images/MYGH1.jpg";
import { axiosInstance } from "../axios/axios";
import { useNavigate} from "react-router-dom";
import useAuth from '../hooks/useAuth';


const Register = () => {

  useEffect(()=>{
		document.title = "MGS | Register";
	});

    const navigate = useNavigate();
	const errRef  = useRef();
	const {setUser, setAccessToken, setAndPersistUserId } = useAuth();

	const [email, setEmail] = useState('');
	const [username, setUsername] = useState('');
	const [first_name, setFirstName] = useState('');
	const [last_name, setLastName] = useState('');
	const [phone_number, setPhoneNumber] = useState('');
	const [gender, setGender] = useState('');
    let errorsObj = { email: '', password: '', password2: '', first_name:'', last_name:'', phone_number:'', username:'', gender:'' };
	const [errMsg, setErrMsg] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [backenderrors, setBackendErrors] = useState({});

    useEffect(() => {
        setErrMsg('');
    }, [email, password, password2, first_name, last_name, phone_number, username, gender]);


    const onSignUp = async (e) =>{
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        if (password2 === '') {
            errorObj.password2 = 'Confirm Password is Required';
            error = true;
        }
		if (password !== password2) {
			errorObj.password2 = 'Passwords do not match';
			error = true;
		}
		if (gender == '') {
			errorObj.gender = 'Gender required';
			error = true;
		}
        if (first_name === '') {
            errorObj.first_name = 'First Name is Required';
            error = true;
        }
        if (username === '') {
            errorObj.username = 'Username is Required';
            error = true;
        }
        if (last_name === '') {
            errorObj.last_name = 'Last Name is Required';
            error = true;
        }
        if (phone_number === '') {
            errorObj.phone_number = 'Phone Number is Required';
            error = true;   
        }
        setErrors(errorObj);

        if (error) return;

        try{

            const response = await axiosInstance.post(`user/owner/`, JSON.stringify({ 
                user: {
                    username, 
                    email, 
                    password, 
                    first_name, 
                    last_name, 
                   
                  },
				  password2,
                  phone_number,
				  gender,
             }))

			if (response.status === 201) {
				const loginResponse = await axiosInstance.post(`user/login/`, JSON.stringify({ 
					username, 
					password,
				}))

			 const accessToken = loginResponse?.data?.tokens?.access_token;
			 const refreshToken = loginResponse?.data?.tokens?.refresh_token;
			 const userId = loginResponse?.data?.user_id;
			 const userDetails ={
				 id: loginResponse?.data?.user_id,
				 is_staff: loginResponse?.data?.is_staff,
				 is_admin: loginResponse?.data?.is_admin,
				 is_owner: loginResponse?.data?.is_owner,
				 is_active: loginResponse?.data?.is_active,
			 }
            if (loginResponse.status === 200) {
			setAndPersistUserId(userId)
			setAccessToken({username, password, accessToken, refreshToken})

			setUser(userDetails);
			window.location.href = `/admin/dashboard/${userId}`;
				} else {
				setErrMsg('Login failed');
				navigate('/login');
            	}
			}
		 } catch (error) {
            if(!error.response) {
                setErrMsg('Network Error' );}
            else if(error.response.data){
                setErrMsg( error.response.data.message);
				setBackendErrors(error.response.data);
            }
            else {
                setErrMsg('Something went wrong, Registration Failed' );
            }
            }
        }

    return (
    <>
      
    <div className="authincation h-100 p-meddle">
			<div className="container h-100">
				<div className="row justify-content-center h-100 align-items-center">
					<div className="col-md-6">
						<div className="authincation-content">
							<div className="row no-gutters">
								<div className="col-xl-12">
									<div className="auth-form">
										<div className="text-center mb-3">
											<Link to="/">
												<img src={logo} alt="" />
											</Link>
										</div>
									
                                        <h4 className="text-center mb-4 ">Register to have your school online</h4>
                                        <span style={{color: 'red'}}><strong><p ref={errRef} className={errMsg}>{errMsg}</p></strong></span>
          
										<form onSubmit={onSignUp} >
											<div className="form-group mb-3">
												<label className="mb-1 ">
													<strong>Username</strong>
												</label>
												{backenderrors?.user?.username && backenderrors?.user?.username === "A user with that username already exists."? <div className="text-red " style={{fontWeight:'bold'}}> User Name already exits. Choose another name</div>: null}
												<input name='username' type="text" className="form-control" placeholder="username" 
												value={username} onChange={(e) => setUsername(e.target.value)} />
        										<span style={{color:'red'}}> {errors.username && <div style={{fontWeight:'bold'}}>{errors.username}</div>}</span>
												{/* <input type="text" className="form-control" placeholder="username"/> */}
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
													<strong>First Name</strong>
												</label>
												<input name='first_name' type="text" className="form-control" placeholder="first name" 
												value={first_name} onChange={(e) => setFirstName(e.target.value)} />
												{/* value={owner.user.first_name} onChange={handleChange}/> */}
        										<span style={{color:'red'}}>{errors.first_name && <div style={{fontWeight:'bold'}}>{errors.first_name}</div>}</span>
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
													<strong>Last Name</strong>
												</label>
												<input type="text" name="last_name" className="form-control" placeholder="last name" 
												value={last_name} onChange={(e) => setLastName(e.target.value)} />
												{/* value={owner.user.last_name} onChange={handleChange}/> */}
       										<span style={{color: 'red'}}>{errors.last_name && <div style={{fontWeight:'bold'}}>{errors.last_name}</div>}</span>
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
													<strong>Gender</strong>
												</label>
												<select name="gender" className="form-control" >
													<option disabled>Choose Gender</option>
													<option value={gender} onChange={(e) => setGender(e.target.value)}>Female</option>
													<option value={gender} onChange={(e) => setGender(e.target.value)}>Male</option>
												</select>
       										<span style={{color: 'red'}}>{errors.gender && <div style={{fontWeight:'bold'}}>{errors.gender}</div>}</span>
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
												  <strong>Email</strong>
												</label>
												<input type="email" name="email" className="form-control" placeholder="hello@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
												{/* // value={owner.user.email} onChange={handleChange}/> */}
       											<span style={{color:'red'}}>{errors.email && <div style={{fontWeight:'bold'}}>{errors.email}</div>}</span>
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
												  <strong>Phone Number</strong>
												</label>
												{/* {backenderrors.phone_number && <div>Error: {backenderrors.phone_number}</div>} */}
												{backenderrors?.phone_number && backenderrors?.phone_number === "owner profile with this phone number already exists."? <div className="text-red" style={{fontWeight:'bold'}}> Phone number already exist</div>: null}
												<input type="number" name="phone_number" className="form-control" placeholder="Phone Number" value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} />
												{/* value={owner.phone_number} onChange={handleChange}/> */}
       											<span style={{color:'red'}}> {errors.phone_number && <div style={{fontWeight:'bold'}}>{errors.phone_number}</div>}</span>
											</div>										
											<div className="form-group mb-3">
												<label className="mb-1 ">
													<strong>Password</strong>
												</label>
												<input type="password" name="password" className="form-control" placeholder="Password"
												value={password} onChange={(e) => setPassword(e.target.value)} />
												 {/* value={owner.user.password} onChange={handleChange}/> */}
        										<span style={{color:'red'}}> {errors.password && <div style={{fontWeight:'bold'}}>{errors.password}</div>}</span>
								
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
												<strong>Confirm Password</strong>
												</label>
												{errors.password2 && <div className="text-red" style={{fontWeight:'bold'}}>{errors.password2}</div>}
												<input type="password" name="password2" className="form-control" placeholder="Confirm Password" value={password2} onChange={(e) => setPassword2(e.target.value)} />												
											</div>
											<div className="text-center mt-4">
												<button type="submit" className="btn btn-primary btn-block">Register Me</button>
											</div>
										</form>
										<div className="new-account mt-3">
											<p className="">
												Already have an account?{" "}
												<Link className="text-primary" to="/login">
													Sign in
												</Link>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
            </div>
         {/* )} */}
    	</>

    );
};


export default Register;


