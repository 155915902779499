import React, {useEffect, useContext, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import SchoolHeader from './SchoolHeader';
import parse from 'html-react-parser';

const baseUrl='https://myghschool.myghschools.com/school';

const Achievement = () => {

  useEffect(()=>{
		document.title = "MGS | Achievement";
	}, []);
  
  const [achievements, setAchievement] = useState(null);
  const [schools, setSchools] = useState(null);
  const [contact, setContact] = useState(null);  
  const { selectedSchool } = useContext(SchoolContext);


  const truncateDescription = (vision) => {
    if (vision.length > 50) {
      return vision.substring(0, 50) + "...";
    } else {
      return vision;
    }
  };
    

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${selectedSchool}`);
      setSchools(response.data);
      } catch (error) {
      }
    };
    fetchSchoolData();
  }, [selectedSchool]);


  useEffect(() => {

    const fetchAchievementData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/achievements`);
      setAchievement(response.data);
      } catch (error) {
      }
    };
    fetchAchievementData();
  },[selectedSchool]);
  

    useEffect(() => {
      const fetchContactData = async () => {
        try {
        const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
        setContact(response.data);
        } catch (error) {
        }
      };
      fetchContactData();
    },[selectedSchool]);

  return (
   <React.Fragment>
 
<div className="full-width-header">

<SchoolHeader /> 


</div> 

<div className="rs-breadcrumbs breadcrumbs-overlay " style={{ backgroundImage: `url(${schools && schools?.banner})`, backgroundSize: '100%' }}>
  <div className="breadcrumbs-inner">
      <div className="container">
          <div className="row">
              <div className="col-md-12 text-center pt-5">
                  <h1 className="page-title">OUR ACHIEVEMENTS</h1>
                  <ul>
                      <li>
                      <Link to={`/schoolhome/${selectedSchool}`}>Home</Link>
                      </li>
                      <li>Our Achievements</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>

  <div id="rs-courses-3" className="rs-courses-3 sec-spacer">
  <div className="card container pt-5">
  <div className="abt-title">
      <h2>ALL OUR ACHIEVEMENTS</h2>
  </div>
        
    <div className="row grid">

{achievements && achievements.map((achievement, index) => (
  
   <div className="  col-lg-3 col-md-6 " key={achievement.id}>
   <div className="course-item">
       <div className="course">
         <Link to={`/achievementsingle/${achievement.id}`}> <img src={achievement?.mainImage} alt="" style={{width:"300px", height:'200px'}} /> </Link> 
         
      </div>
       <div className="course-body">
        
         <div className="course-desc">
         <h3 className='text-red'><span style={{fontSize:'0.8em', color:'black'}}>Title: </span>{achievement.title}</h3>
           <h3 className="course-title"><Link to={`/achievementsingle/${achievement.id}`}>{achievement?.name}</Link></h3>
           <div>
            {parse(truncateDescription(achievement?.description))}
           </div>
         </div>
       </div>
  
   </div>			
  </div> 



 ))}
       
   </div> 
  </div>
   </div>


   <footer id="rs-footer" className="bg3 rs-footer mt-0">
 <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 &&  contact[0]?.address}<br/>
 								{schools && schools?.town}<br/>
 								{schools && schools?.district}<br/>
 								{schools && schools?.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 && contact[0]?.gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 && contact[0]?.phone_number}<br/>
									{contact && contact.length > 0 &&  contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 &&  contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.facebook}<br/>
									{contact && contact.length > 0 &&  contact[0]?.instagram}<br/>
									{contact && contact.length > 0 && contact[0]?.website}<br/>
									{contact && contact.length > 0 &&  contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>

  <div id="scrollUp">
      <i className="fa fa-angle-up"></i>
  </div> 
  

   <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
       <span aria-hidden="true" className="fa fa-close"></span>
     </button>
       <div className="modal-dialog modal-dialog-centered">
           <div className="modal-content">
               <div className="search-block clearfix">
                   <form>
                       <div className="form-group">
                           <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                     </div>
                   </form>
               </div>
           </div>
       </div>
   </div> 


 </React.Fragment>

  )
}

export default Achievement