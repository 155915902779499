import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./AuthContext/AuthProvider"; 
import { BrowserRouter} from 'react-router-dom';  
import  ThemeContext  from "./context/ThemeContext"; 
import { SchoolProvider } from './AuthContext/SchoolProvider';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
    <BrowserRouter>
      <ThemeContext>
        
          <SchoolProvider>
          <App />
          </SchoolProvider>
        
      </ThemeContext>
    </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


