
// import axios from '../axios/axios';
import { axiosInstance } from '../axios/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { setAccessToken, setCSRFToken } = useAuth();

  const refresh = async () => {

      const response = await axiosInstance.post('user/refresh-token/')
      setAccessToken(response.data.access)
      setCSRFToken(response.headers['x-csrftoken'])
      return { accessToken: response.data.access, csrfToken: response.headers['x-csrftoken']}
      
    }
 
  return refresh;
}

export default useRefreshToken;

