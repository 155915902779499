

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";



const baseUrl = "https://myghschool.myghschools.com/school";

function EditContact() { 

  useEffect(()=>{
		document.title = "MGS | Contact";
	}); 
    
  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [setResponsess] = useState([]);

  const [formData, setFormData] = useState({
    phone_number: "",
    phone_number2: "",
    email: "",
    address: "",
    website: "",
    gps_address: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    whatsapp: "",
    school: selectedSchool, 
  });


  const goBack = () => {  
    navigate(-1);
  };
  const [error, setError] = useState(null);


  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
      axios.get(`${baseUrl}/schools/${selectedSchool}/contacts/${id}/`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData({
          ...res.data,
          
        });
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id]);


const handleChange = (e) => {
  const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  
}


const handleSubmit = async (e) => {
  e.preventDefault();
  const formDataObj = new FormData();
  formDataObj.append("phone_number", formData.phone_number);
  formDataObj.append("phone_number2", formData.phone_number2);
  formDataObj.append("email", formData.email);
  formDataObj.append("address", formData.address);
  formDataObj.append("website", formData.website);
  formDataObj.append("gps_address", formData.gps_address);
  formDataObj.append("facebook", formData.facebook);
  formDataObj.append("instagram", formData.instagram);
  formDataObj.append("twitter", formData.twitter);
  formDataObj.append("youtube", formData.youtube);
  formDataObj.append("whatsapp", formData.whatsapp);
  formDataObj.append("school", formData.school); //school field

  // try {
    const token = accessToken
    const response = await axios.patch(`https://myghschool.rubykaryo.com/school/schools/${selectedSchool}/contacts/${id}/`, formDataObj, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`,
      },
    });
    if(response.status === 200){
      Swal.fire({
        icon: "success",
        title: "achievement updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      
      goBack();
    }

};
if (isLoading) {
  return <h2>Loading...</h2>; // Render a loading message while the data is being fetched
}
  return (
    <Container>
      <Row>
        <Col>
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit Contact Details</h3>
          </div>
          <button onClick={goBack} className="btn btn-primary btn-sm">Back to Contact</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Phone Number</strong></label>
               <input type="number" id="phone_number" name="phone_number" value={formData.phone_number} onChange={handleChange} className="form-control" required />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Phone Number 2</strong></label>
               <input type="number" id="phone_number2" name="phone_number2" value={formData.phone_number2} onChange={handleChange} className="form-control" required />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Email</strong></label>
               <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="form-control" required />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Address</strong></label>
               <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} className="form-control" required />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>GPS Address</strong></label>
               <input type="text" id="gps_address" name="gps_address" value={formData.gps_address} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong> Website</strong></label>
               <input type="text" id="website" name="website" value={formData.website} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Facebook</strong></label>
               <input type="text" id="facebook" name="facebook" value={formData.facebook} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Twitter</strong></label>
               <input type="text" id="twitter" name="twitter" value={formData.twitter} onChange={handleChange} className="form-control"  />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Instagram</strong></label>
               <input type="text" id="instagram" name="instagram" value={formData.instagram} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Youtube</strong></label>
               <input type="text" id="youtube" name="youtube" value={formData.youtube} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Whatsapp</strong></label>
               <input type="number" id="whatsapp" name="whatsapp" value={formData.whatsapp} onChange={handleChange} className="form-control" />
            </div>
            </div>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default EditContact;

