

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



function AddDonations() { 

    useEffect(()=>{
		document.title = "MGS | Dashboard/Donation";
	}); 
    
  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [backendErrors, setBackendErrors] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    beneficiary: "",
    collected_by: "",
    amount_requested: "",
    description: "",
    position: "",
    image1: null,
    image2: null,
    image3: null,
    school: selectedSchool,  //assuming there's a school field in the course model
  });

  const goBack = () => {  
    navigate(-1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "image1" || name === "image2" || name === "image3") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("title", formData.title);
    formDataObj.append("beneficiary", formData.beneficiary);
    formDataObj.append("collected_by", formData.collected_by);  
    formDataObj.append("amount_requested", formData.amount_requested);
    formDataObj.append("description", formData.description);
    formDataObj.append("position", formData.position);
    if (formData.image1) {
        formDataObj.append("image1", formData.image1);
    }
    if (formData.image2) {
        formDataObj.append("image2", formData.image2);
    }
    if (formData.image3) {
        formDataObj.append("image3", formData.image3);
    }
    
    formDataObj.append("school", formData.school); //school field

    try {
      const token = accessToken
      const response = await axios.post(`https://myghschool.myghschools.com/donation/schools/${selectedSchool}/projects/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      // setResponsess(response)
      Swal.fire({
        icon: "success",
        title: "Board of Director Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      
      goBack();
    } catch (err) {
      if (err.response) {
      setBackendErrors(err);
    }
  }

  };
  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Donation Project</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Donations</button><br/><br/>
          <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Title</strong></label><span style={{color:'red'}}>*</span>
              {backendErrors?.response?.data?.title && backendErrors?.response?.data?.title=== "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Title of donation required</div>: null}
               <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} className="form-control"  placeholder="Title of Project" required/>
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Beneficiary</strong></label><span style={{color:'red'}}>*</span>
              {backendErrors?.response?.data?.beneficiary && backendErrors?.response?.data?.beneficiary === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Beneficiary name required</div>: null}
               <input type="text" id="beneficiary" name="beneficiary" value={formData.beneficiary} onChange={handleChange} className="form-control"  placeholder="Who will benefit from the donation" required/>
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Project Supervisor</strong></label><span style={{color:'red'}}>*</span>
              {backendErrors?.response?.data?.collected_by && backendErrors?.response?.data?.collected_by === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Name of supervisor required</div>: null}
               <input type="text" id="collected_by" name="collected_by" value={formData.collected_by} onChange={handleChange} className="form-control" placeholder="Who is responsible for the donation" required/>
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Position</strong></label><span style={{color:'red'}}>*</span>
              {backendErrors?.response?.data?.position && backendErrors?.response?.data?.position=== "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Position of supervisor is required</div>: null}
               <input type="text" id="position" name="position" value={formData.position} onChange={handleChange} className="form-control"  placeholder="Person's position in the school" required/>
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Amount Requested</strong></label><span style={{color:'red'}}>*</span>
              {backendErrors?.response?.data?.amount_requested && backendErrors?.response?.data?.amount_requested === "A valid number is required."? <div className="text-red " style={{fontWeight:'bold'}}> Amount requested is required </div>: null}
               <input type="number" id="amount_requested" name="amount_requested" value={formData.amount_requested} onChange={handleChange} className="form-control" placeholder="Amount needed for the project" required/>
            </div>
            </div>

            <div className="col-lg-6 mb-2">
            <div className="form-group">
               <label className="mb-1 "><strong>Description</strong></label><span style={{color:'red'}}>*</span>
               <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
             </div>
             </div><br/>
             <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.image1 ? formData.image1.name : "Upload Donation Image"}
                  <input type="file" id="image1" name="image1" onChange={handleChange} className="d-none"/>
               <br/>
                <div className="edit-content">
                {formData.image1 && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, image1: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.image1 === 'string'
                ? <img src={formData.image1} alt="Existing" />
                : <img src={URL.createObjectURL(formData.image1)} alt="Uploaded" />
              }</div>  )}
              </div>
              </label>
              <br/>
              <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.image2 ? formData.image2.name : "Upload Donation Image"}
                  <input type="file" id="image2" name="image2" onChange={handleChange} className="d-none" />
               <br/>
                <div className="edit-content">
                {formData.image2 && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, image2: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.image2 === 'string'
                ? <img src={formData.image2} alt="Existing" />
                : <img src={URL.createObjectURL(formData.image2)} alt="Uploaded" />
              }</div>  )} </div>  </label><br/>

             <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.image3 ? formData.image3.name : "Upload Donation Image"}
                  <input type="file" id="image3" name="image3" onChange={handleChange} className="d-none" />
               <br/>
                <div className="edit-content">
                {formData.image3 && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, image3: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.image3 === 'string'
                ? <img src={formData.image3} alt="Existing" />
                : <img src={URL.createObjectURL(formData.image3)} alt="Uploaded" />
              }</div>  )}</div>  </label><br/>
              <br/>
             
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddDonations;


