

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const baseUrl = "https://myghschool.myghschools.com/school";
function EditHealthFacility() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool} = useContext(SchoolContext);
  const [isLoading, setIsLoading] = useState(true);
  // const [setResponsess] = useState([]);
  const {id} = useParams();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    mainImage1: null,
    mainImage2: null,
    mainImage3: null,
    mainImage4: null,
    video: null,
    school: selectedSchool,  //assuming there's a school field in the course model
  });

  const goBack = () => {  
    navigate(-1);
  };

  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
      axios.get(`${baseUrl}/schools/${selectedSchool}/healthfacility/${id}/`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData({
          ...res.data,          
        });

      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id]);


  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "mainImage1" || name === "mainImage2" || name === "mainImage3" || name === "mainImage4" || name === "video") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    // formDataObj.append("description", formData.description);
    if (formData.title === '') {
      formDataObj.append("description", null); // or whatever represents "no data" in your backend
    } else {
      formDataObj.append("description", formData.description);
    }
    if (!formData.mainImage1) {
      formDataObj.append("mainImage1", "");  // or whatever represents "no video" in your backend
    } else if (formData.mainImage1 && formData.mainImage1 instanceof File) {
      formDataObj.append("mainImage1", formData.mainImage1);
    }
    if (!formData.mainImage2) {
      formDataObj.append("mainImage2", "");  // or whatever represents "no video" in your backend
    } else if (formData.mainImage2 && formData.mainImage2 instanceof File) {
      formDataObj.append("mainImage2", formData.mainImage2);
    }
    if (!formData.mainImage3) {
      formDataObj.append("mainImage3", "");  // or whatever represents "no video" in your backend
    } else if (formData.mainImage3 && formData.mainImage3 instanceof File) {
      formDataObj.append("mainImage3", formData.mainImage3);
    }
  if (!formData.mainImage4) {
    formDataObj.append("mainImage4", "");  // or whatever represents "no video" in your backend
  } else if (formData.mainImage4 && formData.mainImage4 instanceof File) {
    formDataObj.append("mainImage4", formData.mainImage4);
  }
  if (!formData.video) {
    formDataObj.append("video", "");  // or whatever represents "no video" in your backend
  } else if (formData.video && formData.video instanceof File) {
    formDataObj.append("video", formData.video);
  }
    formDataObj.append("school", formData.school); //school field

      const token = accessToken
      const response = await axios.patch(`${baseUrl}/schools/${selectedSchool}/healthfacility/${id}/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });

      if (response.status === 200|| response.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Health Facility data updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      
      goBack();
  
  }


  };
      
    if (isLoading) {
      return <h2>Loading...</h2>; 
    }

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit Health Facility</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Health Facility page</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3 edit-content">
              <label className="mb-1 "><strong>Name</strong></label>
               <input type="text" id="name" name="name" value={formData?.name} onChange={handleChange} className="form-control" required />
            </div>
            </div>

            <div className="col-lg-6 mb-2">
            <div className="form-group">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData?.description} onChange={(content) => setFormData({ ...formData, description: content })} />
               <button type="button" onClick={() => setFormData(prev => ({...prev, description: ''}))} className="btn btn-sm btn-danger">Clear</button>   
             </div>
             </div>
            <label className="btn btn-success col-lg-6 mb-2 " >
                {formData?.mainImage1 ? formData?.mainImage1?.name : "Upload Health Facility Image"}
                  <input type="file" id="mainImage1" name="mainImage1" onChange={handleChange} className="d-none" />
                <br/>
                <div className="edit-content">
              {formData?.mainImage1 && (
                <div>
                {typeof formData?.mainImage1 === 'string'
                ? <img src={formData?.mainImage1} alt="Existing" />
                : <img src={URL.createObjectURL(formData?.mainImage1)} alt="Uploaded" />
              }
                <button type="button" onClick={() => setFormData(prev => ({...formData, mainImage1: null}))} className="btn btn-sm btn-danger">Remove</button><br/><br/></div> )} </div> </label><br/>
            <label className="btn btn-success col-lg-6 mb-2 " >
                {formData?.mainImage2 ? formData?.mainImage2?.name : "Upload Health Facility Image"}
                  <input type="file" id="mainImage2" name="mainImage2" onChange={handleChange} className="d-none" />
             <br/>
                <div className="edit-content">
              {formData?.mainImage2 && (
                <div>
                <button type="button" onClick={() => setFormData({...formData, mainImage2: null})} className="btn btn-sm btn-danger" style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red'}}>X</button>
                {typeof formData?.mainImage2 === 'string'
                ? <img src={formData?.mainImage2} alt="Existing" />
                : <img src={URL.createObjectURL(formData?.mainImage2)} alt="Uploaded" />
                }</div>  )} </div> </label><br/>
            <label className="btn btn-success col-lg-6 mb-2 " >
                {formData?.mainImage3 ? formData?.mainImage3.name : "Upload Health Facility Image"}
                  <input type="file" id="mainImage3" name="mainImage3" onChange={handleChange} className="d-none" />
                <br/>
                <div className="edit-content">
              {formData?.mainImage3 && (
                    <div>
                    <button type="button" onClick={() => setFormData({...formData, mainImage3: null})} className="btn btn-sm btn-danger" style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red'}}>X</button>
                {typeof formData?.mainImage3 === 'string'
                ? <img src={formData?.mainImage3} alt="Existing" />
                : <img src={URL.createObjectURL(formData?.mainImage3)} alt="Uploaded" />
                }</div>)} </div> </label><br/>
            <label className="btn btn-success col-lg-6 mb-2 " >
                {formData?.mainImage4 ? formData?.mainImage4?.name : "Upload Health Facility Image"}
                  <input type="file" id="mainImage4" name="mainImage4" onChange={handleChange} className="d-none" />
               <br/>
                <div className="edit-content">
              {formData?.mainImage4 && (
                <div>
                <button type="button" onClick={() => setFormData({...formData, mainImage4: null})} className="btn btn-sm btn-danger" style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red'}}>X</button>
                {typeof formData?.mainImage4 === 'string'
                ? <img src={formData?.mainImage4} alt="Existing" />
                : <img src={URL.createObjectURL(formData?.mainImage4)} alt="Uploaded" />
                }</div> )} </div> </label><br/>
             
            <label className="btn btn-success col-lg-6 mb-2 " >
                {formData?.video ? formData?.video?.name : "Upload Health Facility Video"}
                  <input type="file" id="video" name="video" onChange={handleChange} className="d-none" />
                <br/>
                <div className="edit-content">
              {formData?.video && (
                <div style={{position: "relative", width: "200px", height: "150px"}}>
                <button type="button" onClick={() => setFormData({...formData, video: null})} className="btn btn-sm btn-danger" style={{position:"absolute", zIndex:10}}>x</button>
                <video width="100%" height="150px" controls style={{position: "absolute", top: 0, left: 0}}>
                {typeof formData?.video === 'string'
                ? <source src={formData?.video} alt="Existing" />
                : <source src={URL.createObjectURL(formData?.video)} alt="Uploaded" style={{ width: "100%", objectFit:'cover'}} />
                }
                </video>
                </div>)}</div> </label>
                <br/><br/>
             
            <Button variant="primary" type="submit">
              Update
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditHealthFacility;


