

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const baseUrl = "https://myghschool.myghschools.com/donation";

function EditDonation() { 

    let navigate = useNavigate();
    const { accessToken } = useAuth();
    const { selectedSchool} = useContext(SchoolContext);
    const [isLoading, setIsLoading] = useState(true);
    const [backendErrors, setBackendErrors] = useState(null);
    const { id } = useParams();
    const [setResponsess] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    beneficiary: "",
    collected_by: "",
    amount_requested: "",
    description: "",
    position: "",
    image1: null,
    image2: null,
    image3: null,
    video: null,
    school: selectedSchool,
  });

  const goBack = () => {  
    navigate(-1);
  };
  const [setError] = useState(null);

  // to fetch courses from the server that can be use to populate the course field in the form

  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
      axios.get(`${baseUrl}/schools/${selectedSchool}/projects/${id}`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData({
          ...res.data,
        });
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id]);


const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "image1" || name === "image2" || name === "image3" || name === "video") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("title", formData.title);
    formDataObj.append("beneficiary", formData.beneficiary);
    formDataObj.append("collected_by", formData.collected_by);  
    formDataObj.append("amount_requested", formData.amount_requested);
    formDataObj.append("description", formData.description);
    formDataObj.append("position", formData.position);
    if (!formData.image1) {
      formDataObj.append("image1", "");  // or whatever represents "no video" in your backend
    } else if (formData.image1 && formData.image1 instanceof File) {
      formDataObj.append("image1", formData.image1);
    }
    if (!formData.image2) {
      formDataObj.append("image2", "");  // or whatever represents "no video" in your backend
    } else if (formData.image2 && formData.image2 instanceof File) {
      formDataObj.append("image2", formData.image2);
    }
    if (!formData.image3) {
      formDataObj.append("image3", "");  // or whatever represents "no video" in your backend
    } else if (formData.image3 && formData.image3 instanceof File) {
      formDataObj.append("image3", formData.image3);
    }
    if (!formData.video) {
      formDataObj.append("video", "");  // or whatever represents "no video" in your backend
    } else if (formData.video && formData.video instanceof File) {
      formDataObj.append("video", formData.video);
    }
    formDataObj.append("school", formData.school); //school field

    try {
      const token = accessToken
      const response = await axios.patch(`http://127.0.0.1:8000/donation/schools/${selectedSchool}/projects/${id}/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      setResponsess(response);
      Swal.fire({
        icon: "success",
        title: "Board of Director Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      
      goBack();
    } catch (err) {
      if (err.response) {
        setError("Something went wrong. Please try again later.");
        setBackendErrors(err);
      }
  }


  };

  if (isLoading) {
    return <h2>Loading...</h2>; // Render a loading message while the data is being fetched
  }

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit Donation</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Donation</button><br/><br/>
          <form onSubmit={handleSubmit}>
          
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Title</strong></label>
              {backendErrors?.response?.data?.title && backendErrors?.response?.data?.title=== "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Title of donation required</div>: null}
               <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} className="form-control" required />
            </div>
            </div><br></br>
            <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Beneficiary</strong></label>
              {backendErrors?.response?.data?.beneficiary && backendErrors?.response?.data?.beneficiary === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Beneficiary name required</div>: null}
               <input type="text" id="beneficiary" name="beneficiary" value={formData.beneficiary} onChange={handleChange} className="form-control" required />
            </div>
            </div>
            <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Project Supervisor</strong></label>
              {backendErrors?.response?.data?.collected_by && backendErrors?.response?.data?.collected_by === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Name of supervisor required</div>: null}
               <input type="text" id="collected_by" name="collected_by" value={formData.collected_by} onChange={handleChange} className="form-control" required />
            </div>
            </div>
            <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Position</strong></label>
              {backendErrors?.response?.data?.position && backendErrors?.response?.data?.position === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Position of supervisor is required</div>: null}
               <input type="text" id="position" name="position" value={formData.position} onChange={handleChange} className="form-control" required />
            </div>
            </div>
            <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Amount Requested (Ghc)</strong></label>
              {backendErrors?.response?.data?.amount_requested && backendErrors?.response?.data?.amount_requested === "A valid number is required."? <div className="text-red " style={{fontWeight:'bold'}}> Amount requested is required </div>: null}
               <input type="number" id="amount_requested" name="amount_requested" value={formData.amount_requested} onChange={handleChange} className="form-control" required />
            </div>
            </div>
            <div className="col-lg-6 mb-2">
            <div className="form-group">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
             </div>
             </div><br></br>
             <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.image1 ? formData.image1.name : "Upload Donation Image"}
                  <input type="file" id="image1" name="image1" onChange={handleChange} className="d-none"/>
               <br/>
                <div className="edit-content">
                {formData.image1 && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, image1: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.image1 === 'string'
                ? <img src={formData.image1} alt="Existing" />
                : <img src={URL.createObjectURL(formData.image1)} alt="Uploaded" />
              }</div>  )}
              </div>
              </label>
              <br/>

              <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.image2 ? formData.image2.name : "Upload Donation Image"}
                  <input type="file" id="image2" name="image2" onChange={handleChange} className="d-none" />
               <br/>
                <div className="edit-content">
                {formData.image2 && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, image2: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.image2 === 'string'
                ? <img src={formData.image2} alt="Existing" />
                : <img src={URL.createObjectURL(formData.image2)} alt="Uploaded" />
              }</div>  )} </div>  </label><br/>

              <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.image3 ? formData.image3.name : "Upload Donation Image"}
                  <input type="file" id="image3" name="image3" onChange={handleChange} className="d-none" />
               <br/>
                <div className="edit-content">
                {formData.image3 && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, image3: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.image3 === 'string'
                ? <img src={formData.image3} alt="Existing" />
                : <img src={URL.createObjectURL(formData.image3)} alt="Uploaded" />
              }</div>  )}</div>  </label><br/>
           
           <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.video ? formData.video.name : "Upload Health Facility Video"}
                  <input type="file" id="video" name="video" onChange={handleChange} className="d-none" />
                <br/>
                <div className="edit-content">
                {formData.video && (
                <div style={{position: "relative", width: "200px", height: "150px"}}>
                  <button type="button" onClick={() => setFormData({...formData, video: null})} className="btn btn-sm btn-danger" style={{position:"absolute", zIndex:10}}>X</button>
                <video width="200" height="150" controls style={{position: "absolute", top: 0, left: 0}} >
                {typeof formData.video === 'string'
                ? <source src={formData.video} alt="Existing" />
                : (formData.video instanceof File) 
                ? <source src={URL.createObjectURL(formData.video)} alt="Uploaded" />
                : null
                }
                </video>
                </div>)}</div> </label><br/><br/>
                <Button variant="primary" type="submit">
                    Update
                </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditDonation;

