

import React, { useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function AddHealthFacility() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [setResponsess] = useState('');
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    mainImage1: null,
    mainImage2: null,
    mainImage3: null,
    mainImage4: null,
    video: null,
    school: selectedSchool,  //assuming there's a school field in the course model
  });

  const goBack = () => {  
    navigate(-1);
  };

  const [error, setError] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "mainImage1" || name === "mainImage2" || name === "mainImage3" || name === "mainImage4" || name === "video") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("description", formData.description);
    if (formData.mainImage1) {
        formDataObj.append("mainImage1", formData.mainImage1);
    }
    if (formData.mainImage2) {
        formDataObj.append("mainImage2", formData.mainImage2);
    }
    if (formData.mainImage3) {
        formDataObj.append("mainImage3", formData.mainImage3);
    }
    if (formData.mainImage4) {
        formDataObj.append("mainImage4", formData.mainImage4);
    }
    if (formData.video) {
        formDataObj.append("video", formData.video);
    }
    formDataObj.append("school", formData.school); //school field

    try {
      const token = accessToken
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/healthfacility/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      setResponsess(response);
      Swal.fire({
        icon: "success",
        title: "Health Faciltiy Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      
      goBack();
    } catch (err) {
      if (err.response.status === 400) {
      setError("Name already exist. Please try again.");
    }
    else{
      setError("Something went wrong. Please try again later.");
    }
  }

  };
  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Health Facility</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Health Facility page</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-groupmb-3 edit-content">
              <label className="mb-1 "><strong>Name</strong></label>
               <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-control" required />
            </div>
            </div>

            <div className="col-lg-6 mb-2">
            <div className="form-group ">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
             </div>
             </div>
            <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.mainImage1 ? formData.mainImage1.name : "Upload Health Facility Image"}
                  <input type="file" id="mainImage1" name="mainImage1" onChange={handleChange} className="d-none" />
               <br/>
                <div className="edit-content">
                {formData.mainImage1 && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, mainImage1: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.mainImage1 === 'string'
                ? <img src={formData.mainImage1} alt="Existing" style={{ width: '100px', height: '100px' }} />
                : <img src={URL.createObjectURL(formData.mainImage1)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
              }
                </div>  )} </div></label> <br/>
                <div className="edit-content">
            <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.mainImage2 ? formData.mainImage2.name : "Upload Health Facility Image"}
                  <input type="file" id="mainImage2" name="mainImage2" onChange={handleChange} className="d-none" />
                <br/>
                {formData.mainImage2 && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, mainImage2: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.mainImage2 === 'string'
                ? <img src={formData.mainImage2} alt="Existing" style={{ width: '100px', height: '100px' }} />
                : <img src={URL.createObjectURL(formData.mainImage2)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
              }
                </div>  )}</label> </div><br/>
                <div className="edit-content">
            <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.mainImage3 ? formData.mainImage3.name : "Upload Health Facility Image"}
                  <input type="file" id="mainImage3" name="mainImage3" onChange={handleChange} className="d-none" />
               <br/>
                {formData.mainImage3 && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, mainImage3: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.mainImage3 === 'string'
                ? <img src={formData.mainImage3} alt="Existing" style={{ width: '100px', height: '100px' }} />
                : <img src={URL.createObjectURL(formData.mainImage3)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
              }
                </div>  )} </label> </div> <br/>
                <div className="edit-content">
            <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.mainImage4 ? formData.mainImage4.name : "Upload Health Facility Image"}
                  <input type="file" id="mainImage4" name="mainImage4" onChange={handleChange} className="d-none" />
               <br/>
                {formData.mainImage4 && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, mainImage4: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.mainImage4 === 'string'
                ? <img src={formData.mainImage4} alt="Existing" style={{ width: '100px', height: '100px' }} />
                : <img src={URL.createObjectURL(formData.mainImage4)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
              }
                </div>  )} </label> </div><br/>
                <div className="edit-content">
            <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.video ? formData.video.name : "Upload Health Facility Video"}
                  <input type="file" id="video" name="video" onChange={handleChange} className="d-none" />
                <br/>
                {formData.video && (
                <div style={{position: "relative", width: "200px", height: "150px"}}>
                  <button type="button" onClick={() => setFormData({...formData, video: null})} className="btn btn-sm btn-danger" style={{position:"absolute", zIndex:10}}>X</button>
                <video width="200" height="150" controls style={{position: "absolute", top: 0, left: 0}} >
                {typeof formData.video === 'string'
                ? <source src={formData.video} alt="Existing" style={{ width: '100px', height: '100px' }} />
                : (formData.video instanceof File) 
                ? <source src={URL.createObjectURL(formData.video)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
                : null
                }
                </video>
                </div>)}</label></div><br/><br/>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddHealthFacility;


