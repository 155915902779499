import React,{useState, useEffect, useContext}  from 'react';
import {Link} from 'react-router-dom';
import {Tab, Nav} from 'react-bootstrap';
import axios from 'axios';
import SchoolNavbar from '../../layouts/SchoolNavbar';
import { useParams, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import useAuth from '../../../hooks/useAuth';
import { Dropdown } from "react-bootstrap";
import parse from 'html-react-parser';


const baseUrl="https://myghschool.myghschools.com";

const ViewSubject =()=> {

	const [subjects, setSubjects] = useState([]);
	const { selectedSchool } = useContext(SchoolContext);
	const {id} = useParams();
	const {accessToken} = useAuth();
	const navigate = useNavigate();

	
	const goBack= () =>{
		navigate(-1);
	}

	useEffect(() => {
		const fetchSubject = async () => {
		  try {
			const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/subjects/${id}`);
	        setSubjects(response.data);
		  } catch (error) {
		  }
		};
	
		fetchSubject();
	  }, [id, selectedSchool]);


	  const handleDelete = async (subjectId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this school!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/subjects/${subjectId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(subjects)){
					const newSubjects = subjects.filter(subject => subject.id !== subjectId);
					setSubjects(newSubjects);
				}
				swal('Good job!', 'Successfully Deleted', "success");

				goBack();
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your school data is safe!");
		  }
		});
	  }
	  
	return(
		<>
				
		<SchoolNavbar/>
		<div className="col">
		<h2>Subject Details</h2>
		<button className="btn btn-primary btn-xs mb-4" onClick={() => goBack()}>Back</button>
		</div>
			<div className="row">
				<div className="col-xl-8 col-xxl-7">
					<div className="card">
						<div className="card-body">
							<div className="course-content d-flex justify-content-between flex-wrap">
								<div>
									<h3><span style={{fontSize:'14px', color:'red'}}>Subject Title:</span> {subjects.name}</h3>
								</div>
								<div className="mt-2">
								<Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fa-solid fa-pen-to-square text-primary me-2"></i>
					  	<Link to={`/admin/editsubject/${id}`}>
                        Edit Subject </Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fa-solid fa-trash text-primary me-2"></i>
                       <Link to="#" onClick={() => handleDelete(subjects.id)}> Delete Subject </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>						
								</div>
							</div>
							<div className="video-img style-1">
								<div className="view-demo">
									<img src={subjects.image} alt="" />
							
								</div>	
							</div>
							<Tab.Container defaultActiveKey='About'>
								<div className="course-details-tab style-2 mt-4">
									<nav>
										<Nav as='div' className="nav nav-tabs tab-auto" id="nav-tab">
											<Nav.Link as='button' className="nav-link" id="nav-about-tab" eventKey='About' type="button">Subject Description</Nav.Link>
									
										</Nav>
									</nav>
									<Tab.Content className="tab-content" id="nav-tabContent">
										<Tab.Pane  id="nav-about" eventKey='About'>
											<div className="about-content">
												<h4>What the subject is all about</h4>
												{subjects.description && typeof subjects.description === 'string' ? parse(subjects.description) : null}
											</div>
										</Tab.Pane>								
									</Tab.Content>
								</div>
							
							</Tab.Container>	
						</div>	
					</div>
				</div>
				<div className="col-xl-4 col-xxl-5">
		
					<div className="custome-accordion">
						<div className="card" >
						<div className=" pt-0">
						<h2 className="accordion-header border-0">
							<span className="acc-heading">COURSES UNDER THIS SUBJECT</span>
							</h2>
							{subjects.course && subjects.course.map((courses, i) => (
							    <div key={courses.id} className="accordion-body card-body pt-0">
								<div key={i} className="acc-courses">
									<div className="d-flex justify-content-between align-items-center">
										<div className="d-flex align-items-center">
											<span className="acc-icon">
							
											</span>
											<Link to={`/admin/viewcourse/${courses.id}`}>
												<h4 className="m-0">{courses.name}</h4>
											</Link>
										</div>
									</div>
								</div>
								</div>
						
							))}
							</div>
							</div>
					</div>						
				</div>
			</div>	
		</>
	)
}
export default ViewSubject;

