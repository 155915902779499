import { Routes, Route } from 'react-router-dom'
import Error404 from './frontcomponent/Error404'
import SchoolHome from './frontcomponent/school/SchoolHome'
import Courses from './frontcomponent/school/Courses'
import Event from './frontcomponent/school/Event'
import Subjects from './frontcomponent/school/Subjects'
import Gallery from './frontcomponent/school/Gallery'
import Blog from './frontcomponent/school/Blog'
import Achievement from './frontcomponent/school/Achievement'
import Contact from './frontcomponent/school/Contact'
import Subjectpage from './frontcomponent/school/Subjectpage'
import CourseSingle from './frontcomponent/school/CourseSingle'
import EventSingle from './frontcomponent/school/EventSingle'
import BlogSingle from './frontcomponent/school/BlogSingle'
import GallerySingle from './frontcomponent/school/GallerySingle'
import AchievementSingle from './frontcomponent/school/AchievementSingle'

// Main page urls imports
import Main from './frontcomponent/mainpage/Main'
import AllUniversities from './frontcomponent/mainpage/AllUniversities'
import AllShs from './frontcomponent/mainpage/AllShs'
import AllJhs from './frontcomponent/mainpage/AllJhs'
import AllNursing from './frontcomponent/mainpage/AllNursing'
import AllTeacherTraining from './frontcomponent/mainpage/AllTeachersTraining'
import AllNVTI from './frontcomponent/mainpage/AllNVTI'
import AllOtherTertiary from './frontcomponent/mainpage/AllOtherTertiary'
import AllNursery from './frontcomponent/mainpage/AllNursery'
import AllCreche from './frontcomponent/mainpage/AllCreche'
import AllBasic from './frontcomponent/mainpage/AllBasic'
import AllSchools from './frontcomponent/mainpage/AllSchools'

    // public schools
import AllPublicUniversities from './frontcomponent/mainpage/AllPublicUniversities'
import AllPublicNursing from './frontcomponent/mainpage/AllPublicNursing'
import AllPublicTeacher from './frontcomponent/mainpage/AllPublicTeacherTraining'
import AllPublicNVTI from './frontcomponent/mainpage/AllPublicNVTI'
import AllPublicOtherTertiary from './frontcomponent/mainpage/AllPublicOtherTertiary'
import AllPublicBasic from './frontcomponent/mainpage/AllPublicBasic'
import AllPublicSHS from './frontcomponent/mainpage/AllPublicSHS'
import AllPublicJHS from './frontcomponent/mainpage/AllPublicJHS'

    // private schools
import AllPrivateUniversities from './frontcomponent/mainpage/AllPrivateUniversities'
import AllPrivateNursing from './frontcomponent/mainpage/AllPrivateNursing'
import AllPrivateNVTI from './frontcomponent/mainpage/AllPrivateNVTI'
import AllPrivateOtherTertiary from './frontcomponent/mainpage/AllPrivateOtherTertiary'
import AllPrivateBasic from './frontcomponent/mainpage/AllPrivateBasic'
import AllPrivateSHS from './frontcomponent/mainpage/AllPrivateSHS'
import AllPrivateJhs from './frontcomponent/mainpage/AllPrivateJhs'
import AllPrivateNursery from './frontcomponent/mainpage/AllPrivateNursery'
import AllPrivateCreche from './frontcomponent/mainpage/AllPrivateCreche'
import AllPrivateTeacherTraining from './frontcomponent/mainpage/AllPrivateTeacher'

    //shs schools by category
import CategoryA from './frontcomponent/mainpage/CategoryA'
import CategoryB from './frontcomponent/mainpage/CategoryB'
import CategoryC from './frontcomponent/mainpage/CategoryC'
import CategoryD from './frontcomponent/mainpage/CategoryD'

    //News for the main page
import MainNews from './frontcomponent/mainpage/NewsRoom/MainNews'
import MainNewsSingle from './frontcomponent/mainpage/NewsRoom/MainNewsSingle'
import Health from './frontcomponent/mainpage/NewsRoom/Health'
import Sports from './frontcomponent/mainpage/NewsRoom/Sport'
import Politics from './frontcomponent/mainpage/NewsRoom/Politics'
import Entertainment from './frontcomponent/mainpage/NewsRoom/Entertainment'
import Business from './frontcomponent/mainpage/NewsRoom/Business'
import Love from './frontcomponent/mainpage/NewsRoom/Love'
import Technology from './frontcomponent/mainpage/NewsRoom/Technology'
import ForgetPassword from '../pages/ForgetPassword'

//Board of Directors
import BoardofDirectors from './frontcomponent/school/BoardofDirectors'
import HealthFacility from './frontcomponent/school/HealthFacility'
import Alumni from './frontcomponent/school/Alumni'
import HeadofDepartment from './frontcomponent/school/HeadofDepartments'
import PtaMember from './frontcomponent/school/PtaMembers'
import HeadofSchool from './frontcomponent/school/HeadofSchool'
import DepartmentHeads from './frontcomponent/school/DepartmentHeads'

//Donation
import Donation from './frontcomponent/school/Donations/Donation'
import ViewDonation from './frontcomponent/school/Donations/ViewDonation'
import ThankYou from './frontcomponent/school/Donations/ThankYou'



const FrontIndex = () =>{

    return (
        <Routes>
     
        {/* <Route path="school" element={ <Schools /> } /> */}


        <Route path="schoolhome/:id" element={ <SchoolHome /> } />
        <Route path="courses/:id" element={ <Courses /> } />
        <Route path="event/:id" element={ <Event /> } />
        <Route path="eventsingle/:id" element={ <EventSingle />} />
        <Route path="subject/:id" element={ <Subjects /> } />
        <Route path="gallery/:id" element={ <Gallery /> } />
        <Route path="gallerysingle/:id" element={ <GallerySingle /> } />
        <Route path="blog/:id" element={ <Blog /> } />
        <Route path="blogsingle/:id" element={ <BlogSingle /> } />
        <Route path="achievement/:id" element={ <Achievement /> } />
        <Route path="achievementsingle/:id" element={ <AchievementSingle /> } />
        <Route path="contact/:id" element={ <Contact /> } />
        <Route path="subjectpage/:id" element={ <Subjectpage /> } />
        <Route path="coursesingle/:id" element={ <CourseSingle /> } />

        {/* Main page urls */}
        {/* all schools */}
        <Route path="/" element={ <Main /> } />
        <Route path="/alluniversities" element={ <AllUniversities /> } />
        <Route path="/allshs" element={ <AllShs /> } />
        <Route path="/alljhs" element={ <AllJhs /> } /> 
        <Route path="/allnursing" element= { <AllNursing /> } /> 
        <Route path="/allteachertraining" element= { <AllTeacherTraining /> } />
        <Route path="/allnvti" element= { <AllNVTI /> } />   
        <Route path="/allothertertiary" element= { <AllOtherTertiary /> } />
        <Route path="/allnursery" element={ <AllNursery />} />
        <Route path="/allcreche" element={ <AllCreche />} />
        <Route path="/allbasic" element={ <AllBasic />} />
        <Route path="/allschools" element={ <AllSchools />} />
        

        {/* all public pages */}
        <Route path="/publicuniversities" element={ <AllPublicUniversities /> } />
        <Route path="/publicnursing" element= { <AllPublicNursing /> } />
        <Route path="/publicteachertraining" element= { <AllPublicTeacher /> } />
        <Route path="/publicnvti" element= { <AllPublicNVTI /> } />
        <Route path="/publicothertertiary" element= { <AllPublicOtherTertiary /> } />
        <Route path="/publicbasic" element={ <AllPublicBasic />} />
        <Route path="/publicshs" element={ <AllPublicSHS />} />
        <Route path="/publicjhs" element={ <AllPublicJHS />} />

        {/* all private pages */}
        <Route path="/privateuniversities" element={ <AllPrivateUniversities /> } />
        <Route path="/privatenursing" element= { <AllPrivateNursing /> } />
        <Route path="/privateteachertraining" element= { <AllPrivateTeacherTraining /> } />
        <Route path="/privatenvti" element= { <AllPrivateNVTI /> } />
        <Route path="/privateothertertiary" element= { <AllPrivateOtherTertiary /> } />
        <Route path="/privatebasic" element={ <AllPrivateBasic />} />
        <Route path="/privateshs" element={ <AllPrivateSHS />} />
        <Route path="/privatejhs" element={ <AllPrivateJhs />} />
        <Route path="/privatenursery" element={ <AllPrivateNursery />} />
        <Route path="/privatecreche" element={ <AllPrivateCreche />} />

        {/* shs schools by category */}
        <Route path="/categorya" element={<CategoryA />} />
        <Route path="/categoryb" element={<CategoryB />} />
        <Route path="/categoryc" element={<CategoryC />} />
        <Route path="/categoryd" element={<CategoryD />} />
        
        {/* news */}
        <Route path="/mainnews" element={ <MainNews />} />
        <Route path="/mainnewssingle/:id" element={ <MainNewsSingle />} />
        <Route path="/health" element={ <Health />} />
        <Route path="/sports" element={ <Sports />} />
        <Route path="/politics" element={ <Politics />} />
        <Route path="/entertainment" element={ <Entertainment />} />
        <Route path="/business" element={ <Business />} />
        <Route path="/love" element={ <Love />} />
        <Route path="/technology" element={ <Technology />} />

        {/* Board of Directors */}
        <Route path="/boardofdirectors/:id" element={ <BoardofDirectors />} />
        <Route path="/healthfacility/:id" element={ <HealthFacility />} />
        <Route path="/alumni/:id" element={ <Alumni />} />
        <Route path="/departments/:id" element={ <HeadofDepartment />} />
        <Route path="/ptamember/:id" element={ <PtaMember />} />
        <Route path="/headofschool/:id" element={ <HeadofSchool />} />
        <Route path="/departmentheads/:id" element={ <DepartmentHeads />} />
        
        {/* forget password */}
        <Route path="/forgetpassword" element={ <ForgetPassword />} />

        {/* Donation */}
        <Route path="/donation/:id" element={ <Donation />} />
        <Route path="/viewdonation/:id" element={ <ViewDonation />} />
        <Route path="/thankyou" element={ <ThankYou />} />

        


        {/* error 404 */}
        <Route path="*" element={<Error404 />} />

    </Routes>

    )

}

export default FrontIndex;