
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddDeptMembers() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [departments, setDepartments] = useState([]);
  const departmentsOptions = departments.map(department => ({ value: department.id, label: department.name }));

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: null,
    school: selectedSchool, 
    department: [], 
  });

  const goBack = () => {  
    navigate(-1);
  };
  const [error, setError] = useState(null);
  

  useEffect(() => {

    if(selectedSchool){
      const token = accessToken
         axios.get(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/departments/`,{
        headers: {
        
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDepartments(res.data);
      }
        )
      
      .catch(error => console.error(error));
    }
  }, [selectedSchool, accessToken]);

  const handleSelectChange = (selectedOptions) => {
    setFormData({ ...formData, course: selectedOptions.map(option => option.value) });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("position", formData.position);
    formDataObj.append("school", formData.school); 
    if (formData.image) {
      formDataObj.append("image", formData.image);
  }
    formData.department.forEach((departmentId) => {
      formDataObj.append('department', departmentId);   
    });
  
    try {
      const token = accessToken
      for (let pair of formDataObj.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/departmentmembers/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
        
      });
      Swal.fire({
        icon: "success",
        title: "Department Member Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      goBack();
    } catch (err) {
      if (err.response.status === 400) {
      setError("Name already exist. Please try again.");
    }
    else{
      setError("Something went wrong. Please try again later.");
    }
  }
  };

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="back-button">Add Department Mem</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Department Member</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
            <label className="mb-1 col-6"><strong>Select department</strong></label>
            <Select 
              Id="department" 
              isMulti 
              onChange={handleSelectChange} 
              options={departmentsOptions}
              className="col-6" 
            />
            <div className="form-group col-6"><br></br>
              <label className="mb-1 "><strong>Name</strong></label>
               <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-control" required />
            </div><br></br>
            <div className="form-group col-6">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
             </div><br></br>
                <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.image ? formData.image.name : "Upload Subject Image"}
                  <input type="file" id="image" name="image" onChange={handleChange} className="d-none" />
                </label><br/>
              {formData.image && (
                typeof formData.image === 'string'
                ? <img src={formData.image} alt="Existing" style={{ width: '100px', height: '100px' }} />
                : <img src={URL.createObjectURL(formData.image)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
                  )}<br/><br/>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
        </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddDeptMembers;


