

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



const baseUrl = "https://myghschool.myghschools.com/school";

function EditAchievement() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool} = useContext(SchoolContext);
  const [selectedImages, setSelectedImages] = useState([]);
  const [setInitialGalleryImages] = useState([]);
  const [backendErrors, setBackendErrors] = useState(null);
  const {id} = useParams();
  const [ setOldDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [setResponsess] = useState([]); // to store the response from the backend

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    mainImage: null,
    image: null,
    achievedate: "",
    school: selectedSchool,//to ensure that the course is selected to be associated with the subject
  });


  const goBack = () => {  
    navigate(-1);
  };
  const [error] = useState(null);


  const handleSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    e.target.value = "";
    setFormData(prevState => ({ ...prevState, image: selectedFilesArray }));

  };


const removeImageFromGallery = (imageId) => {
  const updatedImages = formData.image.filter(image => image.id !== imageId);
  setFormData(prev => ({ ...prev, image: updatedImages }));
}

  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
      axios.get(`${baseUrl}/schools/${selectedSchool}/achievements/${id}/`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData({
          ...res.data,
          image: null, 
          date: formatDate(res.data.date),

        });
        setInitialGalleryImages(res.data.galleryimages);
        setOldDate(res.data.date);
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id, setFormData, setInitialGalleryImages, setOldDate]);

const formatDate = (dateStr) => {
  let date = new Date(dateStr);
  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);
  return `${day}-${month}-${year}`;
}

const handleChange = (e) => {
  const { name, value } = e.target;
  if (name === "mainImage") {
    setFormData({ ...formData, mainImage: e.target.files[0] });
  }
  else {
      // const { value } = e.target;/
    setFormData({ ...formData, [name]: value });
  }
}


const handleSubmit = async (e) => {
  e.preventDefault();
  const formDataObj = new FormData();
  if (formData.title === '') {
    formDataObj.append("title", null); // or whatever represents "no data" in your backend
  } else {
    formDataObj.append("title", formData.title);
  }

  if (formData.title === '') {
    formDataObj.append("description", null); // or whatever represents "no data" in your backend
  } else {
    formDataObj.append("description", formData.description);
  }
  
  if (formData.mainImage && formData.mainImage.name) { // Check if the image file is an object with a name property
    formDataObj.append("mainImage", formData.mainImage);
  } else if (typeof formData.mainImage === 'string') { // If the image file is a string (URL), don't append it
  } else if (formData.mainImage) { // If the image file is a Blob without a name property
    formDataObj.append("mainImage", formData.mainImage, 'image.jpg'); // Add a filename
  }

 if (formData.achievementimages) {
  formData.achievementimages.forEach(img => {
      formDataObj.append("achievementimages", img.id); // assuming that each image object has an id
  });
}
if (formData.image) {
  for (let i = 0; i < formData.image.length; i++) {
  formDataObj.append("image", formData.image[i]);
  }

}
  formDataObj.append("school", formData.school); 

  if (formData.achievedate) {
      formDataObj.append("achievedate", formData.achievedate);
  }

  try {
    const token = accessToken
    const response = await axios.patch(`https://myghschool.rubykaryo.com/school/schools/${selectedSchool}/achievements/${id}/`, formDataObj, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`,
      },
    });
    setResponsess(response.data);
    Swal.fire({
      icon: "success",
      title: "achievement updated Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
    
    goBack();
  } catch (err) {
    if (err){
      setBackendErrors(err.response.data);
  }
}

};


if (isLoading) {
  return <h2>Loading...</h2>; // Render a loading message while the data is being fetched
}

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
        <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit Achievement</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Achievement</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
        <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
              <label className="mb-1 "><strong>Title</strong></label>
               <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} className="form-control" required />
            </div>
            </div>

            <div className="col-lg-6 mb-2">
            <div className="form-group">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
               <button type="button" onClick={() => setFormData(prev => ({...prev, description: ''}))} className="btn btn-sm btn-danger">Clear</button>   
             </div>
             </div>
              <br/>
            <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
                <label className="mb-1 "><strong>Date</strong></label><br/>
                <label className="mb-1 "><strong>Achievement Date</strong></label>
                {backendErrors?.response?.data?.date && backendErrors?.response?.data.date === "This field may not be blank." ? <div className="text-red " style={{fontWeight:'bold'}}> Date required</div>: null}
                <DatePicker selected={formData.achievedate ? new Date(formData.achievedate) : null} onChange={(date) => setFormData({ ...formData, achievedate: date })} className="form-control mobile-date-input" />
            </div>
            </div>
            <label className="btn btn-success col-lg-6 col-xl-6 mb-2 " >
                {formData.mainImage ? formData.mainImage.name : "Upload Main Achievement Image"}
                  <input type="file" id="mainImage" name="mainImage" onChange={handleChange} className="d-none" />
                </label><br/>
                <div className="edit-content">
              {formData.mainImage && (
                typeof formData?.mainImage === 'string'
                ? <img src={formData?.mainImage} alt="Existing" />
                : <img src={URL.createObjectURL(formData.mainImage)} alt="Uploaded" />
                  )}
                   </div><br/><br/>
            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label className="btn btn-success w-100" >
                    {formData.images ? `${formData.image.length} files selected` : "You Can Upload More Than One Images"}
                    <input type="file" id="image" name="image" onChange={handleSelectFile} multiple className="d-none" />
               
                      {selectedImages.length > 0 &&
                      (selectedImages.length > 10 ? (
                        <p className="error">
                          You can't upload more than 10 images! <br />
                          <span>
                            please delete <b> {selectedImages.length - 10} </b> of them{" "}
                          </span>
                        </p>
                      ) : (<div className="upload-btn">
                        {selectedImages.length} IMAGE SELECTED
                      {selectedImages.length === 1 ? "" : "S"} 
                      </div>
                    ))}
                    </label>
                    <div className="row multi-images img">
                    {formData.images && formData.image.map((imageObj, index) => (
                      
                          <div key={imageObj.id} className="col-md-6">
                            <div className="image position-relative">
                            <img src={imageObj.image} alt="upload" className="img-fluid" style={{height:'150px'}}/>
                            <button type="button" onClick={() => removeImageFromGallery(imageObj.id)} className="btn btn-sm btn-danger position-absolute" style={{top: '5px', right: '5px'}}> X </button>
                            <p>{index + 1}</p>
                          </div>
                          </div>
                      ))}
                    </div>
                      </div>
                      </div>
                <Button variant="primary" type="submit">
                    Submit Update
                </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditAchievement;

