import React from 'react'
import {Link, useParams } from 'react-router-dom'
import { useState, useContext, useEffect } from 'react'
import axios from 'axios';
import {SchoolContext} from '../../../../AuthContext/SchoolProvider';
import SchoolHeader from '../SchoolHeader';


const baseUrl='https://myghschool.myghschools.com/school';
const ThankYou = () => {
  
    useEffect(()=>{
        document.title =  "MGS | Thank You ";
    }); 
    
  const [schools, setSchools] = useState(null);
  const [contact, setContact] = useState(null);
  const { id } = useParams();
  const { selectedSchool} = useContext(SchoolContext);

  
  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${id}`);
      setSchools(response.data);
      } catch (error) {
      }
    };
  fetchSchoolData();
  }, [id]);


  useEffect(() => {
    const fetchContactData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
      setContact(response.data);
      } catch (error) {
      }
    };
    fetchContactData();
    }, [selectedSchool]);



  return (
    <React.Fragment>

  <div className="inner-page">

		<div className="full-width-header">

    <SchoolHeader /> 


		<div className="rs-breadcrumbs breadcrumbs-overlay" style={{ backgroundImage: `url(${schools && schools?.banner})` , backgroundSize: '100%'}}>
		    <div className="breadcrumbs-inner">
		        <div className="container">
		            <div className="row">
		                <div className="col-md-12 text-center"><br/><br/>
		                    <h1 className="page-title pt-5">Thank You For Donating</h1>
		                    <ul>
		                        <li>
		                         <Link to={`/schoolhome/${selectedSchool}`} className="active">Home</Link>
		                        </li>
		                        <li>Donations</li>
		                    </ul>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>

        <div className="rs-events-2 sec-spacer">
       
            <div className="card container pt-5">
            <div className="abt-title text-center">
            <h2>Thank You for donating to our a school's project.</h2>
            <p style={{fontSize:"1.2em", fontFamily:'inherit'}}>We are grateful and we ask that you visit the donation page to see your contribution to the project</p>
			<Link to={`/donation/${selectedSchool}`} className="btn btn-primary">Go to Home Page</Link><br/><br/>
          </div>
                
       	 </div>
        </div>

   
    
   <footer id="rs-footer" className="bg3 rs-footer mt-0">
    <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 &&  contact[0]?.address}<br/>
 								{schools && schools?.town}<br/>
 								{schools && schools?.district}<br/>
 								{schools && schools?.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 &&  contact[0]?.gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.phone_number}<br/>
									{contact && contact.length > 0 &&  contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 &&  contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 && contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.facebook}<br/>
									{contact && contact.length > 0 && contact[0]?.instagram}<br/>
									{contact && contact.length > 0 && contact[0]?.website}<br/>
									{contact && contact.length > 0 &&  contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>
           

        <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
        	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true" className="fa fa-close"></span>
	        </button>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="search-block clearfix">
                        <form>
                            <div className="form-group">
                                <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</div>
</div>

</React.Fragment>
   
  )
}

export default ThankYou