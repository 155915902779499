
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";

function AddContact() { 
  useEffect(()=>{
		document.title = "MGS | Contact";
	}); 
    

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [backendErrors, setBackendErrors] = useState(null);
  const [setResponsess] = useState(null);
  const [formData, setFormData] = useState({
    phone_number: "",
    phone_number2: "",
    email: "",
    address: "",
    website: "",
    gps_address: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    whatsapp: "",
    school: selectedSchool, 
  });

  const goBack = () => {  
    navigate(-1);
  };
  const [ setError] = useState(null);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("phone_number", formData.phone_number);
    formDataObj.append("phone_number2", formData.phone_number2);
    formDataObj.append("email", formData.email);
    formDataObj.append("address", formData.address);
    formDataObj.append("website", formData.website);
    formDataObj.append("gps_address", formData.gps_address);
    formDataObj.append("facebook", formData.facebook);
    formDataObj.append("instagram", formData.instagram);
    formDataObj.append("twitter", formData.twitter);
    formDataObj.append("youtube", formData.youtube);
    formDataObj.append("whatsapp", formData.whatsapp);
    formDataObj.append("school", formData.school); //school field
    try {
      const token = accessToken
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/contacts/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      setResponsess(response)
      Swal.fire({
        icon: "success",
        title: "Gallery Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      
      goBack();

    } catch (err) {
      if (err.response.status === 400) {
      setError("Name already exist. Please try again.");
      setBackendErrors(err.response.data);
    }
    else{
      setError("Something went wrong. Please try again later.");
    }
  }

  };
  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Contact Details</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Contact</button><br/><br/>
          {/* <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span> */}
          <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Phone Number</strong></label>
              {backendErrors?.phone_number && backendErrors?.phone_number=== "contact with this phone number already exists."? <div className="text-red " style={{fontWeight:'bold'}}> Phone number already exists</div>: null}
               <input type="number" id="phone_number" name="phone_number" value={formData.phone_number} onChange={handleChange} className="form-control" required />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Phone Number 2</strong></label>
              {backendErrors?.phone_number2 && backendErrors?.phone_number2 === "contact with this phone number2 already exists."? <div className="text-red " style={{fontWeight:'bold'}}> Phone number already exists</div>: null}
               <input type="number" id="phone_number2" name="phone_number2" value={formData.phone_number2} onChange={handleChange} className="form-control" required />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Email</strong></label>
               <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="form-control" required />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Address</strong></label>
               <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} className="form-control" required />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>GPS Address</strong></label>
               <input type="text" id="gps_address" name="gps_address" value={formData.gps_address} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong> Website</strong></label>
               <input type="text" id="website" name="website" value={formData.website} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Facebook</strong></label>
               <input type="text" id="facebook" name="facebook" value={formData.facebook} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Twitter</strong></label>
               <input type="text" id="twitter" name="twitter" value={formData.twitter} onChange={handleChange} className="form-control"  />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Instagram</strong></label>
               <input type="text" id="instagram" name="instagram" value={formData.instagram} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Youtube</strong></label>
               <input type="text" id="youtube" name="youtube" value={formData.youtube} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Whatsapp</strong></label>
               <input type="number" id="whatsapp" name="whatsapp" value={formData.whatsapp} onChange={handleChange} className="form-control" />
            </div>
            </div>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddContact;


