

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const baseUrl="https://myghschool.myghschools.com";

function EditNews() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    title: "",
    category: "",
    story: "",
    author: "",
    featured_image: "",
    image2: "",

  });


  const goBack = () => {  
    navigate(-1);
  };
  const [error, setError] = useState(null);


  useEffect(() => {

      const token = accessToken
      axios.get(`${baseUrl}/schoolblog/${id}/`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData({
          ...res.data,          
        });
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    
  }, [accessToken, id]);



const handleChange = (e) => {
  const { name, value } = e.target;
  if (["image2", "featured_image"].includes(name)) {
    setFormData({ ...formData, [name]: e.target.files[0] });
  } else {
    setFormData({ ...formData, [name]: value });
  }
};


const handleSubmit = async (e) => {
  e.preventDefault();
  const formDataObj = new FormData();
  formDataObj.append("title", formData.title);
  formDataObj.append("category", formData.category);
  formDataObj.append("story", formData.story);
  formDataObj.append("author", formData.author);

  if (formData.featured_image instanceof File) {
    formDataObj.append("featured_image", formData.featured_image);
  } else {
    formDataObj.append("featured_image_url", formData.featured_image);
  }
  if (formData.image2 instanceof File) {
    formDataObj.append("image2", formData.image2);
  } else {
    formDataObj.append("image2_url", formData.image2);
  }

  try {
    const token = accessToken
    const response = await axios.patch(`${baseUrl}/schoolblog/${id}/`, formDataObj, {
      headers: {
        // "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`,
      },
    });
    //... existing submit logic
    Swal.fire({
      icon: "success",
      title: "News updated Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
    goBack();
  } catch (err) {
    setError("Something went wrong. Please try again later.");
  
    }

  if(isLoading){
    return ( <h3>Loading...</h3>
  )}

};

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit News</h3>
          </div>
          <button onClick={goBack} className="btn btn-primary btn-sm col-3">Back to News</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
        
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Title</strong></label>
               <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} className="form-control"/>
            </div>
            </div>
            <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Author</strong></label>
               <input type="text" id="author" name="author" value={formData.author} onChange={handleChange} className="form-control"/>
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
               <label className="text-label" htmlFor="school_level"><strong>Category<span style={{color: 'red'}}>*</span></strong></label>
                  <select id="school_level" name="category" value={formData.category} onChange={handleChange} className="form-select mb-3">
                    <option disabled>{formData.category} </option> 
                    <option value="Entertainment">Entertainment</option>
                    <option value="Sports">Sports</option>
                    <option value="Politics">Politics</option>
                    <option value="Health">Health</option>
                    <option value="Love & Relationship">Love & Relationship</option>
                    <option value="Business">Business</option>
                    <option value="Technology">Technology</option>
                  </select>
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Story</strong></label>
               <ReactQuill theme="snow" value={formData.story} onChange={(content) => setFormData({ ...formData, story: content })} />
              
            </div>
            </div>
            <label className="btn btn-success w-100 col-3" >
                {formData.featured_image ? formData.featured_image.name : "Upload Subject Image"}
                 <input type="file" id="featured_image" name="featured_image" onChange={handleChange} className="d-none" />
                </label>
                {formData.featured_image && (
                typeof formData.featured_image === 'string'
                    ? <img src={formData.featured_image} alt="Existing" style={{ width: '100px', height: '100px' }} />
                    : <img src={URL.createObjectURL(formData.featured_image)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
                )}
       
            <label className="btn btn-success w-100 col-3" >
                {formData.image2 ? formData.image2.name : "Upload Subject Image"}
                 <input type="file" id="image2" name="image2" onChange={handleChange} className="d-none" />
                </label>
                {formData.image2 && (
                typeof formData.image2 === 'string'
                    ? <img src={formData.image2} alt="Existing" style={{ width: '100px', height: '100px' }} />
                    : <img src={URL.createObjectURL(formData.image2)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
                )}
       
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditNews;

