import React, {useState, useContext, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom' 
import axios from 'axios'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import SchoolHeader from './SchoolHeader';
import parse from 'html-react-parser';

const baseUrl='https://myghschool.myghschools.com/school';

const Blog = () => {

  useEffect(()=>{
		document.title = "MGS | Blog";
	});
   

  const { id } = useParams();
  const [blogs, setBlog] = useState(null);
  const [schools, setSchools] = useState(null);
  const [contact, setContact] = useState(null);
  const { selectedSchool } = useContext(SchoolContext);

  
  const truncateDescription = (vision) => {
    if (vision.length > 150) {
      return vision.substring(0, 150) + "...";
    } else {
      return vision;
    }
  };
    

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${id}`);
      setSchools(response.data);
      } catch (error) {
      }
    };


  const fetchBlogData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/blogs`);
    setBlog(response.data);
    } catch (error) {
    }
  };


  const fetchContactData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
    setContact(response.data);
    } catch (error) {
    }
  };

  fetchBlogData();
  fetchSchoolData();
  fetchContactData();
  }, [id, selectedSchool]);



  return (
    // <div>
    <React.Fragment>
    <div className="inner-page">
      
    <SchoolHeader /> 
  
		<div className="rs-breadcrumbs breadcrumbs-overlay" style={{ backgroundImage: `url(${schools && schools?.banner})`, backgroundSize: '100%' }}>
		    <div className="breadcrumbs-inner">
		        <div className="container">
		            <div className="row">
		                <div className="col-md-12 text-center">
		                    <h1 className="page-title pt-5">OUR NEWS</h1>
		                    <ul>
		                        <li>
                            <Link to={`/schoolhome/${selectedSchool}`}>Home</Link>
		                        </li>
		                        <li>News</li>
		                    </ul>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>
    

		  <div className="card blog-page-area sec-spacer">
      <h2 className="text-center">News From Our Campus</h2>
        {blogs && blogs.map((blog, index) => (
          
          <div className="card container pt-3 " key={blog.id}>
				  <div className="row mb-2 blog-inner">
                    <div className="col-lg-3 col-md-3">
                        <div className="blog-images">
                            <Link to={`/blogsingle/${blog.id}`}> <img src={blog?.mainImage} alt="blog" width={100} height={200} style={{}}/></Link>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-3">
                        <div className="blog-content">
                            <div className="date">
                                <i className="fa fa-calendar-check-o"></i> {blog?.created_at}
                            </div>
                            <h4 style={{ textTransform: 'capitalize' }}> <Link to={`/blogsingle/${blog.id}`}>{blog?.title}</Link></h4>
                            <ul className="blog-meta">
                                <li className="time"><Link to={`/blogsingle/${blog.id}`}><i className="fa fa-user-o text-red"></i><span  style={{color:'red'}}> {blog?.author} </span> </Link></li>
                            </ul>
                            <div>{parse(truncateDescription(blog?.description))}</div>
                           <Link to={`/blogsingle/${blog.id}`} className="primary-btn" >Read More</Link>
                        </div> 
                  </div>
			  	  </div>
			  </div>
        ))}
			  
		  </div>
  
        <div id="rs-branches" className="rs-branches sec-color pt-100 pb-70">
              <div className="container">
                      <div className="sec-title mb-70 text-center">
                          <h2>Ads Space</h2>      
                        <p>Consider advertising with us </p>
                      </div>
           
            </div>
          </div>
       
        <footer id="rs-footer" className="bg3 rs-footer mt-0">
 <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 &&  contact[0]?.address}<br/>
 								{schools && schools?.town}<br/>
 								{schools && schools?.district}<br/>
 								{schools && schools?.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 && contact[0]?.gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.phone_number}<br/>
									{contact && contact.length > 0 &&  contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 && contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 && contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 && contact[0]?.facebook}<br/>
									{contact && contact.length > 0 && contact[0]?.instagram}<br/>
									{contact && contact.length > 0 && contact[0]?.website}<br/>
									{contact && contact.length > 0 && contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>



            <div className="footer-bottom">
          <div className="container">
              <div className="copyright text-black">
                  <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
              </div>
          </div>
         </div>
        </footer>
     
        <div id="scrollUp">
            <i className="fa fa-angle-up"></i>
        </div>
        

        <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
        	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true" className="fa fa-close"></span>
	        </button>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="search-block clearfix">
                        <form>
                            <div className="form-group">
                                <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
    {/* // </div> */}
    </React.Fragment>
  )
}

export default Blog