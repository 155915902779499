import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import SchoolNavbar from '../../layouts/SchoolNavbar';
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import axios from 'axios';
import swal from 'sweetalert';
import useAuth from '../../../hooks/useAuth';



const baseUrl='https://myghschool.myghschools.com/school';

function AllEvents(){
	useEffect(()=>{
		document.title = "MGS | Dashboard";
	});

	
	const { selectedSchool } = useContext(SchoolContext);
	const [page, setPage] = useState(1);
	const itemsPerPage = 12; // number of items to return per request

	const {accessToken} = useAuth();
	const [events, setEvents] = useState([]);
	useEffect(() =>{
	axios.get(`${baseUrl}/schools/${selectedSchool}/events`).then((response)=>{
		setEvents(response.data);
	});
	},[selectedSchool]);

	const handleDelete = async (eventId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this school!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/events/${eventId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(events)){
					const newEvents = events.filter(event => event.id !== eventId);
					setEvents(newEvents);
				} else {
					console.error('Schools is not an array: ');
				}
				swal('Good job!', 'Successfully Deleted', "success");
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your school data is safe!");
		  }
		});
	  }

	return(
		<>
		    <SchoolNavbar />
				<div className="widget-heading d-flex justify-content-between align-items-center">
				<h3 className="m-0">All Events</h3>
				<Link to={`/admin/addevent/${selectedSchool}`} className="btn btn-primary btn-sm">Add Event</Link>
			</div>
			<div className="row col-lg-12">
				{events.map((data, index)=>(
					<div className="col-lg-6 col-xl-3" key={index}>
						<div className="card all-crs-wid">
							<div className="card-body">
								<div className="courses-bx">
									<div className="dlab-media">
										<img src={data.mainImage} alt="" />
									</div>
									<div className="dlab-info">
										<div className="dlab-title d-flex justify-content-between">
											<div>
												<h4><Link to={`/admin/viewevent/${data.id}`}>Title: {data.title}</Link></h4>
												<h4>Start Date: {new Date(data.startDate).toLocaleDateString()}</h4>
												<h4>Start Time: {data.openingtime}</h4>
												
												{data.events && data.events.map((event, idx) => (
												<p className="m-0" key={idx}>{event.id}
														<svg className="ms-1" width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
															<circle cx="2" cy="2.5" r="2" fill="#DBDBDB"/>
														</svg>
													</p>
												))}
											</div>	
										</div>
										<button className="btn btn-sm btn-primary " onClick={() => handleDelete(data.id)}>Delete</button>
										<Link to={`/admin/viewevent/${data.id}`}><button  className="btn  btn-sm btn-primary m-3">View</button></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
				
			</div>	
			<div className="pagination-down">
				<div className="d-flex align-items-center justify-content-center flex-wrap">
					<ul>
						<li><Link to={"#"} onClick={() => setPage(page => Math.max(page - 1, 1))}><i className="fas fa-chevron-left"></i></Link></li>
						<li><Link to={"#"} className="active">{page}</Link></li>
						<li><Link to={"#"} onClick={() => setPage(page => Math.min(page + 1, Math.ceil(events.length / itemsPerPage)))}><i className="fas fa-chevron-right"></i></Link></li>
					</ul>
				</div>
			</div>
		</>
	
	)
}
export default AllEvents;


