
import { ColumnFilter } from './ColumnFilter';
import { Link } from 'react-router-dom';

	
export const COLUMNS = [
	
	{
		Header : 'id',
		Footer : 'id',
		accessor: 'id',
		Filter: ColumnFilter,
		Cell: ({row}) => <Link to={`/admin/userdetails/${row.original.user.id}`}>{row.original.user.user_id}</Link>
	},
	{
		Header : 'First Name',
		Footer : 'First Name',
		accessor: 'user.first_name',
		Filter: ColumnFilter,
		Cell: ({row}) => <Link to={`/admin/userdetails/${row.original.user.id}`}>{row.original.user.first_name}</Link>

	},
	{
		Header : 'Last Name',
		Footer : 'Last Name',
		accessor: 'user.last_name',
		Filter: ColumnFilter,
		Cell: ({row}) => <Link to={`/admin/userdetails/${row.original.user.id}`}>{row.original.user.last_name}</Link>
	},
	{
		Header : 'Email Id',
		Footer : 'Email Id',
		accessor: 'user.email',
		Filter: ColumnFilter,
	},
	{
		Header : 'Username',
		Footer : 'Username',
		accessor: 'user.username',
		Filter: ColumnFilter,
	},
	{
		Header : 'Address',
		Footer : 'Address',
		accessor: 'address',
		Filter: ColumnFilter,
	},
	{
		Header : 'Phone',
		Footer : 'Phone',
		accessor: 'phone_number',
		Filter: ColumnFilter,
	},
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone_number'
			},
		]
	},
]

