

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";


const baseUrl="https://myghschool.myghschools.com/";

function EditPTAMember() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    position: "",
    image: null,
    school: selectedSchool, //this is toe ensure that school is selected to be associated with the subject
  });


  const goBack = () => {  
    navigate(-1);
  };
  const [error, setError] = useState(null);
  


  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
         axios.get(`${baseUrl}school/schools/${selectedSchool}/ptas/${id}`,{
        headers: {
        
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData(res.data);
      }
        )  
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id]);

 
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setFormData({ ...formData, [name]: e.target.files[0] || null });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("position", formData.position);

    if (formData.image && formData.image.name) { // Check if the image file is an object with a name property
        formDataObj.append("image", formData.image);
      } else if (typeof formData.image === 'string') { // If the image file is a string (URL), don't append it
        // Do nothing
      } else if (formData.image) { // If the image file is a Blob without a name property
        formDataObj.append("image", formData.image, 'image.jpg'); // Add a filename
      }
    formDataObj.append("school", formData.school); 

    try {
      const token = accessToken
      const response = await axios.patch(`${baseUrl}/schools/${selectedSchool}/ptas/${id}/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      //... existing submit logic
      Swal.fire({
        icon: "success",
        title: "PTA Member Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      goBack();
      
    } catch (err) {
      if (err.response.status === 400) {
      setError("Name already exist. Please try again.");
    }
    else{
      setError("Something went wrong. Please try again later.");
    }
  }
  }; 

  if (isLoading) {
    return <h2>Loading...</h2>; 
  }

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit PTA Member</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to PTA Members</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3 edit-content">
              <label className="mb-1 "><strong>Name</strong></label>
               <input type="text" id="name" name="name" value={formData?.name} onChange={handleChange} className="form-control"  />
            </div>
            </div><br></br>
            <div className="col-lg-6 mb-2">
            <div className="form-group mb-3 edit-content">
               <label className="mb-1 "><strong>Position</strong></label>
               <input type="text" id="position" name="position" value={formData?.position} onChange={handleChange} className="form-control"  />
             </div>
             </div><br></br>
       
                 <label className="btn btn-success edit-content" >
                {formData?.image ? formData?.image?.name : "Upload PTA Member Image"}
                 <input type="file" id="image" name="image" onChange={handleChange} className="d-none" />
                 <div className=" edit-content">
                {formData?.image && (
                typeof formData?.image === 'string'
                    ? <img src={formData?.image} alt="Existing" />
                    : <img src={URL.createObjectURL(formData?.image)} alt="Uploaded" style={{width:"100%", objectFit: "cover"}} />
                )}  </div></label> <br></br>
                <Button variant="primary" type="submit">
                    Update
                </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditPTAMember;

