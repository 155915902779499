import React, {useState, useContext, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import SchoolHeader from './SchoolHeader';
import parse from 'html-react-parser';


const baseUrl='https://myghschool.myghschools.com/school';
const Gallery = () => {

  const { id } = useParams();
  const [gallery, setGallery] = useState(null);
  const [schools, setSchools] = useState(null);
  const [contact, setContact] = useState(null);
  const { selectedSchool} = useContext(SchoolContext);

  useEffect(()=>{
		document.title = "MGS | Gallery";
	});
    
  
  const truncateDescription = (vision) => {
    if (vision.length > 50) {
      return vision.substring(0, 50) + "...";
    } else {
      return vision;
    }
  };
    

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${id}`);
      setSchools(response.data);
      } catch (error) {
      }
    };


  const fetchGalleryData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/galleries`);
    setGallery(response.data);
    } catch (error) {
    }
  };


  const fetchContactData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
    setContact(response.data);
    } catch (error) {
    }
  };

  fetchGalleryData();
  fetchSchoolData();
  fetchContactData();
  }, [id, selectedSchool]);

  const formatDate = (dateStr) => {
    let date = new Date(dateStr);
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  }
  return (
  <React.Fragment>

<div className="inner-page">

<SchoolHeader />


<div className="rs-breadcrumbs breadcrumbs-overlay " style={{ backgroundImage: `url(${schools && schools.banner})` , backgroundSize: '100%' }}>
  <div className="breadcrumbs-inner">
      <div className="container">
          <div className="row">
              <div className="col-md-12 text-center">
                  <h1 className="page-title pt-5">OUR Gallery</h1>
                  <ul>
                      <li>
                      <Link to={`/schoolhome/${selectedSchool}`}>Home</Link>
                      </li>
                      <li>Our Gallery</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>
	
        <div className="rs-gallery-4 rs-gallery sec-spacer">
            <div className=" card container p-5">
            	<div className="sec-title-2 mb-50 text-center">
            	    <h2>GALLERY</h2>      
            		<p>School Activaties In Pictures</p>
            	</div>
            	<div className="row">
                {gallery && gallery.map((gallery, index) => (

                  
                    <div className="col-lg-3 col-md-6 grid-item " key={gallery.id}>
                    <div className="card mb-4 course-item shadow-sm pd-md-5">
                        <div className="course">
                          <Link to={`/gallerysingle/${gallery.id}`}> <img src={gallery?.mainImage} alt="gallery_image" style={{width:"300px", height:'200px'}} /> <br/><br/>
                            <h4 >Title: <span style={{color:'red'}}> {gallery?.title}</span></h4>  </Link>
                       </div>
                        {/* <div className="course-body"> */}
                          <div className="course-desc">
                            <Link to={`/gallerysingle/${gallery.id}`}><h4 className="course-title text-black">Date: {formatDate(gallery.date)}</h4></Link>
                            {/* <p  className="p-2"> */}
                            <h4> Description: </h4> <span style={{fontSize:'1.2em'}}> {parse(truncateDescription(gallery?.description))}</span>
                            {/* </p> */}
                          </div>
                        {/* </div> */}
                   
                    </div>			
                   </div> 

                ))}
            	
        	
            </div>
        </div>

        <div id="rs-branches" className="rs-branches sec-color pt-100 pb-70">
            <div className="container">
                <div className="sec-title mb-70 text-center">
                    <h2>Ads Space</h2>      
                	<p>Consider advertising with us </p>
                </div>
		
			</div>
		</div>

        <footer id="rs-footer" className="bg3 rs-footer mt-0">
    <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 &&  contact[0]?.address}<br/>
 								{schools && schools?.town}<br/>
 								{schools && schools?.district}<br/>
 								{schools && schools?.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 &&  contact[0]?.gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.phone_number}<br/>
									{contact && contact.length > 0 && contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 &&  contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 && contact[0]?.facebook}<br/>
									{contact && contact.length > 0 &&  contact[0]?.instagram}<br/>
									{contact && contact.length > 0 && contact[0]?.website}<br/>
									{contact && contact.length > 0 &&  contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>


        {/* <div id="scrollUp">
            <i className="fa fa-angle-up"></i>
        </div>
         */}
     
        <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
        	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true" className="fa fa-close"></span>
	        </button>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="search-block clearfix">
                        <form>
                            <div className="form-group">
                                <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
  
</div>
</div>
    </React.Fragment>
  )
}

export default Gallery