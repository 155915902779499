
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddSubject() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [courses, setCourses] = useState([]);
  const coursesOptions = courses.map(course => ({ value: course.id, label: course.name }));
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: null,
    // subjects: [],
    school: selectedSchool,  //assuming there's a school field in the course model
    course: [], //assuming there's a courses field in the course model
  });

  const goBack = () => {  
    navigate(-1);
  };
  const [error, setError] = useState(null);
  

  useEffect(() => {
    // Fetch the list of subjects from the server associated with a specific school
    if(selectedSchool){
      const token = accessToken
         axios.get(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/courses/`,{
        headers: {
        
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCourses(res.data);
      }
        )
      
      .catch(error => console.error(error));
    }
  }, [selectedSchool, accessToken]);

  const handleSelectChange = (selectedOptions) => {
    setFormData({ ...formData, course: selectedOptions.map(option => option.value) });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("description", formData.description);
    formDataObj.append("school", formData.school); 
    if (formData.image) {
      formDataObj.append("image", formData.image);
  }
    formData.course.forEach((courseId) => {
      formDataObj.append('course', courseId);
    });
    try {
      const token = accessToken
      for (let pair of formDataObj.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/subjects/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
        
      });
      Swal.fire({
        icon: "success",
        title: "Subject Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      goBack();
    } catch (err) {
      if (err.response.status === 400) {
      setError("Name already exist. Please try again.");
    }
    else{
      setError("Something went wrong. Please try again later.");
    }
  }
  };

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Subject</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Subjects</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
          <div className="col-lg-6">
            <label className="mb-1"><strong>Select course</strong></label>
            <Select 
              Id="course" 
              isMulti 
              onChange={handleSelectChange} 
              options={coursesOptions}
              className="col-6" 
            />
            </div>
            <div className="col-lg-6">
            <div className="form-group"><br></br>
              <label className="mb-1 "><strong>Name</strong></label>
               <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-control" required />
            </div></div><br></br>
            <div className="col-lg-6">
            <div className="form-group">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
             </div></div><br></br>
                <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.image ? formData.image.name : "Upload Subject Image"}
                  <input type="file" id="image" name="image" onChange={handleChange} className="d-none" />
                </label><br/>
              {formData.image && (
                typeof formData.image === 'string'
                ? <img src={formData.image} alt="Existing" style={{ width: '100px', height: '100px' }} />
                : <img src={URL.createObjectURL(formData.image)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
                  )}<br/><br/>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
        </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddSubject;


