import React, {useContext, useState} from 'react'
import { Link } from 'react-router-dom'
import { SchoolContext } from '../../../AuthContext/SchoolProvider'
import SchoolNavbar from '../../layouts/SchoolNavbar';
import { Dropdown } from "react-bootstrap";
import useAuth from '../../../hooks/useAuth'
import { useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

const baseUrl = 'https://myghschool.myghschools.com/school'
const Contact = () => {

  useEffect(()=>{
		document.title = "MGS | Contact";
	}); 
    
  const { selectedSchool } = useContext(SchoolContext);

	const {accessToken} = useAuth();
	const [contacts, setContacts] = useState([]);

	useEffect(() =>{
	axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`).then((response)=>{

		setContacts(response.data);
	});

	},[selectedSchool]);

	const handleDelete = async (contactId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this contact Details!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/contacts/${contactId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(contacts)){
					const newContacts = contacts.filter(contact => contact.id !== contactId);
					setContacts(newContacts);
				} else {

				}
				swal('Good job!', 'Successfully Deleted', "success");
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your contact data is safe!");
		  }
		});
	  }

    return (
      <>
      <SchoolNavbar />
      <div className="container">
        {contacts && contacts.length === 0 ? (
               <div className="widget-heading d-flex justify-content-between align-items-center">
               <h3 className="m-0">School Contact Details</h3>
               <Link to={`/admin/addcontact/${selectedSchool}`} className="btn btn-primary btn-sm">Add Contact</Link>
             </div> 
        ):(

        <div className="row">
          <div className="col-lg-8">
            <div className="card">
              <div className="card-header justify-content-between" style={{fontSize:'1.5em'}}>
                <div>
                <strong>School Contact</strong>
                </div>
              <div className="mt-2 mt-6">
								<Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item as="div" className="dropdown-item">
					          <i className="fa-solid fa-pen-to-square text-primary me-2"></i>
                      <Link to={`/admin/editcontact/${contacts[0].id}`}>
                        Edit Contact
                      </Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="div" className="dropdown-item">
					          <i className="fa-solid fa-trash text-primary me-2"></i>
                       <Link to="#" onClick={() => handleDelete(contacts[0].id)}> Delete Contact </Link>
                      </Dropdown.Item>
             
                    </Dropdown.Menu>
                  </Dropdown>						
								</div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-10">
                    <div className="row contacts">
                      <div className='col-md-6'><h5><strong>Phone Number</strong></h5></div>
                      <div className="col-md-6" ><h6 style={{color:'red'}}>{contacts[0]?.phone_number}</h6></div>
                    </div>
                    <div className="row">
                      <div className='col-md-6'><h5 ><strong>Phone Number 2</strong></h5></div>
                      <div className='col-md-6'><h6 style={{color:'red'}}>{contacts[0]?.phone_number2}</h6></div>
                    </div>
                    <div className="row">
                      <div className='col-md-6'><h5><strong>Email</strong></h5></div>
                      <div className='col-md-6'><h6 style={{color:'red'}}>{contacts[0]?.email}</h6></div>
                    </div>
                    <div className="row">
                      <div className='col-md-6'><h5><strong>Address</strong></h5></div>
                      <div className='col-md-6'><h6 style={{color:'red'}}>{contacts[0]?.address}</h6></div>
                    </div>
                    <div className="row">
                      <div className='col-md-6'><h5><strong>GPS Address</strong></h5></div>
                      <div className='col-md-6'><h6 style={{color:'red'}}>{contacts[0]?.gps_address}</h6></div>
                    </div>
                    <div className="row">
                      <div className='col-md-6'><h5><strong>Website</strong></h5></div>
                      <div className='col-md-6'><h6 style={{color:'red'}}>{contacts[0]?.website}</h6></div>
                    </div>
                    <div className="row">
                      <div className='col-md-6'><h5><strong>Facebook</strong></h5></div>
                      <div className='col-md-6'><h6 style={{color:'red'}}>{contacts[0]?.facebook}</h6></div>
                    </div>
                    <div className="row">
                      <div className='col-md-6'><h5><strong>Twitter</strong></h5></div>
                      <div className='col-md-6'><h6 style={{color:'red'}}>{contacts[0]?.twitter}</h6></div>
                    </div>
                    <div className="row">
                      <div className='col-md-6'><h5><strong>Instagram</strong></h5></div>
                      <div className='col-md-6'><h6 style={{color:'red'}}>{contacts[0]?.instagram}</h6></div>
                    </div>
                    <div className="row">
                      <div className='col-md-6'><h5><strong>Youtube</strong></h5></div>
                      <div className='col-md-6'><h6 style={{color:'red'}}>{contacts[0]?.youtube}</h6></div>
                    </div>
                    <div className="row">
                      <div className='col-md-6'><h5><strong>Whatsapp Number</strong></h5></div>
                      <div className='col-md-6'><h6 style={{color:'red'}}>{contacts[0]?.whatsapp}</h6></div>
                    </div>
                    
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          // </div>
             )}
          </div>    
          </>                  
    );
};

export default Contact


