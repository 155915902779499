import React, {useEffect, useState, useContext} from 'react'
import Assets1Loader from '../AssetsLoader'
import {Link} from 'react-router-dom'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import MainNav from './layout/MainNav';
import MainFooter from './layout/MainFooter';
import './layout/search.css';
import axios from 'axios';
import parse from 'html-react-parser';
import { SchoolContext } from "../../../AuthContext/SchoolProvider";

const baseUrl = "https://myghschool.myghschools.com"
const Main = () => {

    const [news, setNews] = useState([]);
    const [schools, setSchools] = useState([]);
    const [featuredSchools, setFeaturedSchools] = useState([]);
    const [verifiedSchools, setVerifiedSchools] = useState([]);
    const { setSelectedSchool } = useContext(SchoolContext);


    const handleSchoolSelect = (schoolId) => {
		setSelectedSchool(schoolId);
	 };


    useEffect(() => {
        document.title = "MGS | Home"
    }, []);

     const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const [month, day, year] = new Date(dateString).toLocaleDateString("en-US", options).split("/");
        return `${day}-${month}-${year}`;
      }
    
   
      const truncateDescription = (description) => {
        if (description.length > 100) {
          return description.substring(0, 100) + "...";
        } else {
          return description;
        }
    };


    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 1024 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 1024, min: 850 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 850, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
 

      
      useEffect(() =>{
        fetchSchoolData();
    },[]);

      const fetchSchoolData = async () => {
        try {
        const response = await axios.get(`${baseUrl}/school/search/publicshs/`);
        setSchools(response.data);
        } catch (error) {
        }
      };


      useEffect(() =>{
        fetchFeaturedSchoolData();
    },[]);
      useEffect(() =>{
        fetchVerifiedSchoolData();
    },[]);

      const fetchFeaturedSchoolData = async () => {
        try {
        const response = await axios.get(`${baseUrl}/school/search/featured/`);
        setFeaturedSchools(response.data);
        } catch (error) {
        }
      };

      const fetchVerifiedSchoolData = async () => {
        try {
        const response = await axios.get(`${baseUrl}/school/search/verified/`);
        setVerifiedSchools(response.data);
        } catch (error) {
        }
      };


      useEffect(() =>{
        fetchNewsData();
    },[]);

     const fetchNewsData = async () => {
        try {
        const response = await axios.get(`${baseUrl}/schoolblog/`);
        setNews(response.data);
        } catch (error) {
        }
      };

  return (
    <React.Fragment>

    <Assets1Loader />


<button className="scroll__top scroll-to-target" data-target="html">
    <i className="fas fa-angle-up"></i>
</button>

<MainNav />
<main>                         
    <section >
        <div className="container card pt-5 mt-2">
            <div className="tgbanner__grid">
                <div className="tgbanner__post big-post">
                    <div className="tgbanner__thumb tgImage__hover">
                    <Link to="/register"><img src="assets1/img/logo/MYGHAN.jpg" alt="img"/></Link>
                    </div>
                    <div className="tgbanner__content">
                        <h2 className="title tgcommon__hover">Do you own or manage a school?</h2>
                        <h5>Register your school now and build your own school website for free</h5>
                        <li className="news-btn"> <Link to="/register" className="btn">Register Now</Link> </li>
                    </div>
                </div>
                <div className="tgbanner__side-post">
                    <div className="tgbanner__post small-post">
                        <div className="tgbanner__thumb tgImage__hover">
                            <Link to="/alluniversities" ><img src="assets1/img/images/publicuni.jpg" alt="img"/></Link>
                        </div>
                        <div className="tgbanner__content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><Link to="/alluniversities">Universities</Link></li>
                            </ul>
                            <Link to="/alluniversities" > <h2 className="title tgcommon__hover">All universities in Ghana</h2></Link>
                        </div>
                    </div>
                    <div className="tgbanner__post small-post">
                        <div className="tgbanner__thumb tgImage__hover">
                        <Link to="/allshs" ><img src="assets1/img/images/public_shs.jpg" alt="img"/></Link>
                        </div>
                        <div className="tgbanner__content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><Link to="/allshs" >Senior High Secondary Schools</Link></li>
                            </ul>
                            <h2 className="title tgcommon__hover"><Link to="/allshs" >All Secondary schools in Ghana </Link></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- banner-area-end -->

    <!-- trending-area --> */}
    <section className="trending-post-area section__hover-line pt-10">
        <div className="card container pt-4 pb-4">
            <div className="section__title-wrap ">
                <div className="row align-items-end">
                    <div className="col-sm-6">
                        <div className="section__title">
                            <span className="section__sub-title">All Category</span>
                            <h3 className="section__main-title">View All Categories </h3>
                        </div>
                    </div>
                    
                </div>
            </div>
            <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={5000} keyBoardControl={true} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]} >
          
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/publicshs"><img src="assets1/img/images/publicshssch.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/publicshs">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/publicshs">ALL PUBLIC SHS</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1 mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/privateshs"><img src="assets1/img/images/shs.jpeg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/privateshs">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/privateshs">ALL PRIVATE SHS</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/publicuniversities"><img src="assets1/img/images/publicuni.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/publicuniversities">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/publicuniversities">ALL PUBLIC UNIVERSITIES</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/privateuniversities"><img src="assets1/img/images/privateuniversities.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="privateuniversities">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/privateuniversities">ALL PRIVATE UNIVERSITIES</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/publicjhs"><img src="assets1/img/images/allpublicjhs.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/publicjhs">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/publicjhs">ALL PUBLIC JHS</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/privatejhs"><img src="assets1/img/images/allprivatejhs.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/privatejhs">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/privatejhs">ALL PRIVATE JHS</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/publicnursing"><img src="assets1/img/images/allpublicnursing.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/publicnursing">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/publicnursing">ALL PUBLIC NURSING</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/privatenursing"><img src="assets1/img/images/allPrivatenursing.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/privatenursing">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/privatenursing">ALL PRIVATE NURSING</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/publicothertertiary"><img src="assets1/img/images/myghschool.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/publicothertertiary">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/publicothertertiary">ALL PUBLIC OTHER TERTIARY</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/privateteachertraining"><img src="assets1/img/images/myghschool.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/privateteachertraining">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/privateteachertraining">ALL PRIVATE OTHER TERTIARY</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/publicteachertraining"><img src="assets1/img/images/publicteachertraining.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/publicteachertraining">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/publicteachertraining">ALL PUBLIC TEACHER TRAININGS</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/privateteachertraining"><img src="assets1/img/images/privateteacher_training.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/privateteachertraining">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/privateteachertraining">ALL PRIVATE TEACHER TRAININGS</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/publicbasic"><img src="assets1/img/images/publicbasicsch.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/publicbasic">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/publicbasic">ALL PUBLIC BASIC SCHOOLS</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/privatebasic"><img src="assets1/img/images/privatebasicsch.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/privatebasic">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/privatebasic">ALL PRIVATE BASIC SCHOOLS</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/publicnvti"><img src="assets1/img/images/nvti.jpeg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/publicnvti">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/publicnvti">ALL PUBLIC NVTI</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/privatenvti"><img src="assets1/img/images/myghschool.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/privatenvti">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/privatenvti">ALL PRIVATE NVTI</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/privatecreche"><img src="assets1/img/images/nursery.jpeg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/privatecreche">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/privatecreche">ALL PRIVATE CRECHE</Link></h4>  
                                </div>
                            </div>
                            <div className="trending__post m-1  mb-5">
                                <div className="trending__post-thumb tgImage__hover">
                                    {/* <a href="#" className="addWish"></a> */}
                                    <Link to="/privatenursery"><img src="assets1/img/images/allprivatenursery.jpg" alt="img"/></Link>
                                </div>
                                <div className="trending__post-content">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category"><Link to="/privatenursery">Category</Link></li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"><Link to="/privatenursery">ALL PRIVATE NURSERY</Link></h4>  
                                </div>
                            </div>
            </Carousel>
        </div>
    </section>


    <div className="advertisement pt-4 pb-4">
        <div className="container">
            <div className="col-12">
                {/* <div className="advertisement__image text-center">
                    <Link to="#"><img src="assets1/img/" alt="advertisement"/></Link>
                </div> */}
            </div>
        </div>
    </div>
    
    {/* <!-- advertisement-area-end -->

    <!-- Video-post-area --> */}
    <section className="video-post-area section__hover-line white-bg pt-5 pb-80">
        <div className="container">
            <div className="section__title-wrap mb-40">
                <div className="row align-items-end">
                    <div className="col-sm-6">
                        <div className="section__title">
                            <span className="section__sub-title">Latest</span>
                            <h3 className="section__main-title">Recent News Post</h3>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="section__read-more text-start text-sm-end">
                            <Link to="/mainnews">More News <i className="far fa-long-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-8 col-lg-7">
                        {news && news.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 2).map((news, index) => (
                            
                            <div className=" row mb-4" key={news.id}>
                            <div className="video__post-thumb col-md-6">
                            <Link to={`/mainnewssingle/${news.id}`} ><img src={news.featured_image} style={{width:'400px', height:'300px'}} alt="img"/></Link>
                            </div>
                            <div className="video__post-content col-md-6">
                                <ul className="tgbanner__content-meta list-wrap">
                                    <li className="category"><Link to={`/mainnewssingle/${news.id}`} >{news.category}</Link></li>
                                <br/>
                                    <li>Published on: {formatDate(news.created_at)}</li>
                                </ul>
                                <h3 className="title tgcommon__hover"><Link to={`/mainnewssingle/${news.id}`} ><span style={{color:'red', fontSize:'0.8em'}}>Title: </span> {news.title}</Link></h3>
                                <Link to={`/mainnewssingle/${news.id}`}>
                                    <div><span style={{color:'red', fontSize:'0.8em'}}>Story: </span>{parse(truncateDescription(news.story))}</div>
                                    </Link>
                                    <div className="latest__post-read-more">
                                    <Link to={`/mainnewssingle/${news.id}`}>Read More <i className="far fa-long-arrow-right"></i></Link>
                                    </div>
                            </div>
                            </div>
                    ))}
                   
                </div>
                <div className="col-xl-4 col-lg-5">
                {news && news.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 4).map((news, index) => (
                    <div className="video__post-item side-post" key={news.id}>
                        <div className="video__post-thumb tgImage__hover">
                        <Link to={`/mainnewssingle/${news.id}`} ><img src={news.featured_image} alt="img"/></Link>
                        </div>
                        <div className="video__post-content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><a href="blog.html">{news.category}</a></li>
                                <li><span className="by">By</span> <a href="blog.html">{formatDate(news.created_at)}</a></li>
                            </ul>
                            <h3 className="title tgcommon__hover"><Link to={`/mainnewssingle/${news.id}`} >{news.title}</Link></h3>
                        </div>
                    </div>  
                            ))}          
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Video-post-area-end -->

    <!-- hand-picked-area --> */}
   
    <section className="hand-picked-area black-bg fix section__hover-line pt-5 " >
        <div className="container">
            <div className="section__title-wrap section__title-white mb-40">
                <div className="row align-items-end">
                    <div className="col-sm-6">
                        <div className="section__title">
                            <span className="section__sub-title">All Public SHS</span>
                            <h3 className="section__main-title">All Public SHS </h3>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="section__read-more text-start text-sm-end">
                            <Link to="/allpublicshs">View More <i className="far fa-long-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="trending__slider dark-post-slider">
            <div className="swiper-container handpicked-active">
            {/* <div className="swiper-wrapper"> */}
            <div className="row">
            <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={5000} keyBoardControl={true} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]} >

            {/* className="trending__post"     tgImage__hover      */}
                    {schools && schools.slice(0, 10).map((school, index) => (


                        <div className="trending__post mb-40" key={school.id}>
                            <div className="trending__post-thumb tgImage__hover ">
                                {/* <Link to="/allprivateprimary" className="addWish"></Link> */}
                                <Link to={`schoolhome/${school.id}`} style={{width:'320px', height:'240px'}} onClick={() => handleSchoolSelect(school.id)}><img src={school.school_logo} alt="img"/></Link>
                            </div>
                            <div className="trending__post-content">
                                <ul className="tgbanner__content-meta list-wrap">
                                    <li><span className="by">Alias: </span> </li>
                                    <li> <Link to={`schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}>{school && school.school_alias}</Link></li>
                                </ul>
                                <h4 className="title tgcommon__hover " ><Link to={`schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}>{school && school.school_name}</Link></h4>
                            </div>
                        </div>
                         ))}
                 
                </Carousel>
                   </div>  
            </div>
        </div>
      
    </section>
    {/* <!-- hand-picked-area-end -->

    <!-- featured schools--> */}
    <section className="stories-post-area section__hover-line pt-5 pb-40">
        <div className="card container pt-5">
            <div className="section__title-wrap mb-40">
                <div className="row align-items-end">
                    <div className="col-sm-6">
                        <div className="section__title">
                            <span className="section__sub-title">Featured Schools</span>
                            <h3 className="section__main-title">Featured Schools For The Week</h3>
                        </div>
                    </div>
                </div>
            </div>
            <h2>Featured Schools</h2>
            <div className="row pb-4">
            <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={5000} keyBoardControl={true} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]} >
            {/* <div className="col-xl-3 col-lg-4 col-md-6" > */}
            {featuredSchools && featuredSchools.slice(0, 20).map((school, index) => (
         
                    <div className="trending__post stories-small-post__item" key={school.id} style={{ margin: '10px' }}>
                        <div className="trending__post-thumb tgImage__hover">
                            <Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)} ><img src={school.school_logo} alt="img" style={{width:'520px', height:'340px'}}/></Link>
                        </div>
                        <div className="trending__post-content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}><span style={{color:'black'}}>Alias: </span>{school.school_alias}</Link></li>
                                <li><i className="fa-sharp fa-solid fa-location-dot" style={{color:'red'}}></i>&nbsp;<Link to={`/schoolhome/${school.id}`} >{school.town}</Link></li>
                            </ul>
                            <h4 className="title tgcommon__hover"><Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}>{school.school_name}</Link></h4><br/>
                        </div>
                    </div>
                      ))} 
                {/* </div> */}
                </Carousel>
            </div>
        </div>
    </section>
    <section className="stories-post-area section__hover-line ">
        <div className="card container pt-5">
            <div className="section__title-wrap mb-40">
                <div className="row align-items-end">
                    <div className="col-sm-6">
                        <div className="section__title">
                            <span className="section__sub-title">Verified Schools</span>
                            <h3 className="section__main-title">Verified Schools For The Month</h3>
                        </div>
                    </div>
                </div>
            </div>
            <h2>Verified Schools</h2>
            <div className="row pb-4">
            <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={5000} keyBoardControl={true} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]} >
            {/* <div className="col-xl-3 col-lg-4 col-md-6" > */}
            {verifiedSchools && verifiedSchools.slice(0, 20).map((school, index) => (
         
                    <div className="trending__post stories-small-post__item" key={school.id} style={{ margin: '10px' }}>
                        <div className="trending__post-thumb tgImage__hover">
                            <Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)} ><img src={school.school_logo} alt="img" style={{width:'520px', height:'340px'}}/></Link>
                        </div>
                        <div className="trending__post-content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}><span style={{color:'black'}}>Alias: </span>{school.school_alias}</Link></li>
                                <li><i className="fa-sharp fa-solid fa-location-dot" style={{color:'red'}}></i>&nbsp;<Link to={`/schoolhome/${school.id}`} >{school.town}</Link></li>
                            </ul>
                            <h4 className="title tgcommon__hover"><Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}>{school.school_name}</Link></h4><br/>
                        </div>
                    </div>
                      ))} 
                {/* </div> */}
                </Carousel>
            </div>
        </div>
    </section>

</main>

<MainFooter/>


    </React.Fragment>
  )
}

export default Main