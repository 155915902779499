import React, { useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { useEffect } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const baseUrl="https://myghschool.myghschools.com/";

function EditSchool() {
  useEffect(()=>{
		document.title = "MGS | Edit School";
	});

    const { accessToken, userId } = useAuth(); // user is an object with keys [id, username, email, first_name, last_name, is_staff, 
    let navigate = useNavigate();
    const {id} = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [setResponsess] = useState("");

    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [ourHistory, setOurHistory] = useState("");
    const [ourVision, setOurVision] = useState("");
    const [ourMission, setOurMission] = useState("");

    useEffect(() => {
      if (!id) {
        navigate('/dashboard');
      }
    }, [id, navigate]);

    useEffect(() => {
      const fetchData = async () => {
        if (userId) {
           
        try {
        const response = await axios.get(`${baseUrl}user/users/${userId}`, {
          headers: {
          Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
            setUser(response.data);
            setLoading(false);
          })
          setResponsess(response.data);
        } catch (error) {
        }
      }
      };
      fetchData();
      }, [userId, accessToken, setResponsess]);
  
      

    const [formData, setFormData] = useState({
        school_name: "",
        year_established: "",
        school_web_name:"",
        school_alias:"",
        school_type:"",
        school_level:"",
        school_category:"",
        status:"",
        address: "",
        town:"",
        region: "",
        is_active:"",
        district: "",
        school_motto: "",
        banner: null,
        school_logo: null,
        our_history_image: null,
        our_vision_image: null,
        our_mission_image: null,
      });

      const goBack = () => {  
        navigate(-1);
      };
      
  const [ setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (["banner", "school_logo", "our_mission_image","our_vision_image","our_history_image"].includes(name)) {
      setFormData({ ...formData, [name]: e.target.files[0] });
     
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    const token = accessToken
    axios.get(`${baseUrl}school/schools/${id}/`,{
        headers: {  
            "Authorization": `Bearer ${token}`,
            },
        })
        .then((res) => {
            setFormData({
                ...res.data,                
            });
            setWelcomeMessage(res.data.welcome_message || "");
            setOurHistory(res.data.our_history || "");
            setOurVision(res.data.our_vision || "");
            setOurMission(res.data.our_mission || "");
            }) 
  }, [accessToken, id]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();

    const updatedFormData = {
      ...formData,
      welcome_message: welcomeMessage,
      our_history: ourHistory,
      our_vision: ourVision,
      our_mission: ourMission,
    };
  formDataObj.append("school_name", formData.school_name);
  formDataObj.append("year_established", formData.year_established);
  formDataObj.append("school_web_name", formData.school_web_name);
  formDataObj.append("school_alias", formData.school_alias);
  formDataObj.append("school_type", formData.school_type);
  formDataObj.append("school_level", formData.school_level);
  formDataObj.append("school_category", formData.school_category);
  formDataObj.append("our_history", updatedFormData.our_history);
  formDataObj.append("our_vision", updatedFormData.our_vision);
  formDataObj.append("our_mission", updatedFormData.our_mission);
  formDataObj.append("address", formData.address);
  formDataObj.append("town", formData.town);
  formDataObj.append("region", formData.region);
  formDataObj.append("status", formData.status);
  formDataObj.append("is_active", formData.is_active);
  formDataObj.append("district", formData.district);
  formDataObj.append("welcome_message", updatedFormData.welcome_message);
  formDataObj.append("school_motto", formData.school_motto);

  if (formData.banner && formData.banner.name) { 
    formDataObj.append("banner", formData.banner);
  } else if (typeof formData.banner === 'string') { 
  } else if (formData.banner) { 
    formDataObj.append("banner", formData.banner, 'image.jpg'); 
  }
 
  if (formData.school_logo && formData.school_logo.name) { 
    formDataObj.append("school_logo", formData.school_logo);
  } else if (typeof formData.school_logo === 'string') { 
  } else if (formData.school_logo) { 
    formDataObj.append("school_logo", formData.school_logo, 'image.jpg');
  }

if (formData.our_history_image && formData.our_history_image.name) { 
    formDataObj.append("our_history_image", formData.our_history_image);
  } else if (typeof formData.our_history_image === 'string') { 
  } else if (formData.our_history_image) { 
    formDataObj.append("our_history_image", formData.our_history_image, 'image.jpg'); 
  }

if (formData.our_vision_image && formData.our_vision_image.name) { // Check if the image file is an object with a name property
    formDataObj.append("our_vision_image", formData.our_vision_image);
  } else if (typeof formData.our_vision_image === 'string') { // If the image file is a string (URL), don't append it
    // Do nothing
  } else if (formData.our_vision_image) { // If the image file is a Blob without a name property
    formDataObj.append("our_vision_image", formData.our_vision_image, 'image.jpg'); // Add a filename
  }

if (formData.our_mission_image && formData.our_mission_image.name) { 
    formDataObj.append("our_mission_image", formData.our_mission_image);
  } else if (typeof formData.our_mission_image === 'string') { 
  } else if (formData.our_mission_image) { // If the image file is a Blob without a name property
    formDataObj.append("our_mission_image", formData.our_mission_image, 'image.jpg'); // Add a filename
  }

    try {
     
    const token = accessToken
      const response = await axios.patch(`${baseUrl}school/schools/${id}/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        
        },       
      });

      if (response.status === 201) {
        setFormData({ name: "", description: "", image: null });
        setError(null);

      if (response.status === 201) {
        setFormData({
          school_name: "",
          year_established: "",
          school_web_name: "",
          school_alias: "",
          school_type: "",
          school_level: "",
          school_category: "",
          our_history: "",
          our_vision: "",
          our_mission: "",
          welcome_message: "",
          address: "",
          town: "",
          region: "",
          status: "",
          is_active: "",
          district: "",
          school_motto: "",
          banner: null,
          school_logo: null,
          our_history_image: null,
          our_vision_image: null,
          our_mission_image: null,
        });
        setError(null);
      Swal.fire({
        icon: "success",
        title: "School update Successfully",
        showConfirmButton: false,
        timer: 1500,
      });  
    }
  }
  goBack();
    } catch (err) {
    setError("Error submitting the form. Please try again.");
    }

  };

  if (loading) {
    return <div>Loading...</div>;
}

  return (
    // <Container>
    <>
    <div className="widget-heading d-flex justify-content-between align-items-center">
    <h3 className="m-0">Edit School</h3>
        </div>
   <button onClick={goBack} className="btn btn-primary btn-sm">Back to School</button><br/><br/>
    <div className="authincation ">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-md-8">
						<div className="authincation-content">
							<div className="row no-gutters">
								<div className="col-xl-12">
									<div className="auth-form">
										<div className="text-center mb-3">
											<Link to="/login">
											</Link>
										</div>
							<h4 className="text-center mb-4 ">Register to have your school online</h4>
                             <form onSubmit={handleSubmit}>
                                <div className="row">
                                <div className="col-lg-12 mb-3">
                                    <div className="form-group mb-3">
                                        <label className="text-label"><strong>School Name<span style={{color: 'red'}}>*</span></strong></label>
                                        <input type="text" name="school_name" value={formData.school_name} onChange={handleChange} className="form-control" required placeholder="Please Enter School Name" />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label"><strong>School Alias<span style={{color: 'red'}}>*</span></strong></label>
                                        <input type="text" name="school_alias" className="form-control" placeholder="eg: Ghanasco"  value={formData.school_alias} onChange={handleChange} required
                                        />
                                    </div>
                                    </div>
                                 
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label"><strong>School Motto<span style={{color: 'red'}}>*</span></strong></label>
                                        <input type="text" name="school_motto" className="form-control"  placeholder="Please enter schoo motto" value={formData.school_motto} onChange={handleChange} required
                                        />
                                    </div>
                                    </div>
                                    {user.is_staff && (
                                          <div className="col-lg-6 mb-2">
                                          <div className="form-group mb-3">
                                          <label className="text-label"><strong>School Category</strong></label>
                                          <select id="school_category" name="school_category" value={formData.school_category} onChange={handleChange}  className="form-select mb-3">
                                                  <option disabled>Select Category of the School</option> 
                                                  <option value="A">A</option>
                                                  <option value="B">B</option>
                                                  <option value="C">C</option>
                                                  <option value="D">D</option>
                                              </select>
                                          </div>
                                          </div>
                                    )}
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label"><strong>Year Established<span style={{color: 'red'}}>*</span></strong></label>
                                        <input  type="date" name="year_established" className="form-control" placeholder="Year the school was established" value={formData.year_established} onChange={handleChange}  required
                                        />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Type of School<span style={{color: 'red'}}>*</span></strong></label>
                                        <select id="school_type" name="school_type" value={formData.school_type} onChange={handleChange} className="form-select mb-3">
                                            <option >Choose... Government or Private</option> 
                                            <option value="government school">Government School</option>
                                            <option value="private school">Private School</option>
                                        </select>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_level"><strong>School Level<span style={{color: 'red'}}>*</span></strong></label>
                                        <select id="school_level" name="school_level" value={formData.school_level} onChange={handleChange} className="form-select mb-3">
                                            <option disabled>Choose... JHS, SHS, etc</option> 
                                            <option value="university">University</option>
                                            <option value="nursing">Nursing</option>
                                            <option value="teachertraining">Teacher Training College</option>
                                            <option value="othertertiary">Other Tertiary</option>
                                            <option value="shs">SHS</option>
                                            <option value="nvti">NVTI</option>
                                            <option value="JHS">JHS</option>
                                            <option value="basic">Primary Schol</option>
                                            <option value="nursery">Nursery & KG</option>
                                            <option value="creche">Creche</option>
                                            required
                                        </select>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="address"><strong>School Address<span style={{color: 'red'}}>*</span></strong></label>
                                        <input  type="text" name="address"  className="form-control"  placeholder="school Address"
                                           value={formData.address} onChange={handleChange} required
                                        />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="town"><strong>School Town<span style={{color: 'red'}}>*</span></strong></label>
                                        <input  type="text" name="town"  className="form-control"  placeholder="Town school is located"
                                          value={formData.town} onChange={handleChange} required/>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="district"><strong>School District<span style={{color: 'red'}}>*</span></strong></label>
                                        <input type="text" name="district" className="form-control" placeholder="District school is located" value={formData.district} onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="region"><strong>School Region<span style={{color: 'red'}}></span></strong></label>
                                    <select id="region" name="region" value={formData.region} onChange={handleChange}      className="form-select mb-3">
                                            <option disabled>Choose... Region</option>
                                            <option value="Ahafo Region">Ahafo Region</option>
                                            <option value="Ashanti Region">Ashanti Region</option>
                                            <option value="Bono Region">Bono Region</option>
                                            <option value="Bono East Region">Bono East Region</option>
                                            <option value="Central Region">Central Region</option>
                                            <option value="Eastern Region">Eastern Region</option>
                                            <option value="Greater Accra Region">Greater Accra Region</option>
                                            <option value="Northern Region">Northern Region</option>
                                            <option value="North East Region">North East Region</option>
                                            <option value="Oti Region">Oti Region</option>
                                            <option value="Savannah Region">Savannah Region</option>
                                            <option value="Upper East Region">Upper East Region</option>
                                            <option value="Upper West Region">Upper West Region</option>
                                            <option value="Western Region">Western Region</option>
                                            <option value="Western North Region">Western North Region</option>
                                        </select>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="status"><strong>School Status<span style={{color: 'red'}}>*</span></strong></label>                                    
                                        <select id="status" name="status" value={formData.status} onChange={handleChange} className="form-select mb-3">
                                            <option disabled>Choose... Day or Boarding</option> 
                                            <option value="Day">Day</option>
                                            <option value="Boarding">Boarding</option>
                                            <option value="Hostel">Hostel</option>
                                        required
                                        </select>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Welcome Message<span style={{color: 'red'}}>*</span></strong></label>
                                    <ReactQuill theme="snow" value={welcomeMessage} onChange={setWelcomeMessage} />
                                    </div>
                                    </div>
                                    
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>School History<span style={{color: 'red'}}>*</span></strong></label>
                                    <ReactQuill theme="snow" value={ourHistory} onChange={setOurHistory} />
                                    </div>
                                    </div>
                                    
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Our Vision<span style={{color: 'red'}}>*</span></strong></label>
                                      <ReactQuill theme="snow" value={ourVision} onChange={setOurVision} />
                                    </div>
                                    </div>
                                    
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Our Mission<span style={{color: 'red'}}>*</span></strong></label>
                                      <ReactQuill theme="snow" value={ourMission} onChange={setOurMission} />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>School logo<span style={{color: 'red'}}>*</span></strong></label>
                                    {formData.school_logo ? formData.school_logo.name : "Upload schoo logo Image"}
                                        <input  type="file" name="school_logo" className="form-control" onChange={handleChange} 
                                        />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>School Banner<span style={{color: 'red'}}>*</span></strong></label>
                                    {formData.banner ? formData.banner.name : "Upload banner image"}
                                        <input type="file" name="banner" className="form-control" onChange={handleChange} />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Our Vission Image<span style={{color: 'red'}}>*</span></strong></label>
                                    {formData.our_vision_image ? formData.our_vision_image.name : "Upload our vision image"}
                                        <input   type="file" name="our_vision_image" className="form-control" onChange={handleChange} />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Our Mission Image<span style={{color: 'red'}}>*</span></strong></label>
                                    {formData.our_mission_image ? formData.our_mission_image.name : "Upload our mission image"}
                                        <input  type="file" name="our_mission_image" className="form-control"  onChange={handleChange} />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Our History Image<span style={{color: 'red'}}>*</span></strong></label>
                                    {formData.our_history_image ? formData.our_history_image.name : "Upload our history image"}
                                        <input type="file"  name="our_history_image" className="form-control" onChange={handleChange} />
                                    </div>
                                    </div>
                                   
                                </div>
		
                                <div className="text-center mt-4">
                                    <button type="submit" className="btn btn-primary btn-block">Update School</button>
                                </div>
							</form>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>


    </>

  );
}

export default EditSchool;
