import React, {useContext, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { SchoolContext } from '../../../AuthContext/SchoolProvider'
import SchoolNavbar from '../../layouts/SchoolNavbar'
import  useAuth  from '../../../hooks/useAuth'
import axios from 'axios'


const AllDonations = () => {

    useEffect(()=>{
		document.title = "MGS | Dashboard";
	});
    
    const { selectedSchool } = useContext(SchoolContext);
    const [donations, setDonations] = useState([])
    const [loading, setLoading] = useState(true)
    const { accessToken } = useAuth();


    useEffect(() => {
        const getDonations = async () => {
            try {
                const response = await axios.get(`https://myghschool.myghschools.com/donation/schools/${selectedSchool}/projects/`,
                {
                    header:{
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                setDonations(response.data)
                setLoading(false)
            } catch (err) {
                console.error(err.message)
            }
        }
        getDonations()
    }, [selectedSchool, accessToken])

	if (loading) {
		return(
			<h2>Loading...</h2>
		)
	}

  return (
    <div>
<SchoolNavbar />
	
<div className="widget-heading d-flex justify-content-between align-items-center">
				<h3 className="m-0">All Donations</h3>
				<Link to={`/admin/adddonation/${selectedSchool}`} className="btn btn-primary btn-sm">Add Donation</Link>
			</div>

			<div className="row">
			{donations && donations.map((data, index)=>(
					<div className="col-xl-4 col-md-6" key={index}>
						<div className="card all-crs-wid">
							<div className="card-body">
								<div className="courses-bx">
									<div className="dlab-media">
										<img src={data.image1} alt="" />
									</div>
									<div className="dlab-info">
										<div className="dlab-title d-flex justify-content-between">
											<div>
												<h4><Link to="#"><span style={{color:'red', fontSize:'0.8em'}}>Title:</span> {data?.title}</Link></h4>
												<h4><Link to="#"><span style={{color:'red', fontSize:'0.8em'}}>Beneficiary:</span> {data?.beneficiary}</Link></h4>
												<h4><Link to="#"><span style={{color:'red', fontSize:'0.8em'}}>Amount:</span> {data?.amount_requested}</Link></h4>
											</div>	
										</div>
										<div className="d-flex justify-content-between content align-items-center">
											<Link to={`/admin/editdonation/${data.id}`} className="btn btn-xs btn-primary m-1">Edit</Link>
											<Link to={`/admin/viewdonation/${data.id}`} className="btn btn-xs btn-primary m-1">View</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
				
			</div>	
    </div>
  )
}

export default AllDonations