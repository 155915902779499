import React, { useState, useEffect, useMemo, useContext  } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Dropdown } from "react-bootstrap";
import { Col, Card,  Tab, Nav} from "react-bootstrap";
import SchoolNavbar from "../../layouts/SchoolNavbar";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import swal from "sweetalert";
import parse from "html-react-parser";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const baseUrl="https://myghschool.myghschools.com/";


const Frontpage = () => {
 
  let {id} = useParams();
  const [schools, setSchools] = useState(null);
  const { selectedSchool, setSelectedSchool } = useContext(SchoolContext);
  const [contact, setContact] = useState(null);
  const { accessToken, userId } = useAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const axiosPrivateInstance = useAxiosPrivate()
  const navigate = useNavigate();
  const [setResponsess] = useState(null);


  useEffect(()=>{
	document.title = "MGS | Dashboard";
});

useEffect(() => {
	// Update the global context whenever the schoolId from the URL changes
	setSelectedSchool(id);
}, [id, setSelectedSchool]);


  useEffect(() => {
	  const FetchData = async () => {
		  if (userId) {
			
		try {
		  const response = await axios.get(`https://myghschool.myghschools.com/user/users/${userId}`, {
			headers: {
			  Authorization: `Bearer ${accessToken}`
			}
		  })
		  .then(response => {
			  setUser(response.data);
			  setLoading(false);
			})
			setResponsess(response.data);
		} catch (error) {
		}
	  }
	  };
	  FetchData();
	}, [userId, accessToken, setResponsess]);

  const verifySchool = async (schoolId) => {
	swal({
		title: "Are you sure?",
		text: "You are about to verify or unverify this School!",
		icon: "warning",
		buttons: true,
		dangerMode: true,
	  })
	  .then(async (willVerify) => {
		if (willVerify) {
	try {
	  const response = await axios.post(`${baseUrl}school/schools/${selectedSchool}/verify/`, {}, {
		  headers: {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${accessToken}`,
		  },
		});
  
	  if (response.data.success) {
		// alert(`School verification status is now: ${response.data.verified_school}`);
		if(response.data.verified_school ===true){
			swal('Good job!', 'Successfully Verified the School', "success");
		}else{
			swal('Good job!', 'Successfully Unverified the School', "success");
		}
		window.location.reload();
	  } else {
		// alert("Failed to verify school");
		swal('Good job!', 'Successfully Deleted', "success");
	  }
	} catch (error) {
	  console.error("Error verifying school:");
	}
	} else {
	swal("School verified status remain the same!");
 			} });				
  };
  

// to verified the school by the admin
  const featuredSchool = async (schoolId) => {
	swal({
		title: "Are you sure?",
		text: "You are about to set this school as featured or unfeatured school!",
		icon: "warning",
		buttons: true,
		dangerMode: true,
	  })
	  .then(async (willFeatured) => {
		if (willFeatured) {
	try {
	  const response = await axios.post(`${baseUrl}school/schools/${selectedSchool}/featured/`, {
		  headers: {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${accessToken}`,
		  },
		});
  
		if (response.data.success) {
			// alert(`School verification status is now: ${response.data.verified_school}`);
			if(response.data.featured_school ===true){
				swal('Good job!', 'Successfully Added School to Featured List', "success");
			}else{
				swal('Good job!', 'Successfully Remove School from Featured List', "success");
			}
			window.location.reload();
		  } else {
			// alert("Failed to verify school");
			swal('Good job!', 'Successfully Deleted', "success");
		  }
	} catch (error) {
	  console.error("Error verifying school:");
	}
	} else {
	swal("School Featured status remain the same!");
 			} });				
  };

  
// to verified the school by the admin
  const is_activeSchool = async (schoolId) => {
	swal({
		title: "Are you sure?",
		text: "You are about to set this school as Activate or Deactivate school!",
		icon: "warning",
		buttons: true,
		dangerMode: true,
	  })
	  .then(async (willDelete) => {
		if (willDelete) {
	try {
	  const response = await axios.post(`${baseUrl}school/schools/${selectedSchool}/is_active/`, {
		  headers: {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${accessToken}`,
		  },
		});
  
		if (response.data.success) {
			// alert(`School verification status is now: ${response.data.verified_school}`);
			if(response.data.is_active ===true){
				swal('Good job!', 'Successfully Activated the School', "success");
			}else{
				swal('Good job!', 'Successfully Block the School', "success");
			}
			window.location.reload();
		  } else {
			// alert("Failed to verify school");
			swal('Good job!', 'Successfully Deleted', "success");
		  }
	} catch (error) {
	  console.error("Error verifying school:");
	}
		} else {
	swal("Your school status remain the same!");
 			} });				
  };

//how to undelete school by the admin
  const handleUndelete = async (schoolId) => {
    try {
		
        const token = accessToken;
		const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        const response = await axios.post(`${baseUrl}school/schools/${selectedSchool}/undelete/`, null, config);

        if(response.status === 200){
            swal('Good job!', 'Successfully Restored', "success");
            // Update the frontend to reflect the change, e.g., refresh the list of schools
        } else {
            swal('Oops', 'Something went wrong!', "error");
        }
        
    } catch (error) {
        swal('Oops', 'Something went wrong!', "error");
    }
}

//soft delete school
  const handleDelete = async (schools) => {
	swal({
	  title: "Are you sure?",
	  text: "Once deleted, you will not be able to recover this school!",
	  icon: "warning",
	  buttons: true,
	  dangerMode: true,
	})
	.then(async (willDelete) => {
	  if (willDelete) {
		try {
		  const token = accessToken
		  const response = await axios.delete(`${baseUrl}school/schools/${selectedSchool}/`,
		  {
			headers: {
			  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
			}
		  });
  
		  if(response.status === 200 || response.status === 204){
			if(Array.isArray(schools)){
				const newSchools = schools.filter(school => school.id !== selectedSchool);
				setSchools(newSchools);
			}
			swal('Good job!', 'Successfully Deleted', "success");
			window.location.href = `/admin/dashboard/${userId}`;
		  } else {
			swal('Oops', 'Something went wrong!', "error");
		  }
		 
		} catch (error) {
		  swal('Oops', 'Something went wrong!', "error");
		}
	  } else {
		swal("Your school data is safe!");
	  }
	});
  }

	useEffect(() => {
		const FetchData = async () => {
			try {
				const response = await axiosPrivateInstance.get(`${baseUrl}school/schools/${id}`);
			
			setSchools(response.data);

			} catch (error) {
				navigate(`/admin/*`);
			}
		}; 
	
		FetchData();
	}, [id, axiosPrivateInstance, selectedSchool]);
	

		useEffect(() =>{
			const fetchData = async () => {
			try{
			const response = await axios.get(`${baseUrl}school/schools/${selectedSchool}/contacts`)
			setContact(response.data);
			} catch(error) {
			};
				}; fetchData();
			},[selectedSchool]);


const historyContent = useMemo(() => {
	return schools ? (
	  <>

		<h3 style={{textDecoration:'underline'}}>This is School History</h3>
		<div style={{fontSize:'14px'}}>{parse(schools?.our_history)}</div><br/>
		<img src={schools?.our_history_image} alt="" className="img-fluid mb-3 w-50 rounded" />
	  </>
	) : (
	  <p>No information to display</p>
	)
  }, [schools]); 
  
  const profileContent = useMemo(() => schools ? (
    <>
                  <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> School Name<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span>{schools?.school_name}</span>
									</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-3">
                      <h5 className="f-w-500"> School Motto<span className="pull-right">:</span></h5>
                    </div>
                  {/* </div> */}
									<div className="col-6">
										<span>{schools?.school_motto}</span>
									</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-3">
                      <h5 className="f-w-500"> School Town<span className="pull-right">:</span></h5>
                    </div>         
									<div className="col-9">
										<span>{schools.town}</span>
									</div>
                  </div>
                  <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> School District<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span>{schools?.district}</span>
									</div>
                  </div>
                  <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> School Region<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span>{schools?.region} </span>
									</div>
                  </div>

    </>
      ) : (
        <p>No informatio display</p>
        
      
  ),[schools]); // <-- schools as a dependency

  const messageContent = useMemo(() =>schools ?(
    <>
      <div style={{fontSize:'14px'}}>{parse(schools.welcome_message)}</div>
    </>
     ) : (
      <p>No data available</p>
  ),[schools]); // <-- schools as a dependency	
  
  const contactContent = useMemo(() => contact && contact[0] ? (
    <>
                <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> Phone Number<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span style={{fontSize:'14px'}}>{contact[0]?.phone_number}</span>
									</div>
                  </div>
                <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> Phone Number 2<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span style={{fontSize:'14px'}}>{contact[0]?.phone_number2}</span>
									</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-3">
                      <h5 className="f-w-500"> Email<span className="pull-right">:</span></h5>
                    </div>
                  {/* </div> */}
									<div className="col-6">
										<span style={{fontSize:'14px'}}>{contact[0]?.email}</span>
									</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-3">
                      <h5 className="f-w-500"> Address<span className="pull-right">:</span></h5>
                    </div>         
									<div className="col-9">
										<span style={{fontSize:'14px'}}>{contact[0]?.address}</span>
									</div>
                  </div>
                  <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> GPS Address<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span style={{fontSize:'14px'}}>{contact[0]?.gps_address}</span>
									</div>
                  </div>
                  <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> Facebook<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span style={{fontSize:'14px'}}>{contact[0]?.facebook} </span>
									</div>
                  </div>
                  <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> Twitter<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span style={{fontSize:'14px'}}> {contact[0]?.twitter}</span>
									</div>
                  </div>
                  <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> Instagram<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span style={{fontSize:'14px'}}> {contact[0]?.instagram} </span>
									</div>
                  </div>
                  <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> Youtube<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span style={{fontSize:'14px'}}>{contact[0]?.youtube} </span>
									</div>
                  </div>
                  <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> Whatsapp Number<span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span style={{fontSize:'14px'}}>{contact[0]?.whatsapp} </span>
									</div>
                  </div>
                  <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> Website <span className="pull-right">:</span></h5>
									</div>
                  {/* </div> */}
									<div className="col-9">
										<span style={{fontSize:'14px'}}> {contact[0]?.website} </span>
									</div>
                  </div>
    </>
     ) : (
      <p>No information to display</p>
  ), [contact]); // <-- schools as a dependency

  const tabData = [
    {
      name: "Our History",
      icon: "history",
      content: historyContent,
    },
    {
      name: "About The School",
      icon: "user",
      content: profileContent,
    },
    {
      name: "Welcome Message",
      icon: "envelope",
      content: messageContent,
    },
    {
      name: "Contact",
      icon: "phone",
      content: contactContent,
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
}
 

  return (
    <div>
		<h3>school Details of <span style={{color:'red'}}> {schools?.school_name}</span></h3>

		  <SchoolNavbar />
      <div>

        <div className="row">
          <div className="col-lg-12">
            <div className="profile card card-body px-3 pt-3 pb-0">
              <div className="profile-head">
                <div className="photo-content-front ">
                  <div className="rounded">
					{schools?.banner ==null ? (
					<h2>Upload School Banner</h2>
					) : (<img style={{width:'100%', height:'300px'}} src={schools?.banner}  alt="profile" />)
						}
				  </div>
                </div>
                <div className="profile-info">
                  <div className="profile-photo">
                    <img
                      src={schools?.school_logo}
                      className="img-fluid rounded-circle"
                      alt="profile" 
                    />
                  </div>
                  <div className="profile-details">
                    <div className="profile-email px-2 pt-2">
                    <h3 className="mb-2 text-black">{schools?.school_name}</h3>
                      <p><span style={{color:'red'}}> School Full Name</span></p>
                    </div>
					<Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
					  as={Dropdown.Toggle}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fa-solid fa-pen-to-square text-primary me-2"></i>
					  <Link to={`/admin/editschool/${id}`} >
                        Edit School
						</Link>
                      </Dropdown.Item>
					
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fa-solid fa-trash text-primary me-2"></i>
					  {schools?.deleted===false ? (
							<Link to="#" onClick={() => handleDelete(schools.id)}> Delete School </Link>
						):(
							<Link to="#" onClick={() =>handleUndelete(schools.id)}>Undelete School</Link>
						)}
                       
                      </Dropdown.Item>
					  {user.is_staff ? ( 
						
						<Dropdown.Item as="div" className="dropdown-item">
                        <i className="fa fa-ban text-primary me-2" />
						{schools?.verified_school===true ? (
							<Link to="#" onClick={() =>verifySchool(schools.id)}>Unverify School</Link>
						):(
							<Link to="#" onClick={() =>verifySchool(schools.id)}>Verify School</Link>
						)}
                      </Dropdown.Item>
					):(null)}
                  
				  {user.is_staff ? ( 
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fas fa-check-circle text-primary me-2" />
					  {schools?.is_active===true ? (
                        <Link to="#" onClick={() =>is_activeSchool(schools.id)}>Block School</Link>
						):(
						<Link to="#" onClick={() =>is_activeSchool(schools.id)}>Unblock School</Link>
						)}
                      </Dropdown.Item>
					  	):(null)}

					{user.is_staff ? ( 
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fas fa-star text-primary me-2" />
					  {schools?.featured_school===true ? (
                        <Link to="#" onClick={() =>featuredSchool(schools.id)}>Unfeatured this School</Link>
						):(
						<Link to="#" onClick={() =>featuredSchool(schools.id)}>Featured this School</Link>
						)}
                      </Dropdown.Item>
					  ):(null)}
                    </Dropdown.Menu>
                  </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="row">
          <div className="col-xl-4">
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-body">
								<div className="profile-statistics">
									<div className="text-center">
										<div className="row">
                    <div className="card-body pt-3"	>	
                          <div className="profile-blog ">
						  {
								schools?.school_category ==='' ? (
									<p>Only <strong>Government Schools</strong> have Category for now. If you are a government school, Contact Admin to update category now.</p>
								) : (
									<h4>{schools?.school_category}</h4>
								)
								}
                            <p className="mb-0 text-red">Category</p>
                          </div>
                        </div>	
                      <div className="card-body pt-1"	>	
                        <div className="profile-blog ">
                      <h4>{schools?.school_type}</h4>
                          <p className="mb-0 text-red">School Type</p>
                        </div>
                      </div>	
                    <div className="card-body pt-1"	>	
                      <div className="profile-blog ">
                        <h4>{schools?.school_level}</h4>
                        <p className="mb-0 text-red">Level</p>
                      </div>
                    </div>	
                    <div className="card-body pt-1"	>	
                      <div className="profile-blog ">
                        <h4>{schools?.status}</h4>
                        <p className="mb-0 text-red">Status</p>
                      </div>
                    </div>	
                        
                      <div className="card-body pt-1"	>	
								<div className="profile-blog ">
							<h4>{schools?.year_established}</h4>
									<p className="mb-0 text-red">Year Established</p>
								</div>
							</div>	
										</div>
									
									</div>
								
								</div>
							</div>
						</div>
					</div>	
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<h5 className="text-primary">Vision</h5>
							</div>	
							<div className="card-body pt-3"	>	
								<div className="profile-blog ">
									
									 <h4 className="text-center">Our Vision</h4>
									<div className="mb-0">
								{typeof schools?.our_vision === 'string' ? parse(schools?.our_vision) : schools?.our_vision}
									</div><br/>
									<img  src={schools?.our_vision_image}  alt="vision_image" className="img-fluid  mb-2 w-50" />
								</div>
							</div>	
						</div>
					</div>
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<h5 className="text-primary">Mission</h5>
							</div>	
							<div className="card-body pt-3"	>	
								<div className="profile-blog ">
									
								 <h4 className="text-center">Our Mission</h4>
									<div className="mb-0">
									{typeof schools?.our_mission === 'string' ? parse(schools?.our_mission) : schools?.our_mission}
									</div>
									<img  src={schools?.our_mission_image}  alt="profile" className="img-fluid  mb-2 w-50" />
								</div>
							</div>	
						</div>
					</div>
				</div>	
            </div>	
        <Col xl={8}>
          <Card>
            <Card.Header>
              <Card.Title>Details of School</Card.Title>
            </Card.Header>
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className="default-tab">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} me-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className="pt-4">
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        <h4 style={{color:'red'}}>{data.name}</h4>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
      
            </div>
          </div> 
        </div>
  );
};

export default Frontpage;
