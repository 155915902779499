import React  from 'react';
import {Link } from 'react-router-dom';
import "swiper/css";
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import swal from "sweetalert";
import SchoolNavbar from '../../layouts/SchoolNavbar';
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";

const baseUrl='https://myghschool.myghschools.com/school';

function AllAlumni(){

	const { selectedSchool } = useContext(SchoolContext);

	const { accessToken } = useAuth();
	const [alumnimembers, setAlumnimembers] = useState(null);
	const [page, setPage] = useState(1);
	const itemsPerPage = 12; // number of items to return per request

	useEffect(() =>{
		axios.get(`${baseUrl}/schools/${selectedSchool}/alumni/`).then((response)=>{
			setAlumnimembers(response.data);
		})	
		.catch((error) => {
		});
		},[selectedSchool]);

		
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const [month, day, year] = new Date(dateString).toLocaleDateString("en-US", options).split("/");
    return `${day}-${month}-${year}`;
  }


	const handleDelete = async (alumnimemberId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this Alumni Member!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/alumni/${alumnimemberId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(alumnimembers)){
					const newAlumnimembers = alumnimembers.filter(alumnimember => alumnimember.id !== alumnimemberId);
					setAlumnimembers(newAlumnimembers);
				} else {
				}
				swal('Good job!', 'Successfully Deleted', "success");
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  console.error(`Error deleting school: ${error}`);
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your Alumni data is safe!");
		  }
		});
	  }


	return(
		<>
		  <SchoolNavbar />
			
				
			<div className="widget-heading d-flex justify-content-between align-items-center">
				<h3 className="m-0">All Alumni Members</h3>
				<Link to={`/admin/addalumni/${selectedSchool}`} className="btn btn-primary btn-sm">Add Alumni Member</Link>
			</div>

			<div className="row">
			{alumnimembers && alumnimembers?.map((data, index)=>(
					<div className="col-xl-4 col-md-6" key={index}>
						<div className="card all-crs-wid">
							<div className="card-body">
								<div className="courses-bx">
									<div className="dlab-media">
										<img src={data?.image} alt="" />
									</div>
									<div className="dlab-info">
										<div className="dlab-title d-flex justify-content-between">
											<div>
												<h4><span style={{color:'red', fontSize:'0.8em'}}>Name: </span>{data?.name}</h4>
												<h4 className="m-0">
												<span style={{color:'red', fontSize:'0.8em'}}>Position:</span> {data?.position && typeof data?.position === 'string' ? data?.position : null}</h4>
										<h4><span style={{color:'red', fontSize:'0.8em'}}>Date Started:</span> {formatDate(data?.started_year)}</h4>
										<h4>
												<span style={{color:'red', fontSize:'0.8em'}}>Date Ended: </span>
												{data?.end_year == null ? 'still in office' : formatDate(data?.end_year)}
												</h4>
											
											</div>	
										</div>
										<div className="d-flex justify-content-between content align-items-center">
										
											<button className="btn btn-xs btn-danger" onClick={() => handleDelete(data.id)}>Delete</button>
											<Link to={`/admin/editalumni/${data.id}`} className="btn btn-xs btn-primary m-1">Edit</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
				
			</div>	
		
			<div className="pagination-down align-content-center">
				<div className="d-flex align-items-center justify-content-center flex-wrap">
					<ul>
						<li><Link to={"#"} onClick={() => setPage(page => Math.max(page - 1, 1))}><i className="fas fa-chevron-left"></i></Link></li>
						<li><Link to={"#"}> {page}</Link></li>
						<li><Link to={"#"} onClick={() => setPage(page => Math.min(page + 1, Math.ceil(alumnimembers.length / itemsPerPage)))}><i className="fas fa-chevron-right"></i></Link></li>
					</ul>
				</div>
			</div>
		</>
	
	)
}
export default AllAlumni;

