import React, {useEffect, useContext, useState} from 'react'
import { Link} from 'react-router-dom'
import axios from 'axios'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import SchoolHeader from './SchoolHeader';


const baseUrl='https://myghschool.myghschools.com/school';

const Contact = () => {

  useEffect(()=>{
		document.title = "MGS | Contact";
	},[]);
   
//   const { id } = useParams();
  const [contact, setContact] = useState(null);
  const [schools, setSchools] = useState(null);
  const { selectedSchool} = useContext(SchoolContext);

  useEffect(() => {
  const fetchContactData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
    setContact(response.data);
    } catch (error) {
    }
  };

  const fetchSchoolData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}`);
    setSchools(response.data);
    } catch (error) {
    }
  };

  fetchContactData();
  fetchSchoolData();
  }, [selectedSchool]);


  return (
   <React.Fragment>
  <div className="full-width-header">

  <SchoolHeader /> 

<div className="rs-breadcrumbs breadcrumbs-overlay" style={{ backgroundImage: `url(${schools && schools?.banner})` , backgroundSize: '100%'}}>
<div className="breadcrumbs-inner">
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
                <h1 className="page-title pt-5">Contact</h1>
                <ul>
                    <li>
                       <Link to={`/schoolhome/${selectedSchool}`}>Home</Link>
                    </li>
                    <li>Cantact</li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div>

<div className="contact-page-section sec-spacer">
  <div className="container">
    <h2 className="text-center">Contact Us For Any Enquiries</h2>

    <div className="row contact-address-section">
    <div className="col-md-6 pl-0">
      <div className="contact-info contact-address">
        <i className="fa fa-map-marker"></i>
        <h4>Address</h4>
        <span style={{color:'red', fontSize:'1.2em'}}>Address: </span>{contact && contact.length > 0 &&  contact[0]?.address}<br/>
 				<span style={{color:'red', fontSize:'1.2em'}}>Town: </span>{schools && schools?.town}<br/>
 				<span style={{color:'red', fontSize:'1.2em'}}>District: </span>{schools && schools?.district}<br/>
 				<span style={{color:'red', fontSize:'1.2em'}}>Region: </span>{schools && schools?.region}<br/>
 				<span style={{color:'red', fontSize:'1.2em'}}>GPS Location: </span>{contact && contact?.length > 0 &&  contact[0]?.gps_address}<br/>	
      </div>
    </div>
    <div className="col-md-6">
      <div className="contact-info contact-phone">
        <i className="fa fa-phone"></i>
        <h4>Phone Number</h4>
        <span style={{color:'red', fontSize:'1.2em'}}>Phone Number: </span>{contact && contact.length > 0 &&  contact[0]?.phone_number}<br/>
        <span style={{color:'red', fontSize:'1.2em'}}>Phone Number 2: </span>{contact && contact.length > 0 &&  contact[0]?.phone_number2}<br/>
        <span style={{color:'red', fontSize:'1.2em'}}>WhatsApp: </span>{contact && contact.length > 0 &&  contact[0]?.whatsapp}<br/>
      </div>
    </div>
    <div className="col-md-6 pr-0">
      <div className="contact-info contact-email">
        <i className="fa fa-envelope"></i>
        <h4>Email Address</h4>
        <span style={{color:'red', fontSize:'1.2em'}}>Email: </span>{contact && contact.length > 0 &&  contact[0]?.email}<br/>
        </div>
      </div>
    <div className="col-md-6 pr-0">
      <div className="contact-info contact-email">
      <i className="fa-sharp fa-solid fa-square-share-nodes"></i>
        <h4>Social Media</h4>
        <span style={{color:'red', fontSize:'1.2em'}}>Facebook: </span> {contact && contact.length > 0 &&  contact[0]?.facebook}<br/>
				<span style={{color:'red', fontSize:'1.2em'}}>Instagram: </span>{contact && contact.length > 0 &&  contact[0]?.instagram}<br/>
				<span style={{color:'red', fontSize:'1.2em'}}>Website: </span>{contact && contact.length > 0 &&  contact[0]?.website}<br/>
				<span style={{color:'red', fontSize:'1.2em'}}>YouTube: </span>{contact && contact.length > 0 &&  contact[0]?.youtube}<br/>
        </div>
      </div>
    </div>

   
  </div>
</div>


<footer id="rs-footer" className="bg3 rs-footer mt-0">
 <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact?.length > 0 &&  contact[0]?.address}<br/>
 								{schools && schools?.town}<br/>
 								{schools && schools?.district}<br/>
 								{schools && schools?.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact?.length > 0 &&  contact[0]?.gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact?.length > 0 &&  contact[0]?.phone_number}<br/>
									{contact && contact?.length > 0 && contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact?.length > 0 &&  contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact?.length > 0 &&  contact[0]?.facebook}<br/>
									{contact && contact?.length > 0 &&  contact[0]?.instagram}<br/>
									{contact && contact?.length > 0 && contact[0]?.website}<br/>
									{contact && contact?.length > 0 &&  contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-red">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-red">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>

    <div id="scrollUp">
        <i className="fa fa-angle-up"></i>
    </div>


    <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" className="fa fa-close"></span>
      </button>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="search-block clearfix">
                    <form>
                        <div className="form-group">
                            <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>
    </React.Fragment>

  )
}

export default Contact