


import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function AddEvent() { 
  useEffect(()=>{
		document.title = "MGS | Dashboard";
	});

  
  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [selectedImages, setSelectedImages] = useState([]);
  const [backendErrors, setBackendErrors] = useState(null);
  const [setResponsess] =useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    mainImage: null,
    image: null,
    startDate: "",
    endDate: "",
    openingtime: "",
    closingtime: "",
    venue: "",
    school: selectedSchool,  //assuming there's a school field in the course model
  });

  const formatDateForBackend = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  

  
  const handleSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    e.target.value = "";
    setFormData(prevState => ({ ...prevState, image: selectedFilesArray }));
  };


  const goBack = () => {  
    navigate(-1);
  };
  const [error, setError] = useState(null);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mainImage") {
      setFormData({ ...formData, mainImage: e.target.files[0] });
    }
    else {
        // const { value } = e.target;/
      setFormData({ ...formData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formDataObj = new FormData();
    formDataObj.append("title", formData.title);
    formDataObj.append("description", formData.description);
    
    if (formData.mainImage) {
        formDataObj.append("mainImage", formData.mainImage);
    }

    if (formData.image) {
        for (let i = 0; i < formData.image.length; i++) {
        formDataObj.append("image", formData.image[i]);
        }
    }
    formDataObj.append("school", formData.school); //school field
    formDataObj.append("venue", formData.venue);
    if (formData.startDate) {
      const formattedStartDate = formatDateForBackend(formData.startDate);
        formDataObj.append("startDate", formattedStartDate);
      }
    if (formData.endDate) {
      const formattedEndDate = formatDateForBackend(formData.endDate);
        formDataObj.append("endDate", formattedEndDate);
      }
    if (formData.openingtime) {
        formDataObj.append("openingtime", formData.openingtime);
      }
    if (formData.closingtime) {
        formDataObj.append("closingtime", formData.closingtime);
      }

    try {
      const token = accessToken
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/events/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      setResponsess(response)
      Swal.fire({
        icon: "success",
        title: "School Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      
      goBack();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setError("Name already exists. Please try again.");
        setBackendErrors(err.response.data)
      } else {
        setError("Something went wrong. Please try again later.");
      }
    }
  setIsLoading(false);

  };
  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Event</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Event</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Title</strong></label>
               <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} className="form-control" required placeholder="Title of Event"/>
            </div>
            </div>
            <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
                <label className="mb-1 "><strong>Event Start Date</strong></label><br/>
                <DatePicker selected={formData.startDate ? new Date(formData.startDate) : null} onChange={(date) => setFormData({ ...formData, startDate: date })} className="form-control mobile-date-input" />
            </div>
            </div>
              <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
                <label className="mb-1 "><strong>Event End Date</strong></label><br/>
                <DatePicker selected={formData.endDate ? new Date(formData.endDate) : null} onChange={(date) => setFormData({ ...formData, endDate: date })} className="form-control mobile-date-input" />
            </div>
            </div>
            <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
                    <label className="mb-1 "><strong>Event Start Time</strong></label><br/>
                    <DatePicker selected={formData.openingtime ? new Date(`1970-01-01T${formData.openingtime}`) : null} onChange={(time) => {const formattedTime = time.toTimeString().substring(0, 5); // Format time as "HH:MM"
                  setFormData({ ...formData, openingtime: formattedTime }); }} showTimeSelect showTimeSelectOnly timeIntervals={15}
                dateFormat="h:mm aa" timeCaption="Time" className="form-control mobile-time-input" />
                </div>
            </div>
            <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
                <label className="mb-1 "><strong>Event Close Time</strong></label><br/>
                <DatePicker selected={formData.closingtime ? new Date(`1970-01-01T${formData.closingtime}`) : null} onChange={(time) => {const formattedTime = time.toTimeString().substring(0, 5); // Format time as "HH:MM"
                  setFormData({ ...formData, closingtime: formattedTime }); }} showTimeSelect showTimeSelectOnly timeIntervals={15}
                dateFormat="h:mm aa" timeCaption="Time" className="form-control mobile-time-input" />
            </div>
            </div>
        
            <div className="col-lg-6 mb-2">
            <div className="form-group">
                <label className="mb-1 "><strong>Venue</strong></label>
                <input type="text" id="venue" name="venue" value={formData.venue} onChange={handleChange} className="form-control" required placeholder="Venue of the Event"/>
            </div>
            </div>
            
            <div className="col-lg-6 mb-2">
            <div className="form-group">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
                <button type="button" onClick={() => setFormData(prev => ({...prev, description: ''}))} className="btn btn-sm btn-danger">Clear</button>   
             </div>
             </div>
                <label className="btn btn-success col-lg-6 mb-2  " >
                {formData.mainImage ? formData.mainImage.name : "Upload Main Event Image"}
                  <input type="file" id="mainImage" name="mainImage" onChange={handleChange} className="d-none" />
                 <br/>
                <div className="edit-content">
              {formData.mainImage && (
                  <div style={{ position: "relative" }}>
                  <button onClick={() => setFormData({...formData, mainImage: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.mainImage === 'string'
                ? <img src={formData.mainImage} alt="Existing" />
                : <img src={URL.createObjectURL(formData.mainImage)} alt="Uploaded" />
              }
                </div> )}
                  </div>
                    </label>
                  <br/><br/>  
            
            <div className="col-lg-6 mb-2">
            <div className="form-group">
            <label className="btn btn-success w-100" >
                    {formData.image ? `${formData.image.length} files selected` : "You Can Upload More Than One Images"}
                    <input type="file" id="image" name="image" onChange={handleSelectFile} multiple className="d-none" />
               
                      {selectedImages.length > 0 &&
                      (selectedImages.length > 10 ? (
                        <p className="error">
                          You can't upload more than 10 images! <br />
                          <span>
                            please delete <b> {selectedImages.length - 10} </b> of them{" "}
                          </span>
                        </p>
                      ) : (<div className="upload-btn">
                        {selectedImages.length} IMAGE SELECTED
                      {selectedImages.length === 1 ? "" : "S"} 
                      </div>
                    ))}
                    </label>
                    <div className="row multi-images img">
                    {selectedImages && selectedImages.map((image, index) => (
                      
                          <div key={image} className="col-md-6">
                            <div className="image position-relative">
                            <img src={image} alt="upload" style={{width: '100%'}}/>
                            <p>{index + 1}</p>
                          </div>
                          </div>
                      ))}
                    </div>
                        </div>
                        </div>
             
                        
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddEvent;


