import React, {useState, useEffect, useContext} from 'react'
import { Link, useParams } from 'react-router-dom'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import axios from 'axios'
import { Modal, Carousel } from "react-bootstrap";
import SchoolHeader from './SchoolHeader';
import parse from 'html-react-parser';


const baseUrl='https://myghschool.myghschools.com/school';

const AchievementSingle = () => {

    useEffect(()=>{
		document.title = "MGS | Achievement";
	});
   
   
  const [schools, setSchools] = useState(null);
  const [achievements, setAchievement] = useState(null);
  const [allachievements, setAllAchievement] = useState(null);   
  const [contact, setContact] = useState(null);
  const { id } = useParams();
  const { selectedSchool } = useContext(SchoolContext);


	const [showModal, setShowModal] = useState(false);
  const [carouselImageIndex, setCarouselImageIndex] = useState(0);


  
	const openModal = (index) => {
    setCarouselImageIndex(index);
    setShowModal(true);
  };

const closeModal = () => setShowModal(false);

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${selectedSchool}`);
      setSchools(response.data);
      } catch (error) {
      }
    };


  const fetchAchievementData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/achievements/${id}`);
    setAchievement(response.data);
    } catch (error) {
    }
  };

  const fetchAllAchievementData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/achievements/`);
    setAllAchievement(response.data);
    } catch (error) {
    }
  };


  const fetchContactData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
    setContact(response.data);
    } catch (error) {
    }
  };

  fetchAchievementData();
  fetchAllAchievementData();
  fetchSchoolData();
  fetchContactData();
  }, [id, selectedSchool]);

  const formatDate = (dateStr) => {
    let date = new Date(dateStr);
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  }
  

  return (
    <React.Fragment>

<div className="inner-page">
   
<SchoolHeader /> 
	
 
		<div className="rs-breadcrumbs breadcrumbs-overlay" style={{ backgroundImage: `url(${schools && schools?.banner})`, backgroundSize: '100%' }}>
		    <div className="breadcrumbs-inner">
		        <div className="container">
		            <div className="row">
		                <div className="col-md-12 text-center pt-5">
		                    <h1 className="page-title">ACHIEVEMENT DETAILS</h1>
		                    <ul>
		                        <li>
                            <Link to={`/schoolhome/${selectedSchool}`} className="active">Home</Link>
		                        </li>
		                        <li>{achievements && achievements?.title}</li>
		                    </ul>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>

        <div className="rs-event-details pt-100 pb-6">
            <div className=" card container pt-5">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="event-details-content">
                            <h3 className="event-title"><span style={{fontSize:"0.8em", color:'red'}}>Title: </span>{achievements && achievements?.title}</h3>
                            <div className="event-meta">
                                <div className="event-date">
                                    <i className="fa fa-calendar"></i>
                                    <span>{achievements && formatDate(achievements?.date)}</span>
                                </div> 
                            </div>
                            <div className="event-img">
                              
                                <img src={achievements && achievements?.mainImage} alt="Event Details Images" />
                            </div>
                            <div className="event-desc">
                            <h3><span style={{color:'red', fontSize:'0.8em'}}>Title: </span>Event Details</h3>
                               <span style={{fontSize:'1.2em'}}>{achievements && parse(achievements?.description)} </span>
                            </div>
                            <div className="col-lg-12 col-md-12">
                        <div className="related-courses">
                        <div id="rs-courses-3" className="rs-gallery sec-spacer"> 
                        <div className="container">
                        <h3 className="text-center ">Images of <span style={{color:'red'}}>{achievements && achievements?.title}</span> achievement</h3><br/>,<br/>
                          <div className="row grid">
                          {achievements && achievements?.images.map((image, i) => (

                              <div className="col-lg-4 col-md-6 grid-item" key={image.id}>
                              <div className="mb-4 course-item shadow-sm">
                                  <img src={image.image} alt="Gallery" onClick={() => openModal(i)} style={{cursor:'pointer', width:'300px', height:'40vh'}} />
                              </div>
                              </div>

                          ))}
                          </div>
                          </div>
                          </div>
                      </div>
                      </div>

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="sidebar-area">                    
                            <div className="latest-courses">
                                <h3 className="title">Other Achievements</h3>
                               <div className="row">
                                  {allachievements && allachievements.slice(0, 6).map((allachievement, i) => (
                                    
                                    <div className="post-item" key={allachievement.id}>
                                    <div className="post-img">
                                    <Link to={`/achievementsingle/${allachievement.id}`}><img src={allachievement?.mainImage} alt="" title="News image"/></Link>
                                    </div>
                                    <div className="post-desc">
                                        <h4><Link to={`/achievementsingle/${allachievement.id}`}>{allachievement?.title}</Link></h4>
                                        <span className="duration"> 
                                        <i className="fa-solid fa-calendar-days"></i> &nbsp;{formatDate(allachievement?.date)}
                                        </span> <br/>
                                      
                                      
                                       
                                    </div>
                                </div>
                                  ))}
                                    
                            </div>
                            </div>
                           
                        </div>
                    </div>

                  
                      <Modal size="lg" show={showModal} onHide={closeModal} className="col-lg-12">
                    <Modal.Header closeButton >
                        <Modal.Title>Achievement Gallery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="card-body pb-1">
                        <Carousel activeIndex={carouselImageIndex} onSelect={setCarouselImageIndex}>
                            {achievements && achievements?.images.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <img className="col-lg-3 col-md-6 mb-4 "
                                        src={image.image} alt="Slide" style={{ width:"80%" }}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Modal.Body>
                   </Modal>
                </div>
            </div>
        </div>
      
        <div id="rs-branches" className="rs-branches sec-color pt-100 pb-70">
            <div className="container">
                <div className="sec-title mb-70 text-center">
                    <h2>ADS SPACE</h2>      
                    <p>Consider advertising with us</p>
                </div>
                           
            </div>
        </div>

            
   <footer id="rs-footer" className="bg3 rs-footer mt-0">
 <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 &&  contact[0]?.address}<br/>
 								{schools && schools?.town}<br/>
 								{schools && schools?.district}<br/>
 								{schools && schools?.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 &&  contact[0]?.gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.phone_number}<br/>
									{contact && contact.length > 0 &&  contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 &&  contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.facebook}<br/>
									{contact && contact.length > 0 && contact[0]?.instagram}<br/>
									{contact && contact.length > 0 &&  contact[0]?.website}<br/>
									{contact && contact.length > 0 && contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>

        <div id="scrollUp">
            <i className="fa fa-angle-up"></i>
        </div>
        

        <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
        	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true" className="fa fa-close"></span>
	        </button>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="search-block clearfix">
                        <form>
                            <div className="form-group">
                                <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

  
</div>
    </React.Fragment>
  )
}

export default AchievementSingle