import React, {useState, useEffect, useContext} from 'react'
import { Link, useParams } from 'react-router-dom'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import axios from 'axios'
import { Modal, Carousel } from "react-bootstrap";
import SchoolHeader from './SchoolHeader';
import parse from 'html-react-parser';


const baseUrl='https://myghschool.myghschools.com/school';

const BlogSingle = () => {

    useEffect(()=>{
		document.title = "MGS | Blog";
	});
   

  const [schools, setSchools] = useState(null);
  const [blogs, setBlogs] = useState(null);
  const [allblogs, setAllBlogs] = useState(null);   
  const [contact, setContact] = useState(null);
  const { id } = useParams();
  const { selectedSchool } = useContext(SchoolContext);


	const [showModal, setShowModal] = useState(false);
  const [carouselImageIndex, setCarouselImageIndex] = useState(0);


  
	const openModal = (index) => {
    setCarouselImageIndex(index);
    setShowModal(true);
  };

const closeModal = () => setShowModal(false);

  
  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${selectedSchool}`);
      setSchools(response.data);
      } catch (error) {
      }
    };


  const fetchBlogData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/blogs/${id}`);
    setBlogs(response.data);
    } catch (error) {
    }
  };

  const fetchAllBlogData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/blogs/`);
    setAllBlogs(response.data);
    } catch (error) {
    }
  };


  const fetchContactData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
    setContact(response.data);
    } catch (error) {
    }
  };

  fetchBlogData();
  fetchAllBlogData();
  fetchSchoolData();
  fetchContactData();
  }, [id, selectedSchool]);


  return (
    <React.Fragment>

<div className="inner-page">
   
<SchoolHeader /> 

		
		<div className="rs-breadcrumbs breadcrumbs-overlay" style={{ backgroundImage: `url(${schools && schools.banner})`, backgroundSize: '100%' }}>
		    <div className="breadcrumbs-inner">
		        <div className="container">
		            <div className="row">
		                <div className="col-md-12 text-center">
		                    <h1 className="page-title">NEWS DETAILS</h1>
		                    <ul>
		                        <li>
                            <Link to={`/schoolhome/${selectedSchool}`} className="active">Home</Link>
		                        </li>
		                        <li>{blogs && blogs.title}</li>
		                    </ul>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>

        <div className="rs-event-details pt-100 pb-6">
            <div className=" card container pt-50">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="event-details-content">
                            <h3 className="event-title pb-30 text-center"><span style={{fontSize:"0.8em", color:'red'}}>Title: </span>{blogs && blogs.title}</h3>
                       
                            <div className="event-img">
                              
                                <img src={blogs && blogs.mainImage} alt="Event Details Images" />
                            </div>
                            <div className="single-blog-details sec-spacer">
                            <h3 className="event-title text-center"><span style={{fontSize:"0.8em", color:'red'}}>Title: </span>{blogs && blogs.title}</h3>
                            <blockquote>
                                  <i class="fa fa-quote-right" aria-hidden="true"></i>
                                  {blogs && parse(blogs.description)}
                              </blockquote>
                            </div>
                            {/* <div id="googleMap"></div> */}
                        
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="sidebar-area">                    
                            <div className="latest-courses">
                                <h3 className="title">Other News</h3>
                               <div className="row">
                                  {allblogs && allblogs.slice(0, 6).map((allblog, i) => (
                                    
                                    <div className="post-item" key={allblog.id}>
                                    <div className="post-img">
                                    <Link to={`/blogsingle/${allblog.id}`}><img src={allblog?.mainImage} alt="" title="News image"/></Link>
                                    </div>
                                    <div className="post-desc">
                                        <h4><Link to={`/blogsingle/${allblog.id}`}>{allblog?.title}</Link></h4>
                                        <span className="duration"> 
                                        <i className="fa-solid fa-calendar-days"></i> &nbsp;{allblog?.created_at}
                                        </span> <br/>
                                        
                                    </div>
                                </div>
                                  ))}
                                    
                            </div>
                            </div>
                           
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="related-courses">
                        <div id="rs-courses-3" className="rs-gallery sec-spacer"> 
                        <div className="container">
                        <h3 classNameName="text-center mb-6">Images of <span style={{color:'red'}}>{blogs && blogs?.title}</span> News</h3>
                          <div className="row grid">
                          {blogs && blogs?.blogimages.map((blogimage, i) => (

                              <div className="col-lg-4 " key={blogimage.id}>
                              <div className="mb-4 course-item shadow-sm">
                                  <img src={blogimage?.image} alt="Gallery" onClick={() => openModal(i)} style={{cursor:'pointer', width:'300px', height:'40vh'}} />
                             
                              </div>
                              </div>

                          ))}
                          </div>
                          </div>
                          </div>
                      </div>
                      </div>

                      <Modal size="lg" show={showModal} onHide={closeModal} classNameName="col-lg-12">
                    <Modal.Header closeButton >
                        <Modal.Title>Blog Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="card-body pb-1">
                        <Carousel activeIndex={carouselImageIndex} onSelect={setCarouselImageIndex}>
                            {blogs && blogs?.blogimages.map((blogimage, index) => (
                                <Carousel.Item key={index}>
                                    <img className="col-lg-3 col-md-6 mb-4 "
                                        src={blogimage.image} alt="Slide" style={{ width:"80%" }}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Modal.Body>
                   </Modal>
                </div>
            </div>
        </div>
      
        <div id="rs-branches" className="rs-branches sec-color pt-100 pb-70">
            <div className="container">
                <div className="sec-title mb-70 text-center">
                    <h2>Ads Space</h2>      
                    <p>Contact admin to place your ads here</p>
                </div>
                           
            </div>
        </div>

            
   <footer id="rs-footer" className="bg3 rs-footer mt-0">
 <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 &&  contact[0]?.address}<br/>
 								{schools && schools?.town}<br/>
 								{schools && schools?.district}<br/>
 								{schools && schools?.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 &&  contact[0]?.gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.phone_number}<br/>
									{contact && contact.length > 0 &&  contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 &&  contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 && contact[0]?.facebook}<br/>
									{contact && contact.length > 0 &&  contact[0]?.instagram}<br/>
									{contact && contact.length > 0 && contact[0]?.website}<br/>
									{contact && contact.length > 0 &&  contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>

        <div id="scrollUp">
            <i className="fa fa-angle-up"></i>
        </div>
        

        <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
        	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true" className="fa fa-close"></span>
	        </button>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="search-block clearfix">
                        <form>
                            <div className="form-group">
                                <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        

</div>
    </React.Fragment>
  )
}

export default BlogSingle