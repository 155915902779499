import React from 'react'

const Assets1Loader = () => {
  return (
    <>
    <link rel="shortcut icon" type="image/x-icon" href="/assets1/img/favicon.png"/>
    {/* <!-- Place favicon.ico in the root directory --> */}
  

    {/* <!-- CSS here --> */}
    <link rel="stylesheet" href="/assets1/css/bootstrap.min.css"/>
    <link rel="stylesheet" href="/assets1/css/animate.min.css"/>
    <link rel="stylesheet" href="/assets1/css/magnific-popup.css"/>
    <link rel="stylesheet" href="/assets1/css/fontawesome-all.min.css"/>
    <link rel="stylesheet" href="/assets1/css/imageRevealHover.css"/>
    <link rel="stylesheet" href="/assets1/css/swiper-bundle.css"/>
    <link rel="stylesheet" href="/assets1/css/flaticon.css"/>
    <link rel="stylesheet" href="/assets1/css/slick.css"/>
    <link rel="stylesheet" href="/assets1/css/spacing.css"/>
    <link rel="stylesheet" href="/assets1/css/main.css"/>

    {/* <!-- JS here --> */}
    <script src="/assets1/js/vendor/jquery-3.6.0.min.js"></script>
    <script src="/assets1/js/bootstrap.min.js"></script>
    <script src="/assets1/js/isotope.pkgd.min.js"></script>
    <script src="/assets1/js/imagesloaded.pkgd.min.js"></script>
    <script src="/assets1/js/jquery.magnific-popup.min.js"></script>
    <script src="/assets1/js/jquery.marquee.min.js"></script>
    <script src="/assets1/js/imageRevealHover.js"></script>
    <script src="/assets1/js/swiper-bundle.js"></script>
    <script src="/assets1/js/TweenMax.min.js"></script>
    <script src="/assets1/js/slick.min.js"></script>
    <script src="/assets1/js/ajax-form.js"></script>
    <script src="/assets1/js/wow.min.js"></script>
    <script src="/assets1/js/main.js"></script>
    </>
  )
}

export default Assets1Loader