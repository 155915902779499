
import React, { createContext, useState, useEffect } from 'react';

// First, create a context for the school data
const SchoolContext = createContext();

// Then create a provider component
const SchoolProvider = ({ children }) => {
  // Initialize the state for the selected school
  const [selectedSchool, setSelectedSchool] = useState(() => {
    const localData = localStorage.getItem('selectedSchool');
    // return localData ? JSON.parse(localData) : null;
    return localData && localData !== "undefined" ? JSON.parse(localData) : null;
  });

  // Sync selectedSchool with localStorage
  useEffect(() => {
    localStorage.setItem('selectedSchool', JSON.stringify(selectedSchool));
  }, [selectedSchool]);

  // This is the value that will be available to components using the context
  const value = {
    selectedSchool,
    setSelectedSchool,
    
  };

  // Render the provider, making the value available to child components
  return (
    <SchoolContext.Provider value={value}>
      {children}
    </SchoolContext.Provider>
  );
};

// Export the SchoolContext and SchoolProvider
export { SchoolContext, SchoolProvider };

