import React,{useState, useEffect, useContext}  from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import SchoolNavbar from '../../layouts/SchoolNavbar';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from '../../../hooks/useAuth';
import { Dropdown, Modal, Carousel } from "react-bootstrap";




const baseUrl='https://myghschool.myghschools.com/school';

const ViewFrontimage =()=> {


	const [frontimages, setFrontimages] = useState([]);
	const { selectedSchool } = useContext(SchoolContext);
	const {accessToken} = useAuth();
	const navigate = useNavigate();

	const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
	

	const openModal = (index) => {
    setSelectedImageIndex(index);
        setShowModal(true);
    };

    const closeModal = () => setShowModal(false);

	const goBack= () =>{
		navigate(-1);
	}
      
	useEffect(() => {
		const fetchGallery = async () => {
		  try {
			const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/frontimage/`);
      setFrontimages(response.data);
         
		  } catch (error) {
		  }
		};
	
		fetchGallery();
	  }, [selectedSchool]);

	  const handleDelete = async (frontimageId) => {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Front image!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async (willDelete) => {
        if (willDelete) {
        try {
          const token = accessToken
          const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/frontimage/${frontimageId}/`,
          {
          headers: {
            'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
          }
          });
      
          if(response.status === 200 || response.status === 204){
          if(Array.isArray(frontimages)){
            const newFrontimages = frontimages.filter(frontimage => frontimage.id !== frontimageId);
            setFrontimages(newFrontimages);
          } 
          swal('Good job!', 'Successfully Deleted', "success");
  
          goBack();
          } else {
          swal('Oops', 'Something went wrong!', "error");
          }
         
        } catch (error) {
          swal('Oops', 'Something went wrong!', "error");
        }
        } else {
        swal("Your front images data is safe!");
        }
      });
      }

  
      return(
        <>
            
        <SchoolNavbar/>
        <div className="col">
        <h2>Front Image Details</h2>
        </div>
        <div className="container">
		      {frontimages && frontimages.length === 0 ? (
               <div className="widget-heading d-flex justify-content-between align-items-center">
               <h3 className="m-0">School Front Images Details</h3>
               <Link to={`/admin/addfrontimage/${selectedSchool}`} className="btn btn-primary btn-sm">Add Front Images</Link>
             </div> 
             ):(
          <div className="row">
            <div className="col-xl-12 col-xxl-7">
              <div className="card">
                <div className="card-body">
                  <div className="course-content d-flex justify-content-between flex-wrap">
                    <div>
                      <h3><span style={{fontSize:'14px', color:'red'}}> Title:</span> {frontimages[0]?.title}</h3>
                    </div>
                    <div className="mt-2">
                    <Dropdown className="dropdown ms-auto">
                        <Dropdown.Toggle
                          variant="primary"
                          className="btn btn-primary light sharp i-false"
                          data-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                          <Dropdown.Item as="div" className="dropdown-item">
                <i className="fa-solid fa-trash text-primary me-2"></i>
                           <Link to="#" onClick={() => handleDelete(frontimages[0].id)}> Delete Images </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>						
                    </div>
                    
                  </div>
                    <div className="row col-12">
                              {frontimages.map((frontimage, index) => (
                                  <div className="accordion-body card-body pt-0" key={frontimage.id}>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex align-items-center">
                                        <div className="acc-icon">
                                          {frontimage.images && frontimage.images.map((image, subIndex) => (
                                            <div className="dlab-media" key={image.id}>
                                              <img src={image.image} alt="frontimage" onClick={() => openModal(subIndex)} className="pt-3" style={{ width:"100%", objectFit: 'cover' }}/>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                    ))}
                                 </div>
                              </div>	
                            </div>
                          </div>
                    <Modal size="lg" show={showModal} onHide={closeModal} className="col-lg-12">
                                <Modal.Header closeButton >
                                    <Modal.Title>Front Images</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="card-body pb-1">
                                    <Carousel activeIndex={selectedImageIndex} onSelect={setSelectedImageIndex}>
                                    {frontimages[0]?.images && frontimages[0]?.images.map((image, index) => (
                                            <Carousel.Item key={index}>
                                                <img className="col-lg-3 col-md-6 mb-4 "
                                                    src={image.image} alt="Slide" style={{ width:"100%", objectFit: "contain"}}
                                                />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Modal.Body>
                            </Modal>
                            </div>
              )}
          </div>   
        </>
      );

}
export default ViewFrontimage