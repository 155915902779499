import React from 'react'
import {Link, useParams } from 'react-router-dom'
import { useState, useContext, useEffect } from 'react'
import axios from 'axios';
import {SchoolContext} from '../../../../AuthContext/SchoolProvider';
import SchoolHeader from '../SchoolHeader';
import parse from 'html-react-parser';

const baseUrl='https://myghschool.myghschools.com/school';


const Donation = () => {
  
    useEffect(()=>{
        document.title =  "MGS | Donation Page";
    }, []); 
    
  const [schools, setSchools] = useState(null);
  const [projects, setProjects] = useState(null);
  const [contact, setContact] = useState(null);
  const { id } = useParams();
  const { selectedSchool } = useContext(SchoolContext);

  const truncateDescription = (event) => {
    if (event.length > 100) {
      return event.substring(0, 100) + "...";
    } else {
      return event;
    }
  };
  
  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${id}`);
      setSchools(response.data);
      } catch (error) {
      }
    };

  fetchSchoolData();
  }, [id]);


  useEffect(() => {

    const fetchEventData = async () => {
      try {
      const response = await axios.get(`https://myghschool.myghschools.com/donation/schools/${selectedSchool}/projects/`);
      setProjects(response.data);
      } catch (error) {
      }
    };
  
    fetchEventData();
    }, [selectedSchool]);


   useEffect(() => {
    const fetchContactData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
      setContact(response.data);
      } catch (error) {
      }
    };
    fetchContactData();
    }, [selectedSchool]);



  return (
    <React.Fragment>

  <div className="inner-page">

		<div className="full-width-header">

    <SchoolHeader /> 


		<div className="rs-breadcrumbs breadcrumbs-overlay" style={{ backgroundImage: `url(${schools && schools?.banner})` , backgroundSize: '100%'}}>
		    <div className="breadcrumbs-inner">
		        <div className="container">
		            <div className="row">
		                <div className="col-md-12 text-center"> <br/><br/>
		                    <h1 className="page-title pt-5">Donation Page</h1>
		                    <ul>
		                        <li>
		                         <Link to={`/schoolhome/${selectedSchool}`} className="active">Home</Link>
		                        </li>
		                        <li>Donations</li>
		                    </ul>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>

        <div className="rs-events-2 sec-spacer">
       
            <div className="card container pt-5">
            <div className="abt-title text-center">
            <h2>Our Donation Page</h2>
            <p style={{fontSize:"1.2em", fontFamily:'inherit'}}>Thank you for donating to our school projects for better education. We really appreciate you.</p>
          </div>
                <div className="row space-bt30">
                {projects && projects.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((project, index) => (
                    <div className="col-lg-6 col-md-12 mb-30" key={project.id}>
                    	  <div className="card event-item">
	                        <div className="row rs-vertical-middle">
                        
	                        	<div className="col-md-6">
	                        	    <div className="">
                                <Link to={`/viewdonation/${project.id}`} >  <img src={project?.image1} alt="events Images" style={{width:300, height: 300}}/> </Link>
	                        	    </div>                        		
	                        	</div>
	                        	<div className="col-md-6 p-2">
	                    	        <div className="event-content">
	                    	        	<h3 className="event-title"><Link to={`/viewdonation/${project.id}`} >{project?.title}</Link></h3>
	                    	        	<h3 className="event-title"><Link to={`/viewdonation/${project.id}`} > <span style={{fontSize:'0.8em', color:'red'}}>Amount:</span> {project?.amount_requested}</Link></h3>
                    	        		
	                    	        	<div className="event-desc">
	                    	        		<div>
	                    	        			{parse(truncateDescription(project?.description))}
	                    	        		</div>
	                    	        	</div>
	                    	        	<div className="event-btn">
	                    	        		<Link to={`/viewdonation/${project.id}`}>Donate Now</Link>
	                    	        	</div>
	                    	        </div>                    		
	                        	</div>
                            </div>
                         </div>
                        </div>
                          ))}
	                        </div>                    		
                    	</div>
                    </div>
            
               

        <div id="rs-branches" className="rs-branches sec-color pt-100 pb-70">
            <div className="container">
                <div className="sec-title mb-70 text-center">
                    <h2>Ads Space</h2>      
                	<p>Consider advertising with us </p>
                </div>
				<div className="row">
          <h3>Place for ads</h3>
				</div>
			</div>
		</div>

   
    
   <footer id="rs-footer" className="bg3 rs-footer mt-0">
    <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 &&  contact[0]?.address}<br/>
 								{schools && schools?.town}<br/>
 								{schools && schools?.district}<br/>
 								{schools && schools?.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 &&  contact[0]?.gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.phone_number}<br/>
									{contact && contact.length > 0 &&  contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 &&  contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 && contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.facebook}<br/>
									{contact && contact.length > 0 && contact[0]?.instagram}<br/>
									{contact && contact.length > 0 && contact[0]?.website}<br/>
									{contact && contact.length > 0 &&  contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>
           

        <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
        	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true" className="fa fa-close"></span>
	        </button>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="search-block clearfix">
                        <form>
                            <div className="form-group">
                                <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</div>
</div>

</React.Fragment>
   
  )
}

export default Donation