import React,{useState, useEffect, useContext}  from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import SchoolNavbar from '../../layouts/SchoolNavbar';
import { useParams, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from '../../../hooks/useAuth';
import parse from 'html-react-parser';



const baseUrl='https://myghschool.myghschools.com/school';

const ViewDepartment =()=> {

	
	const [departments, setDepartments] = useState([]);
	const {selectedSchool} = useContext(SchoolContext);
	const {id} = useParams();
	const {accessToken} = useAuth();
	const navigate = useNavigate();

	
	const goBack= () =>{
		navigate(-1);
	}

			// reduce the words and letters in the description
	const truncateDescription = (description) => {
	if (description.length > 30) {
	 return description.substring(0, 30) + "...";
	} else {
	return description;
	}
	};
		

	useEffect(() => {
		const fetchDepartment = async () => {
		  try {
			const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/departments/${id}`);
            // const departmentsArray = Object.values(response.data);
            setDepartments([response.data]);
		  } catch (error) {
		  }
		};
	
		fetchDepartment();
	  }, [id, selectedSchool]);


	  const handleDelete = async (departmentId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this school!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/departments/${departmentId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(departments)){
					const newDepartments= departments.filter(department => department.id !== departmentId);
					setDepartments(newDepartments);
				} else {
					console.error('department is not an array: ', departments);
				}
				swal('Good job!', 'Successfully Deleted', "success");

				goBack();
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  console.error(`Error deleting school: ${error}`);
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your Department data is safe!");
		  }
		});
	  }
	return(
		
            <>
              <SchoolNavbar />
                
                <h3 className="text-center">Members of <span className='text-red'>{ departments && departments[0]?.name}</span> Department</h3><br/>
                <div className="widget-heading d-flex justify-content-between align-items-center">
                    
                    {/* <div className="m-0"> */}
                    <button onClick={goBack} className="back-button">Back to Department</button><br/><br/>
                         <Link to={`/admin/adddeptmember/${selectedSchool}`} className="back-button">Add Department Member</Link>
                    {/* </div> */}
                  
                </div>
    
                <div className="row">
                {departments?.length > 0 && departments[0]?.members && departments[0]?.members.map((data, index)=>(
                        <div className="col-xl-4 col-md-6" key={index}>
                            <div className="card all-crs-wid">
                                <div className="card-body">
                                    <div className="courses-bx">
                                        <div className="dlab-media">
                                            <img src={data?.image} alt="" />
                                        </div>
                                        <div className="dlab-info">
                                            <div className="dlab-title d-flex justify-content-between">
                                                <div>
                                                    <h4><Link to={"./admin/course-details-1"}>{data?.name}</Link></h4>
                                                    <div className="m-0">
                                                    {data?.position && typeof data?.position === 'string' ? parse(truncateDescription(data?.position)) : null}
                                                        <svg className="ms-1" width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="2" cy="2.5" r="2" fill="#DBDBDB"/>
                                                        </svg>	
                                                    </div>
                                                </div>	
                                            </div>
                                            <div className="d-flex justify-content-between content align-items-center">
                                            
                                                <button className="btn btn-xs btn-danger" onClick={() => handleDelete(data.id)}>Delete</button>
                                                <Link to={`/admin/editdeptmember/${data.id}`} className="btn btn-xs btn-primary m-1">Edit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    
                </div>	
            </>
        
        )
}
export default ViewDepartment;

