import React, {useState, useEffect} from 'react'
import MainNav from '../layout/MainNav'
import Assets1Loader from '../../AssetsLoader'
import { Link, useParams, useNavigate } from 'react-router-dom'
import MainFooter from '../layout/MainFooter'
import axios from 'axios'
import '../layout/search.css';
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import parse from 'html-react-parser';


const baseUrl = 'https://myghschool.myghschools.com/school'

const MainNews = () => {
    
  const [news, setNews] = useState([]);
  const [allNews, setAllNews] = useState([]);
  const navigate = useNavigate();
  const { id} = useParams();
  
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const [month, day, year] = new Date(dateString).toLocaleDateString("en-US", options).split("/");
    return `${day}-${month}-${year}`;
  }


  const goBack = () => {  
    navigate(-1);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 1024, min: 850 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 850, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


	useEffect(() =>{
        const fetchNewsData = async () => {
            try {
            const response = await axios.get(`${baseUrl}/schoolblog/${id}`);
            setNews(response.data);
            } catch (error) {
            }
          };
        fetchNewsData();
    },[id]);

   


      useEffect(() => {

            const fetchAllNews = async () =>{
                try {
                const response = await axios.get(`${baseUrl}/schoolblog/`);
                setAllNews(response.data);
            } catch (error) {
            }
      }
      fetchAllNews();
    },[]);


  return (

    <React.Fragment>
{/* <div> */}
    <Assets1Loader />
    
    <MainNav/>

    <main>
        <div className="breadcrumb-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb-content">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item" ><Link to="" onClick={goBack}>Nature</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{news && news.title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="blog-details-area pt-2 pb-100">
            <div className="container card pt-5">
                <div className="row justify-content-center">
                
                    <div className="col-xl-8 col-lg-7">
                        <div className="blog-details-wrap">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><a href="blog.html">{news && news.category}</a></li>
                                <li><span className="by">By</span> <a href="blog.html">{news && news?.user?.first_name}</a></li>
                                <li>{formatDate(news && news.created_at)}</li>                              
                            </ul>
                            <h2 className="title text-center">{news && news.title}</h2>
                            <div className="blog-details-thumb d-flex justify-content-center align-items-center">
                                <img src={news && news.featured_image} alt="featured_image" style={{width:"700px", height:"500px"}}/>
                            </div>
                            <div className="blog-details-content">
                           
                                <div className="text-center">{news && news.story ? parse(news.story) : 'No story available'}</div>
                              
                        
                                <div className="details-advertisement">
                                    <h4 className="title">Advertisement</h4>
                                    <Link to="#"><img src="/assets1/img/others/advertisement02.png" alt="img"/></Link>
                                </div>
                           
                            </div>      
                            <div>
                                <h2>Other Stories</h2>
                                <div className="row">
                                <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={2000} keyBoardControl={true} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]} >
                                {allNews && allNews.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 10).map((news, index) => (
                                        
                                        <div className="mt-10 p-2" key={news.id}>
                                        <a href={`/mainnewssingle/${news.id}`} >
                                       <div className="sidePost__item"  style={{ backgroundImage: `url(${news.featured_image})` }}/> </a>
                                       <div>
                                           <a href={`/mainnewssingle/${news.id}`} >{news.category}</a>
                                           <h5 className="title tgcommon__hover"><a href={`/mainnewssingle/${news.id}`}>{news.title}</a></h5><br/>
                                       </div>
                                       </div>
                                   ))}
                                </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-mb-20">
                        <aside className="blog-sidebar">
                           
                            <div className="widget sidebar-widget widget_categories">
                                <h4 className="widget-title">Trending Category</h4>
                                <ul className="list-wrap">
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="/assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/technology">technology</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="/assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/business">business</Link>                                     
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="/assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/entertainment">Entertainment</Link>                                     
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="/assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/sports">Sports</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="/assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/politics">politics</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="/assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/health">Health</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="/assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/love">Love & Relationship</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="widget sidebar-widget">
                                <h4 className="widget-title">Check This Out (ads)</h4>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="/assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div><br/>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="/assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div><br/>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="/assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div><br/>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="/assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>

    </main>

    <MainFooter />
    {/* </div> */}
   </React.Fragment>
  )
}

export default MainNews