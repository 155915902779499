import React, {useState, useEffect, useContext} from 'react'
import MainNav from './layout/MainNav'
import Assets1Loader from '../AssetsLoader'
import { Link } from 'react-router-dom'
import MainFooter from './layout/MainFooter'
import axios from 'axios'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';


const baseUrl = 'https://myghschool.myghschools.com/school'
const AllSchools = () => {

  const [schools, setSchools] = useState(null);

  const { setSelectedSchool } = useContext(SchoolContext);
  
  useEffect(() =>{
    fetchSchoolData();
},[]);

const handleSchoolSelect = (schoolId) => {
  setSelectedSchool(schoolId);
 };

 const fetchSchoolData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/`);
    setSchools(response.data);
    } catch (error) {
    }
  };


  return (
    <React.Fragment>

<Assets1Loader />

<MainNav/>

    {/* <!-- banner-area --> */}
    <section className="tgbanner__area">
        <div className="container">
            <div className="tgbanner__grid">

              {/* main page banner */}
            <section className="featured-post-area section__hover-line ">
                        <div className="container">
                            <div className="section__title-wrap mb-40">
                                <div className="row align-items-end">
                                    <div className="col-sm-6">
                                        <div className="section__title">
                                            {/* <span className="section__sub-title">Featured</span> */}
                                            <h3 className="section__main-title">All Schools in Ghana</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                              {schools && schools.map((school, index) => (
                                    <div className="col-lg-6 col-sm-6" key={school.id}>
                                    <div className="featured__post"> 
                                        {/* <div className="featured__thumb"> <img src={school && school.school_logo}/>01</div> */}
                                        <Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}>  <img src={school && school.school_logo} width={70} height={70} alt='school_logo'  className="rounded-circle"/></Link>
                                        <div className="featured__content">
                                        {/* <img src={school && school.school_logo}/> */}
                                            <ul className="tgbanner__content-meta list-wrap">
                                                {/* <li className="category"><a href="blog.html">nature</a></li> */}
                                                <li><span className="by">Alias: </span> <Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}>{school && school.school_alias}</Link></li>
                                            </ul>
                                            <h4 className="title tgcommon__hover"><Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}>{school && school.school_name}</Link></h4>
                                        </div>
                                    </div>
                                </div> 
                              
                              ))}
                            
                            </div>
                        </div>
                    </section>

                <div className="tgbanner__side-post">
                    <div className="tgbanner__post small-post">
                        <div className="tgbanner__thumb tgImage__hover">
                            <Link to="/alluniversities" ><img src="assets1/img/images/publicuni.jpg" alt="img"/></Link>
                        </div>
                        <div className="tgbanner__content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><Link to="/alluniversities">Universities</Link></li>
                            </ul>
                            <Link to="/alluniversities" > <h2 className="title tgcommon__hover">All universities in Ghana</h2></Link>
                        </div>
                    </div>
                    <div className="tgbanner__post small-post">
                        <div className="tgbanner__thumb tgImage__hover">
                        <Link to="/allshs" ><img src="assets1/img/images/allpublicnursing.jpg" alt="img"/></Link>
                        </div>
                        <div className="tgbanner__content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><Link to="/allnursing" >All Nursing Schools</Link></li>
                            </ul>
                            <h2 className="title tgcommon__hover"><Link to="/allnursing" >All Nursing schools in Ghana </Link></h2>
                        </div>
                    </div>
                    <div className="tgbanner__post small-post">
                        <div className="tgbanner__thumb tgImage__hover">
                        <Link to="/allshs" ><img src="assets1/img/images/nvti.jpeg" alt="img"/></Link>
                        </div>
                        <div className="tgbanner__content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><Link to="/allnvti" >All NVTI schools</Link></li>
                            </ul>
                            <h2 className="title tgcommon__hover"><Link to="/allnvti" >All NVTI schools in Ghana </Link></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

<MainFooter />


    </React.Fragment>
  )
}

export default AllSchools