

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";


const baseUrl = "https://myghschool.myghschools.com/school";

function EditFrontimage() { 

  useEffect(()=>{
		document.title = "MGS | Dashboard";
	});

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [selectedImages, setSelectedImages] = useState([]);
  const [backendErrors, setBackendErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [setResponsess] = useState(null);

  const {id} = useParams();

  const [formData, setFormData] = useState({
    title: "",
    image: null,
    school: selectedSchool,//to ensure that the course is selected to be associated with the subject
  });


  const goBack = () => {  
    navigate(-1);
  };

  const handleSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    e.target.value = "";
    setFormData(prevState => ({ ...prevState, image: selectedFilesArray }));

  };

const removeImageFromGallery = (imageId) => {
  const updatedImages = formData.images.filter(image => image.id !== imageId);
  setFormData(prev => ({ ...prev, images: updatedImages }));
}


  useEffect(() => {
    if(selectedSchool){
      const token = accessToken;
      const url = `${baseUrl}/schools/${selectedSchool}/frontimage/${id}/`;

      axios.get(url,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData({
          ...res.data,
          image: null,
             
        });
        setSelectedImages(res.data);
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id]);


  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    
  }

const handleSubmit = async (e) => {
  e.preventDefault();
  const formDataObj = new FormData();
  if (formData.title === '') {
    formDataObj.append("title", null); 
  } else {
    formDataObj.append("title", formData.title);
  }

  if (formData.image) {
    formData.image.forEach(img => {
        formDataObj.append("image", img.id); // assuming that each image object has an id
    });
  }
  
if (formData.image) {
  formData.image.forEach(img => {
      formDataObj.append("image", img.id); // assuming that each image object has an id
  });
}
  formDataObj.append("school", formData.school);

  try {
    const token = accessToken;
    const response = await axios.patch(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/frontimage/${id}/`, formDataObj, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`,
      },
    });

    setResponsess(response);
    Swal.fire({
      icon: "success",
      title: "gallery updated Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
    
    goBack();
  } catch (err) {
    if (err){
      setBackendErrors(err);
  }
}

};

if (isLoading) {
  return <h2>Loading...</h2>; // Render a loading message while the data is being fetched
}
  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
        <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit Front Image</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Front Images</button><br/><br/>
        <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
              <label className="mb-1 "><strong>Title</strong></label>
               <input type="text" id="title" name="title" value={formData?.title} onChange={handleChange} className="form-control" />
            </div>
            </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group">
            <label className="btn btn-success w-100" >
                    {formData?.image ? `${formData?.image.length} files selected` : "You Can Upload More Than One Images"}
                    <input type="file" id="image" name="image" onChange={handleSelectFile} multiple className="d-none" />
               
                      {selectedImages?.images?.length > 0 &&
                      (selectedImages?.images?.length > 10 ? (
                        <p className="error">
                          You can't upload more than 10 images! <br />
                          <span>
                            please delete <b> {selectedImages?.images.length - 10} </b> of them{" "}
                          </span>
                        </p>
                      ) : (<div className="upload-btn">
                        {selectedImages?.images?.length} IMAGE SELECTED
                      {selectedImages?.images?.length === 1 ? "" : "S"} 
                      </div>
                    ))}
                    </label>
                    <div className="row multi-images img">
                      {selectedImages?.images.map((imageURL, index) => (
                          <div key={index} className="col-md-6">
                            <div className="image position-relative">
                              <img src={imageURL.image} alt="upload" />
                              <button type="button" onClick={() => removeImageFromGallery(index)} className="btn btn-sm btn-danger position-absolute" style={{ top: '5px', right: '5px' }} > X </button>
                              <p>{index + 1}</p>
                            </div>
                          </div>
                        ))}       
                      
                    </div>
                      </div>
                      </div>
                <Button variant="primary" type="submit">
                    Submit Update
                </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditFrontimage