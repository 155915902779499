

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const baseUrl = "https://myghschool.myghschools.com/school";

function EditDepartment() { 
  
  useEffect(()=>{
		document.title = "MGS | Dashboard";
	});

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    mainImage: null,
    school: selectedSchool, //this is toe ensure that school is selected to be associated with the subject
  });


  const goBack = () => {  
    navigate(-1);
  };
  const [error, setError] = useState(null);
  


  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
      axios.get(`${baseUrl}/schools/${selectedSchool}/departments/${id}`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
       
      })
      .then((res) => {
        setFormData({
          ...res.data,
          
        });
       
      })
      
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mainImage") {
      setFormData({ ...formData, [name]: e.target.files[0] || null });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("description", formData.description);

    if (formData.mainImage && formData.mainImage.name) { // Check if the image file is an object with a name property
        formDataObj.append("mainImage", formData.mainImage);
      } else if (typeof formData.mainImage === 'string') {

      } else if (formData.mainImage) { // If the image file is a Blob without a name property
        formDataObj.append("mainImage", formData.mainImage, 'mainImage.jpg'); // Add a filename
      }
    formDataObj.append("school", formData.school); //school field
   
      
    try {
      const token = accessToken
      const response = await axios.patch(`${baseUrl}/schools/${selectedSchool}/departments/${id}/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      Swal.fire({
        icon: "success",
        title: "Department Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    
      goBack();
      
    } catch (err) {
      if (err.response.status === 400) {
      setError("Name already exist. Please try again.");
    }
    else{
      setError("Something went wrong. Please try again later.");
    }
  }
  }; 
 
  if (isLoading) {
    return <h2>Loading...</h2>; 
  }

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit Department</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Department</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
          <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content"><br/>
              <label className="mb-1 "><strong>Name</strong></label>
               <input type="text" id="name" name="name" value={formData?.name} onChange={handleChange} className="form-control"  />
            </div>
            </div><br/>
            <div className="col-lg-6 mb-2">
            <div className="form-group">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData?.description} onChange={(content) => setFormData({ ...formData, description: content })} />
             </div>
             </div><br/>
       
             <label className="btn btn-success col-lg-6 mb-2  " >
                {formData?.mainImage ? formData?.mainImage.name : "Upload Main Event Image"}
                  <input type="file" id="mainImage" name="mainImage" onChange={handleChange} className="d-none" />
                 <br/>
                <div className="edit-content">
              {formData?.mainImage && (
                typeof formData?.mainImage === 'string'
                ? <img src={formData?.mainImage} alt="Existing" />
                : <img src={URL.createObjectURL(formData?.mainImage)} alt="Uploaded" />
                  )}
                  </div>
                    </label>
                  <br/><br/>  
                <Button variant="primary" type="submit">
                    Update
                </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditDepartment;

