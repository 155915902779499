import React, { useContext } from "react";

/// React router dom
import { useLocation, Routes, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import AdminProfile from "./components/Dashboard/AdminProfile";
import UserDetails from "./components/Users/UserDetails";

//main admin dashboard
import AllSchools from "../pages/Allschools/AllschoolList/AllSchools";
import AdminRegister from "../pages/AdminRegister";
import Admins from "../pages/Admins/AdminList/Admins";
import AdminDetails from "../pages/Admins/AdminDetails";

//Courses----
import CoursesMain from './components/Courses/CoursesMain';
import AddCourse from  './components/Courses/AddCourse';
import EditCourse from './components/Courses/EditCourse';
import ViewCourse from "./components/Courses/ViewCourse";


//Subject
 import SubjectList from './components/Subjects/SubjectList';
 import AddSubject from './components/Subjects/AddSubject';
 import EditSubject from './components/Subjects/EditSubject';
 import ViewSubject from './components/Subjects/ViewSubject';


//school front page links
import Frontpage from './components/Schoolfrontpage/Frontpage';
import AddSchool from './components/Schoolfrontpage/AddSchool';
import EditSchool from './components/Schoolfrontpage/EditSchool';

//School Events
import Events from './components/Events/AllEvents';
import AddEvent from './components/Events/AddEvent';
import EditEvent from './components/Events/EditEvent';
import ViewEvent from './components/Events/ViewEvent';

//School Achievements
import AllAchievements from './components/Achievement/AllAchievements';
import AddAchievement from './components/Achievement/AddAchievement';
import EditAchievement from './components/Achievement/EditAchievement';
import ViewAchievement from './components/Achievement/ViewAchievement';


//School Gallery
import AllGalleries from './components/Gallery/AllGalleries';
import AddGallery from './components/Gallery/AddGallery';
import EditGallery from './components/Gallery/EditGallery';
import ViewGallery from './components/Gallery/ViewGallery';

//School blog
import AllBlogs from './components/Blog/AllBlog';
import AddBlog from './components/Blog/AddBlog';
import EditBlog from './components/Blog/EditBlog';
import ViewBlog from './components/Blog/ViewBlog';

//School Contact
import Contact from './components/Contact/Contact';
import AddContact from './components/Contact/AddContact';
import EditContact from './components/Contact/EditContact';

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";


//News Blog
import AddNews from './components/News/AddNews';
import EditNews from './components/News/EditNews';
import ViewNews from './components/News/ViewNews';
import AllNews from './components/News/AllNews';

/// Table
import UserList from "./components/Users/UserList/UserList";
import ChangePassword from "../pages/ChangePassword";



/// Pages
import Registration from "../pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import UserProfile from "./components/Dashboard/UserProfile";


// Departments links
import AddDepartment from "./components/Department/AddDepartment";
import EditDepartment from "./components/Department/EditDepartment";
import AllDepartment from "./components/Department/AllDepartment";
import ViewDepartment from "./components/Department/ViewDepartment";
import AddDeptMember from "./components/Department/AddDeptMember";
import EditDeptMember from "./components/Department/EditDeptMember";
import AllDeptMembers from "./components/Department/AllDeptMembers";

// Board of Directors
import AddBoardMember from "./components/BoardofDirectors/AddBoardMember";
import EditBoardMember from "./components/BoardofDirectors/EditBoardMember";
import AllBoardMembers from "./components/BoardofDirectors/AllBoardMembers";

// PTA members
import AddPTAmember from "./components/PTAmembers/AddPTAmember";
import EditPTAmember from "./components/PTAmembers/EditPTAmember";
import AllPTAmembers from "./components/PTAmembers/AllPTAmembers";

//Health Facilities
import AddHealthFacility from "./components/HealthFacility/AddHealthFacility";
import EditHealthFacility from "./components/HealthFacility/EditHealthFacility";
import HealthFacility from "./components/HealthFacility/HealthFacility";

//Alumni
import AddAlumni from "./components/Alumni/AddAlumni";
import EditAlumni from "./components/Alumni/EditAlumni";
import AllAlumni from "./components/Alumni/AllAlumni";

//School management
import AddMgtStaff from "./components/SchoolManagement/AddMgtStaff";
import EditMgtStaff from "./components/SchoolManagement/EditMgtStaff";
import AllMgtStaff from "./components/SchoolManagement/AllMgtStaff";

//School donations
import AddDonation from "./components/Donations/AddDonations";
import EditDonation from "./components/Donations/EditDonation";
import AllDonations from "./components/Donations/AllDonations";
import ViewDonation from "./components/Donations/ViewDonation";


//front images are the main images for the front page
import AddFrontimage from './components/FrontImage/AddFrontimage'
import EditFrontimage from './components/FrontImage/EditFrontimage'
import ViewFrontimage from './components/FrontImage/ViewFrontimage'

const Index =  ({children}) => {
  const { menuToggle } = useContext(ThemeContext);
  const {pathname} = useLocation();

  let path = pathname.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");


  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`} 
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}
			    style={{ minHeight: window.screen.height - 45 }}
		>

      
          <div className={`${!pagePath ? "container-fluid" : ""}`}>

          <Routes>        
          {/* Dashboard */}
            {/* <Route path="/" element={ <Home/> } > */}
              <Route index element={ <Home /> } />
              <Route path="dashboard/:id" element={ <Home /> } />
              <Route path="dashboard-dark/:id" element={ <DashboardDark /> } />
              <Route path="userprofile/:id" element={ <UserProfile /> } />
              <Route path="change-password" element={ <ChangePassword /> } />
              <Route path="userdetails/:id" element={<UserDetails />} />
              <Route path="adminregister" element={ <AdminRegister /> } />
              <Route path="admins" element={ <Admins /> } />
              <Route path="admindetails/:id" element={ <AdminDetails /> } />
              <Route path="adminprofile/:id" element={ <AdminProfile /> } />

              {/* Main admin dashboard */}
              <Route path="allschools" element={ <AllSchools /> } />
              {/* School */}
              <Route path="addschool" element={ <AddSchool /> } />
              <Route path="frontend/:id" element={ <Frontpage /> } />
              <Route path="editschool/:id" element={ <EditSchool /> } />

              {/* Course */}
              <Route path="courses/:school_pk" element={ <CoursesMain /> } />
              <Route path="addcourse/:school_pk" element={ <AddCourse /> } />
              <Route path="editcourse/:id" element={ <EditCourse /> } />
              <Route path="viewcourse/:id" element={ <ViewCourse /> } />

              {/* Subject */}
              <Route path="subjectlist/:school_pk" element={ <SubjectList /> } />
              <Route path="addsubject/:school_pk" element={ <AddSubject /> } />
              <Route path="editsubject/:id" element={ <EditSubject /> } />
              <Route path="viewsubject/:id" element={ <ViewSubject /> } />
              {/* <Route path="activity" element={ <Activity /> } /> */}

              {/* Events */}
              <Route path="events/:school_pk" element={ <Events /> } />
              <Route path="addevent/:school_pk" element={ <AddEvent /> } />
              <Route path="editevent/:id" element={ <EditEvent /> } />
              <Route path="viewevent/:id" element={ <ViewEvent /> } />

              {/* Achievements */}
              <Route path="achievements/:school_pk" element={ <AllAchievements /> } />
              <Route path="addachievement/:school_pk" element={ <AddAchievement /> } />
              <Route path="editachievement/:id" element={ <EditAchievement /> } />
              <Route path="viewachievement/:id" element={ <ViewAchievement /> } />

              {/* Departments */}
              <Route path="departments/:school_pk" element={ <AllDepartment /> } />
              <Route path="adddepartment/:school_pk" element={ <AddDepartment /> } />
              <Route path="editdepartment/:id" element={ <EditDepartment /> } />
              <Route path="viewdepartment/:id" element={ <ViewDepartment /> } />
              <Route path="adddeptmember/:id" element={ <AddDeptMember /> } />
              <Route path="editdeptmember/:id" element={ <EditDeptMember /> } />
              <Route path="alldeptmembers/:id" element={ <AllDeptMembers /> } />

              {/* PTA members */}
              <Route path="ptamembers/:school_pk" element={ <AllPTAmembers /> } />
              <Route path="addptamember/:school_pk" element={ <AddPTAmember /> } />
              <Route path="editptamember/:id" element={ <EditPTAmember /> } />

              {/* Board of Directors */}
              <Route path="boardofdirectors/:school_pk" element={ <AllBoardMembers /> } />
              <Route path="addboardmember/:school_pk" element={ <AddBoardMember /> } />
              <Route path="editboardmember/:id" element={ <EditBoardMember /> } />

              {/* Health Facilities */}
              <Route path="healthfacility/:school_pk" element={ <HealthFacility /> } />
              <Route path="addhealthfacility/:school_pk" element={ <AddHealthFacility /> } />
              <Route path="edithealthfacility/:id" element={ <EditHealthFacility /> } />

              {/* Alumni */}
              <Route path="alumni/:school_pk" element={ <AllAlumni /> } />
              <Route path="addalumni/:school_pk" element={ <AddAlumni /> } />
              <Route path="editalumni/:id" element={ <EditAlumni /> } />
              
              {/* Mgt Staffs */}
              <Route path="mgtstaffs/:school_pk" element={ <AllMgtStaff /> } />
              <Route path="addmgtstaff/:school_pk" element={ <AddMgtStaff /> } />
              <Route path="editmgtstaff/:id" element={ <EditMgtStaff /> } />

              {/* Gallery */}
              <Route path="galleries/:school_pk" element={ <AllGalleries /> } />
              <Route path="addgallery/:school_pk" element={ <AddGallery /> } />
              <Route path="editgallery/:id" element={ <EditGallery /> } />
              <Route path="viewgallery/:id" element={ <ViewGallery /> } /> 

              {/* Blog */}
              <Route path="blogs/:school_pk" element={ <AllBlogs /> } />
              <Route path="addblog/:school_pk" element={ <AddBlog /> } />
              <Route path="editblog/:id" element={ <EditBlog /> } />
              <Route path="viewblog/:id" element={ <ViewBlog /> } />

              {/* Contact */}
              <Route path="contact/:school_pk" element={ <Contact /> } />
              <Route path="addcontact/:school_pk" element={ <AddContact /> } />
              <Route path="editcontact/:id" element={ <EditContact /> } />

              {/* Bootstrap */}
              <Route path="ui-alert" element={ <UiAlert /> } />
              <Route path="ui-accordion" element={ <UiAccordion />} />
              <Route path="ui-badge" element={ <UiBadge /> } />
              <Route path="ui-button" element={ <UiButton /> } />
              <Route path="ui-modal" element={ <UiModal /> } />
              <Route path="ui-button-group" element={ <UiButtonGroup /> } />
              <Route path="ui-accordion" element={ <UiAccordion /> } />
              <Route path="ui-list-group" element={ <UiListGroup />} />
              <Route path="ui-card" element={ <UiCards /> } />
              <Route path="ui-carousel" element={ <UiCarousel /> } />
              <Route path="ui-dropdown" element={ <UiDropDown /> } />
              <Route path="ui-popover" element={ <UiPopOver />} />
              <Route path="ui-progressbar" element={ <UiProgressBar />} />
              <Route path="UiTab" element={ <UiTab /> } />
              <Route path="ui-pagination" element={ <UiPagination /> } />
              <Route path="UiGrid" element={ <UiGrid /> } />
              <Route path="UiTypography" element={ <UiTypography /> } />


              {/* Table */}
              <Route path="users" element={ <UserList /> } />

              {/* Forms */}
              <Route path="form-validation-jquery" element={ <jQueryValidation /> } />

              {/* pages */}
              <Route path="page-register" element={ <Registration /> } />
              <Route path="page-lock-screen" element={ <LockScreen /> } />
              <Route path="page-forgot-password" element= { <ForgotPassword /> } />
              <Route path="page-error-403" element={ <Error403 /> } />
              <Route path="page-error-400" element={ <Error400 /> } />
              <Route path="page-error-500" element={ <Error500 /> } />
              <Route path="page-error-503" element={ <Error503 /> } />
              <Route path="*" element={ <Error404 /> } />
              {/* </Route> */}

              {/* News Blog */}
              <Route path="addnews" element={ <AddNews /> } />
              <Route path="editnews/:id" element={ <EditNews /> } />
              <Route path="viewnews/:id" element={ <ViewNews /> } />
              <Route path="allnews" element={ <AllNews /> } />

              {/* Donations */}
              <Route path="adddonation/:id" element={ <AddDonation/> } />
              <Route path="editdonation/:id" element={ <EditDonation /> } />
              <Route path="viewdonation/:id" element={ <ViewDonation /> } />
              <Route path="alldonations/:id" element={ <AllDonations /> } />

                {/* front images */}
                <Route path="addfrontimage/:school_pk" element={ <AddFrontimage />} />
              <Route path="editfrontimage/:id" element={ <EditFrontimage />} />
              <Route path="viewfrontimage/:id" element={ <ViewFrontimage />} />
              {/* </Route> */}
            </Routes>
        </div>
        </div>
        {!pagePath && <Footer />}
      </div>

	  <ScrollToTop />
    </>
  );
};

export default Index;


