import React,{useState, useEffect, useContext}  from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import SchoolNavbar from '../../layouts/SchoolNavbar';
import { useParams, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from '../../../hooks/useAuth';
import { Dropdown, Modal, Carousel } from "react-bootstrap";
import parse from 'html-react-parser';


const baseUrl='https://myghschool.myghschools.com/donation';

const ViewDonation =()=> {
	
	useEffect(()=>{
		document.title = "MGS | Dashboard";
	});


	const [donations, setDonations] = useState([]);
	const {selectedSchool} = useContext(SchoolContext);
	const {id} = useParams();
	const {accessToken} = useAuth();
	const navigate = useNavigate();

	//function to allow delete to go back to the main page
	const goBack= () =>{
		navigate(-1);
	}

	useEffect(() => {
		const fetchEvent = async () => {
		  try {
			const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/projects/${id}/`);
	        setDonations(response.data);
		  } catch (error) {
		  }
		};
	
		fetchEvent();
	  }, [id, selectedSchool]);


	  const handleDelete = async (donationId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this school!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/projects/${donationId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(donations)){
					const newEvents = donations.filter(donation => donation.id !== donationId);
					setDonations(newEvents);
				} else {
					console.error('Schools is not an array: ', donations);
				}
				swal('Good job!', 'Successfully Deleted', "success");

				goBack();
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your school data is safe!");
		  }
		});
	  }
	
	return(
		<React.Fragment>
				
		<SchoolNavbar/>
		<div className="col">
		<h2>Donation Details</h2>
		<button className="btn btn-primary btn-xs " onClick={() => goBack()}>Back</button>
		</div>
		<div className="row">
        <div className="container col">
            <div className="card">
            <div className="card-header justify-content-between" style={{fontSize:'1.5em'}}>
                    <div>
                    <strong>{donations && donations.title} Details</strong>
                    </div>
                <div className="mt-2 mt-6">
                            <Dropdown className="dropdown ms-auto">
                <Dropdown.Toggle
                  variant="primary"
                  className="btn btn-primary light sharp i-false"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                    </g>
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                  <Dropdown.Item as="div" className="dropdown-item">
                          <i className="fa-solid fa-pen-to-square text-primary me-2"></i>
                  <Link to={`/admin/editdonation/${donations?.id}`}>
                    Edit Donation
                  </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" className="dropdown-item">
                          <i className="fa-solid fa-trash text-primary me-2"></i>
                   <Link to="#" onClick={() => handleDelete(donations?.id)}> Delete Donation </Link>
                  </Dropdown.Item>
         
                </Dropdown.Menu>
              </Dropdown>						
            </div>
          </div>
          <div className="card-body">
            <div className="edit-content">
            <h4><span style={{color:'red'}}>Title:</span> {donations && donations?.title}</h4>
            <h4><span style={{color:'red'}}>Beneficiary:</span> {donations && donations?.beneficiary}</h4>
            <h4><span style={{color:'red'}}>Beneficiary:</span> Ghc {donations && donations?.amount_requested}</h4>
            <h4><span style={{color:'red'}}>Supervisor:</span> {donations && donations?.collected_by}</h4>
            <h4><span style={{color:'red'}}>Position:</span> {donations && donations?.position}</h4>
            <h4><span style={{color:'red'}}>Description:</span> {typeof donations?.description === 'string' ? parse(donations?.description) : null}</h4>
                <br/>
            </div>
            <h3 className='text-center' style={{textDecoration:"underline"}}>Images of {donations && donations?.title} Donation</h3><br/>
			          <div className="row">
                       <div className="col-xl-6col-xl-4 col-lg-4 col-md-4 col-sm-6">
                       <img src={donations && donations?.image1} alt="donation image1" style={{ width:'100%'}}/>
                    </div>
                     <div className="col-xl-6col-xl-4 col-lg-4 col-md-4 col-sm-6">
                       <img src={donations && donations?.image2} alt="donation image2" style={{ width:'100%'}}/>
                    </div>
                    <div className="col-xl-6col-xl-4 col-lg-4 col-md-4 col-sm-6">
                       <img src={donations && donations?.image3} alt="donation image3" style={{ width:'100%'}}/>
                    </div>
                    
			            </div>	
                        </div>
                        </div>
              
                 </div>   
				<div className="col-xl-2 col-xxl-5">
		
					<div className="custome-accordion">
						<div className="card mt-5" >
						{/* <div className=" pt-0"> */}
						<h2 className="accordion-header border-0">
							<span className="acc-heading">Data from donations made</span>
							</h2>
							</div>
							</div>
					{/* </div>						 */}
				    </div>
                </div>
	
		</React.Fragment>
	)
}
export default ViewDonation;

