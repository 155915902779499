import React from 'react'
import {Link } from 'react-router-dom'

const MainFooter = () => {
  return (
    <div>

    <section className="newsletter-area pb-80">
        <div className="container">
            <div className="newsletter__wrap">
                <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-6">
                        <div className="newsletter__title">
                            <span className="sub-title"></span>
                            <h4 className="title">Do you want to register your school and build your own school website?</h4>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <div className="newsletter__form-wrap">
                            <Link to="/register" action="#" className="newsletter__form">
                                <div >
                                
                                  <img src="/assets1/img/logo/MYGHLOGO.png" alt="img" style={{width:"100px",height:"100px"}}/>
                                </div>
                                <button className="btn" type="submit"><span className="text">Register Now</span> </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- newsletter-area-end --> */}


{/* <!-- main-area-end --> */}


{/* <!-- footer-area --> */}
<footer className="footer-area black-bg">
    <div className="container">
        <div className="footer__top-wrap">
            <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div className="footer__widget">
                        <h4 className="fw-title">All Schools</h4>
                        <ul className="list-wrap">
                            <li><Link to="/alluniversities">Universities</Link></li>
                            <li><Link to="/allnursing">Nursing Training Colleges</Link></li>
                            <li><Link to="/allteachertraining">Teacher Training</Link></li>
                            <li><Link to="/allshs">Senior High Schools</Link></li>
                            <li><Link to="/allnvti">NVTI</Link></li>
                            <li><Link to="/alljhs">Junior High Schools</Link></li>
                            <li><Link to="/allbasic">Primary Schools</Link></li>
                            <li><Link to="/allschools">All Schools</Link></li>
                           
                        </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div className="footer__widget">
                        <h4 className="fw-title">Public Schools</h4>
                        <ul className="list-wrap">
                            <li><Link to="/publicuniversities">Public Universities</Link></li>
                            <li><Link to="/publicnursing">Public Nursing Training Colleges</Link></li>
                            <li><Link to="/publicteachertraining">Public Teacher Training</Link></li>
                            <li><Link to="/publicothertertiary">Public Other Tertiary</Link></li>
                            <li><Link to="/publicshs">Public Senior High Schools</Link></li>
                            <li><Link to="/publicnvti">Public NVTI</Link></li>
                            <li><Link to="/publicjhs">Public Junior High Schools</Link></li>
                            <li><Link to="/publicbasic">Public Primary Schools</Link></li>

                        
                        </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div className="footer__widget">
                        <h4 className="fw-title">Private Schools</h4>
                        <ul className="list-wrap">
                            <li><Link to="/privateunivertisies">Private Universities</Link></li>
                            <li><Link to="/privatenursing">Private Nursing Training Colleges</Link></li>
                            <li><Link to="/privateteachertraining">Private Teacher Training</Link></li>
                            <li><Link to="/privateothertertiary">Private Other Tertiary</Link></li>
                            <li><Link to="/privateshs">Private Senior High Schools</Link></li>
                            <li><Link to="/privatenvti">Private NVTI</Link></li>
                            <li><Link to="/privatejhs">Private Junior High Schools</Link></li>
                            <li><Link to="/privateprimary">Private Primary Schools</Link></li>
                            <li><Link to="/privatenursery">Private Nursery Schools</Link></li>
                            <li><Link to="/privatecreche">Private Creche</Link></li>
                          
                        </ul>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div className="footer__widget">
                        <h4 className="fw-title">News</h4>
                        <ul className="list-wrap">
                            <li><Link to="/mainnews">News</Link></li>
                            <li><Link to="/health">Health</Link></li>
                            <li><Link to="/sports">Sports</Link></li>
                            <li><Link to="/entertainment">Entertainment</Link></li>
                            <li><Link to="/politics">Politics</Link></li>
                            <li><Link to="/business">Business</Link></li>
                            <li><Link to="technology">Technology</Link></li>
                            <li><Link to="/love">Love & Relationship</Link></li>
                         
                        </ul>
                    </div>
                </div>
            
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div className="footer__widget">
                        <h4 className="fw-title">Contact Us</h4>
                        <ul className="list-wrap">
                            <li>028734897234</li>
                            <li>myghschool@gmail.com</li>
                            <li>Accra, Ghana</li>
                            <li>www.myghschool.com</li>
                
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer__logo-wrap">
            <div className="row align-items-center">
                <div className="col-lg-3 col-md-4">
                    <div className="footer__logo logo">
                        <Link to="/"><img src="/assets1/img/logo/MYGHLOGO.png" alt="Logo"/></Link>
                    </div>
                </div>
                <div className="col-lg-9 col-md-8">
                    <div className="footer__social">
                        <ul className="list-wrap">
                            <li><a href='https://www.facebook.com/myghschools' target='_blank' rel="noreferrer"><i className="fab fa-facebook-f"></i> Facebook </a></li>
                            <li><a href='https://www.twitter.com/myghschools' target='_blank' rel="noreferrer"><i className="fab fa-twitter"></i> Twitter </a></li>
                            <li><a href='https://www.instagram.com/myghschools' target='_blank' rel="noreferrer"><i className="fab fa-instagram"></i> Instagram </a></li>
                            {/* <li><Link to="#"><i className="fab fa-youtube"></i> Youtube</Link></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer__copyright">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <div className="copyright__text">
                        <p className='fs-6'>Developed By <span className='color:orange'> <Link to="#">NobleHands Technology and Solutions Ltd.</Link></span> - &copy;2024</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>




    </div>
  )
}

export default MainFooter