import React,  { useContext } from 'react'
import { Navbar, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { SchoolContext } from '../../AuthContext/SchoolProvider'
import './SchoolNavbar.css' 


const SchoolNavbar = () => {
  const { selectedSchool} = useContext(SchoolContext);

  return (
    <>
    <Navbar expand="lg"  className="col-lg-12">
    <Container className="card">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav ">
          <Link to={`/admin/frontend/${selectedSchool}`} className="me-4"><h4>School</h4></Link>
          <Link to={`/admin/courses/${selectedSchool}`} className="me-4"><h4>Courses</h4></Link>
          <Link to={`/admin/subjectlist/${selectedSchool}`} className="me-4"><h4>Subjects</h4></Link>
          <Link to={`/admin/events/${selectedSchool}`} className="me-4"><h4>Events</h4></Link>
          <Link to={`/admin/achievements/${selectedSchool}`} className="me-4"><h4>Achievement</h4></Link>
          <Link to={`/admin/galleries/${selectedSchool}`} className="me-4"><h4>Gallery</h4></Link>
          <Link to={`/admin/blogs/${selectedSchool}`} className="me-4"><h4>Blog</h4></Link>
          <Link to={`/admin/contact/${selectedSchool}`} className="me-4"><h4>Contact</h4></Link>
          <Link to={`/admin/departments/${selectedSchool}`} className="me-4"><h4>Department</h4></Link>
          <Link to={`/admin/boardofdirectors/${selectedSchool}`} className="me-4"><h4>Board of Directors</h4></Link>
          <Link to={`/admin/ptamembers/${selectedSchool}`} className="me-4"><h4>PTA Members</h4></Link>
          <Link to={`/admin/healthfacility/${selectedSchool}`} className="me-4"><h4>Health Facility</h4></Link>
          <Link to={`/admin/alumni/${selectedSchool}`} className="me-4"><h4>Alumni</h4></Link>
          <Link to={`/admin/mgtstaffs/${selectedSchool}`} className="me-4"><h4>School Management</h4></Link>
          {/* <Link to={`/admin/alldonations/${selectedSchool}`} className="me-4"><h4>Donations</h4></Link> */}
          <Link to={`/admin/viewfrontimage/${selectedSchool}`} className="me-4"><h4>Front Images</h4></Link>
      </Navbar.Collapse>
    </Container>
    </Navbar>
    </>
  )
}

export default SchoolNavbar

