
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";

function AddFrontimage() { 

  useEffect(()=>{
		document.title = "MGS |Add Images";
	});
   

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [selectedImages, setSelectedImages] = useState([]);
  const [setResponsess] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    image: null,
    school: selectedSchool,  //assuming there's a school field in the course model
  });

  const handleSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    e.target.value = "";
    setFormData(prevState => ({ ...prevState, image: selectedFilesArray }));
  };

  function deleteHandler(image) {
  // Find the index of the image to delete
  const indexToDelete = selectedImages.indexOf(image);
    
  // Remove the image from the selectedImages array
  const newSelectedImages = selectedImages.filter((e) => e !== image);

  // Remove the corresponding file from the formData.image array
  if (indexToDelete !== -1 && formData.image) {
      formData.image.splice(indexToDelete, 1);
  }
  
  // Update states
  setSelectedImages(newSelectedImages);
  setFormData(prevState => ({ ...prevState, image: [...formData.image] }));

  // Revoke the object URL
  URL.revokeObjectURL(image);
  }

  const goBack = () => {  
    navigate(-1);
  };

  const [error, setError] = useState(null);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("title", formData.title);
  if (formData.image) {
    for (let i = 0; i < formData.image.length; i++) {
    formDataObj.append("image", formData.image[i]);
    }
  }
  formDataObj.append("school", formData.school);

      const token = accessToken
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/frontimage/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      if(response.status === 201 || response.status === 200){
        Swal.fire({
          icon: "success",
          title: "Front Image Created Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        
        goBack();
      }
  };
  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Gallery</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to FrontImages</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
              <label className="mb-1 "><strong>Title</strong></label>
               <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} className="form-control" required />
            </div>
            </div>
            <div className="col-lg-6 mb-2">
            <div className="form-group">
            <label className="btn btn-success w-100" >
                    {formData.image ? `${formData.image.length} files selected` : "You Can Upload More Than One Images"}
                    <input type="file" id="image" name="image" onChange={handleSelectFile} multiple className="d-none" />
               
                      {selectedImages.length > 0 &&
                      (selectedImages.length > 10 ? (
                        <p className="error">
                          You can't upload more than 10 images! <br />
                          <span>
                            please delete <b> {selectedImages.length - 10} </b> of them{" "}
                          </span>
                        </p>
                      ) : (<div className="upload-btn">
                        {selectedImages.length} IMAGE SELECTED
                      {selectedImages.length === 1 ? "" : "S"} 
                      </div>
                    ))}
                    </label>
                    <div className="row">
                    {selectedImages && selectedImages.map((image, index) => (
                      
                          <div key={image} className="col-md-6">
                            <div className="image position-relative">
                            <img src={image} alt="upload"/>
                            <button onClick={() => deleteHandler(image)} className="btn btn-sm btn-danger position-absolute" 
                                style={{top: '5px', right: '5px'}}> X </button>
                            <p>{index + 1}</p>
                          </div>
                          </div>
                      ))}
                    </div>
                        </div>
                        </div>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddFrontimage