
import React, { useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddDepartment() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [setResponsess] = useState('');
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    mainImage: null,
    school: selectedSchool,  // there's a school field in the course model
  });

  const goBack = () => {  
    navigate(-1);
  };
  
  const [error, setError] = useState(null);

 
  const handleChange = (e) => {
    const { name, value } = e.target;
        if (name === "mainImage") {
          setFormData({ ...formData, [name]: e.target.files[0] });
        } else {
          setFormData({ ...formData, [name]: value });
       }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("description", formData.description);
    formDataObj.append("mainImage", formData.mainImage);
    formDataObj.append("school", formData.school); //school field

    try {
      const token = accessToken
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/departments/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      setResponsess(response)
      Swal.fire({
        icon: "success",
        title: "Department Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      goBack();
    } catch (err) {
      if (err.response.status === 400) {
      setError("Name already exist. Please try again.");
    }
    else{
      setError("Something went wrong. Please try again later.");
    }
  }
  };

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Department</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Department</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
            {/* Other fields... */}
            <div className="col-lg-6 mb-2">
            <div className="form-group mb-2">
              <label className="mb-1 "><strong>Department Name</strong></label>
               <input type="text" id="name" name="name" value={formData?.name} onChange={handleChange} className="form-control" required placeholder="Name of the Department" />
            </div>
            </div><br></br>
            <div className="col-lg-6 mb-2">
            <div className="form-group mb-2">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData?.description} onChange={(content) => setFormData({ ...formData, description: content })} />
               </div>
               </div><br></br>
      
                <label className="btn btn-success col-lg-6 mb-2 " >
                {formData?.mainImage ? formData?.mainImage.name : "Upload Course Image"}
                  <input type="file" id="mainImage" name="mainImage" onChange={handleChange} className="d-none" />
                  <br/>
                <div className="edit-content">
              {formData?.mainImage && (
                  <div style={{ position: "relative" }}>
                  <button onClick={() => setFormData({...formData, mainImage: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData?.mainImage === 'string'
                ? <img src={formData?.mainImage} alt="Existing" />
                : <img src={URL.createObjectURL(formData?.mainImage)} alt="Uploaded" />
              }
                </div> )}
                  </div>
                    </label><br/>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddDepartment;


