
import { useContext, useDebugValue } from "react";
import AuthContext from "../AuthContext/AuthProvider";

const useAuth = () => {
    const context = useContext(AuthContext);
    const { user, accessToken, refreshToken, csrfToken, userId, setAndPersistUser, setUser, setAccessToken, setRefreshToken, setCSRFToken, setAndPersistUserId } = context;

    useDebugValue(context, context => context?.user ? "Logged In" : "Logged Out");

    return { user, accessToken, refreshToken, csrfToken, userId, setUser, setAccessToken, setRefreshToken, setCSRFToken, setAndPersistUserId };
   
}


export default useAuth;


