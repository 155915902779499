
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function AddMgtStaff() { 
  useEffect(()=>{
		document.title = "MGS | Dashboard";
	});
  
  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool} = useContext(SchoolContext);
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    image: null,
    started_year: "",
    end_year: "",
    school: selectedSchool,  //assuming there's a school field in the course model
  });

  const goBack = () => {  
    navigate(-1);
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setFormData({ ...formData, image: e.target.files[0] });
    }
    else {
      setFormData({ ...formData, [name]: value });
    }
  }

  const formatDateForBackend = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("position", formData.position);
    if (formData.startDate) {
      const formattedStartedYear = formatDateForBackend(formData.started_year);
        formDataObj.append("started_year", formattedStartedYear);
      }
    if (formData.end_year) {
      const formattedEndYear = formatDateForBackend(formData.end_year);
        formDataObj.append("end_year", formattedEndYear);
      }
    if (formData.image) {
        formDataObj.append("image", formData.image);
    }
    formDataObj.append("school", formData.school); //school field

      const token = accessToken
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/schoolmanagement/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      if(response.status === 200 || response.status === 201){
      Swal.fire({
        icon: "success",
        title: "School Management Member Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      
      goBack();
    }
  };
  
  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Management Staff</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Management</button><br/><br/>
          <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Name</strong></label>
               <input type="text" id="name" name="name" value={formData?.name} onChange={handleChange} className="form-control" required />
            </div>
            </div>

            <div className="col-lg-6 mb-2">
            <div className="form-group">
               <label className="mb-1 "><strong>Position</strong></label>
               <input type="text" id="position" name="position" value={formData?.position} onChange={handleChange} className="form-control" required />
             </div>
             </div><br/>
             <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
                <label className="mb-1 "><strong>Start Year</strong></label><br/>
                <DatePicker selected={formData?.started_year ? new Date(formData?.started_year) : null} onChange={(date) => setFormData({ ...formData, started_year: date })} className="form-control mobile-date-input" />
            </div>
            </div>
            <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
                <label className="mb-1 "><strong>End Year</strong></label><br/>
                <DatePicker selected={formData?.end_year ? new Date(formData?.end_year) : null} onChange={(date) => setFormData({ ...formData, end_year: date })} className="form-control mobile-date-input" />
            </div>
            </div>
            <label className="btn btn-success col-lg-6 mb-2  " >
                {formData?.image ? formData?.image.name : "Upload Staff Image"}
                  <input type="file" id="image" name="image" onChange={handleChange} className="d-none" />
                 <br/>
                <div className="edit-content">
              {formData?.image && (
                typeof formData?.image === 'string'
                ? <img src={formData?.image} alt="Existing" />
                : <img src={URL.createObjectURL(formData?.image)} alt="Uploaded" />
                  )}
                  </div>
                    </label>
                  <br/><br/>  

             
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddMgtStaff;


