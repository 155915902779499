import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../axios/axios";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function AddSchool() {
  useEffect(()=>{
		document.title = "MGS | Dashboard";
	});
  
    const { accessToken } = useAuth();
    let navigate = useNavigate();
    const [backendErrors, setBackendErrors] = useState(null);

    const [formData, setFormData] = useState({
        school_name: "",
        year_established: "",
        school_web_name:"",
        school_alias:"",
        school_type:"",
        school_level:"",
        school_category:"",
        our_history: "",
        our_vision: "",
        our_mission:"",
        welcome_message:"",
        address: "",
        town:"",
        status:"",
        region: "",
        is_active:"",
        district: "",
        school_motto: "",
        banner: null,
        school_logo: null,
        our_history_image: null,
        our_vision_image: null,
        our_mission_image: null,

      });

      const goBack = () => {  
        navigate(-1);
      };
      
  const [setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (["banner", "school_logo", "our_mission_image","our_vision_image","our_history_image"].includes(name)) {
      setFormData({ ...formData, [name]: e.target.files[0] });
     
    } else {
      setFormData({ ...formData, [name]: value });
    }

  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
  formDataObj.append("school_name", formData.school_name);
  formDataObj.append("year_established", formData.year_established);
  formDataObj.append("school_web_name", formData.school_web_name);
  formDataObj.append("school_alias", formData.school_alias);
  formDataObj.append("school_type", formData.school_type);
  formDataObj.append("school_level", formData.school_level);
  formDataObj.append("school_category", formData.school_category);
  formDataObj.append("our_history", formData.our_history);
  formDataObj.append("our_vision", formData.our_vision);
  formDataObj.append("our_mission", formData.our_mission);
  formDataObj.append("address", formData.address);
  formDataObj.append("status", formData.status);
  formDataObj.append("town", formData.town);
  formDataObj.append("region", formData.region);
  formDataObj.append("is_active", formData.is_active);
  formDataObj.append("district", formData.district);
  formDataObj.append("welcome_message", formData.welcome_message);
  formDataObj.append("school_motto", formData.school_motto);
  if (formData.banner) formDataObj.append("banner", formData.banner);
  if (formData.school_logo) formDataObj.append("school_logo", formData.school_logo);
  if (formData.our_history_image) formDataObj.append("our_history_image", formData.our_history_image);
  if (formData.our_vision_image) formDataObj.append("our_vision_image", formData.our_vision_image);
  if (formData.our_mission_image) formDataObj.append("our_mission_image", formData.our_mission_image);

    try {
     
    const token = accessToken
      const response = await axiosInstance.post("https://myghschool.myghschools.com/school/schools/", formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
 
      if (response.status === 201) {
        setFormData({
          school_name: "",
          year_established: "",
          school_web_name: "",
          school_alias: "",
          school_type: "",
          school_level: "",
          school_category: "",
          our_history: "",
          our_vision: "",
          our_mission: "",
          welcome_message: "",
          address: "",
          town: "",
          status: "",
          region: "",
          is_active: "",
          district: "",
          school_motto: "",
          banner: null,
          school_logo: null,
          our_history_image: null,
          our_vision_image: null,
          our_mission_image: null,
        });
        setError(null);
      Swal.fire({
        icon: "success",
        title: "School Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    goBack();
}
  // }
   catch (err) {
    if (err.response) {
        setBackendErrors(err);
    } else {
        console.error("not submitting: ", err);
        // setBackendErrors(err);
    }
    setError("Error submitting the form. Please try again.");
}

  };

  return (

    <>
    <Link to="/admin"><button className="btn btn-primary btn-sm">Back to Schools</button></Link><br/><br/>
    <div className="authincation ">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-md-8">
						<div className="authincation-content">
							<div className="row no-gutters">
								<div className="col-xl-12">
									<div className="auth-form">
										<div className="text-center mb-3">
											<Link to="/login">
												{/* <img src={logo} alt="" /> */}
											</Link>
										</div>
							<h4 className="text-center mb-4 ">Register to have your school online</h4>
                             <form onSubmit={handleSubmit}>
                                <div className="row">
                                <div className="col-lg-12 mb-3">
                                    <div className="form-group mb-3">
                                        <label className="text-label"><strong>School Name<span style={{color: 'red'}}>*</span></strong></label>
                                        {backendErrors?.response?.data?.detail && backendErrors?.response?.data?.detail=== "A school with this name already exists."? <div className="text-red " style={{fontWeight:'bold'}}> School with this name exists</div>: null}
                                        <input type="text" name="school_name" value={formData.school_name} onChange={handleChange} className="form-control" placeholder="Please Enter School Name" required
                                        />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label"><strong>School Alias</strong></label>
                                        <input type="text" name="school_alias" className="form-control" placeholder="eg: Ghanasco"  value={formData.school_alias} onChange={handleChange} 
                                        />
                                    </div>
                                    </div>
                                 
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label"><strong>School Motto</strong></label>
                                        <input type="text" name="school_motto" className="form-control"  placeholder="Please enter schoo motto" value={formData.school_motto} onChange={handleChange} 
                                        />
                                    </div>
                                    </div>
                                   
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label"><strong>Year Established</strong></label>
                                        <input  type="date" name="year_established" className="form-control" placeholder="Year the school was established" value={formData.year_established} onChange={handleChange} required/>
                                    </div>
                                    </div>
                                {/* <div className="row"> */}
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Type of School<span style={{color: 'red'}}>*</span></strong></label>
                                        <select id="school_type" name="school_type" value={formData.school_type} onChange={handleChange} className="form-select mb-3">
                                            <option >Choose... Government or Private</option> 
                                            <option value="government">Government School</option>
                                            <option value="private">Private School</option>
                                        required
                                        </select>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_level"><strong>School Level<span style={{color: 'red'}}>*</span></strong></label>
                                    {backendErrors?.response?.data?.school_level && backendErrors?.response?.data?.school_level === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Please provide school level</div>: null}
                                        <select id="school_level" name="school_level" value={formData.school_level} onChange={handleChange} className="form-select mb-3">
                                            <option disabled >Choose...</option> 
                                            <option value="university">University</option>
                                            <option value="nursing">Nursing</option>
                                            <option value="teachertraining">Teacher Training College</option>
                                            <option value="othertertiary">Other Tertiary</option>
                                            <option value="shs">SHS</option>
                                            <option value="nvti">NVTI</option>
                                            <option value="Complex">Complex (Jhs, Primary, Nursery)</option>
                                            <option value="JHS">JHS</option>
                                            <option value="basic">Primary Schol</option>
                                            <option value="nursery">Nursery & KG</option>
                                            <option value="creche">Creche</option>
                                            required
                                        </select>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="address"><strong>School Address<span style={{color: 'red'}}>*</span></strong></label>
                                    {backendErrors?.response?.data?.address && backendErrors?.response?.data?.address === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Please provide school address</div>: null}
                                        <input  type="text" name="address"  className="form-control"  placeholder="school Address"
                                           value={formData.address} onChange={handleChange} required
                                        />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="town"><strong>School Town</strong></label>
                                    {backendErrors?.response?.data?.town && backendErrors?.response?.data?.town === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Please provide school level</div>: null}
                                        <input  type="text" name="town"  className="form-control"  placeholder="Town school is located"
                                          value={formData.town} onChange={handleChange} />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="district"><strong>School District</strong></label>
                                    {backendErrors?.response?.data?.district && backendErrors?.response?.data?.district === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Please provide school district</div>: null}
                                        <input type="text" name="district" className="form-control" placeholder="District school is located" value={formData.district} onChange={handleChange} />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="region"><strong>School Region<span style={{color: 'red'}}></span></strong></label>
                                    {backendErrors?.response?.data?.region && backendErrors?.response?.data?.region === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Please provide school region</div>: null}
                                    <select id="region" name="region" value={formData.region} onChange={handleChange}      className="form-select mb-3">
                                            <option disabled>Choose... Region</option>
                                            <option value="Ahafo Region">Ahafo Region</option>
                                            <option value="Ashanti Region">Ashanti Region</option>
                                            <option value="Bono Region">Bono Region</option>
                                            <option value="Bono East Region">Bono East Region</option>
                                            <option value="Central Region">Central Region</option>
                                            <option value="Eastern Region">Eastern Region</option>
                                            <option value="Greater Accra Region">Greater Accra Region</option>
                                            <option value="Northern Region">Northern Region</option>
                                            <option value="North East Region">North East Region</option>
                                            <option value="Oti Region">Oti Region</option>
                                            <option value="Savannah Region">Savannah Region</option>
                                            <option value="Upper East Region">Upper East Region</option>
                                            <option value="Upper West Region">Upper West Region</option>
                                            <option value="Western Region">Western Region</option>
                                            <option value="Western North Region">Western North Region</option>
                                        </select>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="status"><strong>School Status<span style={{color: 'red'}}>*</span></strong></label>
                                    {backendErrors?.response?.data?.status && backendErrors?.response?.data?.status === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Please provide school region</div>: null}
                                        <select id="status" name="status" value={formData.status} onChange={handleChange} className="form-select mb-3">
                                            <option >Choose... Day or Boarding</option> 
                                            <option value="Day">Day</option>
                                            <option value="Boarding">Boarding</option>
                                            <option value="Hostel">Hostel</option>
                                        required
                                        </select>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Welcome Message</strong></label>
                                     
                                    <ReactQuill theme="snow" value={formData.welcome_message} onChange={(content) => setFormData({ ...formData, welcome_message: content })} />
                                    </div>
                                    </div>
                                    
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>School History</strong></label>
                                    
                                     <ReactQuill theme="snow" value={formData.our_history} onChange={(content) => setFormData({ ...formData, our_history: content })} />
                                    </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="our_vision"><strong>Our Vision</strong></label>
                                      <ReactQuill theme="snow" value={formData.our_vision} onChange={(content) => setFormData({ ...formData, our_vision: content })} />
                                    </div>
                                    </div>
                                    
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Our Mission</strong></label>
                                      <ReactQuill theme="snow" value={formData.our_mission} onChange={(content) => setFormData({ ...formData, our_mission: content })} />
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>School logo:&nbsp;&nbsp; </strong></label>
                                    {formData.school_logo ? formData.school_logo.name : "Upload schoo logo Image"}
                                        <input  type="file" name="school_logo" className="form-control" onChange={handleChange} required/>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>School Banner:&nbsp;&nbsp;</strong></label>
                                    {formData.banner ? formData.banner.name : "Upload banner image"}
                                        <input type="file" name="banner" className="form-control" onChange={handleChange} required/>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Our Vission Image:&nbsp;&nbsp;</strong></label>
                                    {formData.our_vision_image ? formData.our_vision_image.name : "Upload our vision image"}
                                        <input   type="file" name="our_vision_image" className="form-control" onChange={handleChange} />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Our Mission Image:&nbsp;&nbsp;</strong></label>
                                    {formData.our_mission_image ? formData.our_mission_image.name : "Upload our mission image"}
                                        <input  type="file" name="our_mission_image" className="form-control"  onChange={handleChange} />
                                    </div>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                    <label className="text-label" htmlFor="school_type"><strong>Our History Image:&nbsp;&nbsp;</strong></label>
                                    {formData.our_history_image ? formData.our_history_image.name : "Upload our history image"}
                                        <input type="file"  name="our_history_image" className="form-control" onChange={handleChange} />
                                    </div>
                                    </div>
                                   
                                </div>
		
                                <div className="text-center mt-4">
                                    <button type="submit" className="btn btn-primary btn-block">Create School</button>
                                </div>
							</form>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
</>

  );
}

export default AddSchool;
