import React, {useEffect, useContext, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import axios from 'axios';
import SchoolHeader from './SchoolHeader';


const baseUrl='https://myghschool.myghschools.com/school';

const HeadofSchool = () => {

  useEffect(()=>{
    document.title = "MGS | Head of School";
});

  const { id } = useParams();
  const [boardofdirectors, setBoardofDirectors] = useState(null);
  const [schools, setSchools] = useState(null);
  const [contact, setContact] = useState(null);
  const { selectedSchool} = useContext(SchoolContext);
  
  console.log('selected school:', selectedSchool)

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${id}`);
      setSchools(response.data);
      } catch (error) {
      }
    };


  const fetchCourseData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/schoolmanagement`);
    setBoardofDirectors(response.data);
    } catch (error) {
    }
  };


  const fetchContactData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
    setContact(response.data);
    } catch (error) {
    }
  };

  fetchCourseData();
  fetchSchoolData();
  fetchContactData();
  }, [id, selectedSchool]);


  return (
   <React.Fragment>
 
<div className="full-width-header">

<SchoolHeader />

<div className="rs-breadcrumbs breadcrumbs-overlay " style={{ backgroundImage: `url(${schools && schools.banner})`, backgroundSize: '100%' }}>
  <div className="breadcrumbs-inner">
      <div className="container">
          <div className="row">
              <div className="col-md-12 text-center">
                  <h1 className="page-title pt-5">Heads of School</h1>
                  <ul>
                      <li>
                      <Link to={`/schoolhome/${selectedSchool}`}>Home</Link>
                      </li>
                      <li>Heads of School</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>

  <div id="rs-courses-3" className="rs-courses-3 sec-spacer">
  <div className="card container pt-5">
  <div className="abt-title text-center">
      <h2>Heads of School</h2>
  </div>
        
    <div className="row grid">

{boardofdirectors && boardofdirectors.map((boardofdirector, index) => (
  
   <div className="col-lg-4 col-md-6 mr-3 " key={boardofdirector.id}>
   <div className="course-item p-2">
       <div className="course">
         <Link to="#"> <img src={boardofdirector.image} alt="" style={{width:"400px", height:'300px'}}/> </Link> 
            {/* <span className="course-value">Active</span> */}
         
      </div>
       <div className="course-body">
         <div className="course-desc">
           <h3 className="text-red" style={{color:"red", fontFamily:'inherite'}}><Link to="#">{boardofdirector.name}</Link></h3>
           <h4>{boardofdirector.position}</h4>
         </div>
       </div>
  
   </div>			
  </div> 

 ))}
       
   </div> 
  </div>
   </div>


   <footer id="rs-footer" className="bg3 rs-footer mt-0">
 <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 && contact[0].address}<br/>
 								{schools && schools.town}<br/>
 								{schools && schools.district}<br/>
 								{schools && schools.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 &&  contact[0].gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0].phone_number}<br/>
									{contact && contact.length > 0 &&  contact[0].phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 &&  contact[0].whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 && contact[0].email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0].facebook}<br/>
									{contact && contact.length > 0 &&  contact[0].instagram}<br/>
									{contact && contact.length > 0 && contact[0].website}<br/>
									{contact && contact.length > 0 &&  contact[0].youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>

  <div id="scrollUp">
      <i className="fa fa-angle-up"></i>
  </div> 
  

   <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
       <span aria-hidden="true" className="fa fa-close"></span>
     </button>
       <div className="modal-dialog modal-dialog-centered">
           <div className="modal-content">
               <div className="search-block clearfix">
                   <form>
                       <div className="form-group">
                           <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                     </div>
                   </form>
               </div>
           </div>
       </div>
   </div> 
</div>

 </React.Fragment>

  )
}

export default HeadofSchool

