import React,{useState, useEffect}  from 'react';
import {Link} from 'react-router-dom';
import {Tab, Nav} from 'react-bootstrap';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import useAuth from '../../../hooks/useAuth';
import { Dropdown } from "react-bootstrap";
import parse from 'html-react-parser';


const baseUrl="https://myghschool.myghschools.com";

const ViewNews =()=> {

	const [blogs, setBlogs] = useState([]);
	const {id} = useParams();
	const {accessToken} = useAuth();
	const navigate = useNavigate();

	
	const goBack= () =>{
		navigate(-1);
	}

	useEffect(() => {
		const fetchBlog = async () => {
		  try {
			const response = await axios.get(`${baseUrl}/schoolblog/${id}/`);
			setBlogs(response.data);
		  } catch (error) {
		  }
		};
	
		fetchBlog();
	  }, [id]);


	  const handleDelete = async (blogsId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this News!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schoolblog/${blogsId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(blogs)){
					const newblogs = blogs.filter(blog => blog.id !== blogsId);
					setBlogs(newblogs);
				} else {
					console.error('News is not an array: ', blogs);
				}
				swal('Good job!', 'Successfully Deleted', "success");

				goBack();
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your News data is safe!");
		  }
		});
	  }
	  
	return(
		<>
		<div className="col">
		<h2>News Details</h2>
		<button className="btn btn-primary btn-xs mb-4" onClick={() => goBack()}>Back</button>
		</div>
			<div className="row">
				<div className="col-xl-8 col-xxl-7">
					<div className="card">
						<div className="card-body">
							<div className="course-content d-flex justify-content-between flex-wrap">
								<div>
									<h3><span style={{fontSize:'14px', color:'red'}}>News Title:</span> {blogs.title}</h3>
									<ul className="d-flex align-items-center raiting my-0 flex-wrap">
										<li><span className="font-w500" style={{color:'red'}}>Author: </span><span style={{color:'black', fontSize:'1.2em'}}>{blogs?.author}</span> </li>
										<li style={{color:'red'}}>Category: <span style={{color:'black', fontSize:'1.2em'}}>{blogs.category}</span></li>
										<li style={{color:'red'}}>Date Published: <span style={{color:'black', fontSize:'1.2em'}}>{new Date(blogs.created_at).toLocaleDateString()}</span></li>
									</ul>
								</div>
								<div className="mt-2">
								<Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fa-solid fa-pen-to-square text-primary me-2"></i>
					  <Link to={`/admin/editnews/${id}`}>
                        Edit News </Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fa-solid fa-trash text-primary me-2"></i>
                       <Link to="#" onClick={() => handleDelete(blogs.id)}> Delete News </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>						
								</div>
							</div>
							<div className="video-img style-1">
								<div className="view-demo">
									<img src={blogs.featured_image} alt="" />
							
								</div>	
							</div>
							<Tab.Container defaultActiveKey='About'>
								<div className="course-details-tab style-2 mt-4">
									<nav>
										<Nav as='div' className="nav nav-tabs tab-auto" id="nav-tab">
											<Nav.Link as='button' className="nav-link" id="nav-about-tab" eventKey='About' type="button">News Story </Nav.Link>
									
										</Nav>
									</nav>
									<Tab.Content className="tab-content" id="nav-tabContent">
										<Tab.Pane  id="nav-about" eventKey='About'>
											<div className="about-content">
												<h4> <span style={{color:'red', fontSize:'1.2em'}}>Title:</span> {blogs.title}</h4>
												<div><span style={{color:'red', fontSize:'1.2em'}}>Main Story: </span>         {blogs && blogs.story ? parse(blogs.story) : 'No story available'}</div>
											</div>
										</Tab.Pane>								
									</Tab.Content>
								</div>
							
							</Tab.Container>	
						</div>	
					</div>
				</div>
				<div className="col-xl-4 col-xxl-5">
		
			
				</div>
			</div>	
		</>
	)
}
export default ViewNews;

