import React, {useState, useContext, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import axios from 'axios'
import { Modal, Carousel } from "react-bootstrap";
import SchoolHeader from './SchoolHeader';
import parse from 'html-react-parser';


const baseUrl='https://myghschool.myghschools.com/school';

const GallerySingle = () => {

  useEffect(()=>{
    document.title = "MGS | Gellery";
});


    const { id } = useParams();
    const [gallery, setSingleGallery] = useState(null);
    const [schools, setSchools] = useState(null);
    const [contact, setContact] = useState(null);
 	const { selectedSchool} = useContext(SchoolContext);


	  const [showModal, setShowModal] = useState(false);
	  const [carouselImageIndex, setCarouselImageIndex] = useState(0);
	
	
	  
		const openModal = (index) => {
		setCarouselImageIndex(index);
		setShowModal(true);
	  };
	
	const closeModal = () => setShowModal(false);

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${selectedSchool}`);
      setSchools(response.data);
      } catch (error) {
      }
    };

	const fetchGalleryData = async () => {
		try {
		const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/galleries/${id}`);
		setSingleGallery(response.data);
		} catch (error) {
		}
	  };


  const fetchContactData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
    setContact(response.data);
    } catch (error) {
    }
  };

  fetchGalleryData();
  fetchSchoolData();
  fetchContactData();
  }, [id, selectedSchool]);


  const formatDate = (dateStr) => {
    let date = new Date(dateStr);
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  }


    const determineMimeType = (url) => {
		if (url.endsWith('.mp4')) return 'video/mp4';
		if (url.endsWith('.webm')) return 'video/webm';
		if (url.endsWith('.avi')) return 'video/ogg';
		if (url.endsWith('.mov')) return 'video/mov';
		
		return 'video/*'; // fallback to a general video MIME type
	}

  return (
    <React.Fragment>
         <div className="inner-page">
		
         <SchoolHeader />
	
   
        <div className="rs-breadcrumbs breadcrumbs-overlay" style={{ backgroundImage: `url(${schools && schools.banner})`}}>
		    <div className="breadcrumbs-inner">
		        <div className="container">
		            <div className="row">
		                <div className="col-md-12 text-center">
		                    <h1 className="page-title pt-5">OUR GALLERY</h1>
		                    <ul>
		                        <li>
		                         <Link to={`/schoolhome/${selectedSchool}`} className="active">Home</Link>
		                        </li>
		                        <li>Gallery</li>
		                    </ul>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>
        <div className="rs-gallery sec-spacer">
            <div className="card container p-5">
            	<div className=" sec-title-2 mb-50 text-center">
            	    <h2>GALLERY DETAILS</h2>      
            		<h3>Pictures of <span style={{color:'red'}}> {gallery && gallery?.title} gallery</span></h3>
               
               
              <br/><br/>
              <div className="row card">
              <div className=" col-md-4">
              <img src={gallery && gallery.mainImage} alt="Gallery" onClick={() => openModal()}  style={{cursor:'pointer',width:'800px', height:'40vh'}}/>
              </div>
              <div className="col-md-6 text-left">
              <h3><span style={{color:'red'}}>Title: </span>{gallery && gallery?.title}</h3>
              <h4> <span style={{color:'red'}}>Date: </span>{gallery && formatDate(gallery?.galdate)}</h4>
              <h3><span style={{color:'red'}}>Description: </span></h3><div>{gallery && parse(gallery?.description)}</div>
              
                </div>
              </div>
            	</div>
              <h3 className="text-lg-center">{gallery && gallery?.galleryimages.length} pictures found </h3><br/>
              <h3 className="text-lg-center"> {gallery && gallery?.galleryimages.length === 0 && <p>No pictures found </p> }</h3>
            	<div className="row">
					{gallery && gallery.galleryimages.map((galleryimage, i) => (
						
					
						<div className="card col-md-4" key={galleryimage.id}>
            			<div className="gallery shadow-lg mb-4">
            			    <img src={galleryimage.image} alt="Gallery" onClick={() => openModal(i)} style={{cursor:'pointer', width:'300px', height:'40vh'}}/>
            			  
            			</div>
            		</div>
					))}
                </div> 
            
                <div className="about-content">
												<h4>Gallery Video</h4>
												{gallery?.video1 && (
													<video width="520" height="440" controls>
														<source src={gallery?.video1} type={determineMimeType(gallery?.video1)} />
														Your browser does not support the video tag.
													</video>
												)}
											</div>
            </div>
        </div>
		<Modal size="lg" show={showModal} onHide={closeModal} className="col-lg-12">
                    <Modal.Header closeButton >
                        <Modal.Title>{gallery && gallery.title}&nbsp;pictures</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="card-body pb-1">
                        <Carousel activeIndex={carouselImageIndex} onSelect={setCarouselImageIndex}>
                            {gallery && gallery.galleryimages.map((galleryimage, index) => (
                                <Carousel.Item key={index}>
                                    <img className="col-lg-3 col-md-6 mb-4 "
                                        src={galleryimage.image || gallery.image} alt="Slide" style={{ width:"80%" }}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Modal.Body>
                   </Modal>

        <footer id="rs-footer" className="bg3 rs-footer mt-0">
 <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 &&  contact[0].address}<br/>
 								{schools && schools.town}<br/>
 								{schools && schools.district}<br/>
 								{schools && schools.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 &&  contact[0].gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0].phone_number}<br/>
									{contact && contact.length > 0 &&  contact[0].phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 && contact[0].whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0].email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 && contact[0].facebook}<br/>
									{contact && contact.length > 0 &&  contact[0].instagram}<br/>
									{contact && contact.length > 0 &&  contact[0].website}<br/>
									{contact && contact.length > 0 &&  contact[0].youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>


        <div id="scrollUp">
            <i className="fa fa-angle-up"></i>
        </div>
        
   
        <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
        	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true" className="fa fa-close"></span>
	        </button>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="search-block clearfix">
                        <form>
                            <div className="form-group">
                                <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    
        

    </div>  
    </React.Fragment>
  )
}

export default GallerySingle