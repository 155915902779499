import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Index from "../jsx/index";


const RequireAuth = () => {

    const {accessToken} = useAuth();
    const location = useLocation();

    return (
        accessToken?
             <Index >
                 <Outlet />
            </Index>
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;
