import React  from 'react';
import {Link } from 'react-router-dom';
import "swiper/css";
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import swal from "sweetalert";
import SchoolNavbar from '../../layouts/SchoolNavbar';
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";

const baseUrl= 'https://myghschool.myghschools.com/school';

function AllBoardMembers(){

	const { selectedSchool } = useContext(SchoolContext);

	const { accessToken } = useAuth();
	const [boardmembers, setBoardmembers] = useState(null);
	const [page, setPage] = useState(1);
	const itemsPerPage = 12; // number of items to return per request
	useEffect(() =>{
		axios.get(`${baseUrl}/schools/${selectedSchool}/boardofdirectors`).then((response)=>{

			setBoardmembers(response.data);
		})	
		.catch((error) => {
		});
		},[selectedSchool]);

		  
	const handleDelete = async (boardmemberId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this school!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/boardofdirectors/${boardmemberId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(boardmembers)){
					const newBoardmembers = boardmembers.filter(boardmember => boardmember.id !== boardmemberId);
					setBoardmembers(newBoardmembers);
				} else {
					console.error('Board Member is not an array: ');
				}
				swal('Good job!', 'Successfully Deleted', "success");
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your school data is safe!");
		  }
		});
	  }


	return(
		<>
		  <SchoolNavbar />
			
				
			<div className="widget-heading d-flex justify-content-between align-items-center">
				<h3 className="m-0">All Board Members</h3>
				<Link to={`/admin/addboardmember/${selectedSchool}`} className="btn btn-primary btn-sm">Add Board Member</Link>
			</div>

			<div className="row">
			{boardmembers && boardmembers?.map((data, index)=>(
					<div className="col-xl-4 col-md-6" key={index}>
						<div className="card all-crs-wid">
							<div className="card-body">
								<div className="courses-bx">
									<div className="dlab-media">
										<img src={data?.image} alt="" />
									</div>
									<div className="dlab-info">
										<div className="dlab-title d-flex justify-content-between">
											<div>
												<h4><Link to={"#"}>{data?.name}</Link></h4>
												<p className="m-0">
												{data?.position && typeof data?.position === 'string' ? (data?.position) : null}
													<svg className="ms-1" width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="2" cy="2.5" r="2" fill="#DBDBDB"/>
													</svg>	
												</p>
											</div>	
										</div>
										<div className="d-flex justify-content-between content align-items-center">
										
											<button className="btn btn-xs btn-danger" onClick={() => handleDelete(data.id)}>Delete</button>
											<Link to={`/admin/editboardmember/${data.id}`} className="btn btn-xs btn-primary m-1">Edit</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
				
			</div>	
		
			<div className="pagination-down align-content-center">
				<div className="d-flex align-items-center justify-content-center flex-wrap">
					<ul>
						<li><Link to={"#"} onClick={() => setPage(page => Math.max(page - 1, 1))}><i className="fas fa-chevron-left"></i></Link></li>
						<li><Link to={"#"}> {page}</Link></li>
						<li><Link to={"#"} onClick={() => setPage(page => Math.min(page + 1, Math.ceil(boardmembers.length / itemsPerPage)))}><i className="fas fa-chevron-right"></i></Link></li>
					</ul>
				</div>
			</div>
		</>
	
	)
}
export default AllBoardMembers;

