import { useState, useEffect } from "react";

const useDebounce = (Query, delay) => {
    const [debouncedQuery, setDebouncedQuery] = useState(Query);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(Query);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }
    , [Query, delay]);

    return debouncedQuery;

};

export default useDebounce;