import React, {useState, useEffect} from 'react'
import MainNav from '../layout/MainNav'
import Assets1Loader from '../../AssetsLoader'
import { Link } from 'react-router-dom'
import MainFooter from '../layout/MainFooter'
import axios from 'axios'
import '../layout/search.css';
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import parse from 'html-react-parser';


const baseUrl = 'https://myghschool.myghschools.com/school'

const MainNews = () => {
    
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10; // number of items to return per request

  
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 1024, min: 850 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 850, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const truncateDescription = (description) => {
    if (description.length > 100) {
      return description.substring(0, 100) + "...";
    } else {
      return description;
    }
};
   
const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const [month, day, year] = new Date(dateString).toLocaleDateString("en-US", options).split("/");
    return `${day}-${month}-${year}`;
  }



	useEffect(() =>{
        fetchNewsData();
    },[]);

     const fetchNewsData = async () => {
        try {
        const response = await axios.get(`${baseUrl}/schoolblog/`);
        setNews(response.data);
        } catch (error) {
        }
      };

  return (

    <React.Fragment>

    <Assets1Loader />
    
    <MainNav/>

        <section className="blog-details-area pt-20 pb-100">
            <div className="container card pt-5"> 
                <div className="row justify-content-center">
                        <h3 className="text-center">All News</h3>
                    <div className="col-xl-8 col-lg-7">
                        <div className="blog-post-wrapper ">
                            {news?.slice((page - 1) * itemsPerPage, page * itemsPerPage)?.map((news, index) => (
                        
                                <div className="row mb-4" key={news.id}>
                                <div className="col-md-6">
                                    <Link to={`/mainnewssingle/${news.id}`}><img src={news?.featured_image} style={{width:'400px', height:'200px'}} alt="img"/></Link>
                                </div>
                                <div className=" col-md-6 pt-3">
                                    <ul className="tgbanner__content-meta list-wrap">
                                        <li className="category" style={{color:'red'}}>{news?.category}</li>
                                        <li>{formatDate(news.created_at)}</li>
                                    </ul>
                                    <h4 className="title tgcommon__hover"> <Link to={`/mainnewssingle/${news.id}`}><span style={{color:'red', fontSize:'0.8em'}}>Title: </span> {news?.title}</Link></h4>
                                    <Link to={`/mainnewssingle/${news.id}`}>
                                    <div><span style={{color:'red', fontSize:'0.8em'}}>Story: </span>{parse(truncateDescription(news.story))}</div>
                                    </Link>
                                    <div className="latest__post-read-more">
                                    <Link to={`/mainnewssingle/${news.id}`}>Read More <i className="far fa-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            ))}
                            <div className="pagination">
                                    <button onClick={() => setPage(page => Math.max(page - 1, 1))} >Previous</button>
                                    <span>Page {page}</span>
                                    <button onClick={() => setPage(page => Math.min(page + 1, Math.ceil(news.length / itemsPerPage)))}>Next</button>                               
                            </div>
                                
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        {/* <aside className="blog-sidebar"> */}
                            <div className="widget sidebar-widget widget_categories">
                                <h4 className="widget-title">Trending Category</h4>
                                <ul className="list-wrap">
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/technology">technology</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/business">business</Link>                                     
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/entertainment">Entertainment</Link>                                     
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/sports">Sports</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/politics">politics</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/health">Health</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/love">Love & Relationship</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="widget sidebar-widget">
                                <div className="sidePost-active">
                                    <h3>Latest News</h3>
                                    <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={2000} keyBoardControl={true} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]} >
                                    {news && news.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 10).map((news, index) => (
                                        
                                         <div key={news.id} style={{ margin: '10px' }}>
                                        <div className="sidePost__item"  style={{ backgroundImage: `url(${news.featured_image})` }}/>
                                        <div>
                                            <Link to={`/mainnewssingle/${news.id}`} >{news.category}</Link>
                                            <h5 className="title tgcommon__hover"><Link to={`/mainnewssingle/${news.id}`}>{news.title}</Link></h5><br/>
                                        </div>
                                        </div>
                                    ))}
                                   </Carousel>
                                    </div>
                                  
                                    </div>
                           
                            <div className="widget sidebar-widget">
                                <h4 className="widget-title">Check This Out (ads)</h4>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div><br/>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div><br/>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div><br/>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                        {/* </aside> */}
                    </div>
                </div>
            {/* </div> */}
        </section>


    {/* </main> */}


    <MainFooter />
   </React.Fragment>
  )
}

export default MainNews