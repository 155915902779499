import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import SchoolNavbar from '../../layouts/SchoolNavbar';
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import axios from 'axios';
import swal from 'sweetalert';
import useAuth from '../../../hooks/useAuth';

const baseUrl="https://myghschool.myghschools.com/";

function SubjectList(){
	const {accessToken} = useAuth();
	const [subjects, setSubjects] = useState([]);
	const { selectedSchool } = useContext(SchoolContext);

	useEffect(() =>{
	axios.get(`${baseUrl}school/schools/${selectedSchool}/subjects`).then((response)=>{
		setSubjects(response.data);
	});
	},[selectedSchool]);

	const handleDelete = async (subjectId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this school!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		}) 
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}school/schools/${selectedSchool}/subjects/${subjectId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){
				if(Array.isArray(subjects)){
					const newSubjects = subjects.filter(subjects => subjects.id !== subjectId);
					setSubjects(newSubjects);
				} 
				swal('Good job!', 'Successfully Deleted', "success");
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your school data is safe!");
		  }
		});
	  }


	return(
		<>
		    <SchoolNavbar />
				<div className="widget-heading d-flex justify-content-between align-items-center">
				<h3 className="m-0">All Subjects</h3>
				<Link to={`/admin/addsubject/${selectedSchool}`} className="btn btn-primary btn-sm">Add Subject</Link>
			</div>
			<div className="row col-lg-12">
				{subjects.map((data, index)=>(
					<div className="col-lg-6 col-xl-3" key={index}>
						<div className="card all-crs-wid">
							<div className="card-body">
								<div className="courses-bx">
									<div className="dlab-media">
										<img src={data.image} alt="" />
									</div>
									<div className="dlab-info">
										<div className="dlab-title d-flex justify-content-between">
											<div>
												<h4><Link to={`/admin/viewsubject/${data.id}`}>{data.name}</Link></h4>
												
												{data.courses && data.courses.map((course, idx) => (
												<p className="m-0" key={idx}>{course.id}
														<svg className="ms-1" width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
															<circle cx="2" cy="2.5" r="2" fill="#DBDBDB"/>
														</svg>
													</p>
												))}
											</div>	
										</div>
									
										<button className="btn btn-xs btn-primary " onClick={() => handleDelete(data.id)}>Delete</button>
										<Link to={`/admin/viewsubject/${data.id}`} ><button  className="btn btn-xs btn-primary m-3">View</button></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
				
			</div>	
			<div className="pagination-down">
				<div className="d-flex align-items-center justify-content-center flex-wrap">
					<ul>
						<li><Link to={"#"}><i className="fas fa-chevron-left"></i></Link></li>
						<li><Link to={"#"} className="active">1</Link></li>
						<li><Link to={"#"}>2</Link></li>
						<li><Link to={"#"}>3</Link></li>
						<li><Link to={"#"}><i className="fas fa-chevron-right"></i></Link></li>
					</ul>
				</div>
			</div>
		</>
	
	)
}
export default SubjectList;


