import React, {useEffect, useContext, useState} from 'react'
import { Link} from 'react-router-dom'
import axios from 'axios'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import parse from 'html-react-parser';
import SchoolHeader from './SchoolHeader';


const baseUrl='https://myghschool.myghschools.com/school';

const HealthFacility = () => {


  useEffect(()=>{
    document.title = "MGS | Health Facility";
});

//   const { id } = useParams();
  const [contact, setContact] = useState(null);
  const [healthfacility, setHealthFacility] = useState(null);
  const [schools, setSchools] = useState(null);
  const { selectedSchool} = useContext(SchoolContext);

  useEffect(() => {
  const fetchHealthFacilityData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/healthfacility`);
    setHealthFacility(response.data);
    } catch (error) {
    }
  };

  const fetchContactData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
    setContact(response.data);
    } catch (error) {
    }
  };

  const fetchSchoolData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}`);
    setSchools(response.data);
    } catch (error) {
    }
  };

  fetchContactData();
  fetchHealthFacilityData();
  fetchSchoolData();
  }, [selectedSchool]);


        
  const determineMimeType = (url) => {
    if (url.endsWith('.mp4')) return 'video/mp4';
    if (url.endsWith('.webm')) return 'video/webm';
    if (url.endsWith('.avi')) return 'video/ogg';
    if (url.endsWith('.mov')) return 'video/mov';
    
    return 'video/*'; // fallback to a general video MIME type
  }


  return (
   <React.Fragment>
  <div className="full-width-header">
  <SchoolHeader />
  
<div className="rs-breadcrumbs breadcrumbs-overlay" style={{ backgroundImage: `url(${schools && schools.banner})` , backgroundSize: '100%'}}>
<div className="breadcrumbs-inner">
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
                <h1 className="page-title pt-5">Health Facility</h1>
                <ul>
                    <li>
                       <Link to={`/schoolhome/${selectedSchool}`}>Home</Link>
                    </li>
                    <li>Health Facility</li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div>

<div className="contact-page-section sec-spacer">
  <div className="container card">
  <h2 className="text-center">Our Health Facility for students</h2>
  {healthfacility && healthfacility.length > 0 ? (
    <div>
    
      <div className="text-center card">
        <h3><span style={{color:'red', fontFamily:'inherit', fontSize:'0.8em'}}>Name:</span> {healthfacility && healthfacility[0]?.name}</h3>
      
            <h4>
              <span style={{color:'red', fontFamily:'inherit', fontSize:'1em'}}>Description:</span> 
              {parse(String(healthfacility[0].description))}
            </h4>
       

      </div>
    <div className="row contact-address-section">
    <div className="col-md-6 pl-0">
    <div className="contact-info contact-phone" style={{marginTop:'10px'}}>
      {healthfacility && <img src={healthfacility[0]?.mainImage1} alt="Health Facility"  style={{width:'400px', height:'300px'}}/>}
      </div>
    </div><br/><br/>
    <div className="col-md-6">
      <div className="contact-info contact-phone" style={{marginTop:'10px'}}>
      {healthfacility && <img src={healthfacility[0]?.mainImage2} alt="Health Facility"  style={{width:'400px', height:'300px'}}/>}
      </div>
    </div><br/><br/>
    <div className="col-md-6 pr-0">
      <div className="contact-info contact-email" style={{marginTop:'10px'}}>
      {healthfacility && <img src={healthfacility[0]?.mainImage3} alt="Health Facility" style={{width:'400px', height:'300px'}} />}
        </div>
      </div><br/><br/>
    <div className="col-md-6 pr-0">
      <div className="contact-info contact-email" style={{marginTop:'10px'}}>
      {healthfacility && <img src={healthfacility[0]?.mainImage4} alt="Health Facility"  style={{width:'400px', height:'300px'}}/>}
        </div>
      </div>
    <div className="col-md-6 pr-0">
    {healthfacility[0]?.video && (
      <div className="contact-info contact-email" style={{marginTop:'10px'}}>
        <video width="320" height="240" controls className="col-12">
              <source src={healthfacility[0]?.video} type={determineMimeType(healthfacility[0]?.video)} />
              Your browser does not support the video tag.
      </video>
        </div>
    )}
      </div>
    </div>
    </div>
) : (
  <h4 className='text-center text-red'>No health facility information available.</h4>
)}
  </div>
  
</div>


<footer id="rs-footer" className="bg3 rs-footer mt-0">
 <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 &&  contact[0].address}<br/>
 								{schools && schools.town}<br/>
 								{schools && schools.district}<br/>
 								{schools && schools.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 &&  contact[0].gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0].phone_number}<br/>
									{contact && contact.length > 0 && contact[0].phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 &&  contact[0].whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0].email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0].facebook}<br/>
									{contact && contact.length > 0 &&  contact[0].instagram}<br/>
									{contact && contact.length > 0 && contact[0].website}<br/>
									{contact && contact.length > 0 &&  contact[0].youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-red">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-red">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>

    <div id="scrollUp">
        <i className="fa fa-angle-up"></i>
    </div>


    <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" className="fa fa-close"></span>
      </button>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="search-block clearfix">
                    <form>
                        <div className="form-group">
                            <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>
    </React.Fragment>

  )
}

export default HealthFacility