import React, {useState, useContext, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import './search.css';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import useDebounce from '../searchhooks/useDebounce';
import {SchoolContext} from '../../../../AuthContext/SchoolProvider';


const baseUrl= 'https://myghschool.myghschools.com/school';

const MainNav = () => {

    const { setSelectedSchool } = useContext(SchoolContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const debouncedSearchQuery = useDebounce(searchQuery, 500);

      const handleSchoolSelect = (schoolId) => {
        setSelectedSchool(schoolId);
       };
 
       const handleSearch = useCallback(async () => {
        try {
          const response = await axios.get(`${baseUrl}/search/?search=${searchQuery}`);
          setSearchResults(response.data);
        } catch (error) {
        }
      }, [searchQuery]);


      useEffect(() => {
        if (debouncedSearchQuery.length >= 3) {
            handleSearch();
        } else {
            setSearchResults([]);
        }
    }, [debouncedSearchQuery, handleSearch]);



    const [isOpen, setIsOpen] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(null);
      
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleSubMenu = (index) => {
        setOpenSubMenu(openSubMenu === index ? null : index);
      };


    const ClearInput = () => {
        setSearchQuery("");
        setSearchResults([]);
        };


  return (
    <div>

<header>
    <div className="header__top">
        <div className="container">
            <div className="row align-items-center">
            <div className="col-lg-4 col-md-6 col-sm-6 order-2 order-lg-0">
                        <div className="header__top-search search-bar-container  "> 
                                         <div className="searchInputs">
                                            <input type="text"  value={searchQuery}  onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search all schools in Ghana..." />
                                            <div className="searchIcon">
                                               {searchResults.length === 0 ? (
                                               <SearchIcon /> 
                                               ):( 
                                               <CloseIcon id="clearBtn" onClick={ClearInput}/>
                                               )}
                                               </div>
                                        </div>
                                        {searchResults.length > 0 && (
                                            <div className="search-results-container">
                                        {searchResults.map((school) => (
                                            <div key={school.id} className="search-result-item">
                                              <Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}> {school.school_name} </Link> 
                                                {school.school_alias} <br/>
                                            </div>
                                            ))}
                                             </div> 
                                             )}
                                    </div>
            </div>
                <div className="col-lg-4 col-md-3 order-0 order-lg-2 d-none d-md-block">
                    <div className="header__top-logo logo text-lg-center">
                       <Link to="/" className="logo-dark"><img src="/assets1/img/logo/MYGH1.jpg" alt="Logo"/></Link>
                    </div>
                </div>
                        
                <div className="col-lg-4 col-md-3 col-sm-6 order-3 d-none d-sm-block">
                    <div className="header__top-right">
                        <ul className="list-wrap">
                            <li className="news-btn">  <Link to="/login" className="btn"><span className="btn-text">LogIn</span></Link> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="header-fixed-height"></div>
    <div id="sticky-header" className="tg-header__area">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="tgmenu__wrap">
                        <nav className="tgmenu__nav">
                            <div className="logo d-block d-md-none">
                              <Link to="/" className="logo-dark"><img src="/assets1/img/logo/MYGH1.jpg" alt="Logo"/></Link>
                          
                            </div>
                            {/* <div className="offcanvas-toggle">
                                    <a href="#"><i className="flaticon-menu-bar"></i></a>
                            </div> */}
                            <div className="tgmenu__navbar-wrap tgmenu__main-menu d-none d-lg-flex">
                                <ul className="navigation">
                                    <li className="active "><Link to="/" >Home</Link></li>
                                    <li className="menu-item-has-children"><Link to="#">All Schools</Link>
                                        <ul className="sub-menu">
                                            <li className="active"><Link to="/allschools" >All Schools</Link></li>
                                            <li><Link to="/alluniversities">All Universities</Link></li>
                                            <li><Link to="/allnursing">All Nursing Schools</Link></li>
                                            <li><Link to="/allteachertraining" >All Teacher Training</Link></li>
                                            <li><Link to="/allothertertiary">All Other Tertiary</Link></li>
                                            <li><Link to="/allnvti">All NVTI</Link></li>
                                            <li><Link to="/allshs" >All SHS</Link></li>
                                            <li><Link to="/alljhs">All JHS</Link></li>
                                            <li><Link to="/allbasic" >All Basic Schools</Link></li>
                                            <li><Link to="/allnursery" >All Nursery</Link></li>
                                            <li><Link to="/allcreche" >All Creche</Link></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="#">Public Schools</Link>
                                        <ul className="sub-menu">
                                            <li className="active"><Link to="/publicuniversities">Universities</Link></li>
                                            <li><Link to="/publicnursing">Nursing Schools</Link></li>
                                            <li><Link to="/publicteachertraining">Teacher Training</Link></li>
                                            <li><Link to="/publicothertertiary">Other Tertiary</Link></li>
                                            <li><Link to="/publicnvti">NVTI</Link></li>
                                            <li><Link to="/publicshs">SHS</Link></li>
                                            <li><Link to="/publicjhs">JHS</Link></li>
                                            <li><Link to="/publicbasic">Basic Schools</Link></li>
                                        </ul>
                                    </li>
                                    <li className=" menu-item-has-children"><Link to="#">Private Schools</Link>
                                        <ul className="sub-menu">
                                        <li className="active"><Link to="/privateuniversities">Universities</Link></li>
                                            <li><Link to="/privatenursing">Nursing Schools</Link></li>
                                            <li><Link to="/privateteachertraining">Teacher Training</Link></li>
                                            <li><Link to="/privateothertertiary">Other Tertiary</Link></li>
                                            <li><Link to="/privatenvti">NVTI</Link></li>
                                            <li><Link to="/privateshs">SHS</Link></li>
                                            <li><Link to="/privatejhs">JHS</Link></li>
                                            <li><Link to="/privatebasic">Basic Schools</Link></li>
                                            <li><Link to="/privatenursery">All Nursery</Link></li>
                                            <li><Link to="/privatecreche">All Creche</Link></li>
                                        </ul>
                                    </li>
                                    <li className=" menu-item-has-children"><Link to="#">SHS Category</Link>
                                        <ul className="sub-menu">
                                        <li className="active"><Link to="/categorya">Category A</Link></li>
                                            <li><Link to="/categoryb">Category B</Link></li>
                                            <li><Link to="/categoryc">Category C</Link></li>
                                          <li><Link to="/categoryd">Category D</Link></li>                                          
                                        </ul>
                                    </li>
                                    <li><Link to="/mainnews">News</Link></li>
                                
                                </ul>
                            </div>
                        </nav>
                        <div className="mobile-nav-toggler" onClick={toggleMenu}> {isOpen ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}</div>
                    </div>
                    {/* <!-- Mobile Menu  --> */}  
               
                        <div className="mobile-menu-container">
                           {/* Mobile Menu */}
                           {isOpen && <div className="overlay" onClick={toggleMenu}></div>}
                        <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
                            <nav>
                                <ul >
                                    <li className="active "><Link to="/" style={{color:'black'}}>Home</Link></li>
                                    {/* <li className="menu-item-has-children"><Link to="#">All Schools</Link> */}
                                    <li className={`menu-item-has-children ${openSubMenu === 1 ? 'open' : ''}`}>
                                     <Link to="#" onClick={() => toggleSubMenu(1)} style={{color:'black'}}>All Schools {openSubMenu === 1 ? '-' : '+'}</Link>
                                        <ul className="sub-menu-1">
                                            <li className="active"><Link to="/allschools" style={{color:'black'}}>All Schools</Link></li>
                                            <li><Link to="/alluniversities" style={{color:'black'}}>All Universities</Link></li>
                                            <li><Link to="/allnursing" style={{color:'black'}}>All Nursing Schools</Link></li>
                                            <li><Link to="/allteachertraining" style={{color:'black'}}>All Teacher Training</Link></li>
                                            <li><Link to="/allothertertiary" style={{color:'black'}}>All Other Tertiary</Link></li>
                                            <li><Link to="/allnvti" style={{color:'black'}}>All NVTI</Link></li>
                                            <li><Link to="/allshs" style={{color:'black'}}>All SHS</Link></li>
                                            <li><Link to="/alljhs" style={{color:'black'}}>All JHS</Link></li>
                                            <li><Link to="/allbasic" style={{color:'black'}}>All Basic Schools</Link></li>
                                            <li><Link to="/allnursery" style={{color:'black'}}>All Nursery</Link></li>
                                            <li><Link to="/allcreche" style={{color:'black'}}>All Creche</Link></li>
                                        </ul>
                                    </li>
                                    <li className={`menu-item-has-children ${openSubMenu === 2 ? 'open' : ''}`}>
                                     <Link to="#" onClick={() => toggleSubMenu(2)} style={{color:'black'}}>Public Schools {openSubMenu === 2 ? '-' : '+'}</Link>
                                        <ul className="sub-menu-1">
                                            <li className="active"><Link to="/publicuniversities" style={{color:'black'}}>Universities</Link></li>
                                            <li><Link to="/publicnursing" style={{color:'black'}}>Nursing Schools</Link></li>
                                            <li><Link to="/publicteachertraining" style={{color:'black'}}>Teacher Training</Link></li>
                                            <li><Link to="/publicothertertiary" style={{color:'black'}}>Other Tertiary</Link></li>
                                            <li><Link to="/publicnvti" style={{color:'black'}}>NVTI</Link></li>
                                            <li><Link to="/publicshs" style={{color:'black'}}>SHS</Link></li>
                                            <li><Link to="/publicjhs" style={{color:'black'}}>JHS</Link></li>
                                            <li><Link to="/publicbasic" style={{color:'black'}}>Basic Schools</Link></li>
                                        </ul>
                                    </li>
                                    <li className={`menu-item-has-children ${openSubMenu === 3 ? 'open' : ''}`}>
                                     <Link to="#" onClick={() => toggleSubMenu(3)} style={{color:'black'}}>Private Schools {openSubMenu === 3 ? '-' : '+'}</Link>
                                        <ul className="sub-menu-1">
                                        <li className="active"><Link to="/privateuniversities" style={{color:'black'}}>Universities</Link></li>
                                            <li><Link to="/privatenursing" style={{color:'black'}}>Nursing Schools</Link></li>
                                            <li><Link to="/privateteachertraining" style={{color:'black'}}>Teacher Training</Link></li>
                                            <li><Link to="/privateothertertiary" style={{color:'black'}}>Other Tertiary</Link></li>
                                            <li><Link to="/privatenvti" style={{color:'black'}}>NVTI</Link></li>
                                            <li><Link to="/privateshs" style={{color:'black'}}>SHS</Link></li>
                                            <li><Link to="/privatejhs" style={{color:'black'}}>JHS</Link></li>
                                            <li><Link to="/privatebasic" style={{color:'black'}}>Basic Schools</Link></li>
                                            <li><Link to="/privatenursery" style={{color:'black'}}>All Nursery</Link></li>
                                            <li><Link to="/privatecreche" style={{color:'black'}}>All Creche</Link></li>
                                        </ul>
                                    </li>
                                    <li className={`menu-item-has-children ${openSubMenu === 4 ? 'open' : ''}`}>
                                     <Link to="#" onClick={() => toggleSubMenu(4)} style={{color:'black'}}>School by Category{openSubMenu === 4 ? '-' : '+'}</Link>
                                        <ul className="sub-menu-1">
                                        <li className="active"><Link to="/categorya" style={{color:'black'}}>Category A</Link></li>
                                            <li><Link to="/categoryb" style={{color:'black'}}>Category B</Link></li>
                                            <li><Link to="/categoryc" style={{color:'black'}}>Category C</Link></li>
                                          <li><Link to="/categoryd" style={{color:'black'}}>Category D</Link></li>                                          
                                        </ul>
                                    </li>
                                    <li><Link to="/mainnews" style={{color:'black'}}>News</Link></li>
                                    <li><Link to="/login" style={{border:'10px', padding:'10px', background:'red', color:'white'}}>Login</Link></li>
                                </ul>
                            </nav>
                        </div>
                        </div>
                    
                </div>
            </div>
        </div>
    </div>

    </header>
    </div>
  )
}

export default MainNav


