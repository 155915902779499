

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const baseUrl = "https://myghschool.myghschools.com/school";

function EditBlog() { 
  useEffect(()=>{
		document.title = "MGS | Dashboard";
	});
  
  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [ setInitialBlogImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [setResponsess] = useState('');
  const {id} = useParams();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    mainImage: null,
    image: null,
    author: "",
    school: selectedSchool,//to ensure that the course is selected to be associated with the subject
  });


  const goBack = () => {  
    navigate(-1);
  };
  const [error, setError] = useState(null);

  const handleSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    e.target.value = "";
    setFormData(prevState => ({ ...prevState, image: selectedFilesArray }));


  };

  const removeImageFromBlog = (imageId) => {
    const updatedImages = formData.blogimages.filter(image => image.id !== imageId);
    setFormData(prev => ({ ...prev, blogimages: updatedImages }));
  }
  
  
  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
      axios.get(`${baseUrl}/schools/${selectedSchool}/blogs/${id}/`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData({
          ...res.data,
          // event: res.data.events.map(event => event.id), // map over the course objects to get an array of IDs
          image: null, 
          date: formatDate(res.data.date),
          
        });
        setInitialBlogImages(res.data.galleryimages);
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id, setInitialBlogImages]);

const formatDate = (dateStr) => {
  let date = new Date(dateStr);
  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);
  return `${day}-${month}-${year}`;
}

const handleChange = (e) => {
  const { name, value } = e.target;
  if (name === "mainImage") {
    setFormData({ ...formData, mainImage: e.target.files[0] });
  }
  else {
    setFormData({ ...formData, [name]: value });
  }
}


const handleSubmit = async (e) => {
  e.preventDefault();
  const formDataObj = new FormData();
  formDataObj.append("title", formData.title);
  formDataObj.append("description", formData.description);
  formDataObj.append("author", formData.author)
  
  if (formData.mainImage && formData.mainImage.name) { // Check if the image file is an object with a name property
    formDataObj.append("mainImage", formData.mainImage);
  } else if (typeof formData.mainImage === 'string') { 
  } else if (formData.mainImage) { // If the image file is a Blob without a name property
    formDataObj.append("mainImage", formData.mainImage, 'image.jpg'); // Add a filename
  }
  
if (formData.blogimages) {
  formData.blogimages.forEach(img => {
      formDataObj.append("blogimages", img.id); // assuming that each image object has an id
  });
}

  if (formData.image) {
      for (let i = 0; i < formData.image.length; i++) {
      formDataObj.append("image", formData.image[i]);
      }
  }
  
  formDataObj.append("school", formData.school); //school field
  formDataObj.append("venue", formData.venue);

  try {
    const token = accessToken
    const response = await axios.patch(`https://myghschool.rubykaryo.com/school/schools/${selectedSchool}/blogs/${id}/`, formDataObj, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`,
      },
    });
    setResponsess(response);
    Swal.fire({
      icon: "success",
      title: "achievement updated Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
    
    goBack();
  } catch (err) {
    if (err.response.status === 400) {
    setError("Name already exist. Please try again.");
  }
  else{
    setError("Something went wrong. Please try again later.");
  }
}

};

if (isLoading) {
  return <h2>Loading...</h2>; 
}
  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
        <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit Blog</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Blog</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
        <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3 edit-content">
              <label className="mb-1 "><strong>Title</strong></label>
               <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} className="form-control" required />
            </div>
            </div>
            
            <div className="col-lg-6 mb-2">
            <div className="form-group">
             <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
             </div>
             </div><br/>
            <div className="col-lg-6 mb-2">
            <div className="form-group edit-content">
               <label className="mb-1 "><strong>Author</strong></label>
               <input type="text" id="author" name="author" value={formData.author} onChange={handleChange} className="form-control" required />
             </div>
             </div>
             <br></br>
            <label className="btn btn-success  col-lg-6 col-xl-6 col-sm-6 mb-2" >
                {formData.mainImage ? formData.mainImage.name : "Upload Main Event Image"}
                  <input type="file" id="mainImage" name="mainImage" onChange={handleChange} className="d-none" />
                <br/>
                <div className="edit-content">
              {formData.mainImage && (
                typeof formData.mainImage === 'string'
                ? <img src={formData.mainImage} alt="Existing" style={{ width: '100%', height: '100px' }}/>
                :<img src={URL.createObjectURL(formData.mainImage)} alt="Uploaded" style={{ width: '100%', objectFit: "contain" }}/>
                  )}
                  </div>
                  </label><br/><br/>            
                  
                <div className="col-lg-6 mb-2">
                <div className="form-group">
                <label className="btn btn-success w-100" >
                    {formData.image ? `${formData.image.length} files selected` : "You Can Upload More Than One Images"}
                    <input type="file" id="image" name="image" onChange={handleSelectFile} multiple className="d-none" />
               
                      {selectedImages.length > 0 &&
                      (selectedImages.length > 10 ? (
                        <p className="error">
                          You can't upload more than 10 images! <br />
                          <span>
                            please delete <b> {selectedImages.length - 10} </b> of them{" "}
                          </span>
                        </p>
                      ) : (<div className="upload-btn">
                        {selectedImages.length} IMAGE SELECTED
                      {selectedImages.length === 1 ? "" : "S"} 
                      </div>
                    ))}
                    </label>
                    <div className="row multi-images img">
                    {formData.blogimages && formData.blogimages.map((imageObj, index) => (
                      
                          <div key={imageObj.id} className="image col-lg-6 col-md-3 col-sm-4 mb-2">
                            <div className="image position-relative">
                            <img src={imageObj.image} alt="upload"/>
                            <button type="button" onClick={() => removeImageFromBlog(imageObj.id)} className="btn btn-sm btn-danger position-absolute" style={{top: '5px', right: '5px'}}> X </button>
                            <p>{index + 1}</p>
                          </div>
                          </div>
                      ))}
                    </div>
                      </div>
                      </div>
                <Button variant="primary" type="submit">
                    Submit Update
                </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditBlog;

