import React  from 'react';
import {Link } from 'react-router-dom';
import "swiper/css";
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import swal from "sweetalert";
import SchoolNavbar from '../../layouts/SchoolNavbar';
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import parse from 'html-react-parser';

const baseUrl='https://myghschool.myghschools.com/school';

function CoursesMain(){

	const { selectedSchool } = useContext(SchoolContext);

	const { accessToken } = useAuth();
	const [courses, setCourses] = useState(null);
	const [page, setPage] = useState(1);
	const itemsPerPage = 12; // number of items to return per request
	useEffect(() =>{
		axios.get(`${baseUrl}/schools/${selectedSchool}/courses`).then((response)=>{

			setCourses(response.data);
		})	
		.catch((error) => {

		});
		},[selectedSchool]);


		// reduce the words and letters in the description
	const truncateDescription = (description) => {
		if (description.length > 30) {
		  return description.substring(0, 30) + "...";
		} else {
		  return description;
		}
	};
		  
	const handleDelete = async (courseId) => {
		swal({
		  title: "Are you sure?",
		  text: "Once deleted, you will not be able to recover this school!",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then(async (willDelete) => {
		  if (willDelete) {
			try {
			  const token = accessToken
			  const response = await axios.delete(`${baseUrl}/schools/${selectedSchool}/courses/${courseId}/`,
			  {
				headers: {
				  'Authorization': `Bearer ${token}`  // replace 'token' with your actual token variable
				}
			  });
	  
			  if(response.status === 200 || response.status === 204){

				if(Array.isArray(courses)){
					const newCourses = courses.filter(course => course.id !== courseId);
					setCourses(newCourses);
				} else {
					console.error('Schools is not an array: ', courses);
				}
				swal('Good job!', 'Successfully Deleted', "success");
			  } else {
				swal('Oops', 'Something went wrong!', "error");
			  }
			 
			} catch (error) {
			  swal('Oops', 'Something went wrong!', "error");
			}
		  } else {
			swal("Your school data is safe!");
		  }
		});
	  }


	return(
		<>
		  <SchoolNavbar />
			
				
			<div className="widget-heading d-flex justify-content-between align-items-center">
				<h3 className="m-0">All Courses</h3>
				<Link to={`/admin/addcourse/${selectedSchool}`} className="btn btn-primary btn-sm">Add Course</Link>
			</div>

			<div className="row">
			{courses && courses.map((data, index)=>(
					<div className="col-xl-4 col-md-6" key={index}>
						<div className="card all-crs-wid">
							<div className="card-body">
								<div className="courses-bx">
									<div className="dlab-media">
										<img src={data.image} alt="" />
									</div>
									<div className="dlab-info">
										<div className="dlab-title d-flex justify-content-between">
											<div>
												<h4><Link to={"./admin/course-details-1"}>{data.name}</Link></h4>
												<div className="m-0">
												{data.description && typeof data.description === 'string' ? parse(truncateDescription(data.description)) : null}
													<svg className="ms-1" width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
														{/* <circle cx="2" cy="2.5" r="2" fill="#DBDBDB"/> */}
													</svg>	
												</div>
											</div>	
										</div>
										<div className="d-flex justify-content-between content align-items-center">
										
											<button className="btn btn-xs btn-danger" onClick={() => handleDelete(data.id)}>Delete</button>
											<Link to={`/admin/editcourse/${data.id}`} className="btn btn-xs btn-primary m-1">Edit</Link>
											<Link to={`/admin/viewcourse/${data.id}`} className="btn btn-xs btn-primary m-1">View</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
				
			</div>	
		
			<div className="pagination-down align-content-center">
				<div className="d-flex align-items-center justify-content-center flex-wrap">
					<ul>
						<li><Link to={"#"} onClick={() => setPage(page => Math.max(page - 1, 1))}><i className="fas fa-chevron-left"></i></Link></li>
						<li><Link to={"#"}> {page}</Link></li>
						<li><Link to={"#"} onClick={() => setPage(page => Math.min(page + 1, Math.ceil(courses.length / itemsPerPage)))}><i className="fas fa-chevron-right"></i></Link></li>
					</ul>
				</div>
			</div>
		</>
	
	)
}
export default CoursesMain;

