

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const baseUrl="https://myghschool.myghschools.com";

function EditSubject() { 

  useEffect(()=>{
		document.title = "MGS | Dashboard";
	});

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [courses, setCourses] = useState([]);
  const [oldcourses, setOldCourses] = useState([]);
  const coursesOptions = courses.map(course => ({ value: course.id, label: course.name }));
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [backendErrors, setBackendErrors] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: null,
    subjects: [],
    school: selectedSchool, //this is toe ensure that school is selected to be associated with the subject
    course: [], //to ensure that the course is selected to be associated with the subject
  });


  const goBack = () => {  
    navigate(-1);
  };
  const [ setError] = useState(null);
  

  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
         axios.get(`${baseUrl}/schools/${selectedSchool}/courses/`,{
        headers: {
        
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCourses(res.data);
      }
        )
      .catch(error => console.error(error));
    }
  }, [selectedSchool, accessToken]);


  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
      axios.get(`${baseUrl}/schools/${selectedSchool}/subjects/${id}`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData({
          ...res.data,
          course: res.data.course.map(course => course.id),
          
        });
        setOldCourses(res.data);
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id]);
  

  const handleSelectChange = (selectedOptions) => {
    setFormData({ ...formData, course: selectedOptions ? selectedOptions.map(option => option.value) : [] });
}


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setFormData({ ...formData, [name]: e.target.files[0] || null });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("description", formData.description);
    if (formData.image && formData.image.name) { // Check if the image file is an object with a name property
        formDataObj.append("image", formData.image);
      } else if (typeof formData.image === 'string') { // If the image file is a string (URL), don't append it
        // Do nothing
      } else if (formData.image) { // If the image file is a Blob without a name property
        formDataObj.append("image", formData.image, 'image.jpg'); // Add a filename
      }
    formDataObj.append("school", formData.school); //school field
    formData.course.forEach((courseId) => {
        formDataObj.append('course', courseId);
      });

    try {
      const token = accessToken
      const response = await axios.patch(`${baseUrl}/schools/${selectedSchool}/subjects/${id}/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      Swal.fire({
        icon: "success",
        title: "Subject Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      goBack();
      
    } catch (err) {
      if (err.response.status === 400) {
        setBackendErrors(err)
      setError("Something went wrong. Please try again later.");
    }
  }
  }; 
  
  if (isLoading) {
    return <h2>Loading...</h2>; 
  }


  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit Subject</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Subjects</button><br/><br/>
          <form onSubmit={handleSubmit}>
            <label className="edit-content mb-1 col-6 "><strong>Select new Course</strong></label>
                <Select className="edit-content col-6" id="course" isMulti onChange={handleSelectChange}  options={coursesOptions} 
                /> 
                <br></br>
                <div className="row ">
                    <div className="edit-content form-group col-md-10 col-4">
                    <label className="edit-content mb-2 col-4"><strong style={{color:'red'}}>Courses associated with this subject</strong></label>
                {oldcourses.course && oldcourses.course.length > 0 ? (
                    
                    <div className="edit-content row col-6">
                    {oldcourses?.course.map((course, index) => (
                       
                        <div key={index} className="edit-content col-2">
                        <p><i className="fa-solid fa-square-check "> {course?.name}</i></p>
                        </div>
                    ))}
                    </div>
                ) : (
                    <p>No associated courses.</p>
                )}
            
                    {/* </div> */}
                    </div>
                </div>

            <div className="form-group col-6 edit-content"><br></br>
              <label className="mb-1 "><strong>Name</strong></label>
              {backendErrors?.response?.data?.name && backendErrors?.response?.data?.name === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Name of donation required</div>: null}
               <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-control" />
            </div><br></br>
            <div className="form-group edit-content col-6">
               <label className="edit-content col-6 mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
             </div><br></br>
       
                 <label className="btn btn-success col-lg-6 mb-2 edit-content" >
                {formData.image ? formData.image.name : "Upload Subject Image"}
                 <input type="file" id="image" name="image" onChange={handleChange} className="d-none" />
                
                {backendErrors?.response?.data?.image && backendErrors?.response?.data?.image === "The submitted data was not a file. Check the encoding type on the form."? <div className="text-red " style={{fontWeight:'bold'}}>Please upload course image</div>: null}
                <div className="edit-content">
                {formData.image && (
                typeof formData.image === 'string'
                    ? <img src={formData.image} alt="Existing"/>
                    : <img src={URL.createObjectURL(formData.image)} alt="Uploaded"  className="col-6"/>
                )}
                </div>
                </label><br/><br/>
                <Button variant="primary" type="submit">
                    Submit Update
                </Button>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditSubject;

