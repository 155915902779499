import { axiosPrivateInstance } from "../axios/axios";
import useAuth from "./useAuth";
import {useEffect} from 'react';
import useRefreshToken from "./useRefreshToken";


const useAxiosPrivate = () => {
    const { accessToken, setAccessToken, csrfToken, user } = useAuth();
    const refresh = useRefreshToken();

    useEffect(() => {

        const requestIntercept = axiosPrivateInstance.interceptors.request.use(
            (config) => {
                if(!config.headers["Authorization"]){
                    config.headers["Authorization"] = `Bearer ${accessToken}`;
                    config.headers['X-CSRFToken'] = csrfToken;
                }
                return config;
            }, 
            (error) => Promise.reject(error )
        );
        const responseIntercept = axiosPrivateInstance.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (!prevRequest?.sent && (error?.response?.status === 403 || error?.response?.status === 401 )){
                    prevRequest.sent = true;
                    //set new tokens to be use 
                    const {accessToken: newAccessToken, csrfToken: newCSRFToken } = await refresh();
                    setAccessToken(newAccessToken);
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    prevRequest.headers['X-CSRFToken'] = newCSRFToken;
                     
                    return axiosPrivateInstance(prevRequest);
                }
                return Promise.reject(error);
            }
        );
        return () => {
             axiosPrivateInstance.interceptors.request.eject(requestIntercept);
             axiosPrivateInstance.interceptors.response.eject(responseIntercept);
        }  
        }, [accessToken, user])


    return axiosPrivateInstance;
}

export default useAxiosPrivate;

