
import React, {useContext, Fragment, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import profile from "../../../images/profile/profile.png";
import PageTitle from "../../layouts/PageTitle";
import { useEffect } from "react";
import axios from "axios";
import  useAuth  from "../../../hooks/useAuth";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";


const baseUrl="https://myghschool.myghschools.com"

const AdminDetails = () => {
	useEffect(()=>{
		document.title = "MGS | Dashboard";
	});

	
	const [activeToggle, setActiveToggle] = useState("Schools-created");
	const { id } = useParams();
	const {accessToken} = useAuth();
	const [admin, setAdmin] = useState([]);
	const { setSelectedSchool } = useContext(SchoolContext);
	const [schools, setSchools] = useState(null);

	const [formData, setFormData] = useState({
		user: {
			username: "",
			first_name: "",
			last_name: "",
			email: "",
			// password: "",
		},
		middle_name: "",
		phone_number: "",
		phone_number2: "",
		admin_image: null,
		position: "",
		address: "",
		town: "",
		city: "",
		Region: "",
		country: "",
		gender: "",
		gps_address: "",
		department:"",

	  });

	  const handleSchoolSelect = (schoolId) => {
		setSelectedSchool(schoolId);
	 };
		 
  const truncateDescription = (vision) => {
		if (vision.length > 50) {
		  return vision.substring(0, 50) + "...";
		} else {
		  return vision;
		}
	}; 


	const handleChange = (event) => {
		const { name, value } = event.target;
	  
		if (name === "admin_image") {
		  setFormData({ ...formData, admin_image: event.target.files[0] });
		} else if (name.includes("user.")) {
		  const field = name.split(".")[1]; // splits "user.username" into "user" and "username" and selects "username"
		  setFormData({
			...formData,
			user: {
			  ...formData.user,
			  [field]: value,
			},
		  });
		} else {
		  setFormData({ ...formData, [name]: value });
		}
	  };
	  

	useEffect(() => {
		const token = accessToken;
		const fetchSchoolsbyId = async () => {
			const result = await axios.get(`${baseUrl}/school/schools?user_id=${id}`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setSchools(result.data);
		}
		fetchSchoolsbyId();
	}, [id, accessToken]);


	
	const toggleUserActivation = async (id) => {
		try{
			await axios.put(`${baseUrl}/user/user/${id}/activate/`);
		} catch(error) {
		}
	}

	useEffect(() => {

		const token = accessToken;

		axios.get(`${baseUrl}/user/admin/${id}/`, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
		.then((response) => {
            setAdmin(response.data);
            setFormData({
                ...formData,
                user: {
                    ...formData.user,
                    ...response.data.user
                },
                ...response.data
            });
         
        })
			.catch((error) => {
				
			});

	}, []);



const handleSubmit = async (e) => {
	e.preventDefault();

	const formDataObj = new FormData();
  formDataObj.append("user.first_name", formData.user.first_name);
  formDataObj.append("user.last_name", formData.user.last_name);
  formDataObj.append("user.email", formData.user.email);
  formDataObj.append("middle_name", formData.middle_name); 
  formDataObj.append("phone_number", formData.phone_number);
  formDataObj.append("phone_number2", formData.phone_number2);
  formDataObj.append("position", formData.position);
  formDataObj.append("address", formData.address);
  formDataObj.append("town", formData.town);
  formDataObj.append("city", formData.city);
  formDataObj.append("Region", formData.Region);
  formDataObj.append("country", formData.country);
  formDataObj.append("gender", formData.gender);
  formDataObj.append("gps_address", formData.gps_address);
  formDataObj.append("department", formData.department);

  if (formData.admin_image instanceof File) {
    formDataObj.append("admin_image", formData.admin_image);
  } else {
    formDataObj.append("admin_image_url", formData.admin_image);
  }

	try {
	  const token = accessToken
	  const response = await axios.patch(`${baseUrl}/user/admin/${id}/`, formDataObj, {
		headers: {
		  "Content-Type": "multipart/form-data",
		  "Authorization": `Bearer ${token}`,
		},
		

	  });
	  window.location.reload(true);
	} catch (err) {

  }
  
	}
  
  return (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="Dashboard" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                {/* <div className="cover-photo rounded"></div> */}
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img src={formData.admin_image} className="img-fluid rounded-circle" alt="profile" />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
					{admin && admin?.user ? (admin?.gender === 'Male' ? `Mr. ${admin?.user?.first_name} ${admin?.user?.last_name}` : `Mrs ${admin?.user?.first_name} ${admin?.user?.last_name}`) : 'Loading...'}
					</h4>
                    <p>{admin && admin?.user ? admin?.position : ' Add Position to your profile'}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{admin && admin?.user ? admin?.user?.email : 'Add email'}</h4>
                    <p>Email</p>
                  </div>
                  <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
					
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
					<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item as="div" className="dropdown-item">
					  <i className="fa-solid fa-pen-to-square text-primary me-2"></i>
					  <Link to="#" onClick={() => toggleUserActivation(id)}> Suspend User </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
	  	<div className="col-lg-12">
			
		</div>	
        <div className="col-xl-8">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
						<ul className="nav nav-tabs">
							<li className="nav-item" onClick={() => setActiveToggle("Schools-created")}>
								<Link to="#Schools-created" data-toggle="tab" className={`nav-link ${ activeToggle === "Schools-created" ? "active show" : ""}`}>Schools Created</Link>
								</li>
							<li className="nav-item" onClick={() => setActiveToggle("aboutUser")}>
								<Link to="#about-user"  data-toggle="tab" className={`nav-link ${ activeToggle === "aboutUser" ? "active show" : ""}`}>About User</Link>
								</li>
							<li className="nav-item">
								<Link to="#profile-settings" data-toggle="tab" onClick={() => setActiveToggle("setting")}  className={`nav-link ${ activeToggle === "setting" ? "active show" : ""}`}>Edit User</Link>
							</li>
						</ul>
					<div className="tab-content">
					
					<div className="tab-content">
						<div id="Schools-created" className={`tab-pane fade ${ activeToggle === "Schools-created" ? "active show" : "" }`} >
							<div className="my-post-content pt-3">
								

								<div className="profile-uoloaded-post border-bottom-1 pb-5">
									{/* <img src={profile08} alt="" className="img-fluid w-100 rounded" /> */}
							
										<h3 className="text-black">Schools created by this user</h3>
	
									{schools && schools.length === 0 ? (		
										<h4> You have not created any school</h4>
									):(
									<>
									<h4>Number of Schools:  {schools && schools.length}</h4>
										<div className="col-xl-12 col-xxl-12">
											<div className="row">
											{schools && schools.map((school, index) => (
												<div className="col-xl-12 " key={index}>
													<div className="card dlab-join-card h-auto">
														<div className="card-body">
															<div className="dlab-media d-flex justify-content-between">
															
																<div className="dlab-content">
																<Link to={`/admin/frontend/${school.id}`} onClick={() => handleSchoolSelect(schools.id)}><h4>{school.school_name}</h4></Link>
																<p><strong>Our Vision: </strong>{' '}{truncateDescription(school.our_vision || 'N/A')}</p>
																</div>	
															</div>
														</div>
													</div>
													
												</div>
											))}
											</div>
										</div>
										</>
									)	}
										
									</div>	
									
								</div>
								
							</div>
						</div>
						
						<div id="about-me" className={`tab-pane fade ${ activeToggle === "aboutUser" ? "active show" : ""}`}>
							{/* <div className="profile-about-me">
					
								<div className="pt-4 border-bottom-1 pb-3">
									<h4 className="text-primary">About Me</h4>
									<p className="mb-2">
									{admin && admin.user ? admin.about_me : ' Click on edit button to add'}
									</p>
							
								</div>
							</div> */}
						
							<div className="profile-lang ">
								<h4 className="text-primary mb-2">Position</h4>
								<div className="text-muted pe-3 f-s-16">
								{admin && admin.user ? admin.position : ' Click on edit button to add'}
								</div>
							
							</div>
							<div className="profile-lang">
								<h4 className="text-primary mb-2">Department</h4>
								<div className="text-muted pe-3 f-s-16">
								{admin && admin.user ? admin.department : ' Click on edit button to add'}
								</div>
							
							</div>
							<div className="profile-personal-info">
								<h4 className="text-primary mb-4">
									Personal Information
								</h4>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500"> User Name<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.user.username : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">First Name<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.user.first_name : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Middle Name<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.middle_name : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Last Name<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.user.last_name : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Gender<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.gender : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Email<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.user.email : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Phone Number<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.phone_number : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Phone Number 2<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.phone_number2 : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Address<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.address : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Town<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.town : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">City<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.city : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Region<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.Region : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Country<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.country : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Date Registered<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.user.date_joined : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Last Login<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>{admin && admin.user ? admin.user.last_login : ' Click on edit button to add'}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">User Status<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										{/* <span>{owner && owner.user ? owner.user.is_active : ' Click on edit button to add'}</span> */}
										<span 
										style={{
											backgroundColor: admin && admin.user && admin.user.is_active ? 'green' : 'orange',
											color: 'white', padding: '5px', borderRadius: '5px'
										}}
									>
										{admin && admin.user && admin.user.is_active ? 'Active' : 'Suspended'}
									</span>


									</div>
								</div>
								<h3>Profile Image</h3>
								<div className="form-group">
								<img src={formData.admin_image} alt="Existing" style={{ width: '200px', height: '200px' }} />
												
								</div>
							</div>
						</div>

						<div id="profile-settings" className={`tab-pane fade ${ activeToggle === "setting" ? "active show" : ""}`}>
							<div className="pt-3">
								<div className="settings-form">
									<h4 className="text-primary">Edit User Details</h4>
									<form onSubmit={handleSubmit}>
										<div className="row">
											<div className="form-group mb-3 col-md-6">
												<label className="form-label" >User Name * username can not be change*</label>
												<input type="text"  value={formData.user.username} placeholder="Username" onChange={handleChange} className="form-control" disabled/>
											</div>
											<div className="form-group mb-3 col-md-6">
												<label className="form-label" >Email</label>
												<input type="email" name="user.email" value={formData.user.email} placeholder="Email" onChange={handleChange} className="form-control"/>
											</div>
										
										</div>
										<div className="row">
											<div className="form-group mb-3 col-md-6">
												<label className="form-label" >First Name</label>
												<input type="text" name="user.first_name" value={formData.user.first_name} placeholder="First Name" onChange={handleChange} className="form-control"/>
											</div>
											<div className="form-group mb-3 col-md-6">
												<label className="form-label">Middle Name</label>
												<input type="text" name="middle_name" value={formData.middle_name} placeholder="Middel Name" onChange={handleChange} className="form-control"/>
											</div>
										</div>
										<div className="row">
											<div className="form-group mb-3 col-md-6">
												<label className="form-label" >Last Name</label>
												<input type="text" name="user.last_name" value={formData.user.last_name} placeholder="Last Name" onChange={handleChange} className="form-control"/>
											</div>
											<div className="form-group mb-3 col-md-6">
												<label className="form-label" >Gender</label>
												<select id="gender" name="gender" value={formData.gender} onChange={handleChange} className="form-select mb-3">
													<option disabled>{formData.gender}</option>
													<option value="Female">Female</option>
													<option value="Male">Male</option>
												</select>
											</div>
										</div>
										<div className="row">
											<h5 className="primary">Address</h5>
											<div className="form-group mb-3 col-md-6">
												<label className="form-label">Address</label>
												<input type="text" name="address" value={formData.address} placeholder="address" onChange={handleChange} className="form-control"/>
											</div>
											<div className="form-group mb-3 col-md-6">
												<label className="form-label">GPS Address</label>
												<input type="text" name="gps_address" value={formData.gps_address} placeholder="address" onChange={handleChange} className="form-control"/>
											</div>
										</div>
										<div className="row">
											<div className="form-group mb-3 col-md-6">
												<label className="form-label" >Town </label>
												<input type="text" name="town" value={formData.town} placeholder="Town" onChange={handleChange} className="form-control" />
											</div>
											<div className="form-group mb-3 col-md-6">
												<label className="form-label" >City</label>
												<input type="text" name="city" value={formData.city} placeholder="City" onChange={handleChange} className="form-control" />
											</div>
										</div>
										<div className="row">
										
											<div className="form-group mb-3 col-md-6">
												<label className="form-label">Region</label>
												<select
												  className="form-control"
												  id="inputState"
												  name="Region"
												  value={formData.Region}
												  onChange={handleChange}
												>
												  <option disabled value="">{formData.Region}</option>
												  <option value="Greater Accra">Greater Accra</option>
												  <option value="Ashanti Region">Ashanti Region</option>
												  <option value="Central Region">Central Region</option>
												  <option value="Volta Region">Volta Region</option>
												  <option value="Oti Region">Oti Region</option>
												  <option value="Bono Region">Bono Region</option>
												  <option value="Bono East Region">Bono East Region</option>
												  <option value="Ahafo Region">Ahafo Region</option>
												  <option value="Western Region">Western Region</option>
												  <option value="Western North Region">Western North Region</option>
												  <option value="Eastern Region">Eastern Region</option>
												  <option value="Savanah Region">Savanah Region</option>
												  <option value="Upper East Region">Upper East Region</option>
												  <option value="Upper West Region">Upper West Region</option>
												  <option value="North East Region">North East Region</option>
												  <option value="Northern Region">Northern Region</option>
												</select>
											</div>
											<div className="form-group mb-3 col-md-6">
												<label className="form-label">Country</label>
												<input type="text" name="country" value={formData.country} placeholder="Country" onChange={handleChange} className="form-control" />
												
											</div>
										</div>
								
										<div className="row">
											<div className="form-group mb-3 col-md-6">
												<label className="form-label" >Phone Number </label>
												<input type="number" name="phone_number" value={formData.phone_number} placeholder="Town" onChange={handleChange} className="form-control" />
											</div>
											<div className="form-group mb-3 col-md-6">
												<label className="form-label" >Phone Number 2</label>
												<input type="number" name="phone_number2" value={formData.phone_number2} placeholder="Second Phone Number" onChange={handleChange} className="form-control" />
											</div>
										</div>
										<div className="row">
										
											<div className="form-group mb-3 col-md-6">
												<label className="form-label">Position</label>
											<input type="text" name="position" value={formData.position} placeholder="Position" onChange={handleChange} className="form-control" />
											</div>
											<div className="form-group mb-3 col-md-6">
												<label className="form-label">Department</label>
											<input type="text" name="department" value={formData.department} placeholder="Department" onChange={handleChange} className="form-control" />
											</div>
										<label className="btn btn-success w-100" >
												{formData?.admin_image?.name ? formData.admin_image.name  : "Upload Other Profile Image"}
												<input type="file" id="admin_image" name="admin_image" 	onChange={handleChange} className="d-none" />
											</label>
											<div className="col-lg-6 mb-2">
											<div className="form-group">
											{formData.admin_image  && (
												typeof formData.admin_image  === 'string'
													? <img src={formData.admin_image } alt="Existing" style={{ width: '200px', height: '200px' }} />
													: <img src={URL.createObjectURL(formData.admin_image )} alt="Uploaded" style={{ width: '200px', height: '200px' }} />
												)}
											</div>
										</div>
										</div>
									
										<button className="btn btn-primary me-5" type="submit">Update User</button>
									</form>
								</div>
							</div>
						</div>
						</div>
					</div>
                </div>
              </div>
            </div>
          </div>
        </div>
    

    </Fragment>
  );
};

export default AdminDetails;
