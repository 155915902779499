import React, {useState, useEffect, useContext, useCallback} from 'react'
import MainNav from './layout/MainNav'
import Assets1Loader from '../AssetsLoader'
import { Link } from 'react-router-dom'
import MainFooter from './layout/MainFooter'
import axios from 'axios'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import useDebounce from './searchhooks/useDebounce';
import './layout/search.css';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';


const baseUrl="https://myghschool.myghschools.com/school"

const AllPrivateNursing = () => {
    
  const [schools, setSchools] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const debouncedSearchQuery = useDebounce(searchQuery, 1000);

  const { setSelectedSchool } = useContext(SchoolContext);
  
	useEffect(() =>{
        fetchSchoolData();
    },[]);


    const ClearInput = () => {
        setSearchQuery("");
        setSearchResults([]);
        };
    

    const handleSchoolSelect = (schoolId) => {
      setSelectedSchool(schoolId);
     };

     const fetchSchoolData = async () => {
        try {
        const response = await axios.get(`${baseUrl}/search/privatenursing/`);
        setSchools(response.data);
        } catch (error) {
        }
      };

      
      const handleSearch = useCallback(async () => {
        try {
          const response = await axios.get(`${baseUrl}/search/privatenursing/?search=${searchQuery}`);
          setSearchResults(response.data);
        } catch (error) {
        }
      },[searchQuery]);

      
    useEffect(() => {
        if (debouncedSearchQuery.length >= 3) {
            handleSearch();
        } else {
            setSearchResults([]);
        }
    }, [debouncedSearchQuery, handleSearch]);


  return (
    <React.Fragment>

<Assets1Loader />

<MainNav/>

    {/* <!-- banner-area --> */}
    <section className="tgbanner__area">
        <div className="container">
            <div className="tgbanner__grid">

              {/* main page banner */}
            <section className="featured-post-area section__hover-line ">
                        <div className="container">
                            <div className="section__title-wrap mb-40">
                                <div className="row align-items-end">
                                    <div className="col-sm-6">
                                        <div className="section__title">
                                            {/* <span className="section__sub-title">Featured</span> */}
                                            <h3 className="section__main-title">All Private Nursing Schools in Ghana</h3>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                            <div className="row">

                            <div className="col-sm-12 search-bar-container mb-40"> 
                                         <div className="searchInputs">
                                            <input type="text"  value={searchQuery}  onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search Private Universities in Ghana..." />
                                            <div className="searchIcon">
                                               {searchResults.length === 0 ? (
                                               <SearchIcon /> 
                                               ):( 
                                               <CloseIcon id="clearBtn" onClick={ClearInput}/>
                                               )}
                                               </div>
                                        </div>
                                        {searchResults.length > 0 && (
                                            <div className="search-results-container" style={{width:"485px"}}>
                                        {searchResults.map((school) => (
                                            <div key={school.id} className="search-result-item">
                                              <Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}> {school.school_name} </Link> 
                                                {school.school_alias} <br/>
                                            </div>
                                            ))}
                                             </div> 
                                             )}
                                    </div>
                              {schools && schools.map((school, index) => (
                                    <div className="col-lg-6 col-sm-6" key={school.id}>
                                    <div className="featured__post"> 
                                        <Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}>  <img src={school && school.school_logo} width={70} height={70} alt='school_logo'  className="rounded-circle"/></Link>
                                        <div className="featured__content">
       
                                            <ul className="tgbanner__content-meta list-wrap">
                                              
                                                <li><span className="by">Alias: </span> <Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}>{school && school.school_alias}</Link></li>
                                            </ul>
                                            <h4 className="title tgcommon__hover"><Link to={`/schoolhome/${school.id}`} onClick={() => handleSchoolSelect(school.id)}>{school && school.school_name}</Link></h4>
                                        </div>
                                    </div>
                                </div> 
                              
                              ))}
                            
                            </div>
                        </div>
                    </section>

                <div className="tgbanner__side-post">
                    <div className="tgbanner__post small-post">
                        <div className="tgbanner__thumb tgImage__hover">
                            <Link to="/publicnursing" ><img src="assets1/img/images/allpublicnursing.jpg" alt="img"/></Link>
                        </div>
                        <div className="tgbanner__content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><Link to="/publicnursing">All Public Nursing School</Link></li>
                            </ul>
                            <Link to="/publicnursing" > <h2 className="title tgcommon__hover">All Public Nursing School in Ghana</h2></Link>
                        </div>
                    </div>
                    <div className="tgbanner__post small-post">
                        <div className="tgbanner__thumb tgImage__hover">
                        <Link to="/publicuniversities" ><img src="assets1/img/images/publicuni.jpg" alt="img"/></Link>
                        </div>
                        <div className="tgbanner__content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><Link to="/publicuniversities" >All Public Universities </Link></li>
                            </ul>
                            <h2 className="title tgcommon__hover"><Link to="/publicuniversities" >All Public Universities in Ghana </Link></h2>
                        </div>
                    </div>
                    <div className="tgbanner__post small-post">
                        <div className="tgbanner__thumb tgImage__hover">
                        <Link to="/allteachertraining" ><img src="assets1/img/images/teachertraining.jpeg" alt="img"/></Link>
                        </div>
                        <div className="tgbanner__content">
                            <ul className="tgbanner__content-meta list-wrap">
                                <li className="category"><Link to="/allteachertraining" >All Teacher Training schools</Link></li>
                            </ul>
                            <h2 className="title tgcommon__hover"><Link to="/allteachertraining" >All Teacher Training schools in Ghana </Link></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

<MainFooter />


    </React.Fragment>
  )
}

export default AllPrivateNursing