import React from 'react'
import { useState, useContext, useEffect } from 'react'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import axios from 'axios';
import {Link, useParams} from 'react-router-dom';
// import './schoolheader.css'

const baseUrl="https://myghschool.myghschools.com/school"
const SchoolMenuBar = () => {
    const { selectedSchool} = useContext(SchoolContext);
	const [contact, setContact] = useState(null);	
    const [schools, setSchools] = useState(null);
    const {id} = useParams();


	const [isOpen, setIsOpen] = useState(false);
    const [submenuOpen, setSubmenuOpen] = useState(false);
      
    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setSubmenuOpen(false);
    };

    const toggleSubmenu = (index) => {
      setSubmenuOpen(submenuOpen === index ? null : index);
    };

    

    useEffect(() => {
        const fetchContactData = async () => {
            try {
              const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
              setContact(response.data);
            } catch (error) {
            }
          };

          const fetchSchoolData = async () => {
            try {
              const response = await axios.get(`${baseUrl}/schools/${id}`);
              setSchools(response.data);
            } catch (error) {
            }
          };
            fetchSchoolData();
            fetchContactData();
            }, [selectedSchool, id]);

  return (
    <React.Fragment>
        	<header id="rs-header" className="rs-header">
				
				{/* <!-- Header Top Start --> */}
				<div className="rs-header-top">
					<div className="container">
						<div className="row col-12">
							<div className="col-md-4 col-sm-12">
						        <div className="header-contact">
						            <div id="info-details" className="widget-text">
                                        <i className="glyph-icon flaticon-email"></i>
						                <div className="info-text">
						                   <Link to="/">
						                    	<span>My Gh School</span>
												<h4>Every School is represented</h4>
											</Link>
						                </div>
						            </div>
						        </div>
							</div>
							<div className="col-md-4 col-sm-12">
								<div className="logo-area text-center">
									<span style={{fontSize:'1.2em', color:'red'}}>Welcome to </span>
									<h3>{schools && schools?.school_name}</h3>
									<div className='row'>
										<div className="col-6">
									{schools && schools?.school_category === "" ?
									<span></span> : <h4>Category <span style={{color:'red'}}>{schools && schools?.school_category}</span> school</h4> 	}
									</div>
									<div className="col-6">
									{schools && schools?.status === "" ?
									<span></span> : <h4> <span style={{color:'red'}}>{schools && schools?.status}</span> school</h4> 	}
										</div>
									</div>		
									
								</div>
							</div>
							<div className="col-md-4 col-sm-12">
						        <div className="header-contact pull-right">
						            <div id="phone-details" className="widget-text">
						                <i className="glyph-icon flaticon-phone-call"></i>
						                <div className="info-text">
						                    <div>
						                    	<span>Call Us</span>
												<h4>{contact && contact.length > 0 && contact[0].phone_number}</h4>
											</div>
						                </div>
						            </div>
						        </div>
							</div>
						</div>				
					</div>
				</div>
			<div className="menu-area menu-sticky" style={{backgroundColor:'black'}} >
				<div className="container">
					<div className="main-menu">
						<div className="row relative">
							<Link to="#" className="rs-menu-toggle menu-1"  onClick={toggleMenu}>{isOpen ? <i className="fas fa-times"></i> : <i 	className="fas fa-bars"></i>} Menu </Link>
								{isOpen && <div className="overlay" onClick={toggleMenu}></div>}
									<nav className={`rs-menu  ${isOpen ? 'open' : ''}`} style={{backgroundColor:'black', padding:'5px'}}>
										<ul className="nav-menu" style={{backgroundColor:'black', padding:'5px'}}>
												<li className="menu-item-has-children"> <Link to={`/schoolhome/${selectedSchool}`}>Home</Link></li>
												<li className={`menu-item-has-children  ${submenuOpen ===1 ? 'menu-open' : '' }`}>
                                                    <Link to="#"  onClick={() => toggleSubmenu(1)} >Management{submenuOpen === 1 ? '-' : '+'}</Link>
													<ul className={`submenu ${submenuOpen ? 'open' : ''}`}>
                                                    	<li className="menu-item-has-children dropdown-item"> <Link to={`/boardofdirectors/${selectedSchool}`}>Board of Directors</Link>
		                                                </li>
                                                        <li className="menu-item-has-children dropdown-item"> <Link to={`/headofschool/${selectedSchool}`}>Head of School</Link> </li>
                                                        <li className="menu-item-has-children dropdown-item"> <Link to={`/departments/${selectedSchool}`}>Departments</Link> </li>
                                                        <li className="menu-item-has-children dropdown-item"> <Link to={`/ptamember/${selectedSchool}`}>P.T.A</Link> </li>
                                                        <li className="menu-item-has-children dropdown-item"> <Link to={`/alumni/${selectedSchool}`}>Alumni</Link> </li>
                                                        <li className="menu-item-has-children dropdown-item"> <Link to={`/healthfacility/${selectedSchool}`}>Health Facility</Link> </li>
													</ul>
												</li>
											<li className={`menu-item-has-children  ${submenuOpen ===2 ? 'menu-open' : '' }`}>
                                                    <Link to="#"  onClick={() => toggleSubmenu(2)} >Academic  {submenuOpen === 2 ? '-' : '+'}</Link>
													<ul className={`submenu ${submenuOpen ? 'open' : ''}`}>
                                                    	<li className="menu-item-has-children dropdown-item"> <Link to={`/courses/${selectedSchool}`}>Courses</Link>
		                                                </li>
                                                        <li className="menu-item-has-children dropdown-item"> <Link to={`/subject/${selectedSchool}`}>Subjects</Link> </li>
													</ul>
												</li>
                                           
										
											<li className="menu-item-has-children"> <Link to={`/event/${selectedSchool}`}>Events</Link></li>
											
											<li className="menu-item-has-children"> <Link to={`/blog/${selectedSchool}`}>News</Link>
											
											</li>
											<li className="menu-item-has-children"> <Link to={`/gallery/${selectedSchool}`}>Gallery</Link>
											
											</li>
											<li className="menu-item-has-children"> <Link to={`/achievement/${selectedSchool}`}>Achievement</Link>
											</li>
											<li className={`menu-item-has-children  ${submenuOpen ===3 ? 'menu-open' : '' }`}>
                                                    <Link to="#"  onClick={() => toggleSubmenu(3)}  >Contact {submenuOpen === 3 ? '-' : '+'}</Link>
													<ul className={`submenu ${submenuOpen ? 'open' : ''}`}>
														<li className="menu-item-has-children dropdown-item"> <Link to={`/contact/${selectedSchool}`}>Contact</Link>
														</li>
														 {/* {schools && schools.verified_school === true ? 
															<li className="menu-item-has-children dropdown-item"><Link to={`/donation/${selectedSchool}`}>Donate</Link> </li>: <div className="col-lg-2">
															</div>} */}
														

													</ul>
											</li>
											{schools && schools.verified_school === true ? 
												<li className="menu-item-has-children card p-3" style={{color:'green'}}>Varified School </li>: <div className="col-lg-2">
												</div>}
										</ul>
									</nav>                            
							</div>
						</div>
					</div>
				</div>
			</header>
    </React.Fragment>
  )
}

export default SchoolMenuBar