import React,{useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { SchoolContext } from "../../../AuthContext/SchoolProvider"; 
import AuthContext from "../../../AuthContext/AuthProvider";

//Components
import { ThemeContext } from "../../../context/ThemeContext";
import CalendarBlog from './Dashboard/CalendarBlog';

//images
import Calpng from './../../../images/vector/calpng.png';
import Book from './../../../images/vector/book.png';
import parse from 'html-react-parser';


const baseUrl="https://myghschool.myghschools.com/school"

const Home = () => {

	const { userId } = useContext(AuthContext);
	const {accessToken} = useContext(AuthContext);

	const { setSelectedSchool } = useContext(SchoolContext);
	const [schools, setSchools] = useState(null);
	const { changeBackground } = useContext(ThemeContext);

		const truncateDescription = (vision) => {
			if (vision.length > 50) {
			  return vision.substring(0, 50) + "...";
			} else {
			  return vision;
			}
		};
			  

	useEffect(()=>{
		document.title = "MGS | Dashboard";
	});


	useEffect(() => {
		const token = accessToken;
		try {	
		const fetchSchoolsbyId = async () => {
			const result = await axios.get(`${baseUrl}/schools?user_id=${userId}`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			setSchools(result.data);
		}
		fetchSchoolsbyId();
	} catch (error) {
	}	
	}, [userId, accessToken]);



	const handleSchoolSelect = (schoolId) => {
		setSelectedSchool(schoolId);
	 };


	useEffect(() => {
		changeBackground({ value: "dark", label: "Dark" });
	}, []);


	return(
		<>

		<div className="modal-header"> <Link to="/admin/addschool"><h4 className="btn btn-primary btn-sm">Add School</h4></Link> </div>
		
			<div className="row">
			{schools && schools.length === 0 ? (		
				<h4> You have not created any school</h4>
			 ):(
			<>
			<h4>Number of Schools:  {schools && schools.length}</h4>
				<div className="col-xl-6 col-xxl-12">
					<div className="row">
					{schools && schools.map((school, index) => (
						<div className="col-xl-12 col-xxl-6" key={index}>
							<div className="card dlab-join-card h-auto">
								<div className="card-body">
									<div className="dlab-media d-flex justify-content-between">
									
										<div className="dlab-content">
										<Link to={`/admin/frontend/${school.id}`} onClick={() => handleSchoolSelect(school.id)}><h4>{school.school_name}</h4></Link>
										<p><strong>Our Vision: </strong>{' '}{ parse(truncateDescription(school.our_vision || 'N/A'))}</p>
										</div>	
										<div className="dlab-img">
										<Link to={`/admin/frontend/${school.id}`} onClick={() => handleSchoolSelect(school.id)}><img src={school.school_logo} alt="school logo"  /></Link>
										</div>	
										
										<div className="dlab-icon">
											<img src={Calpng} alt="" className="cal-img" />
											<img src={Book} alt="" className="book-img" />
										</div>
									
									</div>
								</div>
							</div>
							
						</div>
					))}
					</div>
				</div>
				</>
			 )	}	
				<div className="col-xl-6 col-xxl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card score-active">
								<div className="card-header border-0 pb-2 flex-wrap">
									
								</div>
								
							</div>
						</div>
						
						<div className="col-xl-8 col-lg-6">
							<div className="card">
								<div className="card-body card-calendar home-calendar">								
									<CalendarBlog />
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>	
		</>
	)
}
export default Home;

