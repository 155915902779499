import React from 'react'
import { useState, useContext, useEffect } from 'react'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import axios from 'axios';
import {Link, useParams} from 'react-router-dom';
import SchoolMenuBar from './SchoolMenuBar';
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// import 

const baseUrl="https://myghschool.myghschools.com/school"
const SchoolHome = () => {
	
	useEffect(()=>{
		document.title = "MGS | School";
	});
	
    const { selectedSchool} = useContext(SchoolContext);
    const [schools, setSchools] = useState(null);
	const {id} = useParams();
	const [contact, setContact] = useState(null);	
	const [courses, setCourse] = useState(null);
	const [achievements, setAchievement] = useState(null);
	const [events, setEvent] = useState(null);
	const [blogs, setBlog] = useState(null);
	const [frontimage, setFrontImage] = useState(null);


		const truncateDescription = (vision) => {
			if (vision.length > 50) {
			  return vision.substring(0, 50) + "...";
			} else {
			  return vision;
			}
		};

		//carousel for front image
		const responsive = {
			superLargeDesktop: {
			  // the naming can be any, depends on you.
			  breakpoint: { max: 4000, min: 1025 },
			  items: 2
			},
			desktop: {
			  breakpoint: { max: 1024, min: 850 },
			  items: 2
			},
			tablet: {
			  breakpoint: { max: 850, min: 464 },
			  items: 1
			},
			mobile: {
			  breakpoint: { max: 464, min: 0 },
			  items: 1
			}
		  };


		useEffect(() => {
			const fetchSchoolData = async () => {
			  try {
				const response = await axios.get(`${baseUrl}/schools/${selectedSchool}`);
				setSchools(response.data);
			  } catch (error) {
			  }
			};
		
			const fetchContactData = async () => {
			  try {
				const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
				setContact(response.data);
			  } catch (error) {
			  }
			};

			const fetchAchievementData = async () => {
			  try {
				const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/achievements`);
				setAchievement(response.data);
			  } catch (error) {
			  }
			};

			const fetchCourseData = async () => {
			  try {
				const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/courses`);
				setCourse(response.data);
			  } catch (error) {
			  }
			};
		
			const fetchEventData = async () => {
			  try {
				const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/events`);
				setEvent(response.data);
			  } catch (error) {
			  }
			};

			const fetchBlogData = async () => {
			  try {
				const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/blogs`);
				setBlog(response.data);
			  } catch (error) {
			  }
			};
			const fetchFrontImageData = async () => {
			  try {
				const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/frontimage`);
				setFrontImage(response.data);
			  } catch (error) {
			  }
			};
		
			fetchSchoolData();
			fetchContactData();
			fetchAchievementData();
			fetchCourseData();
			fetchEventData();
			fetchBlogData();
			fetchFrontImageData();
		  }, [selectedSchool]);


		  const formatDate = (dateStr) => {
			let date = new Date(dateStr);
			let year = date.getFullYear();
			let month = ("0" + (date.getMonth() + 1)).slice(-2);
			let day = ("0" + date.getDate()).slice(-2);
			return `${day}-${month}-${year}`;
		  }
		  

  return (
	<React.Fragment>

 <div className="home1">
 {/* <div className="inner-page"> */}
		<div className="full-width-header">
			<SchoolMenuBar/>
		
		</div>
        <div id="rs-slider" className="slider-overlay-2">
				<div className="item active">
					<img src={schools && schools?.banner} alt="Slide1" style={{width:'100%', height:'80vh'}}/>
					<div className="slide-content">
						<div className="display-table">
							<div className="display-table-cell">
								<div className="container text-center">
									<h1 className="slider-title" data-animation-in="fadeInLeft" data-animation-out="animate-out"> 
									<span style={{fontSize:'0.5em'}}> WELCOME TO </span><br/>   {schools && schools?.school_name}</h1>

									<p data-animation-in="fadeInUp" data-animation-out="animate-out" className="slider-desc"> <span style={{color:'red'}}>Our Motto:</span> {schools && schools?.school_motto} </p>
									
									<Link to={`/courses/${selectedSchool}`} className="sl-readmore-btn mr-30" data-animation-in="lightSpeedIn" data-animation-out="animate-out">Our Courses</Link>

									<Link to={`/gallery/${selectedSchool}`} className="sl-get-started-btn md-6" data-animation-in="lightSpeedIn" data-animation-out="animate-out">Our School In Pictures</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br/>
				<br/>
        	</div>         
        {/* </div> */}
        {/* <!-- Slider Area End -->
		
		<!-- Services Start --> */}
        <div className="rs-services rs-services-style1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                    	<div className="services-item rs-animation-hover">
                    	    <div className="services-icon">
							<i className="fa-regular fa-newspaper rs-animation-scale-up"></i>              	
                    	    </div>
						<Link to={`/courses/${selectedSchool}`}>
                    	    <div className="services-desc">
                    	        <h4 className="services-title">Courses</h4>
                    	        <p>Courses We Offer On Campus</p>
                    	    </div>
							</Link>
                    	</div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                    	<div className="services-item rs-animation-hover">
                    	    <div className="services-icon">                    	        
                    	        <i className="fa-solid fa-images rs-animation-scale-up"></i>
                    	    </div>
							<Link to={`/gallery/${selectedSchool}`}>
                    	    <div className="services-desc">
                    	        <h4 className="services-title">Gallery</h4>
                    	        <p>Our Activities in Pictures</p>
                    	    </div>
							</Link>
                    	</div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                    	<div className="services-item rs-animation-hover">
                    	    <div className="services-icon">
                    	        <i className="fa-regular fa-newspaper rs-animation-scale-up"></i>
                    	    </div>
							<Link to={`/blog/${selectedSchool}`}>
                    	    <div className="services-desc">
                    	        <h4 className="services-title">News</h4>
                    	        <p>Read News About Our School</p>
                    	    </div>
							</Link>
                    	</div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                    	<div className="services-item rs-animation-hover">
                    	    <div className="services-icon">
                    	        <i className="fa-regular fa-calendar rs-animation-scale-up"></i>
                    	    </div>
							<Link to={`/event/${selectedSchool}`}>
                    	    <div className="services-desc">
                    	        <h4 className="services-title">Events</h4>
                    	        <p>Upcoming and Past Events</p>
                    	    </div>
							</Link>
                    	</div>
                    </div>
                </div>
            </div>
        </div>
		{/* <!-- Services End -->

		<!-- About Us Start --> */}
        <div id="rs-about" className="rs-about sec-spacer">
            <div className="card container pt-5 pb-5">
                <div className="sec-title mb-50 text-center">
                    <h2>ABOUT US</h2>  
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12">
					<div className="text-center text-black" style={{fontSize:'1.5em', fontWeight:'bold'}}>School Logo</div>

                        <div className="about-img rs-animation-hover">
							<img src={schools && schools?.school_logo} alt="school logo"/>
						</div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                    	<div className="about-desc">
                		    <h3>WELCOME TO {schools && schools?.school_alias}</h3>      
                    	</div>
						<div id="accordion" className="rs-accordion-style1">
						    <div className="card">
						        <div className="card-header" id="headingOne">
						            <h3 className="acdn-title" data-bs-toggle="collapse" data-bs-target="#collapseOne">
						          		Our History &nbsp;&nbsp;
						            </h3>
						        </div>
						        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
						            <div className="card-body">
						             {schools && parse(schools?.our_history)}
						            </div>
						        </div>
						    </div>
						    <div className="card">
						        <div className="card-header" id="headingTwo">
						            <h3 className="acdn-title collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" >
						          		Our Mission  &nbsp;&nbsp;
						            </h3>
						        </div>
						        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
						            <div className="card-body">
						             {schools && parse(schools?.our_mission)}
						            </div>
						        </div>
						    </div>
						    <div className="card">
						        <div className="card-header mb-0" id="headingThree">
						            <h3 className="acdn-title collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
						          		Our Vision  &nbsp;&nbsp;
						            </h3>
						        </div>
						        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
						            <div className="card-body">
						               {schools && parse(schools?.our_vision)}
						            </div>
						        </div>
						    </div>
						</div>
                    </div>
                </div>
            </div>
        </div>
         {/* <!-- About Us End --> */}
		 <div id="rs-events" >
			<div className="card container pt-5 pb-5">
				<div className="sec-title mb-50 text-center">
                    <h2>Join Our Community</h2>      
                	<p>Contact Our school to join us now</p>
                </div>
				<div className="row">
			        <div className="col-md-12">
			                <div className="event-item">
			                    <div className="event-img">
			                        <img src="#" alt="" />
                              
			                    </div>
								<div className="row">
								
		                    	{frontimage && frontimage?.map((frontimage, index) => (
									
									<div  key={index}>
										<Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={5000} keyBoardControl={true} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]} >
							
											{frontimage?.images.map((image, subIndex) => (
											<img key={subIndex} src={image?.image} width={800} height={900} alt="front page images" />
										
											))}
											</Carousel>
									
									</div>
									
								))}
								
								
								</div>
			                </div>
			          
			            {/* </div> */}
			        </div>
			    </div>
			</div>
        </div>
		{/* <!-- Courses Start --> */}
        <div id="rs-courses" className="rs-courses sec-color ">
			<div className="card container pt-5">
				<div className="sec-title mb-50 text-center">

                    <h2>OUR COURSES</h2>   
					<div className="margin-bottom-10" style={{color:'red', fontSize:'1.3em'}}>   
                			Some of our courses 
					</div> <br/>
				</div>
				<div>
				<div className="row">
			
					{courses && courses.slice(0.6)?.map((course, index) => (
			        <div className="card col-md-4" key={index}>
						<div className="rs-carousel " data-loop="true" data-items="3" data-margin="30" data-autoplay="true" data-autoplay-timeout="5000" data-smart-speed="1200" data-dots="true" data-nav="true" data-nav-speed="false" data-mobile-device="1" data-mobile-device-nav="true" data-mobile-device-dots="true" data-ipad-device="2" data-ipad-device-nav="true" data-ipad-device-dots="true" data-md-device="3" data-md-device-nav="true" data-md-device-dots="true" >
			                <div >
			                    <div className="cource-img">
			                     <Link to={`/courses/${course.id}`} >  <img src={course?.image} alt="course" /> </Link>
			                    </div>
			                    <div className="course-body">
			                    	
			                      <Link to={`/courses/${course.id}`}><h4 className="course-title">{course?.name}</h4> </Link>
								  <span className="course-category" style={{color:'red'}}>Course Title</span>
			                    	
			                    	<div className="course-desc">
			                    		<div>
			                    			{parse(truncateDescription(course?.description))}
			                    		</div>
			                    	</div>
			                    </div>
			                </div>
						</div>
			    	 </div>
					))}
				</div>
			</div>
		</div>
	   </div>	

        {/* <!-- Courses End --> */}
		
		{/* <!-- Counter Up Section Start-->  */}
        <div className="rs-counter pt-5 bg3">
            <div className="card container pt-50 pb-50">
                <div className="row">
                	<div className="col-lg-6 col-12">
                		<div className=" counter-content">
							<div className="sec-title">
                			<h2 className="counter-title" style={{color:'black'}}>ACHEIVEMENTS</h2>
                			<div className="counter-text">
                				<p style={{fontSize:'1.5em', color:'red'}}>Our Success stories over the years.</p>
                			</div>
							</div>
                			<div className="counter-img rs-image-effect-shine">
                				<img src={schools && schools?.our_history_image} alt="Counter Discussion" />
                			</div>
                		</div>
                	</div>
                	<div className=" col-lg-6 col-md-2 mt-80">
                		<div className="row">
						{achievements && achievements.slice(0, 4).map((achievement, index) => (
							<div className="col-md-6" key={index}>
							<div className="rs-counter-list">
								<Link to={`/achievementsingle/${achievement.id}`} > 
								<img src={achievement?.mainImage} width={200} height={200} alt="Counter Icon" />
								<h4 className="counter-desc  text-center" style={{color:'black'}}>{achievement?.title}</h4>
								</Link>
							</div>
							</div>
							
						))}
						</div>
                	</div>
                </div>
            </div>
        </div>
        <div id="rs-events" className="rs-events sec-spacer">
			<div className="card container pt-5 pb-5">
				<div className="sec-title mb-50 text-center">
                    <h2>OUR UPCOMING AND PREVIOUS EVENTS</h2>      
                	<p>Click to view all our upcoming and previous events</p>
                </div>
				<div className="row">
			        <div className="col-md-12">
						<div className="rs-carousel " data-loop="true" data-items="3" data-margin="30" data-autoplay="false" data-autoplay-timeout="5000" data-smart-speed="1200" data-dots="true" data-nav="true" data-nav-speed="false" data-mobile-device="1" data-mobile-device-nav="true" data-mobile-device-dots="true" data-ipad-device="2" data-ipad-device-nav="true" data-ipad-device-dots="true" data-md-device="3" data-md-device-nav="true" data-md-device-dots="true">
			                <div className="event-item">
			                    <div className="event-img">
			                        <img src="#" alt="" />
                              
			                    </div>
								<div className="row">
		                    	{events && events.slice(0, 3).map((event, index) => (
									
									<div className=" card col-xl-4 col-md-6" key={index}>
										<div className="events-details sec-color">
										<div className="rs-counter-list">
											<Link to={`/eventsingle/${event.id}`} >
											<img src={event?.mainImage} width={200} height={200} alt="Counter Icon" />
											<br/><br/>
											</Link>
										</div>
										
										<div className="event-date">
											<i className="fa fa-calendar"></i>
											<span>{formatDate(event?.startDate)}</span>
										</div>
									<Link to={`/eventsingle/${event.id}`}><h4 className="event-title">{event?.title}</h4></Link>
										<div className="event-meta">
											<div className="event-time">
												<i className="fa fa-clock-o"></i>
												<span>{event?.openingtime} - {event?.closingtime}</span>
											</div>
											<div className="event-location">
												<i className="fa fa-map-marker"></i>
												<span>{event?.venue}</span>
											</div>
										</div>
                                		</div>
									</div>
									
								))}
								</div>
			                </div>
			          
			            </div>
			        </div>
			    </div>
			</div>
        </div>
        <div id="rs-team" className="rs-team sec-color sec-spacer">
            <div className="container">
                <div className="mb-50 text-center">
					<div className="sec-title text-center">
                    <h2>WELCOME MESSAGE </h2>
					<h3>by our Head Master</h3>
					</div>
					<div className="single-blog-details card p-1">
					<blockquote>
                        <i className="fa fa-quote-right" aria-hidden="true"></i>
						<p>{schools && parse(schools?.welcome_message)}</p>
	                    </blockquote>
                	</div>
                </div>
			</div>
		</div>
        {/* <!-- Team End -->


        <!-- Latest News Start --> */}
        <div id="rs-latest-news" className="rs-latest-news sec-spacer">
			<div className="card container pt-5">
				<div className="sec-title mb-50 text-center">
                    <h2>OUR LASTEST NEWS</h2>      
                	<p>News about our great school</p>
                </div>
				<div className="row">
					<div className="col-md-6" >
					{blogs && blogs?.slice(0, 1).map((blog, index) => (
						
						<div className="card news-normal-block" key={index}>
		                    <div className="news-img" style={{width:'400px'}}>
							<Link to={`/blog/${id}`}>
		                        	<img src={blog?.mainImage} width={100} height={100} alt="mainImage" />
		                    	</Link>
		                    </div>
	                    	<div className="news-date">
	                    		<i className="fa fa-calendar-check-o"></i>
	                    		<span>{formatDate(blog?.created_at)}</span>
	                    	</div>
	                    	<Link to={`/blog/${id}`}><h4 className="news-title">{blog?.title}</h4></Link>
	                    	<div className="news-desc">
	                    		<div>
	                    		{parse(blog?.description)}
	                    		</div>
	                    	</div>
	                    	<div className="news-btn">
							<Link to={`/blog/${id}`}>Read More</Link>
	                    	</div>
		                </div>
			       
					
					))}
					 </div>
					 <div className="col-md-6" >
			     {blogs && blogs.slice(1, 4).map((blog, index) => (
					
				
					<div className="  news-list-block" key={index}>
						<div className="  news-list-item">
							<div className="news-img">
							<Link to={`/blog/${id}`}>
									<img src={blog?.mainImage} alt="mainImage" />
								</Link>
							</div>			        			
							<div className="news-content">
							<Link to={`/blog/${id}`}><h5 className="news-title">{blog?.title}</h5></Link>
								<div className="news-date">
									<i className="fa fa-calendar-check-o"></i>
									<span>{formatDate(blog?.created_at)}</span>
								</div>
								<div className="news-desc">
									<div>
										{parse(blog?.description)}
									</div>
								</div>
							</div>			        			
						</div>
					
					</div>
			
				
				))}
			    </div>
			    </div>
			</div>
        </div>
        {/* <!-- Latest News End -->

		<!-- Products Start --> */}
        <div id="rs-products" className="row rs-products sec-spacer sec-color">
			<div className="container col-6">
				<div className="sec-title mb-50 text-center">
					<h2>OUR Contact</h2>
				</div>
				
				<div className="view-btn">
					<Link to={`/contact/${id}`}>Contact Us Now</Link>
				</div>
			</div>
			{schools && schools?.verified_school === true ? 
			<div className="container col-6 d-flex flex-column align-items-center justify-content-center">
				<Link to={`/donation/${selectedSchool}`}>
				<div className="justify-content-center">
				<img src="/assets/images/donation.jpg"  alt="Donation_Image" className="mb-3" style={{width:'250px'}} />
				</div></Link>
				<div className="view-btn">
					<Link to={`/donation/${selectedSchool}`}>Click to Donate to School</Link>
				</div>
			</div>: <div className="col-lg-2"></div>}
		</div>

        {/* <!-- Footer Start -->  */}
        <footer id="rs-footer" className="bg3 rs-footer">
			<div className="container">
				{/* <!-- Footer Address --> */}
				<div>
					<div className="row footer-contact-desc">
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Address</h4>
								<p className="contact-desc">
								{contact && contact?.length > 0 && contact[0]?.address}<br/>
								{schools && schools?.town}<br/>
								{schools && schools?.district}<br/>
								{schools && schools?.region}<br/>
								<span style={{color:'red'}}>GPS Location</span> <br/>
								{contact && contact?.length > 0 && contact[0]?.gps_address}<br/>	
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact?.length > 0 && contact[0]?.phone_number}<br/>
									{contact && contact?.length > 0 && contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact?.length > 0 && contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact?.length > 0 && contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact?.length > 0 && contact[0]?.facebook}<br/>
									{contact && contact?.length > 0 && contact[0]?.instagram}<br/>
									{contact && contact?.length > 0 && contact[0]?.website}<br/>
									{contact && contact?.length > 0 && contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>					
				</div>
			</div>

			<div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
    </footer>




  <div id="scrollUp">
      <i className="fa fa-angle-up"></i>
  </div>
  

      
		<div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
			<button type="button" className="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true" className="fa fa-close"></span>
			</button>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="search-block clearfix">
						<form>
							<div className="form-group">
								<input className="form-control" placeholder="eg: Computer Technology" type="text"/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

    </div>
	</React.Fragment>
  )
}

export default SchoolHome



