

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const baseUrl = "https://myghschool.myghschools.com/school";

function EditAlumniMember() { 

  useEffect(()=>{
		document.title = "MGS | Dashboard";
	});


  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);  

  const [formData, setFormData] = useState({
    name: "",
    position: "",
    image: null,
    started_year: "",
    end_year: "",
    school: selectedSchool, //this is toe ensure that school is selected to be associated with the subject
  });


  const goBack = () => {  
    navigate(-1);
  };
  const [error, setError] = useState(null);
  

  const formatDateForBackend = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  


  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
         axios.get(`${baseUrl}/schools/${selectedSchool}/alumni/${id}`,{
        headers: {
        
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData(res.data);
      }
        )  
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setFormData({ ...formData, image: e.target.files[0] });
    }
    else {
      setFormData({ ...formData, [name]: value });
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("position", formData.position);
    if (formData.started_year) {
      const formattedEndDate = formatDateForBackend(formData.started_year);
        formDataObj.append("started_year", formattedEndDate);
      }
    if (formData.end_year) {
        const formattedEndDate = formatDateForBackend(formData.end_year);
          formDataObj.append("end_year", formattedEndDate);
        }
    if (formData.image && formData.image.name) { // Check if the image file is an object with a name property
        formDataObj.append("image", formData.image);
      } else if (typeof formData.image === 'string') { // If the image file is a string (URL), don't append it
        // Do nothing
      } else if (formData.image) { // If the image file is a Blob without a name property
        formDataObj.append("image", formData.image, 'image.jpg'); // Add a filename
      }
    formDataObj.append("school", formData.school); //school field


    try {
      const token = accessToken
      const response = await axios.patch(`${baseUrl}/schools/${selectedSchool}/alumni/${id}/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      //... existing submit logic
      Swal.fire({
        icon: "success",
        title: "Alumni Member updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      goBack();
      
    } catch (err) {
      if (err.response.status === 400) {
      setError("Name already exist. Please try again.");
    }
    else{
      setError("Something went wrong. Please try again later.");
    }
  }
  }; 

  if (isLoading) {
    return <h2>Loading...</h2>; // Render a loading message while the data is being fetched
  }

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit Alumni Member</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Alumni Member</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
          <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
              <label className="mb-1 "><strong>Name</strong></label>
               <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-control" required />
            </div>
            </div><br></br>
            <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
               <label className="mb-1 "><strong>Position</strong></label>
               <input type="text" id="position" name="position" value={formData.position} onChange={handleChange} className="form-control" required />
             </div>
             </div><br></br>
             <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
                <label className="mb-1 "><strong>Started Year</strong></label><br/>
                <DatePicker selected={formData.started_year ? new Date(formData.started_year) : null} onChange={(date) => setFormData({ ...formData, started_year: date })} className="form-control mobile-date-input" />
            </div>
            </div>
             <div className="col-lg-6 mb-2">
            <div className="form-group col-6 edit-content">
                <label className="mb-1 "><strong>End Year</strong></label><br/>
                <DatePicker selected={formData.end_year ? new Date(formData.end_year) : null} onChange={(date) => setFormData({ ...formData, end_year: date })} className="form-control mobile-date-input" />
            </div>
            </div>
            <label className="btn btn-success col-lg-6 mb-2  " >
                {formData.image ? formData.image.name : "Upload Alumni Image"}
                  <input type="file" id="image" name="image" onChange={handleChange} className="d-none" />
                 <br/>
                <div className="edit-content">
              {formData.image && (
                typeof formData.image === 'string'
                ? <img src={formData.image} alt="Existing" />
                : <img src={URL.createObjectURL(formData.image)} alt="Uploaded" />
                  )}
                  </div>
                    </label>
                  <br/><br/>  
                <Button variant="primary" type="submit">
                    Update
                </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditAlumniMember;

