import { useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo-full.png";
import { axiosInstance } from "../axios/axios";
import { useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

// const baseUrl="https://myghschool.rubykaryo.com/"
const AdminRegister = () => {

  useEffect(()=>{
		document.title = "MGS | Admin Register";
	});

    const navigate = useNavigate();

	const errRef  = useRef();

	const [email, setEmail] = useState('');
	const [username, setUsername] = useState('');
	const [first_name, setFirstName] = useState('');
	const [last_name, setLastName] = useState('');
	const [phone_number, setPhoneNumber] = useState('');
    let errorsObj = { email: '', password: '', password2: '', first_name:'', last_name:'', phone_number:'', username:'' };
	const [errMsg, setErrMsg] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
    const [ setSuccess] = useState(false);


    useEffect(() => {
        setErrMsg('');
    }, [email, password, password2, first_name, last_name, phone_number, username]);


    const onSignUp = async (e) =>{
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        if (password2 === '') {
            errorObj.password2 = 'Confirm Password is Required';
            error = true;
        }
		if (password !== password2) {
			errorObj.password2 = 'Passwords do not match';
			error = true;
		}
        if (first_name === '') {
            errorObj.first_name = 'First Name is Required';
            error = true;
        }
        if (username === '') {
            errorObj.username = 'Username is Required';
            error = true;
        }
        if (last_name === '') {
            errorObj.last_name = 'Last Name is Required';
            error = true;
        }
        if (phone_number === '') {
            errorObj.phone_number = 'Phone Number is Required';
            error = true;   
        }
        setErrors(errorObj);

        if (error) return;

        try{
		
            const response = await axiosInstance.post(`user/admin/`, JSON.stringify({ 
                user: {
                    username, 
                    email, 
                    password, 
                    first_name, 
                    last_name, 
                   
                  },
				  password2,
                  phone_number,
             }))


            if (response.status === 201) {
   
            navigate('/admin/admins');
		
        	setSuccess(true);
			} else {

				setErrMsg('Login failed');

            }
			// }
            Swal.fire({
                icon: "success",
                title: "Admin Created Successfully",
                showConfirmButton: false,
                timer: 1500,
              });

        } catch (error) {
            if(!error.response) {
                setErrMsg('Network Error' );}
            else if(error.response.status === 400){
                setErrMsg( error.response.data.message);
            }
            else {
                setErrMsg('Something went wrong, Registration Failed' );
            }

            }
       
        }
    

    return (
    <>
    
    <div className="authincation h-100 ">
			<div className="container h-100">
				<div className="row justify-content-center h-100 align-items-center">
					<div className="col-md-6">
						<div className="authincation-content">
							<div className="row no-gutters">
								<div className="col-xl-12">
									<div className="auth-form">
										<div className="text-center mb-3">
											<Link to="/login">
												<img src={logo} alt="" />
											</Link>
										</div>
									
                                        <h4 className="text-center mb-4 ">Register New Admin </h4>
                                        <span style={{color: 'red'}}><strong><p ref={errRef} className={errMsg}>{errMsg}</p></strong></span>
          
										<form onSubmit={onSignUp} >
											<div className="form-group mb-3">
												<label className="mb-1 ">
													<strong>Username</strong>
												</label>
												<input name='username' type="text" className="form-control" placeholder="username" 
												value={username} onChange={(e) => setUsername(e.target.value)} />
												{/* value={owner.user.username} onChange={handleChange}/> */}
        										<span style={{color:'red'}}> {errors.username && <div>{errors.username}</div>}</span>
												{/* <input type="text" className="form-control" placeholder="username"/> */}
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
													<strong>First Name</strong>
												</label>
												<input name='first_name' type="text" className="form-control" placeholder="first name" 
												value={first_name} onChange={(e) => setFirstName(e.target.value)} />
        										<span style={{color:'red'}}>{errors.first_name && <div>{errors.first_name}</div>}</span>
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
													<strong>Last Name</strong>
												</label>
												<input type="text" name="last_name" className="form-control" placeholder="last name" 
												value={last_name} onChange={(e) => setLastName(e.target.value)} />
       										<span style={{color: 'red'}}>{errors.last_name && <div>{errors.last_name}</div>}</span>
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
												  <strong>Email</strong>
												</label>
												<input type="email" name="email" className="form-control" placeholder="hello@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
       											<span style={{color:'red'}}>{errors.email && <div>{errors.email}</div>}</span>
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
												  <strong>Phone Number</strong>
												</label>
												<input type="number" name="phone_number" className="form-control" placeholder="Phone Number" value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} />
       											<span style={{color:'red'}}> {errors.phone_number && <div>{errors.phone_number}</div>}</span>
											</div>										
											<div className="form-group mb-3">
												<label className="mb-1 ">
													<strong>Password</strong>
												</label>
												<input type="password" name="password" className="form-control" placeholder="Password"
												value={password} onChange={(e) => setPassword(e.target.value)} />
												 {/* value={owner.user.password} onChange={handleChange}/> */}
        										<span style={{color:'red'}}> {errors.password && <div>{errors.password}</div>}</span>
								
											</div>
											<div className="form-group mb-3">
												<label className="mb-1 ">
												<strong>Confirm Password</strong>
												</label>
												<input type="password" name="password2" className="form-control" placeholder="Confirm Password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
												{errors.password2 && <div>{errors.password2}</div>}
											</div>
											<div className="text-center mt-4">
												<button type="submit" className="btn btn-primary btn-block">Register Admin</button>
											</div>
										</form>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
            </div>
         {/* )} */}
    	</>

    );
};


export default AdminRegister;

