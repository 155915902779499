

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import Select from 'react-select';


const baseUrl = "https://myghschool.myghschools.com/school";

function EditCourse() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [subjects, setSubjects] = useState([]);
  const [oldsubjects, setOldSubjects] = useState([]);
  const subjectsOptions = subjects.map(subject => ({ value: subject.id, label: subject.name }));
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [backendErrors, setBackendErrors] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: null,
    courses: [],
    school: selectedSchool, //this is toe ensure that school is selected to be associated with the subject
    subject: [], //to ensure that the course is selected to be associated with the subject
  });


  const goBack = () => {  
    navigate(-1);
  };
  const [error, setError] = useState(null);
  


  // to fetch courses from the server that can be use to populate the course field in the form
  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
         axios.get(`${baseUrl}/schools/${selectedSchool}/subjects/`,{
        headers: {
        
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSubjects(res.data);
      }
        )
      
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken]);


  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
      axios.get(`${baseUrl}/schools/${selectedSchool}/courses/${id}`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFormData({
          ...res.data,
          subject: res.data.subjects.map(subject => subject.id), // map over the course objects to get an array of IDs
          
        });
        setOldSubjects(res.data);
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
    }
  }, [selectedSchool, accessToken, id]);
  

  const handleSelectChange = (selectedOptions) => {
    setFormData({ ...formData, subject: selectedOptions ? selectedOptions.map(option => option.value) : [] });
}


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setFormData({ ...formData, [name]: e.target.files[0] || null });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("description", formData.description);

    if (formData.image && formData.image.name) { // Check if the image file is an object with a name property
        formDataObj.append("image", formData.image);
      } else if (typeof formData.image === 'string') { // If the image file is a string (URL), don't append it
        // Do nothing
      } else if (formData.image) { // If the image file is a Blob without a name property
        formDataObj.append("image", formData.image, 'image.jpg'); // Add a filename
      }
    formDataObj.append("school", formData.school); //school field

    formData.subject.forEach((subjectId) => {
        formDataObj.append('subjects', subjectId);
      });

    try {
      const token = accessToken
      const response = await axios.patch(`${baseUrl}/schools/${selectedSchool}/courses/${id}/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });

      Swal.fire({
        icon: "success",
        title: "School Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      goBack();
      
    } catch (err) {
      if (err) {
        setBackendErrors(err)
        setError("Something went wrong. Please try again later.");
      }
  }
  }; 

  if (isLoading) {
    return <h2>Loading...</h2>; // Render a loading message while the data is being fetched
  }



  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Edit Course</h3>
          </div>
          <div onClick={goBack} className="back-button">Back to Course</div><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <div className="edit-content">
          <form onSubmit={handleSubmit}>
            <label className="edit-content mb-1 col-6"><strong>Select new subject</strong></label>
                <Select className="edit-content col-6"  id="subject"  isMulti onChange={handleSelectChange} options={subjectsOptions} /> 
                <br></br>
                <div className="row">
                    <div className="edit-content form-group col-md-10 col-4">
                    <label className="edit-content mb-2 col-4"><strong style={{color:'red'}}>Subjects associated with this subject</strong></label>
                {oldsubjects.subjects && oldsubjects.subjects.length > 0 ? (
                    
                    <div className="edit-content row col-6">
                    {oldsubjects.subjects.map((subject, index) => (
                       
                        <div key={index} className="edit-content col-2">
                        <p><i className="fa-solid fa-square-check "> {subject.name}</i></p>
                        </div>
                    ))}
                    </div>
                ) : (
                    <p>No associated subject.</p>
                )}
            
                    {/* </div> */}
                    </div>
                </div>

            <div className="form-group col-6 edit-content"><br></br>
              <label className="mb-1 "><strong>Name</strong></label>
              {backendErrors?.response?.data?.name && backendErrors?.response?.data?.name=== "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Name of donation required</div>: null}
               <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-control" />
            </div><br></br>
             <div className="form-group col-6 edit-content"><br/>
              <label className="mb-1 "><strong>Level</strong></label>
               <input type="text" id="level" name="level" value={formData.level} onChange={handleChange} className="form-control"  placeholder="specific class or students" />
            </div><br></br>
            <div className="row">
            <div className="form-group col-lg-6 edit-content"><br/>
              <label className="mb-1 "><strong>Duration</strong></label>
               <input type="Number" id="duration" name="duration" value={formData.duration} onChange={handleChange} className="form-control"  placeholder="1 or 2 years" />
            </div><br></br> 
            <div className="form-group col--lg-6 edit-content"><br/>
              <label className="mb-1 "><strong>Duration Type</strong></label>
              <select id="duration_type" name="duration_type" value={formData.duration_type} onChange={handleChange} className="form-select mb-3 p-3">
                    <option className="option" disabled>Choose... </option> 
                    <option value="Year">Year(s)</option>
                    <option value="Month">Month(s)</option>
                    <option value="Week">Week(s)</option>
                    <option value="Day">Day(s)</option>
                 
                  </select>
            </div><br></br>
            </div>
            <label className="btn btn-success col-lg-6 mb-2 edit-content" >
                {formData.image ? formData.image.name : "Upload Course Image"}
                  <input type="file" id="image" name="image" onChange={handleChange} className="d-none" />
               <br/>
                {backendErrors?.response?.data?.image && backendErrors?.response?.data?.image=== "The submitted data was not a file. Check the encoding type on the form."? <div className="text-red " style={{fontWeight:'bold'}}>Please upload course image</div>: null}
                <div className="edit-content">
              {formData.image && (
                typeof formData.image === 'string'
                ? <img src={formData.image} alt="Existing" />
                : <img src={URL.createObjectURL(formData.image)} alt="Uploaded"  />
                  )}
                  </div>
                  </label>
                  <br/>
                <Button variant="primary" type="submit">
                    Update
                </Button>
          </form>
          </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditCourse;

