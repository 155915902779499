
import { lazy, Suspense } from 'react';
import { Routes, Route} from 'react-router-dom';
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import RequireAuth from './pages/RequireAuth';
import PersistLogin from './pages/PersistLogin';
import FrontIndex from './jsx/FrontIndex';
import ResetPassword from './pages/Reset-Password';
const SignUp = lazy(() => import('./pages/Register'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => import('./pages/Login'));

function App() {
    return (
        <>
        <div className="vh-100">
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
            }>
     
                    <Routes>
                        <Route PersistLogin element={<PersistLogin />} >
                            <Route path='login' element={<Login />} />
                            <Route path='register' element={<SignUp />} />
                            <Route path='forgot-password' element={<ForgotPassword />} />
                            <Route path='reset-password/:id' element={<ResetPassword />} />
                        
                            <Route path="*" element={<FrontIndex />} />
                    
                            <Route path="admin/*" element={ <RequireAuth /> } />
                   
                        </Route>
                    </Routes>

            </Suspense>
        </div>
        </>
    );
};

export default App;


