


import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function AddBlog() { 

  useEffect(()=>{
		document.title = "MGS | Dashboard";
	});

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [selectedImages, setSelectedImages] = useState([]);
  const [backendErrors, setBackendErrors] = useState(null);
  const [setResponsess] = useState('');
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    mainImage: null,
    image: null,
    author: "",
    school: selectedSchool,  //assuming there's a school field in the course model
  });

  const handleSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    e.target.value = "";
    setFormData(prevState => ({ ...prevState, image: selectedFilesArray }));
  };

  const goBack = () => {  
    navigate(-1);
  };

  const [error, setError] = useState(null);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mainImage") {
      setFormData({ ...formData, mainImage: e.target.files[0] });
    }
    else {
      setFormData({ ...formData, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("title", formData.title);
    formDataObj.append("description", formData.description);
    formDataObj.append("author", formData.author);

    if (formData.mainImage) {
        formDataObj.append("mainImage", formData.mainImage);
    }

    if (formData.image) {
        for (let i = 0; i < formData.image.length; i++) {
        formDataObj.append("image", formData.image[i]);
        }
    }
    formDataObj.append("school", formData.school); //school field

    try {
      const token = accessToken
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/blogs/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      //... existing submit logic
      setResponsess(response)
      Swal.fire({
        icon: "success",
        title: "Gallery Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      
      goBack();
    } catch (err) {
      if (err.response.status === 400) {
      setError("Name already exist. Please try again.");
      setBackendErrors(err.response.data)
    }
    else{
      setError("Something went wrong. Please try again later.");
    }
  }

  };

  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Blog</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Blog</button><br/><br/>
          <span style={{fontSize:'20px'}}> {error && <p className="text-danger">{error}</p>}</span>
          <form onSubmit={handleSubmit}>
        
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="mb-1 "><strong>Title</strong></label>
              {backendErrors?.title && backendErrors?.title === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Title is required</div>: null}
               <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} className="form-control" required />
            </div>
            </div>

            <div className="col-lg-6 mb-2">
            <div className="form-group">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
             </div>
             </div><br/>
            <div className="col-lg-6 mb-2">
            <div className="form-group">
                <label className="mb-1 "><strong>Name of Author</strong></label>
                {backendErrors?.author && backendErrors?.author === "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Author is required</div>: null}
                <input type="text" id="author" name="author" value={formData.author} onChange={handleChange} className="form-control" required />
            </div>
            </div><br/>
            <label className="btn btn-success col-lg-6 mb-2 " ><span style={{color:'red'}}>Required</span>
                {formData.mainImage ? formData.mainImage.name : "Upload Main Blog Image"}
                  <input type="file" id="mainImage" name="mainImage" onChange={handleChange} className="d-none" required />
                <br/>
                <div className="edit-content">
              {formData.mainImage && (
                 <div style={{ position: "relative" }}>
                 <button onClick={() => setFormData({...formData, mainImage: null})} style={{position:"absolute", width:'20px', color:'white', border:'none', backgroundColor:'red', zIndex:10}}>x</button>
                {typeof formData.mainImage === 'string'
                ? <img src={formData.mainImage} alt="Existing" style={{ width: '100px', height: '100px' }} />
                : <img src={URL.createObjectURL(formData.mainImage)} alt="Uploaded" style={{ width: '100%', objectFit: 'contain' }} />
              }
                </div>  )}</div> 
                </label><br/>
                <div className="col-lg-6 mb-2">
            <div className="form-group">
            <label className="btn btn-success w-100" >
                    {formData.image ? `${formData.image.length} files selected` : "You Can Upload More Than One Images"}
                    <input type="file" id="image" name="image" onChange={handleSelectFile} multiple className="d-none" />
               
                      {selectedImages.length > 0 &&
                      (selectedImages.length > 10 ? (
                        <p className="error">
                          You can't upload more than 10 images! <br />
                          <span>
                            please delete <b> {selectedImages.length - 10} </b> of them{" "}
                          </span>
                        </p>
                      ) : (<div className="upload-btn">
                        {selectedImages.length} IMAGE SELECTED
                      {selectedImages.length === 1 ? "" : "S"} 
                      </div>
                    ))}
                    </label>
                    <div className="row multi-images img">
                    {selectedImages && selectedImages.map((image, index) => (
                      
                          <div key={image} className=" col-lg-6 col-md-3 col-sm-4 mb-2">
                            <div className="image position-relative">
                            <img src={image} alt="upload" style={{width: '100%'}}/>
                            <p>{index + 1}</p>
                          </div>
                          </div>
                      ))}
                    </div>
                        </div>
                        </div>
             
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddBlog;


