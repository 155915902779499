import React, {useState, useEffect, useCallback} from 'react'
import MainNav from '../layout/MainNav'
import Assets1Loader from '../../AssetsLoader'
import { Link } from 'react-router-dom'
import MainFooter from '../layout/MainFooter'
import axios from 'axios'
import useDebounce from '../searchhooks/useDebounce';
import '../layout/search.css';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-multi-carousel';


const baseUrl = 'https://myghschool.myghschools.com/school'

const Love = () => {
    
  const [lovenews, setLoveNews] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [allnews, setAllNews] = useState([]);

  const [page, setPage] = useState(1);
  const itemsPerPage = 10; 

  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  
	useEffect(() =>{
        fetchSchoolData();
    },[]);


    const handleSearch = useCallback(async () => {
        try {
          const response = await axios.get(`${baseUrl}/search/love/?search=${searchQuery}`);
          setSearchResults(response.data);
        } catch (error) {
        }
      }, [searchQuery]);


      useEffect(() => {
        if (debouncedSearchQuery.length >= 3) {
            handleSearch();
        } else {
            setSearchResults([]);
        }
    }, [debouncedSearchQuery, handleSearch]);


    const ClearInput = () => {
        setSearchQuery("");
        setSearchResults([]);
        };


     const fetchSchoolData = async () => {
        try {
        const response = await axios.get(`${baseUrl}/search/love/`);
        setLoveNews(response.data);
        } catch (error) {
        }
      };

      useEffect(() => {

        const fetchAllNews = async () =>{
            try {
            const response = await axios.get(`${baseUrl}/schoolblog/`);
            setAllNews(response.data);
        } catch (error) {
        }
        }
        fetchAllNews();
      },[]);
      const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 1024 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 1024, min: 850 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 850, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

  return (
<React.Fragment>

<Assets1Loader />

<MainNav/>

    <section className="tgbanner__area">
        <div className="container">
            <div className="tgbanner__grid">
              
                                 
            <section className="featured-post-area section__hover-line">
                        <div className="container">
                            <div className="section__title-wrap ">
                                <div className="row align-items-end">
                                    <div className="col-sm-6">
                                        <div className="section__title">
                                            <h3 className="section__main-title">News/Love & Relationship Category</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                            <div className="col-sm-12 search-bar-container mb-40"> 
                                         <div className="searchInputs">
                                            <input type="text"  value={searchQuery}  onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search Story in Love & Relationship Category..." />
                                            <div className="searchIcon">
                                               {searchResults.length === 0 ? (
                                               <SearchIcon /> 
                                               ):( 
                                               <CloseIcon id="clearBtn" onClick={ClearInput}/>
                                               )}
                                               </div>
                                        </div>
                                        {searchResults.length > 0 && (
                                            <div className="search-results-container" style={{width:"485px"}}>
                                        {searchResults.map((lovenews) => (
                                            <div key={lovenews.id} className="search-result-item">
                                              <Link to={`/mainnewssingle/${lovenews.id}`}> {lovenews.title} </Link> 
                                               
                                            </div>
                                            ))}
                                             </div> 
                                             )}
                                    </div>
                              {lovenews && lovenews.map((lovenew, index) => (
                                    <div className="col-lg-6 col-sm-6" key={lovenew.id}>
                                    <div className="featured__post"> 
                                        <Link to={`/mainnewssingle/${lovenew.id}`}>  <img src={lovenew && lovenew.featured_image} width={70} height={70} alt='school_logo'  className="rounded-circle"/></Link>
                                        <div className="featured__content">
       
                                            <ul className="tgbanner__content-meta list-wrap">
                                              
                                                <li><span className="text-red">Category: </span> <Link to={`/mainnewssingle/${lovenew.id}`} >{lovenew && lovenew.category}</Link></li>
                                            </ul>
                                            <h4 className="title tgcommon__hover"><Link to={`/mainnewssingle/${lovenew.id}`}>{lovenew && lovenew.title}</Link></h4>
                                        </div>
                                    </div>
                                </div> 
                              
                              ))}
                            
                            </div>
                            <br/>
                        </div>
                        <div className="pagination">
                      
                                    <button onClick={() => setPage(page => Math.max(page - 1, 1))} >Previous</button>
                                    <span>Page {page}</span>
                                    <button onClick={() => setPage(page => Math.min(page + 1, Math.ceil(lovenews.length / itemsPerPage)))}>Next</button>
                 
                       </div>
                    </section>
                
                    <div className="widget sidebar-widget widget_categories">
                                <h4 className="widget-title">Trending Category</h4>
                                <ul className="list-wrap">
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/technology">technology</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/business">business</Link>                                     
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/entertainment">Entertainment</Link>                                     
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/sports">Sports</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/politics">politics</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/health">Health</Link>
                                    </li>
                                    <li>
                                        <div className="thumb"><a href="blog.html"><img src="assets1/img/category/RUCST_logo.jpg" alt="img"/></a></div>
                                        <Link to="/love">Love & Relationship</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="widget sidebar-widget">
                                <div className="sidePost-active">
                                    <h3>Latest News</h3>
                                    <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={2000} keyBoardControl={true} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]} >
                                    {allnews && allnews.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 10).map((news, index) => (
                                        
                                         <div key={news.id} style={{ margin: '10px' }}>
                                        <div className="sidePost__item"  style={{ backgroundImage: `url(${news.featured_image})` }}/>
                                        <div>
                                            <Link to={`/mainnewssingle/${news.id}`} >{news.category}</Link>
                                            <h5 className="title tgcommon__hover"><Link to={`/mainnewssingle/${news.id}`}>{news.title}</Link></h5><br/>
                                        </div>
                                        </div>
                                    ))}
                                   </Carousel>
                                    </div>
                                  
                                    </div>
                           
                            <div className="widget sidebar-widget">
                                <h4 className="widget-title">Check This Out (ads)</h4>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div><br/>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div><br/>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div><br/>
                                <div className="sidebarInsta__wrap">
                                   
                                    <div className="sidebarInsta__slider-wrap">
                                      
                                       <img src="assets1/img/logo/MYGH1.jpg" alt="img"/>    
                                    </div>
                                    <div className="sidebarInsta__bottom">
                                        <Link to="#" target="_blank" className="btn"><i className="fa-regular fa-face-smile"></i><span className="text">Click Here</span></Link>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
            </div>
        </div>
    </section>

<MainFooter />


    </React.Fragment>
  )
}

export default Love

