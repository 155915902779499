import React from 'react'
import {Link } from 'react-router-dom'
import { useState, useContext, useEffect } from 'react'
import axios from 'axios';
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import './schoolheader.css';

const baseUrl='https://myghschool.myghschools.com/school';
const SchoolHeader = () => {

  useEffect(()=>{
    document.title = "MGS | School Heads";
});

    const [schools, setSchools] = useState(null);
    const [ setContact] = useState(null);
    const { selectedSchool } = useContext(SchoolContext);

    
    const [isOpen, setIsOpen] = useState(false);
    const [submenuOpen, setSubmenuOpen] = useState(false);
      
    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setSubmenuOpen(false);
    };

    const toggleSubmenu = (index) => {
      setSubmenuOpen(submenuOpen === index ? null : index);
    };

    

    useEffect(() => {
        const fetchSchoolData = async () => {
          try {
          const response = await axios.get(`${baseUrl}/schools/${selectedSchool}`);
          setSchools(response.data);
          } catch (error) {
          }
        };
  
      fetchSchoolData();
      },[selectedSchool]);
    

      useEffect(() => {
        const fetchContactData = async () => {
          try {
          const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
          } catch (error) {
          }
        }
        fetchContactData();
      }, [selectedSchool]);

  return (
    <div>
 
  <div className="inner-page">

<div className="full-width-header">

<div className="rs-toolbar">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <div className="rs-toolbar-left">
          <div className="welcome-message">
          <Link to="/"> <i className="fa fa-graduation-cap"></i><span>MY Gh School</span> </Link> 
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="rs-toolbar-right">
          <Link to="/">My Gh School</Link>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
    
<header id="rs-header-2" className="rs-header-2">

    <div className="menu-area menu-sticky">
         <div className="container">
           <div className="row rs-vertical-middle">
             <div className="row">
              <div className="col-lg-4 logo-area ">
                {/* <div className="logo-area"> */}
                 <Link to={`/schoolhome/${selectedSchool}`}> <img src={schools && schools?.school_logo} width={60} height={60} alt="logo" className="rounded-circle"/></Link>
                {/* </div> */}
            </div>
      <div className="col-lg-6 pt-5">
          <h4 className='text-center' style={{fontSize:'2em', color:'white'}}>{schools && schools?.school_name}</h4>
          <div className='row'>
										<div className="col-6">
									{schools && schools?.school_category === "" ?
									<span></span> : <h4 className='text-white text-center'>Category <span style={{color:'red', fontSize:'1.2em'}}>{schools && schools?.school_category}</span> school</h4> 	}
									</div>
									<div className="col-4">
									{schools && schools?.status === "" ?
									<span></span> : <h4 className='text-white text-center'> <span style={{color:'red', fontSize:'1.2em'}}>{schools && schools?.status}</span> school</h4> 	}
										</div>
									</div>	

                    </div>
                    {schools && schools?.verified_school === true ? 
                        <div className="col-lg-2 mt-5 mobile">
                        <img src="/assets/images/verified.png" width={50} height={40} alt="logo" className="card"/>
                      </div> : <div className="col-lg-6">
                      </div>}
                  </div>
                  </div>      
              <div className="col-lg-12 col-md-12 row relative">
              <div className="main-menu">
              <Link to="#" className="rs-menu-toggle menu-1"  onClick={toggleMenu}>{isOpen ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}Menu</Link>
              {isOpen && <div className="overlay" onClick={toggleMenu}></div>}
              <nav className={`rs-menu  ${isOpen ? 'open' : ''}`}>
            
                <ul className="nav-menu">
              
                          <li className="menu-item-has-children"> <Link to={`/schoolhome/${selectedSchool}`}>Home</Link></li>
                                   <li  className={`menu-item-has-children  ${submenuOpen ===1 ? 'menu-open' : '' }`}>
                                   <Link to="#"  onClick={() => toggleSubmenu(1)} >Management {submenuOpen === 1 ? '-' : '+'}</Link>
                                            <ul className={`submenu ${submenuOpen ? 'open' : ''}`}>
                                                <li className="menu-item-has-children dropdown-item"> <Link to={`/boardofdirectors/${selectedSchool}`} style={{padding:'10px'}}>Board of Directors</Link>
                                                </li>
                                                <li className="menu-item-has-children dropdown-item"> <Link to={`/headofschool/${selectedSchool}`}>Head of School</Link> </li>
                                                <li className="menu-item-has-children dropdown-item"> <Link to={`/departments/${selectedSchool}`}>Departments</Link> </li>
                                                <li className="menu-item-has-children dropdown-item"> <Link to={`/ptamember/${selectedSchool}`}>P.T.A</Link> </li>
                                                <li className="menu-item-has-children dropdown-item"> <Link to={`/alumni/${selectedSchool}`}>Alumni</Link> </li>
                                                <li className="menu-item-has-children dropdown-item"> <Link to={`/healthfacility/${selectedSchool}`}>Health Facility</Link> </li>
                                            </ul>
                                        </li>
                                    <li className={`menu-item-has-children  ${submenuOpen ===2 ? 'menu-open' : '' }`}>
                                            <Link to="#" onClick={() => toggleSubmenu(2)} >Academic {submenuOpen === 2 ? '-' : '+'}</Link>
                                            <ul className={`submenu ${submenuOpen ? 'open' : ''}`}>
                                                <li className="menu-item-has-children dropdown-item"> <Link to={`/courses/${selectedSchool}`}>Courses</Link>
                                                </li>
                                                <li className="menu-item-has-children dropdown-item"> <Link to={`/subject/${selectedSchool}`}>Subjects</Link> </li>
                                                {/* <li><a class="dropdown-item" href="#">Submenu item 2</a></li> */}
                                            </ul>
                                        </li>
                                  <li className="menu-item-has-children"> <Link to={`/event/${selectedSchool}`}>Events</Link></li>
                                
                                  <li className="menu-item-has-children"> <Link to={`/blog/${selectedSchool}`}>News</Link>	</li>

                                  <li className="menu-item-has-children"> <Link to={`/gallery/${selectedSchool}`}>Gallery</Link></li>

                                  <li className="menu-item-has-children"> <Link to={`/achievement/${selectedSchool}`}>Achievement</Link>	</li>

                                <li className={`menu-item-has-children  ${submenuOpen ===3 ? 'menu-open' : '' }`}>
                                   <Link to="#" onClick={() => toggleSubmenu(3)} >Contact {submenuOpen === 3 ? '-' : '+'}</Link>
                                    <ul className={`submenu ${submenuOpen ? 'open' : ''}`}>
                                        <li className="menu-item-has-children dropdown-item"> <Link to={`/contact/${selectedSchool}`}>Contact</Link>
                                        </li>
                                        {/* {schools && schools?.verified_school === true ? 
                                      <li className="menu-item-has-children dropdown-item mt-2"><Link to={`/donation/${selectedSchool}`}>Donate</Link> </li>: <div className="col-lg-2">
                                      </div>} */}
													         </ul>
											          </li>
                                </ul>
                       </nav>
                       </div>
                      {/* </div> */}
                   </div>			
              </div>
      </div> 

      </header>  


</div>

    </div>
  )
}

export default SchoolHeader