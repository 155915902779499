
import { ColumnFilter } from './ColumnFilter';
import { Link } from 'react-router-dom';


	
export const COLUMNS = [

	{
		Header : 'School Name',
		Footer : 'School Name',
		accessor: 'school_name',
		Filter: ColumnFilter,
		Cell: ({row}) => <Link to={`/admin/frontend/${row.original.id}`}>{row.original.school_name}</Link>

	},
	{
		Header : 'School Type',
		Footer : 'School Type',
		accessor: 'school_type',
		Filter: ColumnFilter,
	},
	{
		Header : 'School Category',
		Footer : 'School Category',
		accessor: 'school_category',
		Filter: ColumnFilter,
	},
	{
		Header : 'Address',
		Footer : 'Address',
		accessor: 'address',
		Filter: ColumnFilter,
	},
	{
		Header : 'Town',
		Footer : 'Town',
		accessor: 'town',
		Filter: ColumnFilter,
	},
	{
		Header : 'Region',
		Footer : 'Region',
		accessor: 'region',
		Filter: ColumnFilter,
	},
]

export const GROUPED_COLUMNS = [
	
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone_number'
			},
		]
	},
]

