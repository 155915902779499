import React, {useState, useEffect, useContext} from 'react'
import { Link, useParams } from 'react-router-dom'
import {SchoolContext} from '../../../../AuthContext/SchoolProvider';
import { Modal, Carousel } from "react-bootstrap";
import SchoolHeader from '../SchoolHeader';
import parse from 'html-react-parser';
import Swal from "sweetalert2";
import axios from 'axios';

const baseUrl='https://myghschool.myghschools.com/school';

const ViewDonation = () => {

    useEffect(()=>{
		document.title = "MGS | Donation";
	},[]); 

  const [schools, setSchools] = useState(null);
  const [donations, setDonations] = useState(null);
  const [contact, setContact] = useState(null);
  const { id } = useParams();
  const { selectedSchool } = useContext(SchoolContext);
  const [isLoading, setisLoading] = useState(true);
  const [showExpModal, setShowExpModal] = useState(false);

  const [donationstats, setDonationStats] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [amount, setAmount] = useState("");


  const handleExpressPaySubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("firstname", firstName);
    formData.append("lastname", lastName);
    formData.append("email", email);
    formData.append("phonenumber", phoneNumber);
    formData.append("username", username);
    formData.append("amount", amount.toString());
    formData.append("school_id", selectedSchool)
    formData.append("project_id", id)
    formData.append('school_name', schools && schools?.school_name);
    formData.append("project_name", donations && donations.title);
    formData.append("project_description", donations && donations.description);
    formData.append("order-img-url", donations && donations.image1);
    formData.append("target_amount", donations && donations.amount_requested);
  
    try {
        const response = await axios.post(`https://myghschool.myghschools.com/donation/makePayment/`, formData)
        .then(response => {
            if (response.data.data.checkoutUrl) {
                window.location.href = response.data.data.checkoutUrl;
            }
        })
      
    } catch (error) {
 
    }
};

    const [showModal, setShowModal] = useState(false);
    const [carouselImageIndex, setCarouselImageIndex] = useState(0);

const closeModal = () => setShowModal(false);
  

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${selectedSchool}`);
      setSchools(response.data);
    //   console.log('Schools data:', response.data);
      } catch (error) {
      console.log('Error fetching schools:', error)
      .finally(() => setisLoading(false));
      }
    };
  fetchSchoolData();
  }, [ selectedSchool]);


  useEffect(() => {
    const fetchProjectData = async () => {
        try {
        const response = await axios.get(`https://myghschool.myghschools.com/donation/schools/${selectedSchool}/projects/${id}`);
        setDonations(response.data);
        } catch (error) {
        }
      };
        fetchProjectData();
    }, [id, selectedSchool]);
    

 
  useEffect(() => {
    const fetchDonationStatData = async () => {
        try {
            const response = await axios.get(`https://myghschool.myghschools.com/donation/donation_stats/?school_id=${selectedSchool}&project_id=${id}`)
            .then(response => {
                setDonationStats(response.data);
                setisLoading(false);
             })
        } catch (error) {
        setisLoading(false);
        }
      };
        fetchDonationStatData();
    }, [id, selectedSchool]);


  useEffect(() => {
    const fetchContactData = async () => {
        try {
        const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
        setContact(response.data);
        } catch (error) {
        }
      };
    fetchContactData();
    }, [selectedSchool]);


  if (isLoading) {
    return <h2>Loading...</h2>
  }

  return (
    <React.Fragment>


<div className="inner-page">

<SchoolHeader/>
   
    <div className="rs-breadcrumbs breadcrumbs-overlay" style={{ backgroundImage: `url(${schools && schools?.banner})` , backgroundSize: '100%'}}>
		    <div className="breadcrumbs-inner">
		        <div className="container">
		            <div className="row">
		                <div className="col-md-12 text-center">
		                    <h1 className="page-title pt-5">DONATION DETAILS</h1>
		                    <ul>
		                        <li>
                            <Link to={`/schoolhome/${selectedSchool}`} className="active">Home</Link>
		                        </li>
		                        <li>{donations && donations?.title}</li>
		                    </ul>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>

        <div className="rs-event-details pt-100 pb-6">
            <div className=" card container pt-5">
            <button type="button" className="btn btn-success sm col-6" onClick={() => setShowExpModal(true)}>Donate Now</button><br/>
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="event-details-content">
                            <h3 className="event-title"><span style={{fontSize:"0.8em", color:'red'}}>Title: </span>{donations && donations?.title}</h3>
                            <div className="event-meta" style={{fontSize:"1.2em"}}>
                                <div className="event-date">
                                    <i className="fa fa-credit-card"></i>
                                    <span style={{fontSize:"1em", color:'red'}}>Amount Required: </span>Ghc {donations && donations?.amount_requested}
                                </div><br/>
                                <div className="event-location">
                                    <i className="fa fa-hand-holding-usd"></i>
                                    <span style={{fontSize:"1em", color:'red'}}>Beneficiary: </span>{donations && donations?.beneficiary}
                                </div>
                            </div>
                            <div className="event-img">                              
                                <img src={donations && donations?.image1} alt="Event Details Images" style={{width:500, height: 500}}/>
                            </div>
                            <div className="event-desc">
                            <h3 >Donation Details</h3>
                            <h4 ><span style={{fontSize:"0.8em", color:'red'}}>Supervisor: </span>{donations && donations?.collected_by}</h4>
                            <h4 ><span style={{fontSize:"0.8em", color:'red'}}>Position: </span>{donations && donations?.position}</h4>
                            <h4>Donation Description</h4>
                               <span style={{fontSize:'1.2em'}}>{donations && parse(donations?.description)} </span>
                            </div>   
                            <h4 className="text-center text-red">Images of {donations && donations?.title} donation</h4>
                            <div className="row pt-4">
                                <div className="col-lg-6 col-md-12">
                                <img src={donations && donations?.image1} style={{width:500, height: 400}} alt="donation image1"/>
                                </div>
                                <div className="col-xl-6 col-md-4">
                                <img src={donations && donations?.image2} style={{width:500, height: 400}} alt="donation image2"/>
                                </div>
                            </div>                     
                            <div className="row pt-4">
                                <div className="col-lg-6 col-md-12">
                                <img src={donations && donations?.image3} style={{width:500, height: 400}} alt="donation image3"/>
                                </div>
                            </div>                     
                        </div>
                        <button type="button" className="btn btn-success sm col-6" onClick={() => setShowExpModal(true)}>Donate Now</button><br/><br/><br/>
                    </div>
                    
                    <div className="col-lg-4 col-md-4">
                        <div className="sidebar-area">                    
                            <div className="latest-courses">
                                <h3 className="title">Donated So Far</h3>
                            
                                    <p>Number of Donations: {donationstats?.donation_count}</p>
                                    <p>Total Donation Amount: {donationstats?.total_donation_amount}</p>
                                    <p>Target Amount: {donationstats?.target_amount}</p>
                                    <p>Status: {donationstats?.status}</p>
                         
                            </div>
                           
                        </div>
                    </div>

                    <Modal show={showExpModal} onHide={() => setShowExpModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Donate for {donations?.title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={handleExpressPaySubmit}>
                                    <div className="form-group">
                                        <label htmlFor="lastname"  className="col-form-label">Last Name</label>
                                        <input type="text" id="lastname"  className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Please leave empty to remain ananymous"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="lastname"  className="col-form-label">First Name</label>
                                        <input type="text" id="firstname"  className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Please leave empty to remain ananymous"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email" className="col-form-label">Email</label>
                                        <input type="email" id="email" className="form-control"  value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Please leave empty to remain ananymous" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phonenumber" className="col-form-label">Phone Number<span style={{color:'red'}}>*</span></label>
                                        <input type="text" id="phonenumber" className="form-control" required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="required phone number"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="username" className="col-form-label">Username</label>
                                        <input type="text" id="username" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Please enter your username"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="amount" className="col-form-label">Amount <span style={{color:'red'}}>*</span></label>
                                        <input type="number" id="amount" className="form-control" required value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Please enter amount you wish to donate"/><br/>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit Donation</button>
                                </form>
                                <h4 className='text-red'>Our payment is process by <Link to="https://hubtel.com/">Hubtel</Link></h4>
                            </Modal.Body>
                        </Modal>
                      <Modal size="lg" show={showModal} onHide={closeModal} className="col-lg-12">
                    <Modal.Header closeButton >
                        <Modal.Title>Event Gallery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="card-body pb-1">
                        <Carousel activeIndex={carouselImageIndex} onSelect={setCarouselImageIndex}>
                        </Carousel>
                    </Modal.Body>
                   </Modal>
                </div>
            </div>
        </div>
      
        <div id="rs-branches" className="rs-branches sec-color pt-100 pb-70">
            <div className="container">
                <div className="sec-title mb-70 text-center">
                <h2>Ads Space</h2>      
                	<p>Consider advertising with us </p>
                </div>
                           
            </div>
        </div>

            
   <footer id="rs-footer" className="bg3 rs-footer mt-0">
 <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 &&  contact[0]?.address}<br/>
 								{schools && schools?.town}<br/>
 								{schools && schools?.district}<br/>
 								{schools && schools?.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact.length > 0 &&  contact[0]?.gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.phone_number}<br/>
									{contact && contact.length > 0 &&  contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 &&  contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 && contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact.length > 0 &&  contact[0]?.facebook}<br/>
									{contact && contact.length > 0 &&  contact[0]?.instagram}<br/>
									{contact && contact.length > 0 &&  contact[0]?.website}<br/>
									{contact && contact.length > 0 &&  contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>

        <div id="scrollUp">
            <i className="fa fa-angle-up"></i>
        </div>
        

        <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
        	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true" className="fa fa-close"></span>
	        </button>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="search-block clearfix">
                        <form>
                            <div className="form-group">
                                <input className="form-control" placeholder="eg: Computer Technology" type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        

</div>
    </React.Fragment>
  )
}

export default ViewDonation


