import React, {useState, useContext, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import {SchoolContext} from '../../../AuthContext/SchoolProvider';
import SchoolHeader from './SchoolHeader';
import parse from 'html-react-parser';

const baseUrl='https://myghschool.myghschools.com/school';

const CourseSingle = () => {

    useEffect(()=>{
		document.title = "MGS | Course";
	});
   

    const { id } = useParams();
    const [course, setCourse] = useState(null);
    const [allcourse, setAllCourse] = useState(null);   
    const [schools, setSchools] = useState(null);
    const [contact, setContact] = useState(null);
    const { selectedSchool } = useContext(SchoolContext);

    
  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/schools/${selectedSchool}`);
      setSchools(response.data);
      } catch (error) {
      }
    };


  const fetchCourseData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/courses/${id}`);
    setCourse(response.data);
    } catch (error) {
    }
  };

  const fetchAllCourseData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/courses/`);
    setAllCourse(response.data);
    } catch (error) {
    }
  };


  const fetchContactData = async () => {
    try {
    const response = await axios.get(`${baseUrl}/schools/${selectedSchool}/contacts`);
    setContact(response.data);
    } catch (error) {
    }
  };

  fetchCourseData();
  fetchAllCourseData();
  fetchSchoolData();
  fetchContactData();
  }, [id, selectedSchool]);

  return (
    <React.Fragment>

    <div className="inner-page">

		<div className="full-width-header">
        <SchoolHeader /> 


		<div className="rs-breadcrumbs breadcrumbs-overlay" style={{ backgroundImage: `url(${schools && schools?.banner})` }}>
		    <div className="breadcrumbs-inner">
		        <div className="container">
		            <div className="row">
		                <div className="col-md-12 text-center">
		                    <h1 className="page-title pt-5">{course && course?.name}</h1>
		                    <ul>
		                        <li>
                                <Link to={`/schoolhome/${selectedSchool}`}>Home</Link>
		                        </li>
		                        <li>{course && course.name}</li>
		                    </ul>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>

        <div className="rs-courses-details pt-100 pb-70">
            <div className="abt-title text-center">
            <h2 >Course Details</h2>
            </div>
            <div className="card container pt-5 pb-5">
                <div className="row mb-30">
                    <div className="col-lg-8 col-md-12">
                	    <div className="detail-img">
                	        <img src={course && course?.image} alt="Courses Images" style={{height:"80vh"}} />
                	        {/* <div className="course-seats">
                	        	170 <span>SEATS</span>
                	        </div> */}
                	    </div>
                	    <div className="course-content">
                	    	<h3 className="course-title text-red"> <span style={{fontSize:'0.8em', color:'black'}}>Course Title:</span> {course && course?.name}</h3>
                	    	<div className="course-instructor">
                	    		<div className="row">
                	    			<div className="col-md-6">
                	    				<h3 className="instructor-title">BASIC <span className="primary-color">INFORMATION</span></h3>
                	    				<div className="row info-list">
                	    					<div className="col-md-6">
                	    						<ul>
                	    					
                	    							<li>
                	    								<span>Subjects :</span> {course && course?.subjects.length}
                	    							</li>
                	    							<li>
                	    								<span>Duration :</span> {course && course?.duration}
                	    							</li>
                	    							<li>
                	    								<span>Level :</span> {schools && schools?.school_level}
                	    							</li>
                	    						</ul>
                	    					</div>
                	    		
                	    				</div>
                	    			</div>
                	    		</div>
                	    	</div>
                	    </div>
                	    <div className="course-desc">
                	    	<h3 className="desc-title">Course Description</h3>
                	    	<div className=" card desc-text m-2 p-3">
                	                <span style={{fontSize:'1.3em'}}>{course && parse(course?.description)}</span>
                	    	</div>
                            <div className="course-syllabus">
                                <h3 className="desc-title">Subjects Under Course</h3>
                                <div id="accordion" className="rs-accordion-style1">
                                    <div className="row">
                                    {course && course?.subjects.map((subject, i) => (

                                        <div className="card" key={subject.id}>
                                        <div className="card-header" id={`heading${subject.id}`}>
                                            <h3 className="acdn-title" data-bs-toggle="collapse" data-bs-target={`#collapse${subject.id}`}>
                                                <strong>Subject : </strong>
                                                <span>{subject?.name}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </h3>
                                        </div>
                                        <div id={`collapse${subject.id}`} className="collapse show" aria-labelledby={`heading${subject.id}`}data-bs-parent="#accordion">
                                            <div className="card-body">
                                              <Link to={`/subjectpage/${subject.id}`}> <span style={{fontSize:'1.3em'}}>{parse(subject?.description)} </span> </Link>
                                            </div>
                                        </div>
                                        </div>

                                    ))}
                                 
                                 </div>
                                </div>
                            </div>
      

                	    </div>
                    </div>
                    <div className="card col-lg-4 col-md-12">
                        <div className="sidebar-area">
                          
                            <div className="cate-box">
                                <h3 className="title">Subjects Under This Course</h3>
                                <ul>
                                    <div className="row">
                                    {course && course?.subjects.map((subject, i) => (
                                    <li  key={subject.id}>
                                        <div className=''>
                                       <Link to={`/subjectpage/${subject.id}`}> <i className="fa fa-angle-right"  aria-hidden="true">{subject.name}</i> </Link>
                                        </div>
                                    </li>
                                    ))}
                                   </div>
                                </ul>
                            </div>
                            <div className="latest-courses">
                                <h3 className="title">Other Courses</h3>
                                <div className="row">
                                    {allcourse && allcourse.map((allcourse, index) =>(

                                    <div className="post-item" key={allcourse.id}>
                                        <div className="post-img">
                                            <Link to={`/coursesingle/${allcourse.id}`}><img src={allcourse?.image} alt="" title="News image"/></Link>
                                        </div>
                                        <div className="post-desc">
                                            <h4><Link to={`/coursesingle/${allcourse.id}`}>{allcourse?.name}</Link></h4>
                                            <span className="duration"> 
                                                <i className="fa fa-clock-o" aria-hidden="true"></i> {allcourse?.duration}
                                            </span> 
                                            <span className="price">Level: <span>{allcourse.level}</span></span>
                                        </div>
                                    </div>


                                    ))}
                                
                                    </div>
	                       
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
    
            <div className="container pt-100">    

             
            </div>
        </div>
     
    
        <footer id="rs-footer" className="bg3 rs-footer mt-0">
 <div className="container">

     <div>
 					<div className="row footer-contact-desc">
 						<div className="col-md-3">
 							<div className="contact-inner">
 								<i className="fa fa-map-marker"></i>
 								<h4 className="contact-title">Address</h4>
 								<p className="contact-desc">
 								{contact && contact.length > 0 && contact[0]?.address}<br/>
 								{schools && schools?.town}<br/>
 								{schools && schools?.district}<br/>
 								{schools && schools?.region}<br/>
 								<span style={{color:'red'}}>GPS Location</span> <br/>
 								{contact && contact?.length > 0 &&  contact[0]?.gps_address}<br/>	
 								</p>
 							</div>
 						</div>
						 <div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-phone"></i>
								<h4 className="contact-title">Phone Number</h4>
								<p className="contact-desc">
									{contact && contact?.length > 0 &&  contact[0]?.phone_number}<br/>
									{contact && contact?.length > 0 &&  contact[0]?.phone_number2}<br/>
									<span style={{color:'red'}}>Whatsapp Number</span><br/>
									{contact && contact.length > 0 &&  contact[0]?.whatsapp}<br/>
								
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Email Address</h4>
								<p className="contact-desc">
									{contact && contact?.length > 0 &&  contact[0]?.email}<br/>
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="contact-inner">
								<i className="fa fa-map-marker"></i>
								<h4 className="contact-title">Social Media</h4>
								<p className="contact-desc">
									{contact && contact?.length > 0 &&  contact[0]?.facebook}<br/>
									{contact && contact?.length > 0 &&  contact[0]?.instagram}<br/>
									{contact && contact?.length > 0 && contact[0]?.website}<br/>
									{contact && contact?.length > 0 &&  contact[0]?.youtube}<br/>
								</p>
							</div>
						</div>
					</div>				

        </div>

      </div>
      <div className="footer-top bg-black">
          <div className="container">
            
              <div className="footer-share ">
                <br/><br/><br/>
              </div>                                
          </div>
      </div>


      <div className="footer-bottom">
        <div className="container">
            <div className="copyright text-black">
                <p>©2023 <Link to="/"> My Gh School </Link>. All Rights Reserved.</p>
            </div>
        </div>
    </div>
  </footer>

        <div id="scrollUp">
            <i className="fa fa-angle-up"></i>
        </div>
        

        <div aria-hidden="true" className="modal fade search-modal" role="dialog" tabIndex="-1">
        	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true" className="fa fa-close"></span>
	        </button>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="search-block clearfix">
                        <form>
                            <div className="form-group">
                                <input className="form-control" placeholder="eg: Computer Technology" type="text" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
   
    </div>
    </div>
    </React.Fragment>
  )
}

export default CourseSingle