import axios from 'axios';

const BASE_URL="https://myghschool.myghschools.com//"
// const BASE_URL="http://localhost:8000/"

export default axios.create({
    baseURL: BASE_URL,
    });
    
export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
});

export const axiosPrivateInstance = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
});

