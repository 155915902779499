
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from "../../../AuthContext/SchoolProvider";
import useAuth from "../../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddCourse() { 

  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { selectedSchool } = useContext(SchoolContext);
  const [backendErrors, setBackendErrors] = useState(null);
  const [setResponsess] =useState('');
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: null,
    duration: "",
    duration_type: "",
    level: "",
    subjects: [],
    school: selectedSchool,  // there's a school field in the course model
  });

  const goBack = () => {  
    navigate(-1);
  };
  
  const [setError] = useState(null);
  

  useEffect(() => {
    if(selectedSchool){
      const token = accessToken
    axios.get(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/subjects/`,{
        headers: {
        
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => {
        
      }
        )
      .catch(error => console.error(error));
    }
  }, [selectedSchool, accessToken]);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
        if (name === "image") {
          setFormData({ ...formData, [name]: e.target.files[0] });
        } else {
          setFormData({ ...formData, [name]: value });
       }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("description", formData.description);
    formDataObj.append("duration", formData.duration);
    formDataObj.append("duration_type", formData.duration_type);
    formDataObj.append("level", formData.level);
    formDataObj.append("image", formData.image);
    formDataObj.append("school", formData.school); //school field

    try {
      const token = accessToken
      const response = await axios.post(`https://myghschool.myghschools.com/school/schools/${selectedSchool}/courses/`, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      });
      setResponsess(response)
      Swal.fire({
        icon: "success",
        title: "School Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      goBack();
    } catch (err) {
      if (err) {
      setBackendErrors(err)
      setError("Something went wrong. Please try again later.");
    }
  }
  };


  return (
    <Container>
      <Row>
        <Col>
        <div className="card p-5">
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Add Course</h3>
          </div>
          <button onClick={goBack} className="back-button">Back to Courses</button><br/><br/>
          <form onSubmit={handleSubmit}>
            {/* Other fields... */}
            <div className="col-lg-6">
            <div className="form-group">
              <label className="mb-1 "><strong>Name</strong></label>
              {backendErrors?.response?.data?.name && backendErrors?.response?.data?.name=== "This field may not be blank."? <div className="text-red " style={{fontWeight:'bold'}}> Name of donation required</div>: null}
               <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-control"  placeholder="Name of the Course" required/>
            </div>
            </div><br></br>
            <div className="col-lg-6">
            <div className="form-group">
              <label className="mb-1 "><strong>Level</strong></label>
               <input type="text" id="level" name="level" value={formData.level} onChange={handleChange} className="form-control"  placeholder="specific class or students" />
            </div>
            </div><br></br>
            <div className="col-lg-6">
            <div className="row">
            <div className="form-group"><br/>
              <label className="mb-1 " for="duration"><strong>Duration</strong></label>
               <input type="Number" id="duration" name="duration" value={formData.duration} onChange={handleChange} className="form-control"  placeholder="Duration of course" />
            </div><br></br> 
            <div className="form-group"><br/>
              <label className="mb-1 " for="duration_type"><strong>Duration Type</strong></label>
              <select id="duration_type" name="duration_type" value={formData.duration_type} onChange={handleChange} className="form-select mb-3 p-3">
                    <option disabled>Choose...Years, Months, Weeks, Days </option> 
                    <option value="Year">Year(s)</option>
                    <option value="Month">Month(s)</option>
                    <option value="Week">Week(s)</option>
                    <option value="Day">Day(s)</option>
                 
                  </select>
            </div><br></br>
            </div>
            </div>
            <div className="col-lg-6">
            <div className="form-group">
               <label className="mb-1 "><strong>Description</strong></label>
               <ReactQuill theme="snow" value={formData.description} onChange={(content) => setFormData({ ...formData, description: content })} />
             </div></div><br></br>
      
                <label className="btn btn-success col-lg-6 mb-2 " >
                {formData.image ? formData.image.name : "Upload Course Image"}
                  <input type="file" id="image" name="image" onChange={handleChange} className="d-none" />
                </label><br/>
                {backendErrors?.response?.data?.image && backendErrors?.response?.data?.image=== "The submitted data was not a file. Check the encoding type on the form."? <div className="text-red " style={{fontWeight:'bold'}}>Please upload course image</div>: null}
              {formData.image && (
                typeof formData.image === 'string'
                ? <img src={formData.image} alt="Existing" style={{ width: '200px', height: '200px' }} />
                : <img src={URL.createObjectURL(formData.image)} alt="Uploaded" style={{ width: '100px', height: '100px' }} />
                  )}<br/>
            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddCourse;


